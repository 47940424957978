import React, { useState, useRef } from "react";
import {
  Box,
  Stack,
  Typography,
  Snackbar,
  Alert,
  Grow,
  TextField,
  Autocomplete,
  IconButton,
  InputBase,
  Button,
} from "@mui/material";
import { useReferentialContext } from "../../Context";
import { Champion, Scenario } from "../../farsight-api";
import { NeonCard, NeonCardHeader } from "../../NeonComponents/NeonCards";
import { styled } from "@mui/material/styles";
import TeamIcon from "../Icons/TeamIcon";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const getSlotColor = (slot: string, team: "team1" | "team2"): string => {
  const fullSlot = `${team}${slot}`;
  const slotColors: { [key: string]: string } = {
    team1ban1: "#340C0C", team2ban1: "#340C0C",
    team1ban2: "#340C0C", team2ban2: "#340C0C",
    team1ban3: "#340C0C", team2ban3: "#340C0C",
    team1pick1: "#1C2633", team2pick1: "#223E11",
    team1pick2: "#223E11", team2pick2: "#223E11",
    team1pick3: "#223E11", team2pick3: "#5A4D5A",
    team1ban4: "#340C0C", team2ban4: "#340C0C",
    team1ban5: "#340C0C", team2ban5: "#340C0C",
    team1pick4: "#5A4D5A", team2pick4: "#5A4D5A",
    team1pick5: "#5A4D5A", team2pick5: "#1C2633",
  };
  return slotColors[fullSlot] || "#111";
};

const DraftTurn = styled(Box)<{ bgcolor: string }>(({ bgcolor }) => ({
  backgroundColor: bgcolor,
  borderRadius: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
  width: "50%",
  height: "40px",
  color: "white",
  fontSize: "0.75rem",
  boxSizing: "border-box",
})) as typeof Box;

interface Props {
  scenario: Scenario;
  onUpdate: (updated: Scenario) => void;
  onDelete?: (deletedId: number) => void;
  onCopy?: (copied: Scenario) => void;
}

const EditableDraftScenario: React.FC<Props> = ({ scenario, onUpdate, onDelete, onCopy }) => {
  const { farsightApi, championsReferential } = useReferentialContext();
  const [error, setError] = useState<string | null>(null);
  const [editingSlot, setEditingSlot] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [editingTitle, setEditingTitle] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);
  const [titleInput, setTitleInput] = useState(scenario.name);
  const [categoryInput, setCategoryInput] = useState(scenario.scenario_category);
  const gridRef = useRef(null);

  const selectedChampions = Object.values(scenario).filter(Boolean);

  const handleInlineSave = async (slotKey: keyof Scenario, championId: string | null) => {
    try {
      const updated = await farsightApi.draftScenarios.patch({
        id: scenario.id.toString(),
        patched_scenario: {
          [slotKey]: championId,
        },
      });
      onUpdate(updated);
    } catch (err) {
      setError("Failed to update draft slot.");
    }
  };

  const handleMetaSave = async (field: keyof Scenario, value: string) => {
    try {
      const updated = await farsightApi.draftScenarios.patch({
        id: scenario.id.toString(),
        patched_scenario: {
          [field]: value,
        },
      });
      onUpdate(updated);
    } catch {
      setError("Failed to update scenario metadata");
    }
  };

  const handleSwitchSides = async () => {
    const suffixes = ["ban1", "ban2", "ban3", "pick1", "pick2", "pick3", "ban4", "ban5", "pick4", "pick5"];
    const patched: any = {};
    for (const suffix of suffixes) {
      const key1 = `team1${suffix}` as keyof Scenario;
      const key2 = `team2${suffix}` as keyof Scenario;
      const t1 = scenario[key1];
      const t2 = scenario[key2];
      patched[key1] = t2;
      patched[key2] = t1;
    }
    const updated = await farsightApi.draftScenarios.patch({
      id: scenario.id.toString(),
      patched_scenario: patched,
    });
    onUpdate(updated);
  };

  const handleDelete = async () => {
    try {
      await farsightApi.draftScenarios.delete({ id: scenario.id.toString() });
      onDelete?.(scenario.id);
    } catch {
      setError("Failed to delete scenario");
    }
  };

  const handleCopy = async () => {
    try {
      const allowedKeys = [
        'name', 'game_number', 'fearless_bans', 'team1', 'team2', 'prep', 'scenario_side', 'scenario_category',
        'team1pick1', 'team1pick2', 'team1pick3', 'team1pick4', 'team1pick5',
        'team2pick1','team2pick2','team2pick3', 'team2pick4', 'team2pick5',
        'team1ban1', 'team1ban2', 'team1ban3', 'team1ban4', 'team1ban5',
        'team2ban1', 'team2ban2', 'team2ban3', 'team2ban4', 'team2ban5',
      ];
      const filtered = Object.fromEntries(
        Object.entries(scenario).filter(([key]) => allowedKeys.includes(key))
      );
      const copy = await farsightApi.draftScenarios.create({
        id: "new",
        scenario: {
          ...filtered,
          name: `Copy of ${scenario.name}`,
        } as any,
      });
      onCopy?.(copy);
    } catch {
      setError("Failed to copy scenario");
    }
  };

  return (
    <Grow in={true} style={{ transformOrigin: "0 -50 0" }} timeout={300}>
      <Box ref={gridRef}>
        <NeonCard sx={{ width: "350px", height: "530px", borderRadius: 0, mt: 2 }}>
          <NeonCardHeader
            title={editingTitle ? (
              <TextField
                value={titleInput}
                onChange={(e) => setTitleInput(e.target.value)}
                onBlur={() => {
                  handleMetaSave("name", titleInput);
                  setEditingTitle(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleMetaSave("name", titleInput);
                    setEditingTitle(false);
                  }
                }}
                size="small"
                variant="standard"
                autoFocus
              />
            ) : (
              <Typography variant="h6" onClick={() => setEditingTitle(true)} sx={{ cursor: "pointer" }}>{scenario.name}</Typography>
            )}
            subheader={editingCategory ? (
              <TextField
                value={categoryInput || ""}
                onChange={(e) => setCategoryInput(e.target.value)}
                onBlur={() => {
                  handleMetaSave("scenario_category", categoryInput);
                  setEditingCategory(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleMetaSave("scenario_category", categoryInput);
                    setEditingCategory(false);
                  }
                }}
                size="small"
                variant="standard"
                autoFocus
              />
            ) : (
              <Typography variant="body2" onClick={() => setEditingCategory(true)} sx={{ cursor: "pointer" }}>
                {scenario.scenario_category || "Click to add category"}
              </Typography>
            )}
            action={
              <Box>
                <IconButton onClick={handleCopy}><ContentCopyIcon /></IconButton>
                <IconButton onClick={handleDelete}><DeleteIcon /></IconButton>
              </Box>
            }
          />

          <Stack direction="row" justifyContent="space-evenly" alignItems="space-evenly" sx={{ height: '40px', margin: "10px" }} spacing={1}>
            <TeamIcon team_name={scenario.team1} />
            <IconButton onClick={handleSwitchSides}><SwapHorizIcon /></IconButton>
            <TeamIcon team_name={scenario.team2} />
          </Stack>

          <Stack direction="column" spacing={0} textAlign="center" justifyContent="space-evenly" sx={{ width: "350px" }}>
            {["ban1", "ban2", "ban3", "pick1", "pick2", "pick3", "ban4", "ban5", "pick4", "pick5"].map((suffix, index) => {
              const team1Key = `team1${suffix}` as keyof Scenario;
              const team2Key = `team2${suffix}` as keyof Scenario;
              const renderSlot = (slotKey: keyof Scenario, team: "team1" | "team2", isLeft: boolean) => {
                const champId = scenario[slotKey] as string;
                const champ = championsReferential.find((c) => c.id === champId);
                const iconUrl = champ ? `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_40,w_40/v1/championAssets/${champ.id}.png` : null;
                const bgColor = getSlotColor(suffix, team);
                const availableOptions = championsReferential.filter((c) => !selectedChampions.includes(c.id) || c.id === champId);

                return (
                  <DraftTurn key={slotKey} bgcolor={bgColor}>
                    {isLeft && champId && editingSlot !== slotKey && (
                      <IconButton size="small" onClick={() => handleInlineSave(slotKey, null)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}

                    {!isLeft && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ width: 40, height: 40, display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {iconUrl ? (
                            <Box component="img" src={iconUrl} sx={{ width: 40, height: 40 }} />
                          ) : (
                            <Box sx={{ width: 40, height: 40, backgroundColor: "#000" }} />
                          )}
                        </Box>
                      </Box>
                    )}

                    {editingSlot === slotKey ? (
                      <Autocomplete
                        size="small"
                        options={availableOptions}
                        getOptionLabel={(option) => option.name}
                        value={undefined}
                        onChange={(_, newValue) => {
                          handleInlineSave(slotKey, newValue ? newValue.id : null);
                          setEditingSlot(null);
                        }}
                        inputValue={inputValue}
                        onInputChange={(_, newInput) => setInputValue(newInput)}
                        renderInput={(params) => <TextField {...params} variant="standard" autoFocus onBlur={() => setEditingSlot(null)} />}
                        sx={{ width: "100%" }}
                        disableClearable
                      />
                    ) : (
                      <Box sx={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => setEditingSlot(slotKey)}>
                        {champId ? (
                          <Typography variant="body2">{champ?.name}</Typography>
                        ) : (
                          <ExpandMoreIcon fontSize="small" />
                        )}
                      </Box>
                    )}

                    {isLeft && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ width: 40, height: 40, display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {iconUrl ? (
                            <Box component="img" src={iconUrl} sx={{ width: 40, height: 40 }} />
                          ) : (
                            <Box sx={{ width: 40, height: 40, backgroundColor: "#000" }} />
                          )}
                        </Box>
                      </Box>
                    )}

                    {!isLeft && champId && editingSlot !== slotKey && (
                      <IconButton size="small" onClick={() => handleInlineSave(slotKey, null)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </DraftTurn>
                );
              };

              return (
                <Stack key={index} direction="row" alignItems="center" justifyContent="center" spacing={0}>
                  {renderSlot(team1Key, "team1", true)}
                  {renderSlot(team2Key, "team2", false)}
                </Stack>
              );
            })}
          </Stack>
        </NeonCard>

        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Grow>
  );
};

export default EditableDraftScenario;
