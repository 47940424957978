


export class Formatters {
    percent: (v: number) => string;
    integer: (v: number) => string;
    float1: (v: number) => string;
    float2: (v: number) => string;
    float3: (v: number) => string;
    delta: (v: number) => string;

    constructor() {
        this.percent = (v: number) => `${(v*100).toFixed(1)}%`
        this.integer = (v: number) => `${v.toFixed(0)}`
        this.float1 = (v: number) => `${v.toFixed(1)}`
        this.float2 = (v: number) => `${v.toFixed(2)}`
        this.float3 = (v: number) => `${v.toFixed(3)}`
        this.delta = (v: number) => v < 0 ? `${v.toFixed(1)}` : `+${v.toFixed(1)}`
    }
}

const formatters = new Formatters();
export default formatters;