import {CompetitiveGameSummaries, ScrimGameSummaries} from "../../../farsight-api";
import {CircleMarker, Marker} from "react-leaflet";
import {Icon} from "leaflet";
import React from "react";
import {SIDE_COLORS} from "../../../theme";


export default function PlayerMarker(props: {summary: CompetitiveGameSummaries|ScrimGameSummaries,
    timestamp: number,
    containerSize: number,
    display_sweeper?: boolean,
    draggable?:boolean
}){
    const sweeper_events =  props.summary.sweeper_used_events?.filter(event =>
        event.timestamp < props.timestamp && (event.timestamp + event.duration) > props.timestamp
    );
    const sweeper = sweeper_events && sweeper_events[0];

    // props.summary.position_history as any[];
    return props.summary.position_history ? <Marker
        position={[
            props.summary.position_history[props.timestamp][1] * props.containerSize,
            props.summary.position_history[props.timestamp][0] * props.containerSize
        ]}
        icon={
            new Icon({
                className: props.draggable ? undefined : 'animated-marker',
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                iconUrl: `https://res.cloudinary.com/xenesis/image/upload/c_fill,h_40,w_40,r_max,bo_2px_solid_rgb:${SIDE_COLORS[props.summary.side].replace('#','')}/v1/championAssets/${props.summary.champion}.png`
                })
            }
            draggable={props.draggable}
        >
        {
            props.display_sweeper && sweeper && <CircleMarker className={'animated-marker'}
            center={[props.summary.position_history[props.timestamp][1] * props.containerSize,
            props.summary.position_history[props.timestamp][0] * props.containerSize]}
            radius={sweeper.range * props.containerSize}
            pathOptions={{
                color: SIDE_COLORS[props.summary.side], // Stroke color
                weight: 0,
                fill: true,
                fillColor: SIDE_COLORS[props.summary.side],
                fillOpacity: 0.7,
            }}
            />
        }
    </Marker> : <></>
}