import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useReferentialContext } from "../../../Context";
import { MultipleSelectorGrouped } from "../../Abstract/Multiple";
import { SingleSelectorGrouped } from "../../Abstract/Single";
import {Champion} from "../../../farsight-api";

export function MultipleChampionSelect(props: { values: Champion[]; updateValue: (newValue: Champion[]) => void; label?: string }) {
    const { championsReferential } = useReferentialContext();

    const updateSelection = (newSelection: Champion[]) => {
        props.updateValue(newSelection);
    };

    return (
        <Box sx={{ width: "250px" }}>
            <MultipleSelectorGrouped
                getGroup={(option: Champion) => `${option.tags[0]}`}
                getOptionLabel={(option: Champion) => option.name}
                getOptionShort={(option: Champion) => option.name}
                limitTags={1}
                options={championsReferential}
                selected={props.values}
                updateSelection={updateSelection}
                optionDisabled={(option) => false}
                label={props.label? props.label : "Champions"}
                optionDecoration={(option) => <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${option.id}.png`} sx={{ mr: "8px" }} />}
            />
        </Box>
    );
}

export function SingleChampionSelect(props: { value: Champion | null; updateValue: (newValue: Champion | null) => void; updateContext?: boolean | undefined , label?: string}) {
    const { championsReferential, selectedChampion, setSelectedChampion } = useReferentialContext();

    const updateSelection = (newSelection: Champion | null) => {
        if (props.updateContext) {
            setSelectedChampion(newSelection);
        }
        props.updateValue(newSelection);
    };

    useEffect(() => {
        if (props.updateContext) {
            setSelectedChampion(props.value);
        }
        // setLocalChampion(props.value);
    }, [props.value]);

    return (
        <Box sx={{ width: "250px" }}>
            <SingleSelectorGrouped
                getGroup={(option: Champion) => `${option.tags[0]}`}
                getOptionLabel={(option: Champion) => {
                    return option.name
                }}
                getOptionShort={(option: Champion) => option.name}
                options={championsReferential}
                selected={props.value}
                updateSelection={updateSelection}
                optionDisabled={(option) => false}
                label={props.label ? props.label : "Champion"}
                optionDecoration={(option) => <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${option.id}.png`} sx={{ mr: "8px" }} />}
                inputDecoration={(option) => <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${option.id}.png`} sx={{ mr: "8px" }} />}
            />
        </Box>
    );
}
