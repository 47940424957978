import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import FilterSet from "../../../../components/selectors/Filterset";
import {GameMetrics, League} from "../../../../farsight-api";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import {targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import {NeonTab, NeonTabs} from "../../../../NeonComponents/NeonTabs";
import TabPanel from "../../../../components/tabs_switch/tabs_panel";
import MultipleMetricsSelect from "../../../../components/selectors/Shared/MetricsSelect";

export default function CompetitiveChampionsRatings() {
    const context = useReferentialContext();
    context.setCurrentTarget(targets.competitive);

    const [picktype, setPicktype] = useState(null as null | boolean);
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [leagues, setLeagues] = useState(context.selectedLeagues as League[]);
    const [gameNumbers, setGameNumbers] = useState([] as number[]);
    const [tab, setTab] = useState(0);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().getMetrics(
        ['performance','relative_performance','games','winrate','kda','dpm','gd15','csd15','expected_winrate','exp_winrate','winrate_delta_to_expected','exp_bin_winrate','winrate_delta_to_binary_expected','pickturn','teamwise_pickturn'], targets.competitive));

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <Stack direction={'row'} sx={{alignItems:'space-between'}}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                updateContext={true}
                leagues={leagues}
                setLeagues={setLeagues}
                patches={patches}
                setPatches={setPatches}
                picktype={picktype}
                setPicktype={setPicktype}
                gameNumbers={gameNumbers}
                setGameNumbers={setGameNumbers}
            />
                {tab !== 0 ? <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={targets.competitive}/> : undefined}
            </Stack>
            <NeonTabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                <NeonTab label="Overview"/>
                <NeonTab label="Top" asset={'TOP_LANE'} />
                <NeonTab label="Jungle" asset={'JUNGLE'} />
                <NeonTab label="Mid" asset={'MID_LANE'} />
                <NeonTab label="ADC" asset={'BOT_LANE'} />
                <NeonTab label="Support" asset={'UTILITY'}/>
            </NeonTabs>
            <TabPanel value={tab} index={0}>
                {paramObject.additional_filters ? <OverviewTierlists paramObject={paramObject}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <DetailedTierlist paramObject={paramObject} target={targets.competitive} role={'TOP_LANE'} metrics={metrics}/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <DetailedTierlist paramObject={paramObject} target={targets.competitive} role={'JUNGLE'} metrics={metrics}/>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <DetailedTierlist paramObject={paramObject} target={targets.competitive} role={'MID_LANE'} metrics={metrics}/>
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <DetailedTierlist paramObject={paramObject} target={targets.competitive} role={'BOT_LANE'} metrics={metrics}/>
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <DetailedTierlist paramObject={paramObject} target={targets.competitive} role={'UTILITY'} metrics={metrics}/>
            </TabPanel>
            {/*<OverviewDetailed onChange={setDetailed} value={detailed} />*/}
        </WindowContainer>
    );
}

function OverviewTierlists(props: { paramObject: any }) {
    const api = useReferentialContext().farsightApi.computes;
    const cols = ["champion", "games", "rating", "winrate", "cp_frequency"];

    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "TOP_LANE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "JUNGLE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "MID_LANE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "BOT_LANE",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "UTILITY",
                        target: "competitive",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}
function DetailedTierlist(props: { paramObject: any, target: targets, role: string, metrics: GameMetrics[] }) {
    const api = useReferentialContext().farsightApi.computes;
    const aggregate_api=useReferentialContext().farsightApi.getSummariesApi(targets.competitive);

    const [paramObject, setParamObject] = useState<any>(props.paramObject as any);
    const [ratings, setRatings] = useState([] as any[]);
    const [bansStats, setBanStats] = useState([] as any[]);

    const [metricsData, setMetricsData] = useState([] as any[]);
    const [ratingsDone, setRatingsDone] = useState(false);
    const [metricsDone, setMetricsDone] = useState(false);
    const [bansDone, setBansDone] = useState(false);


    const [joinedData, setJoinedData] = useState([] as any[]);
    const [updateObject, setUpdateObject] = useState({} as any);
    const cols = ["champion","rating","performance_with","relative_performance_with","performance_against","relative_performance_against","bans"];

    useEffect(() => {
        setJoinedData([]);
        setUpdateObject(props.paramObject);

        api.champion_ratings({
            ...props.paramObject,
            role: props.role,
            target: props.target,
        }).then(r => setRatings(r)).then(() => setRatingsDone(true));

        aggregate_api.aggregate({
            ...props.paramObject,
            ...paramObject,
            additional_filters: {...props.paramObject.additional_filters, ...paramObject.additional_filters},
            index_on: 'champion',
            groupby: ['champion'],
            ordering: ['champion'],
            metrics: [props.metrics.map(m => m.code).toString()],
            role: props.role,
        }).then(r => setMetricsData(r)).then(() => setMetricsDone(true));

        aggregate_api.aggregate({
            ...props.paramObject,
            ...paramObject,
            additional_filters: {...props.paramObject.additional_filters, ...paramObject.additional_filters},
            index_on: 'ban',
            groupby: ['ban'],
            ordering: ['ban'],
            metrics: [['games'].toString()],
            role: undefined
        }).then(r => setBanStats(r)).then(() => setBansDone(true));

    }, [props.metrics, props.paramObject.watermark, props.role])

    async function request(){
        return joinedData;
    }

    useEffect(() => {
        if(metricsDone && ratingsDone && bansDone){
            const joined_data = [];
            for(let i in ratings){
                const rating_row = ratings[i];
                const additional_data = metricsData[rating_row.champion][0]
                const ban_row = bansStats[rating_row.champion];
                const joined_object = {...rating_row, ...additional_data, bans: ban_row ? ban_row[0].games : 0}
                joined_data.push(joined_object);
            }
            setMetricsDone(false);
            setRatingsDone(false);
            setBansDone(false);
            setJoinedData(joined_data);
            const update = {
                ...props.paramObject,
                ...paramObject,
                additional_filters: {...props.paramObject, ...paramObject}};
            update.watermark = JSON.stringify(update);
            setUpdateObject(update);
        }
    }, [ratingsDone, metricsDone, bansDone])

    return (
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                justifyContent: "center",
                width: "100%",
                display: "flex",
                alignItems: "top",
            }}
            spacing={3}
        >
            <TableDisplayGrid
                display_all={false}
                request={() => request()}
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={updateObject}
                metrics={props.metrics}
            />
        </Stack>
    );
}
