import React, {useEffect, useState} from "react";
import {Stack, Theme, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import {targets, useReferentialContext} from "../../../../Context";
import {Player, Team} from "../../../../farsight-api";
import dayjs, {Dayjs} from "dayjs";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import DatePicker from "../../../../components/selectors/Shared/DateSelect";
import TabPanel, {a11yProps} from "../../../../components/tabs_switch/tabs_panel";
import TeamHistory from "../../../../components/datagrids/Competitive/TeamHistory";
import {SideSelect} from "../../../../components/selectors/YesNoSelector";
import {DraftsBlock} from "../8_DraftScouting/TeamDrafts";
import {PathingMaps} from "../7_MacroScouting/JunglePathing";
import {Level1Maps} from "../7_MacroScouting/Level1";
import Proximities from "../../../../components/Charts/Proximities";
import MetricRadar from "../../../../components/Charts/ForwardPercentage";
import {NeonTab, NeonTabs} from "../../../../NeonComponents/NeonTabs";
import {NeonCard, NeonCardContent, NeonCardHeader} from "../../../../NeonComponents/NeonCards";
import MapOpeningsBlock from "../7_MacroScouting/MapOpenings";


export default function ScoutingSummary() {
    const context = useReferentialContext();
    const [paramObject, setParamObject] = useState({ordering: '-games'} as any);
    const [team, setTeam] = useState(context.selectedTeam as Team|null);
    const [players, setPlayers] = useState([] as Player[]);
    const role_ordered = {
        'TOP_LANE': 1,
        'JUNGLE': 2,
        'MID_LANE': 3,
        'BOT_LANE': 4,
        'UTILITY': 5,
    }

    const [competitive_from, set_competitive_from] = useState(dayjs().subtract(3, 'month'));
    const [soloq_from, set_soloq_from] = useState(dayjs().subtract(14, 'days'));
    const [side, setSide] = useState(null as string|null);

    const [tab, setTab] = useState(0);
    const [playerTab, setPlayerTab] = useState(0);

    useEffect(() => {
        if(team) context.farsightApi.player.list({current_team: team?.team_name, additional_filters:{role__isnull: false, is_sub__isnull: true}}).then(r => {

            // @ts-ignore
            setPlayers(r.sort((a, b) => role_ordered[a.role] - role_ordered[b.role]))
        });
    }, [team])



    return (
        <WindowContainer direction={"column"} spacing={1}>
            <Stack direction={'row'} sx={{justify:'space-between', align:'center', display:'flex'}}>
                <FilterSet paramObject={paramObject}
                           setParamObject={setParamObject}
                           target={targets.competitive}
                           team={team} setTeam={setTeam}/>
                <DatePicker value={competitive_from} onChange={set_competitive_from} label={"Competitive From"} />
                <DatePicker value={soloq_from} onChange={set_soloq_from} label={"SoloQ From"} />
            </Stack>
            <Stack direction={'row'} sx={{height:'86%',width:'100%'}} spacing={1}>
                <NeonTabs value={tab}  onChange={(event, newValue) => setTab(newValue)} sx={{ height: "90%", width:'6%', minWidth:'6%' }} orientation={'vertical'}>
                    <NeonTab  label="Champions"  />
                    <NeonTab  label="Scrim Champions"  />
                    <NeonTab  label="History"  />
                    <NeonTab  label="Drafts"  />
                    <NeonTab label="Pathings" {...a11yProps(4)} />
                    <NeonTab  label="Level 1s" {...a11yProps(5)} />
                    <NeonTab  label="Proximities" {...a11yProps(6)} />
                    <NeonTab  label="Forward%" {...a11yProps(7)} />
                    <NeonTab  label="Lane Openings" {...a11yProps(8)} />
                </NeonTabs>
                <TabPanel value={tab} index={0}>
                    <NeonTabs value={playerTab} onChange={(event, newValue) => setPlayerTab(newValue)}>
                        {
                            players.map((p, index) => <NeonTab label={p.player} asset={p.role} />)
                        }
                    </NeonTabs>
                    {
                        players.map((p, index) => <TabPanel value={playerTab} index={index}>
                                <PlayerChampionsBlock player={p} competitive_from={competitive_from} soloq_from={soloq_from}/>
                            </TabPanel>
                        )
                    }
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    {team ? <ScrimChampionsBlock team={team}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    {team ?
                            <TeamHistory from={competitive_from.unix()} until={dayjs().unix()} team={team ? team.team_name : ''} target={targets.competitive} />
                         : <Typography>Select a team</Typography>}
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    {team ? <SideSelect value={side} onChange={setSide}/>  : <></>}
                    {team ? <DraftsBlock paramObject={{
                        team1: team.team_name,
                        additional_filters: {date__gte: competitive_from.toISOString()},
                        watermark: `${team.team_name}`,
                    }} target={targets.competitive} side={side}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={4}>
                    {team ? <PathingMaps team={team} from={competitive_from} target={targets.competitive}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={5}>
                    {team ? <Level1Maps team={team} from={competitive_from} target={targets.competitive}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={6}>
                    {team ? <ProximitiesBlock players={players} from={competitive_from} target={targets.competitive}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={7}>
                    {team ? <ForwardPercentageBlock team={team} from={competitive_from} target={targets.competitive}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={8}>
                    {team ? <MapOpeningsBlock target={targets.competitive} from={competitive_from} until={dayjs()} team={team}/> : undefined}
                </TabPanel>
            </Stack>

            {/*</Stack>*/}
            {/*<SectionHeader text={'Stats Evolutions'} vertical={false}/>*/}
            {/*<GoldsGraphs target={targets.competitive}/>*/}
            {/*<SectionHeader text={'Stat shares'} vertical={false}/>*/}
            {/*<Stack direction={'row'} sx={{width:'100%', minHeight:'400px'}} spacing={2}>*/}
            {/*{team ?*/}
            {/*    ['damages__sum','total_golds__sum','total_cs__sum','deaths__sum','time_spent_dead__sum'].map(stat => <StatSharePie target={targets.competitive}*/}
            {/*                       paramObject={{*/}
            {/*                           team1: team.team_name,*/}
            {/*                           additional_filters: {date__gte: competitive_from.toISOString()}*/}
            {/*                       }}*/}
            {/*                       stat={stat}/>)*/}
            {/*    : <></>}*/}
            {/*</Stack>*/}
            {/*<SectionHeader text={'Forward %'} vertical={false}/>*/}
            {/*{team ? <Stack direction={'row'} sx={{minHeight: '650px', height: '650px', width: '100%'}} spacing={2}>*/}
            {/*    <ForwardPercentages paramObjectMain={{*/}
            {/*        team1: team.team_name,*/}
            {/*        additional_filters: {date__gte: competitive_from.toISOString()},*/}
            {/*        watermark: 'undefined'*/}
            {/*    }}*/}
            {/*                        paramObjectReference={{*/}
            {/*                            team2: team.team_name,*/}
            {/*                            additional_filters: {date__gte: competitive_from.toISOString()}*/}
            {/*                        }}*/}
            {/*                        target={targets.competitive} metric={'forward_percentage_pre_15'}/>*/}
            {/*    <ForwardPercentages paramObjectMain={{*/}
            {/*        team1: team.team_name,*/}
            {/*        additional_filters: {date__gte: competitive_from.toISOString()},*/}
            {/*        watermark: 'undefined'*/}
            {/*    }}*/}
            {/*                        paramObjectReference={{*/}
            {/*                            team2: team.team_name,*/}
            {/*                            additional_filters: {date__gte: competitive_from.toISOString()}*/}
            {/*                        }}*/}
            {/*                        target={targets.competitive} metric={'forward_percentage_post_15'}/>*/}
            {/*</Stack> : <></>}*/}

        </WindowContainer>
    );
}

function ScrimChampionsBlock(props: {team: Team}){
    const [blindOrCounter, setBlindOrCounter] = useState(null as boolean | null);
    const [from, setFrom] = useState(dayjs().subtract(6, 'weeks'));

    const [paramObject, setParamObject] = useState({ordering: "-games", team1:props.team.team_name} as any);
    const api = useReferentialContext().farsightApi.getSummariesApi(targets.scrims);

    const default_metrics = ['games','performance','relative_performance','winrate'];
    const [metrics, setMetrics] = useState(useReferentialContext().metricsReferential.filter(m => default_metrics.includes(m.code)));

    const request_parameters = {
        ...paramObject,
        groupby: 'champion',
        metrics: [default_metrics.toString()],
        ordering: '-games',
        team1: props.team.team_name
    }

    return <Stack direction={'column'} sx={{width:'100%', height:'100%'}} spacing={3}>
        <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.scrims} date__gte={from} setDateGte={setFrom} picktype={blindOrCounter} setPicktype={setBlindOrCounter}/>
        <Stack direction={'row'} sx={{width:'100%', height:'90%'}} spacing={1}>
            {
                ['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'].map(r => <TableDisplayGrid
                display_all={false}
                request={() => api.aggregate({...request_parameters, role: r})}
                rowStyle={rowStyle.none}
                disp_columns={['champion']}
                sortBy={"games"}
                paramObject={request_parameters}
                metrics={metrics}
            />)
            }

        </Stack>
    </Stack>
}


function PlayerChampionsBlock(props: {player: Player, competitive_from: Dayjs, soloq_from: Dayjs}){
    const {competitive_from, soloq_from} = props;
    const [paramObject, setParamObject] = useState({ordering: '-games'} as any);
    // const [competitive_from, set_competitive_from] = useState(dayjs().subtract(3, 'month'));
    // const [soloq_from, set_soloq_from] = useState(dayjs().subtract(14, 'days'));
    const [blindOrCounter, setBlindOrCounter] = useState(null as boolean | null);
    const competitive_api = useReferentialContext().farsightApi.getSummariesApi(targets.competitive);
    const soloq_api = useReferentialContext().farsightApi.getSummariesApi(targets.soloq);
    const context = useReferentialContext();
    const default_metrics = ['games','performance','relative_performance','winrate'];
    const [meta_champions, set_meta_champions] = useState([] as any[]);

    const [request_parameters_1, set_request_parameters_1] = useState({
        player: props.player.player,
        groupby: 'champion',
        metrics: ['games','performance','relative_performance','winrate','matchups','gd15'].toString(),
        ordering: "-games",
        format: "json",
        additional_filters: {date__gte: competitive_from.toISOString()},
        watermark: `${competitive_from.toISOString()}${props.player.player}`
    } as any);
    const [request_parameters_2, set_request_parameters_2] = useState({
        competitive_player: props.player.player,
        groupby: 'champion',
        role: props.player.role,
        metrics: ['games','performance','relative_performance','winrate','matchups','gd15'].toString(),
        ordering: "-games",
        format: "json",
        additional_filters: {date__gte: soloq_from.toISOString()},
        watermark: `${soloq_from.toISOString()}${props.player.player}`
    }  as any);
    const [metrics, setMetrics] = useState(context.getMetrics(['games','performance','relative_performance','winrate','gd15'], targets.competitive));

    useEffect(() => {
        let params = {
            player: props.player.player,
            groupby: 'champion',
            metrics: ['games','performance','relative_performance','winrate','matchups','gd15'].toString(),
            ordering: "-games",
            format: "json",
            additional_filters: {date__gte: competitive_from.toISOString()},
            watermark: `${competitive_from.toISOString()}${props.player.player}`
        } as any;
        if (blindOrCounter !== null && blindOrCounter !== undefined) {
            params.additional_filters.is_cp = blindOrCounter ? 1 : 0;
            params.watermark += `is_cp${blindOrCounter}`
        };
        set_request_parameters_1(params);
    }, [props.player, competitive_from, blindOrCounter]);

    useEffect(() => {
        set_request_parameters_2({
            competitive_player: props.player.player,
            groupby: 'champion',
            role: props.player.role,
            metrics: ['games','performance','relative_performance','winrate','matchups','gd15'].toString(),
            ordering: "-games",
            format: "json",
            additional_filters: {date__gte: soloq_from.toISOString()},
            watermark: `${soloq_from.toISOString()}${props.player.player}`
        });
    }, [props.player, soloq_from])

    useEffect(() => {
        competitive_api.aggregate(
            {
                additional_filters: {
                    date__gte: competitive_from.toISOString(),
                    league__in: ['LEC,LCK,LPL,LCS,LFL,PCS']
                },
                role: props.player.role,
                ordering: "-games",
                format: "json",
                groupby: 'champion',
                metrics: ['games','performance','relative_performance','winrate','gd15'].toString(),
            } as any
        ).then(r => set_meta_champions(r))
    }, [props.player.role])

    return <Stack sx={{width:'100%', height:'100%', padding:1}} direction={'row'} spacing={1}>
                <NeonCard  sx={{width:'33%', height:'100%'}}>
                    <NeonCardHeader title={'Competitive'}/>
                    <NeonCardContent sx={{width:'100%', height:'89%', flexDirection:'column', padding:0}}>
                        <Stack direction={'column'} sx={{width:'97%', height:'100%'}} spacing={1}>
                            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive} picktype={blindOrCounter} setPicktype={setBlindOrCounter}/>
                            <TableDisplayGrid
                                display_all={false}
                                request={() => competitive_api.aggregate(request_parameters_1)}
                                rowStyle={rowStyle.none}
                                disp_columns={blindOrCounter ? ['champion','matchups'] : ['champion']}
                                sortBy={"games"}
                                paramObject={request_parameters_1}
                                metrics={metrics}
                            />
                        </Stack>
                    </NeonCardContent>
                </NeonCard>

                <NeonCard  sx={{width:'33%', height:'100%'}}>
                    <NeonCardHeader title={'SoloQ Champions'}/>
                    <NeonCardContent sx={{width:'100%', height:'89%', flexDirection:'column', padding:0}}>
                        <Stack direction={'column'} sx={{width:'97%', height:'100%'}} spacing={1}>
                        <TableDisplayGrid
                            display_all={false}
                            request={() => soloq_api.aggregate(request_parameters_2)}
                            rowStyle={rowStyle.none}
                            disp_columns={['champion']}
                            sortBy={"games"}
                            paramObject={request_parameters_2}
                            metrics={metrics}
                        />
                        </Stack>
                    </NeonCardContent>
                </NeonCard>

                <NeonCard  sx={{width:'33%', height:'100%'}}>
                    <NeonCardHeader title={'Comfort on Meta'}/>
                    <NeonCardContent sx={{width:'100%', height:'89%', flexDirection:'column', padding:0}}>
                        <MetaComfort competitive_request_parameters={request_parameters_1} soloq_request_parameters={request_parameters_2} meta_champions={meta_champions}/>
                    </NeonCardContent>
                </NeonCard>
    </Stack>
}

function MetaComfort(props: {competitive_request_parameters: any, soloq_request_parameters: any, meta_champions: any[]}){
    const context = useReferentialContext();
    const competitive_api = useReferentialContext().farsightApi.getSummariesApi(targets.competitive);
    const soloq_api = useReferentialContext().farsightApi.getSummariesApi(targets.soloq);
    const [competitiveData, setCompetitiveData] = useState([] as any[]);
    const [soloqData, setSoloqData] = useState([] as any[]);
    const [competitiveDone, setCompetitiveDone] = useState(false);
    const [soloqDone, setSoloqDone] = useState(false);
    const [joinedData, setJoinedData] = useState([] as any[]);
    const [updateObject, setUpdateObject] = useState({} as any);

    useEffect(() => {
        competitive_api.aggregate({
            ...props.competitive_request_parameters,
            index_on: ['champion'],
        }).then(r => setCompetitiveData(r)).then(() => setCompetitiveDone(true));
        soloq_api.aggregate({
            ...props.soloq_request_parameters,
            index_on: ['champion'],
        }).then(r => setSoloqData(r)).then(() => setSoloqDone(true));

    }, [props.competitive_request_parameters.watermark, props.soloq_request_parameters.watermark])

    useEffect(() => {
        if(soloqDone && competitiveDone && props.meta_champions.length > 0){
            const joined_data = [];
            for(let i in props.meta_champions){
                const rating_row = props.meta_champions[i];
                const competitive_data_row = competitiveData[rating_row.champion]
                const soloq_data_row = soloqData[rating_row.champion]
                const joined_object = {
                    champion: rating_row.champion,
                    in_meta_games: rating_row.games,

                    competitive_games: competitive_data_row? competitive_data_row[0].games : undefined,
                    competitive_winrate: competitive_data_row? competitive_data_row[0].winrate : undefined,
                    competitive_performance: competitive_data_row? competitive_data_row[0].performance : null,
                    competitive_relative_performance: competitive_data_row? competitive_data_row[0].relative_performance : null,

                    soloq_games: soloq_data_row? soloq_data_row[0].games : undefined,
                    soloq_winrate: soloq_data_row? soloq_data_row[0].winrate : undefined,
                    soloq_performance: soloq_data_row? soloq_data_row[0].performance : null,
                    soloq_relative_performance: soloq_data_row? soloq_data_row[0].relative_performance : null,
                }
                joined_data.push(joined_object);
            }
            setSoloqDone(false);
            setCompetitiveDone(false);
            setJoinedData(joined_data);

            const update = {watermark: JSON.stringify(joined_data)};
            setUpdateObject(update);
        }
    }, [soloqDone, competitiveDone, props.meta_champions])

    async function request(){
        return joinedData;
    }

    const columnGroupingModel= [
        {
            groupId: "Competitive",
            children: [{ field: "competitive_games" }, { field: "competitive_winrate" }, { field: "competitive_performance" }, { field: "competitive_relative_performance" }],
            headerClassName: "header",
        },
        {
            groupId: "SoloQ",
            children: [{ field: "soloq_games" }, { field: "soloq_winrate" }, { field: "soloq_performance" }, { field: "soloq_relative_performance" }],
            headerClassName: "header"
        }
    ]

    return <Box sx={{width:'100%', height:'100%'}}>
        <TableDisplayGrid
            display_all={false}
            request={() => request()}
            rowStyle={rowStyle.none}
            disp_columns={['champion', 'in_meta_games','competitive_games','competitive_winrate','competitive_performance','competitive_relative_performance','soloq_games','soloq_winrate','soloq_performance','soloq_relative_performance']}
            sortBy={"in_meta_games"}
            paramObject={updateObject}
            metrics={[]}
            grouping_model={columnGroupingModel}
        />
    </Box>
}

const sectionHeaderStyle = (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(2),
    borderRadius: 2,
    border: '2px solid white',
    color: theme.palette.primary.main,
    "& svg": {
        marginRight: theme.spacing(1),
    },
    justifyContent: "center",
});

function SectionHeader(props: { text: string, vertical: boolean }) {
    return (
        <Box sx={{ display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: 2,
            borderRadius: 2,
            border: '2px solid white',
            "& svg": {
                marginRight: 1,
            },
            justifyContent: "center",
        }} >
            <Typography variant="h5" align={"center"} aria-orientation={'vertical'}>
                {props.text}
            </Typography>
        </Box>
    );
}

function ProximitiesBlock(props: {from: Dayjs, players: Player[], target: targets}){
    const [tab, setTab] = useState(0);

    return <Stack direction={'column'} sx={{width:'100%', height:'90%'}}>
        <NeonTabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
            {
                props.players.map((p, index) => <NeonTab sx={{fontWeight:'bold'}} asset={p.role} label={p.player}  />)
            }
        </NeonTabs>
        <Stack direction={'row'} sx={{width:'100%', height:'100%'}}>
            <Proximities target={props.target} paramObject={{player: props.players[tab].player, additional_filters : {date__gte:props.from.toISOString()}, watermark: props.players[tab].player}} timer={'pre15'}/>
            <Proximities target={props.target} paramObject={{player: props.players[tab].player, additional_filters : {date__gte:props.from.toISOString()}, watermark: props.players[tab].player}} timer={'post15'}/>
        </Stack>
            {/*{*/}
        {/*    props.players.map((p, index) =>*/}
        {/*        <TabPanel value={tab} index={index}>*/}
        {/*            <Proximities target={props.target} paramObject={{player: p.player, additional_filters : {date__gte:props.from.toISOString()}, watermark: p.player}} timer={'pre15'}/>*/}
        {/*        </TabPanel>*/}
        {/*    )*/}
        {/*}*/}
    </Stack>
}

function ForwardPercentageBlock(props: {from: Dayjs, team: Team, target: targets}){
    return <Stack direction={'row'} sx={{minHeight: '650px', height: '650px', width: '100%'}} spacing={2}>
                <MetricRadar paramObjectMain={{
                    team1: props.team.team_name,
                    additional_filters: {date__gte: props.from.toISOString()},
                    watermark: 'undefined'
                }}
                                    paramObjectReference={{
                                        team2: props.team.team_name,
                                        additional_filters: {date__gte: props.from.toISOString()}
                                    }}
                                    target={targets.competitive} metric={'forward_percentage_pre_15'}/>
                <MetricRadar paramObjectMain={{
                    team1: props.team.team_name,
                    additional_filters: {date__gte: props.from.toISOString()},
                    watermark: 'undefined'
                }}
                                    paramObjectReference={{
                                        team2: props.team.team_name,
                                        additional_filters: {date__gte: props.from.toISOString()}
                                    }}
                                    target={targets.competitive} metric={'forward_percentage_post_15'}/>
            </Stack>
}