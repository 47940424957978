import {targets, useReferentialContext} from "../../../Context";
import React, {useState} from "react";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import OverviewDetailed from "../../../components/selectors/OverviewDetailed";
import {Team} from "../../../farsight-api";
import {Stack} from "@mui/material";
import TableDisplayGrid, {rowStyle} from "../../../components/datagrids/shared/TableDisplayGrid";
import dayjs from "dayjs";


export default function TeamChampionsScrims() {
    const context = useReferentialContext();
    const [detailed, setDetailed] = useState(false);

    const [picktype, setPicktype] = useState(null as null | boolean);
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [team, setTeam] = useState(context.teamsReferential.filter(t => t.team_name === context.user.team)[0] as Team);
    const [vs, setVs] = useState(null as Team|null);
    const [from, setFrom] = useState(dayjs().subtract(14, "day"))
    const [until, setUntil] = useState(dayjs())

    const [paramObject, setParamObject] = useState({} as any);
    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.scrims}
                updateContext={false}
                patches={patches}
                setPatches={setPatches}
                picktype={picktype}
                setPicktype={setPicktype}
                team={team}
                setTeam={setTeam}
                vs={vs}
                setVs={setVs}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
            />
            <OverviewDetailed onChange={setDetailed} value={detailed} />
            {
                paramObject.additional_filters ? (!detailed ? <OverviewTeamChampions paramObject={paramObject} target={targets.scrims} /> : <></>) : <></>
            }
        </WindowContainer>
    );
}

function OverviewTeamChampions(props: { paramObject: any, target: targets }) {
    const ctx = useReferentialContext();
    const api = ctx.farsightApi.getSummariesApi(props.target);
    const cols = ["champion"];
    const metrics_cols = ["games", "performance", "winrate", 'teamwise_pickturn'];
    const metrics = ctx.getMetrics(metrics_cols, props.target)

    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.aggregate({
                        ...props.paramObject,
                        groupby:['champion'],
                        role: "TOP_LANE",
                        ordering: ['performance'],
                        metrics: [metrics_cols.toString()]
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                metrics={metrics}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.aggregate({
                        ...props.paramObject,
                        groupby:['champion'],
                        role: "JUNGLE",
                        ordering: ['performance'],
                        metrics: [metrics_cols.toString()]
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                metrics={metrics}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.aggregate({
                        ...props.paramObject,
                        groupby:['champion'],
                        role: "MID_LANE",
                        ordering: ['performance'],
                        metrics: [metrics_cols.toString()]
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                metrics={metrics}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.aggregate({
                        ...props.paramObject,
                        groupby:['champion'],
                        role: "BOT_LANE",
                        ordering: ['performance'],
                        metrics: [metrics_cols.toString()]
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                metrics={metrics}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.aggregate({
                        ...props.paramObject,
                        groupby:['champion'],
                        role: "UTILITY",
                        ordering: ['performance'],
                        metrics: [metrics_cols.toString()]
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                metrics={metrics}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}