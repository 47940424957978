import {DdragonChampion, targets, useReferentialContext} from "../../Context";
import ChampionIcon from "../Icons/ChampionIcon";
import {Player, Team} from "../../farsight-api";
import Autocomplete from "@mui/material/Autocomplete";
import {Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import TeamIcon from "../Icons/TeamIcon";

interface SearchEverythingOption<Option>{
    label: string,
    icon: React.ReactNode,
    changePage: () => void,
    group: string,
    option: DdragonChampion,
    subLabel?: string
}

export default function SearchEverything(){
    const ctx = useReferentialContext();
    const champions = ctx.championsReferential;
    const players = ctx.playersReferential;
    const teams = ctx.teamsReferential;
    const [clicked, setClicked] = useState(false);
    const [possibilities, setPossibilities] = useState([] as SearchEverythingOption<DdragonChampion | Player | Team>[]);
    const [inputValue, setInputValue] = useState("");


    const options = [
        ...teams.map(t => {
            return {
                option: t,
                label: t.team_name,
                subLabel: t.tricode,
                icon: <TeamIcon team_name={t.team_name} sx={{ mr: "8px" }}/>,
                group: 'Team',
                changePage: () => {
                    ctx.setSelectedTeam(t);
                    ctx.setCurrentTab(14);
                }
            }
        }),
        ...champions.map(c => {return {
            option: c,
            label: c.name,
            subLabel: undefined,
            icon: <ChampionIcon champion={c.id} role={'TOP_LANE'} sx={{ mr: "8px" }} size={35}/>,
            group: 'Champion',
            changePage: () => {
                ctx.setSelectedChampion(c);
                ctx.setSelectedRole('TOP_LANE');
                ctx.setSelectedLeagues(ctx.leaguesReferential.filter(l => l.group === 'Major'))
                switch(ctx.currentTarget){
                    case targets.competitive:
                        ctx.setCurrentTab(1);
                        break
                    case targets.soloq:
                        ctx.setCurrentTab(18);
                        break
                    case targets.scrims:
                        ctx.setCurrentTab(25);
                        break;
                    default:
                        ctx.setCurrentTab(1);
                }
            },
        }}),

        ...players.map(p => {return {
            option: p,
            label: p.player,
            subLabel: undefined,
            group: 'Player',
            icon: <Stack direction={"row"}>
                    {p.role && <Box component={"img"}
                                               src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${25},w_${25}/v1/leagueAssets/${p.role}.png`} style={{marginRight: 10}}/>}
                    {<Box>{p.current_team ? "" :  (p.last_team ? "Prev:" : "")}</Box>}
                    {p.current_team || p.last_team ? (
                        <Box
                            component={"img"}
                            src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${25},w_${25}/v1/teamsLogo/${p.current_team ? p.current_team : p.last_team}.png`}
                            sx={{
                                filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                                mr: "10px",
                            }}
                        />
                    ) : <></>}
                </Stack>,
            changePage: () => {
                    ctx.setSelectedPlayer(p);
                    ctx.setSelectedLeagues(ctx.leaguesReferential.filter(l => l.group === 'All'))
                    ctx.setCurrentTab(5);
            },
        }})
    ] as SearchEverythingOption<DdragonChampion | Player | Team>[];

    const filterOptions = (inputValue: string) => {
        return options.filter((option) => {
            return option.label.startsWith(inputValue) || option.subLabel === inputValue
        });
    };

    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
        const newOptions = filterOptions(newInputValue);
        setClicked(true);
        setPossibilities(newOptions.length < 1000 ? newOptions : []);

    };

    return <Autocomplete sx={{width:'300px'}}
        open={clicked && possibilities.length > 0}
        onInputChange={handleInputChange}
        value={null}
        groupBy={(option) => option.group}
        onChange={(event: any, new_value) => {
            setClicked(false);
            if(new_value){
                new_value.changePage();
            }
            setInputValue("");
        }}
        renderOption={(renderOptionProps: any, option: SearchEverythingOption<Player | DdragonChampion | Team>, { selected }) => {
            console.log(option)
                return (
                        <li {...renderOptionProps}>
                            {option.icon}
                            {option.label}
                        </li>
                );
            }}
        renderInput={(params) =>
                inputValue ? <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {/*{props.inputDecoration && selectedItem ? props.inputDecoration(selectedItem) : <></>}*/}
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                /> :  <TextField {...params} label={"Player, Champion, Team..."} variant="outlined" />

            }
            ListboxProps={{ className: "scrollable" }}
            options={possibilities}
            filterOptions={(options, state) => filterOptions(inputValue)}
    />
}