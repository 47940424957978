import {Card, CardHeader, CardContent, CardActions, Typography, CardProps, useTheme} from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef, useMemo } from "react";
import {Skeleton} from "@mui/lab";
import {getNeonColor} from "../theme";
import {useThemeContext} from "../themeContext";


// Function to generate a stable random position per card instance based on DOM id
const generateBlurPosition = (seed: string) => {
  const hash = seed.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const randomFactor = Math.random() * 50; // Add randomness for uniqueness

  return {
    top: `${(hash % 50) + randomFactor}px`, // Random but bounded
    left: `${(hash % 50) + randomFactor}px`,
  };
};


// 🔹 Styled Neon Card (Inner Glow Effect & Depth)
export const NeonCard = styled(
    ({ id, zoom_on_hover, background_glow, glow_color, ...props }: CardProps & {
      id?: string;
      zoom_on_hover?: boolean;
      background_glow?: boolean;
      glow_color?: string;
    }) => {
      const ref = useRef<HTMLDivElement>(null);
      const stableId = useMemo(() => id || Math.random().toString(36).substr(2, 9), [id]);

      // Ensure each card has a slightly different blur position
      const blurPosition = useMemo(() => generateBlurPosition(stableId + Math.random().toString()), [stableId]);
      const theme = useTheme();

      const neonColor = glow_color || getNeonColor(theme); // Use provided color or fallback

      return (
          <Card
              ref={ref}
              id={stableId}
              sx={{
                boxShadow: background_glow
                    ? `0px 0px 7px ${neonColor}`
                    : `0px 0px 30px rgba(25, 25, 35, 0.95)`,
                "&:hover": zoom_on_hover
                    ? {
                      transform: "scale(1.03)",
                      transition: "transform 0.3s ease-in-out",
                      cursor: "pointer",
                    }
                    : {},
              }}
              {...props}
          >
            <div className="neon-glow" style={{ top: blurPosition.top, left: blurPosition.left, background: neonColor }} />
            {props.children}
          </Card>
      );
    }
)(({ theme, glow_color }) => {
  const neonColor = glow_color || getNeonColor(theme); // Ensure glow_color is used

  return {
    width: "250px",
    height: "240px",
    borderRadius: "12px",
    position: "relative",
    overflow: "hidden",
    background: theme.palette.background.paper,
    opacity: 1,

    "& .neon-glow": {
      position: "absolute",
      width: "250px",
      height: "250px",
      background: neonColor, // ✅ Now correctly injected
      opacity: 0.08,
      filter: "blur(50px)",
      borderRadius: "50%",
      pointerEvents: "none",
      zIndex: 0,
    },

    "& > *:not(.neon-glow)": {
      position: "relative",
      zIndex: 1,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "100%",
      transform: glow_color ? "brightness(300%)" : "none",
      height: glow_color ? "3px" : "1px",
      background: neonColor, // ✅ Now correctly injected
      boxShadow: `0px 0px 10px ${neonColor}, 0px 0px 20px ${neonColor}`,
      opacity: 1,
      pointerEvents: "none",
    },
  };
});

// 🔹 Styled Neon Card Header
export const NeonCardHeader = styled(CardHeader)(({ theme }) => ({
  height: "70px",
  marginBottom: 0,
  color: getNeonColor(theme), // Use primary neon color
  // textShadow: `0px 0px 8px ${getNeonColor(theme)}, 0px 0px 16px ${getNeonColor(theme)}`,
  "& .MuiTypography-root": {
    fontWeight: "bold",
  },
}));

// 🔹 Styled Neon Card Content (For Chart)
export const NeonCardContent = styled(CardContent)({
  width: "250px",
  height: "125px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 0
});

// 🔹 Styled Neon Card Footer (Actions)
export const NeonCardActions = styled(CardActions)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
});

interface NeonMainStatCardProps extends CardProps {
  background?: string;
}

export const NeonMainStatCard = styled(Card)<NeonMainStatCardProps>(({ theme, background }) => {
  const ref = useRef<HTMLDivElement>(null);
  const stableId = useMemo(() => Math.random().toString(36).substr(2, 9), []);
  const blurPosition = useMemo(() => generateBlurPosition(stableId), [stableId]);

  return {
    width: "300px",
    height: "125px",
    background: background || "rgba(20, 20, 30, 0.85)", // Use provided background or default
    borderRadius: "12px",
    position: "relative",
    overflow: "hidden",
    backdropFilter: "blur(5px)", // Softens the background
    boxShadow: `
      0px 10px 30px rgba(0, 0, 0, 0.6), 
      0px 4px 15px rgba(0, 0, 0, 0.4), 
      inset 0px 0px 10px rgba(0, 0, 0, 0.3)
    `, // Added depth effect
    "&::before": {
      content: "''",
      position: "absolute",
      top: blurPosition.top,
      left: blurPosition.left,
      width: "200px",
      height: "200px",
      background: getNeonColor(theme),
      opacity: 0.08, // Light blur effect
      filter: "blur(50px)",
      borderRadius: "50%",
      pointerEvents: "none",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "100%",
      height: "2px",
      background: getNeonColor(theme),
      boxShadow: `0px 0px 10px ${getNeonColor(theme)}`,
      opacity: 1,
      pointerEvents: "none",
    },
  };
});

export function NeonSkeletonCard(props: {sx?: any}){
  return <Card sx={props.sx ? props.sx :  {width:'250px', height:'240px'}}>
                    <CardHeader sx={{height:'70px', mb:0}} title={'Loading...'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                    </CardContent>
                </Card>
}