/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    PatchedPriorityPyramid,
    PriorityPyramid,
    PriorityPyramidAggregate,
} from "../models/index";

export interface PriorityPyramidAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    having?: object;
    index_on?: string;
    metrics?: Array<string>;
    name?: string;
    owner?: string;
    vision_of?: string;
}

export interface PriorityPyramidCreateRequest {
    id: string;
    priority_pyramid: Omit<PriorityPyramid, "id">;
}

export interface PriorityPyramidDeleteRequest {
    id: string;
}

export interface PriorityPyramidFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    name?: string;
    owner?: string;
    vision_of?: string;
}

export interface PriorityPyramidGetRequest {
    id: string;
}

export interface PriorityPyramidListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    name?: string;
    ordering?: Array<string>;
    owner?: string;
    vision_of?: string;
}

export interface PriorityPyramidPatchRequest {
    id: string;
    patched_priority_pyramid?: Omit<PatchedPriorityPyramid, "id">;
}

export interface PriorityPyramidVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    name?: string;
    owner?: string;
    vision_of?: string;
}

/**
 *
 */
export class PriorityPyramidApi extends runtime.BaseAPI {
    /**
     */
    async priorityPyramidAggregateRaw(
        requestParameters: PriorityPyramidAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<PriorityPyramidAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling priorityPyramidAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling priorityPyramidAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["vision_of"] != null) {
            queryParameters["vision_of"] = requestParameters["vision_of"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: PriorityPyramidAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<PriorityPyramidAggregate>> {
        const response = await this.priorityPyramidAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidCreateRaw(
        requestParameters: PriorityPyramidCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<PriorityPyramid>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidCreate().',
            );
        }

        if (requestParameters["priority_pyramid"] == null) {
            throw new runtime.RequiredError(
                "priority_pyramid",
                'Required parameter "priority_pyramid" was null or undefined when calling priorityPyramidCreate().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["priority_pyramid"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async create(
        requestParameters: PriorityPyramidCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<PriorityPyramid> {
        const response = await this.priorityPyramidCreateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidDeleteRaw(
        requestParameters: PriorityPyramidDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidDelete().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete(
        requestParameters: PriorityPyramidDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.priorityPyramidDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async priorityPyramidFieldValuesRaw(
        requestParameters: PriorityPyramidFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling priorityPyramidFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling priorityPyramidFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["vision_of"] != null) {
            queryParameters["vision_of"] = requestParameters["vision_of"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: PriorityPyramidFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.priorityPyramidFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidGetRaw(
        requestParameters: PriorityPyramidGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<PriorityPyramid>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: PriorityPyramidGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<PriorityPyramid> {
        const response = await this.priorityPyramidGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidListRaw(
        requestParameters: PriorityPyramidListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<PriorityPyramid>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["vision_of"] != null) {
            queryParameters["vision_of"] = requestParameters["vision_of"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: PriorityPyramidListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<PriorityPyramid>> {
        const response = await this.priorityPyramidListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidPatchRaw(
        requestParameters: PriorityPyramidPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<PriorityPyramid>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidPatch().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["patched_priority_pyramid"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: PriorityPyramidPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<PriorityPyramid> {
        const response = await this.priorityPyramidPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidVariableDistributionRaw(
        requestParameters: PriorityPyramidVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling priorityPyramidVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling priorityPyramidVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling priorityPyramidVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling priorityPyramidVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["vision_of"] != null) {
            queryParameters["vision_of"] = requestParameters["vision_of"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramid/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: PriorityPyramidVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.priorityPyramidVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
