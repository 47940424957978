import React from "react";
import {Marker} from "react-leaflet";
import {InhibitorIcon, Tower2Icon} from "../../Icons/SvgIcons";
import {renderToStaticMarkup} from "react-dom/server";
import L from "leaflet";
import {SIDE_COLORS} from "../../../theme";


interface TurretMarkerProps {
  turret_id: string
  containerSize: number;
  side: 'Blue'|'Red'
}

interface InhibitorMarkerProps {
  inhibitor_id: string
  containerSize: number;
  side: 'Blue'|'Red'
}

export function InhibitorMarker(props: InhibitorMarkerProps){
    let position: [number, number] = [0.5, 0.5];
    let color: string;
    switch (props.side){
        case 'Blue':
            switch (props.inhibitor_id){
                case 'INHIBITOR_BUILDING-BOT_LANE':
                    position = [0.24129119031607263, 0.09012016021361816]
                    break;
                case 'INHIBITOR_BUILDING-TOP_LANE':
                    position = [0.08688634835238736, 0.24719626168224298]
                    break;
                case 'INHIBITOR_BUILDING-MID_LANE':
                    position = [0.22394082044384667, 0.22276368491321763]
                    break;
                default:
                    position = [0.5, 0.5]
                    break;
            }
            color = SIDE_COLORS.Blue;
            break;
        case 'Red':
            switch (props.inhibitor_id){
                case 'INHIBITOR_BUILDING-BOT_LANE':
                    position = [0.922259583053127, 0.7636181575433912]
                    break;
                case 'INHIBITOR_BUILDING-TOP_LANE':
                    position = [0.7663080026899798, 0.9200934579439253]
                    break;
                case 'INHIBITOR_BUILDING-MID_LANE':
                    position = [0.7876933422999327, 0.7869826435246996]
                    break;
                default:
                    position = [0.5, 0.5]
                    break;
            }
            color = SIDE_COLORS.Red;
            break;
    }
    const iconHtml = renderToStaticMarkup(
    <div style={{ position: "relative", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <InhibitorIcon color={color}/>
    </div>
  );
    // Create a Leaflet DivIcon
  const customIcon = new L.DivIcon({
    className: "custom-objective-marker",
    html: iconHtml,
    iconSize: [40, 40], // Adjusted size
      iconAnchor: [20,20]
  });

    return <Marker position={[position[1] * props.containerSize, position[0] * props.containerSize]} icon={customIcon}/>;
}
export function TurretMarker(props: TurretMarkerProps){
    let position: [number, number] = [0.5, 0.5];
    let color: string;
    switch (props.side){
        case 'Blue':
            switch (props.turret_id){
                case 'TOWER_BUILDING-OUTER-TOP_LANE':
                    position = [0.07404169468728984, 0.7050066755674232]
                    break;
                case 'TOWER_BUILDING-OUTER-BOT_LANE':
                    position = [0.7144586415601883, 0.0767022696929239]
                    break;
                case 'TOWER_BUILDING-OUTER-MID_LANE':
                    position = [0.4012104909213181, 0.4349799732977303]
                    break;
                case 'TOWER_BUILDING-INNER-MID_LANE':
                    position = [0.34754539340954943, 0.3292389853137517]
                    break;
                case 'TOWER_BUILDING-INNER-TOP_LANE':
                    position = [0.1097511768661735, 0.4552069425901202]
                    break;
                case 'TOWER_BUILDING-INNER-BOT_LANE':
                    position = [0.473369199731002, 0.10700934579439253]
                    break;
                case 'TOWER_BUILDING-BASE-MID_LANE':
                    position = [0.2535978480161399, 0.254739652870494]
                    break;
                case 'TOWER_BUILDING-BASE-TOP_LANE':
                    position = [0.086684599865501, 0.294192256341789]
                    break;
                case 'TOWER_BUILDING-BASE-BOT_LANE':
                    position = [0.29596503026227305, 0.09165554072096128]
                    break;
                case 'TOWER_BUILDING-NEXUS-MID_LANE-NEXUS1':
                    position = [0.12562205783456623, 0.1595460614152203]
                    break;
                case 'TOWER_BUILDING-NEXUS-MID_LANE-NEXUS2':
                    position = [0.15447209145931406, 0.12863818424566087]
                    break;
                default:
                    position = [0.5, 0.5]
                    break;
            }
            color = SIDE_COLORS.Blue;
            break;
        case 'Red':
            switch (props.turret_id){
                case 'TOWER_BUILDING-OUTER-TOP_LANE':
                    position = [0.298453261600538, 0.9342456608811749]
                    break;
                case 'TOWER_BUILDING-OUTER-BOT_LANE':
                    position = [0.9405514458641561, 0.30874499332443256]
                    break;
                case 'TOWER_BUILDING-OUTER-MID_LANE':
                    position = [0.6102891728312038, 0.5761014686248331]
                    break;
                case 'TOWER_BUILDING-INNER-MID_LANE':
                    position = [0.6648957632817754, 0.6831108144192256]
                    break;
                case 'TOWER_BUILDING-INNER-TOP_LANE':
                    position = [0.5422326832548756, 0.9032710280373831]
                    break;
                case 'TOWER_BUILDING-INNER-BOT_LANE':
                    position = [0.9043039677202421, 0.5571428571428572]
                    break;
                case 'TOWER_BUILDING-BASE-BOT_LANE':
                    position = [0.9242770679219906, 0.7137516688918558]
                    break;
                case 'TOWER_BUILDING-BASE-TOP_LANE':
                    position = [0.7129119031607263, 0.9192256341789052]
                    break;
                case 'TOWER_BUILDING-BASE-MID_LANE':
                    position = [0.7568258238063215, 0.7561415220293725]
                    break;
                case 'TOWER_BUILDING-NEXUS-MID_LANE-NEXUS1':
                    position = [0.8858103564223269, 0.8499332443257677]
                    break;
                case 'TOWER_BUILDING-NEXUS-MID_LANE-NEXUS2':
                    position = [0.8561533288500336, 0.8814419225634179]
                    break;
                default:
                    position = [0.5, 0.5]
                    break;
            }
            color = SIDE_COLORS.Red;
            break;
    }
    const iconHtml = renderToStaticMarkup(
    <div style={{ position: "relative", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Tower2Icon color={color}/>
    </div>
  );
    // Create a Leaflet DivIcon
  const customIcon = new L.DivIcon({
    className: "custom-objective-marker",
    html: iconHtml,
    iconSize: [40, 40], // Adjusted size
      iconAnchor: [20,20]
  });

    return <Marker position={[position[1] * props.containerSize, position[0] * props.containerSize]} icon={customIcon}/>;
}