import { Checkbox, Stack, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { SideSelect } from "../YesNoSelector";
import { ComputesApi, DetailedApi, Team } from "../../../farsight-api";
import {targets, useReferentialContext} from "../../../Context";
import TeamIcon from "../../Icons/TeamIcon";
import {NeonCard, NeonCardHeader} from "../../../NeonComponents/NeonCards";
import {loss_color, scrollable, win_color} from "../../../theme";
import {NeonRow} from "../../../NeonComponents/NeonRow";

export default function SpecificGameSelector(props:
{ team: Team; updateSelection: any; from: Dayjs; until: Dayjs; include_bayes?: null | boolean; side_select: boolean, target: targets, single: boolean }) {
    const [games, setGames] = React.useState([] as any[]);
    const [selectedIndexes, setSelectedIndex] = React.useState([] as boolean[]);
    const [side, setSide] = useState(null as string | null);
    const api = useReferentialContext().farsightApi.detailed;
    const handleSideChange = (newValue: string | null) => {
        const s = newValue;
        console.log(s)
        setSide(s);
        if (s) {
            console.log(games)
            const ns = games.map((game: any) =>  game.side == s && game.bayes_id !== undefined);
            setSelectedIndex(ns);
            props.updateSelection(games.filter((game, index) => ns[index]));
        } else {
            const ns = games.map((game: any) =>  props.include_bayes && game.bayes_id);
            setSelectedIndex(ns);
            props.updateSelection(games.filter((game, index) => ns[index]));
        }
    };
    const handleChange = (index: number) => {
        console.log('Here')
        if(props.single){
            const nsi = games.map(i => false);
            nsi[index] = !nsi[index];
            props.updateSelection(
                games.filter((game, index) => nsi[index])
            )
            setSelectedIndex(nsi);
            console.log(nsi);

        }else{
            console.log(selectedIndexes)
            const nsi = games.map((g, i) => selectedIndexes[i] ? true : false);
            console.log(nsi);
            nsi[index] = !nsi[index];

            props.updateSelection(
                games.filter((game, index) => nsi[index])
            )
            setSelectedIndex(nsi);
            console.log(nsi);
        }
    };

    useEffect(() => {
        setGames([]);
        api.team_history({
            team: props.team.team_name,
            target: props.target,
            from: props.from.unix(),
            until: props.until.unix()
        }).then((data: any[]) => {
            setGames(data);
            // setSelectedIndex(data.map((game: any) => props.include_bayes && game.bayes_id));
            setSelectedIndex([]);
            props.updateSelection([]);
        });
    }, [props.team, props.from, props.until]);

    return (<NeonCard sx={{
                height: '100%',
                width: "100%",
                maxWidth: '400px',
                borderRadius: 2,
                alignItems: "center",
                padding: "5px",
            }}>
            <NeonCardHeader title={`${props.team.team_name}`}
                            action={
                <Stack direction={'row'} sx={{width:'40px', alignItems:'center', justifyContent:'center'}}>

                    <TeamIcon team_name={props.team.team_name} sx={{width:'57px', height:'57px'}}/>
                </Stack>}
                            subheader={`${games.length} games`}
            />
            <Stack direction={'row'} sx={{height:'60px'}}>
                {props.side_select ? <SideSelect onChange={handleSideChange} value={side}/> : undefined}
            </Stack>
        <Stack direction={'column'}
                sx={{
                    overflowY:'auto',
                    display: "flex",
                    height: "80%",
                    width: "100%",
                    padding: 1,
                    ...scrollable
                }}
            >
                        {games.map((game: any, index: number) => (
                            <Tooltip
                                title={!(Boolean(props.include_bayes) && game.bayes_id === null) ? `${game.league_id} ${game.split} ${game.phase}` : `This game has no bayes data attached, information can't be displayed`}
                                placement={"top"}
                                arrow
                                followCursor={true}
                            >
                                <NeonRow zoom_on_hover sx={{
                                            backgroundColor: game.win ? win_color : loss_color,
                                            alignItems: "center",
                                            width: "100%",
                                            display: "flex",
                                            cursor: 'pointer'
                                        }} onClick={(event) => handleChange(index)} direction={'row'}
                                         left_glow={selectedIndexes[index] === true}>
                                        <TeamIcon team_name={game.team2} sx={{ml: 1, mr:1}}/>
                                        {game.patch}
                                        <Stack direction={'row'}>
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_TOP_LANE}.png`} sx={{ ml: 1 }} />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_JUNGLE}.png`} />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_MID_LANE}.png`}  />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_BOT_LANE}.png`} />
                                            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${38},w_${38}/v1/championAssets/${game.champion_UTILITY}.png`} sx={{ mr: 1 }} />
                                        </Stack>
                                        {game.side}
                                    </NeonRow>
                            </Tooltip>
                        ))}
            </Stack>
    </NeonCard>
    );
}