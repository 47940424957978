import {CircleMarker, Marker} from "react-leaflet";
import StyledPopup from "../StyledPopup";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ChampionIcon from "../../Icons/ChampionIcon";
import ItemIcon from "../../Icons/ItemIcon";
import dayjs from "dayjs";
import React, {RefObject, useEffect, useRef, useState} from "react";
import { WardOnMap } from "../../../farsight-api";
import {SIDE_COLORS} from "../../../theme";
import {Icon} from "leaflet";
import { CircleMarker as LeafletCircleMarker } from 'leaflet';
import { Marker as LeafletMarker } from 'leaflet';

const WARD_DEFAULT_RANGE = 0.03;

export default function WardMarker({ event, draggable, containerSize = 750 }: {
  event: WardOnMap;
  containerSize: number;
  draggable?: boolean
}) {
    const markerRef = useRef<LeafletCircleMarker<any>>(null);
    const markerIconRef = useRef<LeafletMarker<any>>(null);
    const theme = useTheme(); // Get MUI Theme
    const [position, setPosition] = useState<[number, number]>([event.position[1] * containerSize, event.position[0] * containerSize]);

    useEffect(() => {
        setPosition([event.position[1] * containerSize, event.position[0] * containerSize]);
    }, [event, containerSize]);

    useEffect(() => {
    if (!draggable || !markerRef.current) return;

    let isDragging = false;

    const onMouseDown = (e: MouseEvent) => {
        console.log(e);
      // e.preventDefault();
      isDragging = true;
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      setPosition((prevPosition) => [
        prevPosition[0] - e.movementY, // Move vertically
        prevPosition[1] + e.movementX, // Move horizontally
      ]);
    };

    const onMouseUp = () => {
      isDragging = false;
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    // @ts-ignore
    markerRef.current.addEventListener("mousedown", onMouseDown);
    // @ts-ignore
    markerIconRef.current.addEventListener("mousedown", onMouseDown);

    return () => {
        // @ts-ignore
        markerRef.current?.removeEventListener("mousedown", onMouseDown);
        // @ts-ignore
        markerIconRef.current?.removeEventListener("mousedown", onMouseDown);
    };
  }, [draggable]);

  if (event.ward_type === "unknown") return null;

  // Get appropriate team color
  // @ts-ignore
  const sideColor = SIDE_COLORS[event.side] || "#FFFFFF";

  // Circle radius in pixels
  const radius = WARD_DEFAULT_RANGE * containerSize;

  // Compute elapsed fraction for stroke
  let fraction = 1;
  if (event.duration && event.remaining !== undefined) {
    fraction = Math.max(0, Math.min(1, event.remaining / event.duration));
  }

  // Circle stroke calculations
  const circumference = 2 * Math.PI * radius;
  const dashArray = circumference;
  const dashOffset = circumference - fraction * circumference;

  // Format times
  const formattedTimestamp = dayjs.duration(event.timestamp, "seconds").format("m:ss");
  const formattedElapsed = event.elapsed !== undefined && event.elapsed !== null
    ? dayjs.duration(event.elapsed, "seconds").format("m:ss")
    : "--:--";
  const formattedRemaining = event.remaining !== undefined && event.remaining !== null
    ? dayjs.duration(event.remaining, "seconds").format("m:ss")
    : "--:--";

  const popup = <StyledPopup autoPan={false}>
        <Stack
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          width="100%"
          sx={{ p: 1 }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ChampionIcon champion={event.champion} role={"TOP_LANE"} />
            <ItemIcon item={event.ward_type} />
          </Stack>

          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Placed at: {formattedTimestamp}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            Elapsed: {formattedElapsed}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            Remaining: {formattedRemaining}
          </Typography>
        </Stack>
      </StyledPopup>

  return (
    <CircleMarker
        ref={markerRef}
      key={`${event.champion}-${event.timestamp}`}
      center={position}
      radius={radius}
        className={'animated-marker'}
      pathOptions={{
        color: sideColor, // Stroke color
        weight: 1,
        dashArray: dashArray.toString(),
        dashOffset: dashOffset.toString(),

        // Fill settings
        fill: true,
        fillColor: sideColor,
        fillOpacity: 0.4,
      }}
    >
      {event.ward_type === 'control' && <Marker ref={markerIconRef} position={position} icon={
        new Icon({
          iconSize: [15, 15],
          iconAnchor: [7.5, 7.5],
          iconUrl: `https://res.cloudinary.com/xenesis/image/upload/c_fill,h_15,w_15,r_max,bo_2px_solid_rgb:${sideColor.replace('#', '')}/v1/leagueAssets/${event.ward_type}.png`
        })
      }>{popup}</Marker>}
      {event.ward_type === 'yellowTrinket' && <Marker ref={markerIconRef}  position={position} icon={
        new Icon({
          iconSize: [15, 15],
          iconAnchor: [7.5, 7.5],
          iconUrl: `https://res.cloudinary.com/xenesis/image/upload/c_fill,h_15,w_15,r_max,bo_2px_solid_rgb:${sideColor.replace('#', '')}/v1/leagueAssets/${event.ward_type}.png`
        })
      }>{popup}</Marker>}
      {event.ward_type === 'sight' && <Marker ref={markerIconRef}  position={position} icon={
        new Icon({
          iconSize: [20, 20],
          iconAnchor: [10, 10],
          iconUrl: `https://res.cloudinary.com/xenesis/image/upload/c_fill,h_15,w_15,r_max,bo_2px_solid_rgb:${sideColor.replace('#', '')}/v1/championAssets/${event.champion}.png`
        })
      }>{popup}</Marker>}
      {popup}
    </CircleMarker>
  );
}
