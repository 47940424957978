import { FormControl, InputLabel, Stack, Typography } from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {soloq_servers} from "../../Context";

export default function ServerSelect(props: { values: string[]; updateValue: any }) {
    const blocks =  {
        BR1 : 'BR1',
        EUN : 'EUN1',
        EUW1 : 'EUW1',
        JP1 : 'JP1',
        KR : 'KR',
        LA1 : 'LA1',
        LA2 : 'LA2',
        ME1 : 'ME1',
        NA1 : 'NA1',
        OC : 'OC',
        PH2 : 'PH2',
        RU : 'RU',
        SEA : 'SEA',
        SG2 : 'SG2',
        TH2 : 'TH2',
        TR1 : 'TR1',
        TW2 : 'TW2',
        VN2 : 'VN2',
    } as any;
    return (
        <FormControl sx={{ width: "150px", height: "56px", zIndex: 0 }}>
            <InputLabel>Servers</InputLabel>
            <Select
                multiple
                value={props.values as any}
                label="Servers"
                onChange={(event) => props.updateValue(event.target.value as any)}
                renderValue={(value: string) => (
                    <Stack direction={"row"} sx={{ justifyContent: "left", width: "100%" }} spacing={1}>

                        {props.values.map((server: string) => blocks[server]).toString()}
                    </Stack>
                )}
            >
                {Object.entries(soloq_servers).map(([code, label]) => (
                    <MenuItem value={code}>
                        <Stack
                            direction={"row"}
                            sx={{
                                justifyContent: "flex-start",
                                width: "100%",
                                alignItems: "center",
                            }}
                            spacing={2}
                        >
                            {label}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
