import React, {SetStateAction, useEffect, useRef, useState} from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import {NeonCard, NeonCardActions, NeonCardContent, NeonCardHeader} from "../../NeonComponents/NeonCards";
import {Button, Stack, TextField, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {GamesData, ScrimsData} from "../../farsight-api";
import {targets, useReferentialContext} from "../../Context";
import {NeonButton} from "../../NeonComponents/Buttons";

interface YouTubePlayerProps {
  timestamp: number;
  setTimestamp: (time: number) => void;
  gameData: GamesData|ScrimsData;
  setGameData: React.Dispatch<SetStateAction<GamesData|ScrimsData>>;
  target: targets.competitive | targets.scrims
}

const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

export function VideoUrlInput(props: {update_api: (url: string) => void}){
    const [videoUrl, setVideoUrl] = useState('');
    return <Stack direction={'row'} sx={{width:'50%', height:'100%', alignItems:'center',justifyContent:'space-evenly'}}>
            <TextField
                label="Enter the VOD Link"
                type="url"
                variant="outlined"
                onChange={(e) => setVideoUrl(e.target.value)}
            />
            <NeonButton sx={{padding:1}} variant={'outlined'} onClick={() => props.update_api(videoUrl.includes('&t=') ? videoUrl : videoUrl + '&t=600')}>Validate</NeonButton>
        </Stack>
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ timestamp, setTimestamp, gameData, setGameData, target }) => {
  const playerRef = useRef<any>(null);
  const [isUserSeeking, setIsUserSeeking] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [inputTime, setInputTime] = useState("");
  const ctx = useReferentialContext();
  // Extract the video ID from standard and live YouTube URLs
  const extractVideoId = (url: string): string | null => {
    const match = url.match(/(?:v=|\/live\/|youtu\.be\/|v=)([A-Za-z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  const vod_link = gameData.vod_game_start_for_replay ? gameData.vod_game_start_for_replay : gameData.vod_game_start as string;
  const videoId = extractVideoId(vod_link);

  // Extract game start time from the URL (if present)
  const extractVideoStartTime = (url: string): number => {
      try {
          const urlParams = new URLSearchParams(new URL(url).search);
          if (urlParams.has("t")) {
              return parseInt(urlParams.get("t") || "0", 10);
          }else{
              return 0
          }
      } catch (error) {
          console.error("Invalid URL:", error);
          return 0
      }
  }
  const [gameStart, setGameStart] = useState<number>(extractVideoStartTime(vod_link));

    useEffect(() => {
        setGameStart(extractVideoStartTime(vod_link));
    }, [gameData]);

  // Seek video when an external timestamp change occurs
  useEffect(() => {
    if (playerRef.current && videoId && !isUserSeeking) {
      const newTime = gameStart + timestamp;
      const playerTime = playerRef.current.getCurrentTime();
      console.log(playerRef.current.getCurrentTime(), newTime)
      if(playerTime && Math.abs(playerTime - newTime) >= 1){
        console.log('Seeked new time')
        playerRef.current.seekTo(newTime);
      }
    }
  }, [timestamp]);

  // Continuously update timestamp as video plays
  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current && isPlaying) {
        try {
          const currentTime = playerRef.current.getCurrentTime();
          if(Math.round(timestamp - currentTime) < 1){
           setTimestamp(Math.round(currentTime - gameStart));
          }
        } catch (error) {
          console.error("Error fetching current time:", error);
        }
      }
    }, 1000); // Update timestamp every second

    return () => clearInterval(interval);
  }, [isPlaying, gameStart, setTimestamp]);

  const [opts, setOpts] = useState<YouTubeProps["opts"]>({
    height: "390",
    width: "640",
    playerVars: {
        autoplay: 1,
        controls: 1,
        rel: 0, // Prevent related videos
        modestbranding: 1,
        mute: 1,
        start: gameStart + timestamp, // Ensure initial load respects timestamp
        disablekb: 1,
        iv_load_policy: 3,
      },
  });

  const handleGameStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTime(event.target.value);
  };

  useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.contentRect) {
                        const width = Math.floor(entry.contentRect.width) * 0.95;
                        const height = (width * 9) / 16;
                        setOpts({
                          height: height,
                          width: width,
                          playerVars: {
                            autoplay: 1,
                            controls: 1,
                            rel: 0, // Prevent related videos
                            modestbranding: 1,
                            disablekb: 1,
                            iv_load_policy: 3,
                            mute: 1,
                            start: gameStart + timestamp, // Ensure initial load respects timestamp
                          },
                        })
                    }
                }
            });
            resizeObserver.observe(containerRef.current);
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

  const applyGameStartAdjustment = () => {
    const [minutes, seconds] = inputTime.split(":").map((v) => parseInt(v, 10));
    if (!isNaN(minutes) && !isNaN(seconds) && playerRef.current) {
      const enteredTimestamp = minutes * 60 + seconds + 1;
      setTimestamp(enteredTimestamp);
      const ngs = playerRef.current.getCurrentTime() - enteredTimestamp;
      setGameStart(ngs);
      ctx.farsightApi.getDataApi(target).patch({id: encodeURIComponent(gameData.game_id),
          patched_games_data:{vod_game_start_for_replay: 'https://www.youtube.com/watch?v=' + videoId + '&t=' + Math.round(ngs)},
          patched_scrims_data:{vod_game_start_for_replay: 'https://www.youtube.com/watch?v=' + videoId + '&t=' + Math.round(ngs)}
      }).then(r => setGameData(r));
    }
  };

  return (
      <NeonCard sx={{width: '100%', height: '100%'}}>
          <NeonCardHeader title={gameData.vod_game_start_for_replay} sx={{marginBot:0, padding:1}}/>
        <NeonCardContent sx={{width: '100%', height: '80%', padding: 0}}>
          <Box ref={containerRef} sx={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            {videoId ? (
                <YouTube
                    videoId={videoId}
                    opts={opts}
                    onReady={(event) => (playerRef.current = event.target)}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                />
            ) : (
                <p>Invalid YouTube link</p>
            )}
          </Box>
        </NeonCardContent>
        <NeonCardActions>
          <Tooltip title={'There can be offsets between the replay tool and the VOD. Write the timer of the game displayed on the VOD at the moment to adjust.'}>
              <Stack sx={{marginTop: "10px", display: "flex", alignItems: "center", gap: "10px"}} direction={'row'}>
                    <TextField
                        label="Current Time"
                        variant="outlined"
                        size="small"
                        value={inputTime}
                        onChange={handleGameStartChange}
                    />
                    <Button variant="contained" onClick={applyGameStartAdjustment}>Reset Offset</Button>
                    <Stack direction={'column'} sx={{width:'250px'}}>
                      <Typography>Vod Start Time: {formatTime(gameStart)}</Typography>
                      <Typography>Current Video Time: {formatTime(timestamp)}</Typography>
                    </Stack>
              </Stack>
          </Tooltip>
        </NeonCardActions>
      </NeonCard>
  );
};

export default YouTubePlayer;