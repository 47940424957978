import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import {PriorityPyramid} from "../../../farsight-api";
import {useReferentialContext} from "../../../Context";

interface Props {
  pyramid: PriorityPyramid | null;
  setPyramid: (pyramid: PriorityPyramid | null) => void;
  disable_edit_create?: boolean;
}

const PyramidSelector: React.FC<Props> = ({ pyramid, setPyramid, disable_edit_create }) => {
  const { farsightApi, user } = useReferentialContext();
  const [pyramids, setPyramids] = useState<PriorityPyramid[]>([]);
  const [newPyramidName, setNewPyramidName] = useState("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPyramids = async () => {
      try {
        const data = await farsightApi.priorityPyramid.list();
        setPyramids(data);
        if (!pyramid && data.length > 0) {
          setPyramid(data[0]);
        }
      } catch {
        setError("Failed to fetch pyramids");
      }
    };
    fetchPyramids();
  }, []);

  const handleCreatePyramid = async () => {
    if (!newPyramidName.trim()) return;
    try {
      const newPyramid = await farsightApi.priorityPyramid.create({
        id: "new",
        priority_pyramid: { name: newPyramidName, owner: user.team, vision_of: user.team },
      });
      setPyramids((prev) => [...prev, newPyramid]);
      setPyramid(newPyramid);
      setNewPyramidName("");
    } catch {
      setError("Failed to create pyramid");
    }
  };

  const handleDeletePyramid = async () => {
    if (!pyramid) return;
    try {
      await farsightApi.priorityPyramid.delete({ id: pyramid.id.toString() });
      setPyramids((prev) => prev.filter((p) => p.id !== pyramid.id));
      setPyramid(null);
    } catch {
      setError("Failed to delete pyramid");
    }
  };

  const handleChange = (id: string) => {
    const selected = pyramids.find((p) => p.id === Number(id));
    if (selected) {
      setPyramid(selected);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={2} mb={2}>
      <Select
        value={pyramid?.id || ""}
        onChange={(e) => handleChange(String(e.target.value))}
        displayEmpty
        size="small"
        sx={{ minWidth: 200 }}
      >
        {pyramids.map((p) => (
          <MenuItem key={p.id} value={p.id}>
            {p.name}
          </MenuItem>
        ))}
      </Select>

      {!disable_edit_create && <TextField
          size="small"
          label="New Pyramid Name"
          value={newPyramidName}
          onChange={(e) => setNewPyramidName(e.target.value)}
      />}
      {!disable_edit_create &&  <Button variant="contained" onClick={handleCreatePyramid}>
        Create
      </Button>}
      {!disable_edit_create &&  <Button
          variant="outlined"
          color="error"
          onClick={handleDeletePyramid}
          disabled={!pyramid}
      >
        Delete
      </Button>}

      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PyramidSelector;
