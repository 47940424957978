import React, {useCallback, useEffect, useRef, useState} from "react";
import WindowContainer from "../../components/layout/WindowContainer";
import dayjs from "dayjs";
import {targets, useReferentialContext} from "../../Context";
import {Box, LinearProgress, Stack, TextField, ToggleButton, Tooltip, Typography} from "@mui/material";
import {
    CompetitiveGameSummaries, CompetitiveGameSummariesApi,
    GamesData,
    MapState,
    ObjectiveKilled,
    PlayerState, ScrimGameSummaries, ScrimGameSummariesApi,
    ScrimsData
} from "../../farsight-api";
import {TimestampSelect} from "../../components/selectors/TimestampRangeSelect";
import {scrollable} from "../../theme";
import DrawerGameSelector, {DrawerGameSelectorScrims} from "../../components/selectors/Competitive/DrawerGameSelector";
import {NeonTab, NeonTabs} from "../../NeonComponents/NeonTabs";
import {NeonCard} from "../../NeonComponents/NeonCards";
import ChampionIcon from "../../components/Icons/ChampionIcon";
import AbilityIcon from "../../components/Icons/AbilityIcon";
import {Skeleton} from "@mui/lab";
import ItemIcon from "../../components/Icons/ItemIcon";
import {GoldIcon, KillsIcon, Tower2Icon, VoidGrubsIcon} from "../../components/Icons/SvgIcons";
import SmallDetailButton from "../../components/Buttons/SmallDetailsButton";
import GoldStatsPieChart from "../../components/Charts/GoldStatsPie";
import {EventCard, humanize_event_type, ObjectiveCard} from "../../components/Cards/EventCards";
import RenderMapState from "../../components/maps/MapStateRender";
import CombinedIcons from "../../components/Icons/CombinedIcons";
import RiftKitDialog from "../../components/maps/RiftKitDialog";
import YouTubePlayer, {VideoUrlInput} from "../../components/MediaPlayers/YoutubeGamePlayer";
import DrawerGameSelectorCompetitive from "../../components/selectors/Competitive/DrawerGameSelector";
import {NeonButton} from "../../NeonComponents/Buttons";
// import { ReactComponent as GoldIcon } from "../../components/icons/svg/Gold.svg";



export function GameReviewerScrims(){
    const [gameSelected, setGameSelected] = useState(null as ScrimsData|null);
    const [tab, setTab] = useState(0);


    return <WindowContainer direction={'column'} spacing={1} >
        <Stack direction={'row'} sx={{height:'40px', width:'100%', alignItems:'center'}} spacing={2}>
            <DrawerGameSelectorScrims selectedGame={gameSelected} setSelectedGame={setGameSelected}/>
            <NeonTabs value={tab} onChange={(event, nv) => setTab(nv)}>
                <NeonTab label={'RiftKit'}/>
                <NeonTab label={'Youtube'}/>
            </NeonTabs>
            {/*{!gameSelected?.vod_game_start ? <NeonButton variant={'outlined'} sx={{padding:2}}>Add Vod</NeonButton> : undefined}*/}
            {/*{gameSelected && playerSummaries ? <TimestampSelect max={str_to_secs(playerSummaries[0].duration).asSeconds()} value={timestamp} onChange={setTimestamp}/> : undefined}*/}
        </Stack>
        {gameSelected ?
            <GameReviewerComponent emh_id={gameSelected.emh_id as string} game_id={gameSelected?.game_id} target={targets.scrims} visualize={
                tab === 0 ? 'riftkit' : 'youtube'
            } game_data={gameSelected}/> : <></>}
    </WindowContainer>
}


export default function GameReviewerCompetitive(){
    const [gameSelected, setGameSelected] = useState(null as GamesData|null);
    const [tab, setTab] = useState(0);


    return <WindowContainer direction={'column'} spacing={1} >
        <Stack direction={'row'} sx={{height:'40px', width:'100%', alignItems:'center'}} spacing={2}>
            <DrawerGameSelectorCompetitive selectedGame={gameSelected} setSelectedGame={setGameSelected}/>
            <NeonTabs value={tab} onChange={(event, nv) => setTab(nv)}>
                <NeonTab label={'RiftKit'}/>
                <NeonTab label={'Youtube'} disabled={!gameSelected?.vod_game_start}/>
            </NeonTabs>
            {/*{gameSelected && playerSummaries ? <TimestampSelect max={str_to_secs(playerSummaries[0].duration).asSeconds()} value={timestamp} onChange={setTimestamp}/> : undefined}*/}
        </Stack>
        {gameSelected ?
            <GameReviewerComponent emh_id={gameSelected?.emh_id as string} game_id={gameSelected?.game_id} target={targets.competitive} visualize={
                tab === 0 ? 'riftkit' : 'youtube'
            } game_data={gameSelected}/> : <></>}
    </WindowContainer>
}

function GameReviewerComponent(props: {game_id: string, emh_id: string, target: targets.scrims | targets.competitive, visualize: 'riftkit'|'youtube', vod_link?: string|null,
game_data: GamesData|ScrimsData}){
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const games_data_api = useReferentialContext().farsightApi.getDataApi(props.target);
    const [gameData, setGameData] = useState(props.game_data)
    const [playerSummaries, setPlayerSummaries] = useState(undefined as any[] | undefined);
    const [gameEvents, setGameEvents] = useState([] as any);
    const [selectedPlayer, setSelectedPlayer] = useState(undefined as CompetitiveGameSummaries| ScrimGameSummaries | undefined);
    const [tab, setTab] = useState(0);
    const [timestamp, setTimestamp] = useState(0);
    const [eventStackSelected, setEventStackSelected] = useState('tabs' as 'tabs'|'player');
    const [objectives, setObjectives] = useState([] as ObjectiveKilled[]);
    const [mapStates, setMapStates] = useState([] as MapState[]);
    const timestampRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setGameData(props.game_data);
    }, [props.game_data]);

    const handleSetTimestamp = useCallback((newTimestamp: number) => {
        setTimestamp(newTimestamp);
        setTimeout(() => {
            timestampRef.current?.focus(); // Ensure it refocuses
        }, 10);
      }, []);


    useEffect(() => {
        if(props.game_id){
             games_data_api.objectives_data({emh_id: props.emh_id}).then(r => setObjectives(r));
             api.list({game:props.game_id, ordering:['participant_id']}).then(
                r => {
                    setPlayerSummaries(r);
                    let events = [] as any[];
                    for(const i in r){
                        const summary: any = r[i];
                        events = [...events, ...summary.champion_kill_events.map((e: any) => {return {type:'CHAMPION_KILL', player:summary.player,data:e, summary:summary}})];
                        events = [...events, ...summary.building_kill_events.map((e: any) => {return {type:'BUILDING_KILL', player:summary.player,data:e, summary:summary}})];
                        events = summary.flashes_forced_events ? [...events, ...summary.flashes_forced_events.map((e: any) => {return {type:'FLASH_FORCED', player:summary.player,data:e, summary:summary}})] : events;
                        events = [...events, ...summary.monster_kill_events.map((e: any) => {return {type:'MONSTER_KILL', player:summary.player,data:e, summary:summary}})];
                        events = summary.ward_events ? [...events, ...summary.ward_events.map((e: any) => {return {type:'WARD_EVENT', player:summary.player,data:e, summary:summary}})] : events;
                    }
                    events.sort((a, b) => a.data.timestamp - b.data.timestamp)
                    setGameEvents(events);
                }
            )

            games_data_api.map_states({emh_id:props.emh_id}).then(r => {
                console.log(r);
                setMapStates(r)
            });
        }
    }, [props.game_id, props.emh_id]);

    function str_to_secs(str: string){
        const [hours, minutes, seconds] = str.split(":").map(Number);
        return dayjs
            .duration({
                hours,
                minutes,
                seconds,
            })
    }

    function handleSetSelectedPlayer(newPlayer: CompetitiveGameSummaries | ScrimGameSummaries){
        setSelectedPlayer(newPlayer);
        setTab(-1);
    }

    return playerSummaries ? <Stack direction={'row'} sx={{width:"100%", height:'94%', maxHeight:'94%', minHeight:'94%'}} spacing={4}>
        <Stack direction={'column'} spacing={1}>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[0]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[1]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[2]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[3]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[4]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
        </Stack>
        <Stack direction={'column'} sx={{justifyContent:'center', display:'flex', alignItems:'center', height:'100%', width:'60%'}} spacing={1}>
            <Stack direction={'row'} sx={{width:'100%', alignItems:'center', justifyContent:'space-evenly'}}>
                <TimestampSelect max={str_to_secs(playerSummaries[0].duration).asSeconds()} value={timestamp} onChange={setTimestamp} ref={timestampRef}/>
                <RiftKitDialog summaries={playerSummaries} timestamp={timestamp} map_state={mapStates[timestamp]}/>
            </Stack>
            <Box sx={{height:'95%', width:'100%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                {
                    props.visualize === 'riftkit' ? <RenderMapState map_states={mapStates} timestamp={timestamp} playerSummaries={playerSummaries}/> :
                        gameData.vod_game_start || gameData.vod_game_start_for_replay ? <YouTubePlayer target={props.target} gameData={gameData} timestamp={timestamp} setTimestamp={setTimestamp} setGameData={setGameData}/> :
                            <VideoUrlInput update_api={(new_url: string) =>
                                games_data_api.patch({id: encodeURIComponent(gameData.game_id),
                                    patched_games_data:{vod_game_start: new_url},
                                    patched_scrims_data:{vod_game_start: new_url},
                                }).then(r => setGameData(r))}/>
                }

            </Box>
        </Stack>
        <Stack direction={'column'} spacing={1}>
            <PlayerStateComponent target={props.target} summary={playerSummaries[5]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[6]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[7]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[8]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
            <PlayerStateComponent target={props.target}  summary={playerSummaries[9]} timestamp={timestamp} selectSummary={handleSetSelectedPlayer} selectedSummary={selectedPlayer}/>
        </Stack>
        <Stack direction={'column'} sx={{maxHeight:'100%', height:'100%', width:'325px'}} spacing={1}>
            <NeonTabs value={tab} onChange={(event, newvalue) => {
                setSelectedPlayer(undefined);
                setTab(newvalue);
            }} sx={{ height: "50px" }}>
                <NeonTab label={'Objectives'}/>
                <NeonTab label={'Teamfights'}/>
            </NeonTabs>
            {
                selectedPlayer ?
                    <PlayerEventsStack summary={selectedPlayer} setTimestamp={handleSetTimestamp}/> :
                    tab === 0 ? <ObjectivesEventStack objectives={objectives} setTimestamp={handleSetTimestamp} /> : <Typography>Teamfights</Typography>
            }
        </Stack>


    </Stack> : <></>
}

function PlayerEventsStack(props: {summary: CompetitiveGameSummaries | ScrimGameSummaries, setTimestamp: (new_timestamp: number) => void}){
    const [eventsList, setEventsList] = useState([] as any[]);
    const summary = props.summary;
    const event_types = ['CHAMPION_KILL','BUILDING_KILL','FLASH_FORCED','MONSTER_KILL','WARD_EVENT','SWEEPER_USED','SUMMONER_SPELL_USED']
    const [selectedTypes, setSelectedTypes] = useState(event_types.map(t => true));
    const iconSize = 25;
    const buttonIcons = [
        <KillsIcon color={'white'} width={iconSize} height={iconSize}/>,
        <Tower2Icon color={'white'} width={iconSize} height={iconSize}/>,
        <AbilityIcon ability={'summonerFlash'} sx={{height:`${iconSize}px`, width:`${iconSize}px`}} hideTooltip={true}/>,
        <VoidGrubsIcon color={'white'} width={iconSize} height={iconSize}/>,
        <AbilityIcon ability={'yellowTrinket'} sx={{height:`${iconSize}px`, width:`${iconSize}px`}} hideTooltip={true}/>,
        <AbilityIcon ability={'Oracle Lens'} sx={{height:`${iconSize}px`, width:`${iconSize}px`}} hideTooltip={true} />,
        <CombinedIcons width={iconSize} height={iconSize}
                       secondary_icon={<AbilityIcon ability={'summonerFlash'} sx={{height:`${12}px`, width:`${12}px`}} hideTooltip={true} />}
                        main_icon={<ChampionIcon champion={props.summary.champion} role={props.summary.role} disable_link size={25}/>}/>,
        null,
    ]


    useEffect(() => {
        setEventsList([]);
        let events = summary.champion_kill_events ? [...summary.champion_kill_events.map((e: any) => {return {type:'CHAMPION_KILL', player:summary.player,data:e, summary:summary}})] : [];
        events = summary.building_kill_events ? [...events, ...summary.building_kill_events.map((e: any) => {return {type:'BUILDING_KILL', player:summary.player,data:e, summary:summary}})] : events;
        events = summary.flashes_forced_events ? [...events, ...summary.flashes_forced_events.map((e: any) => {return {type:'FLASH_FORCED', player:summary.player,data:e, summary:summary}})] : events;
        events = summary.monster_kill_events ? [...events, ...summary.monster_kill_events.map((e: any) => {return {type:'MONSTER_KILL', player:summary.player,data:e, summary:summary}})] : events;
        events = summary.ward_events ? [...events, ...summary.ward_events.map((e: any) => {return {type:'WARD_EVENT', player:summary.player,data:e, summary:summary}})] : events;
        events = summary.sweeper_used_events ? [...events, ...summary.sweeper_used_events.map((e: any) => {return {type:'SWEEPER_USED', player:summary.player,data:e, summary:summary}})] : events;
        events = summary.summoner_spell_used_event ? [...events, ...summary.summoner_spell_used_event.map((e: any) => {return {type:'SUMMONER_SPELL_USED', player:summary.player,data:e, summary:summary}})] : events;
        events.sort((a,b) => a.data.timestamp - b.data.timestamp)
        setEventsList(events);
    }, [props.summary]);

    return <Stack direction={'column'} sx={{ height:'93%', maxHeight:'93%', minHeight:'93%', width:'325px'}} spacing={1}>
        <Stack direction={'row'} sx={{width:'100%', alignItems:'center', justifyContent:'space-around'}}>
            <Typography variant={"h5"} sx={{fontWeight:'bold', width:'100px'}}>{props.summary.player.split(' (')[0]}</Typography>
            <ChampionIcon champion={props.summary.champion} role={props.summary.role} disable_link/>
        </Stack>
        <Stack direction={'row'} sx={{width:'100%', alignItems:'center'}}>
            {
                event_types.map((type, index) =>
                    <Tooltip title={humanize_event_type(type)}>
                        <ToggleButton
                            sx={{padding:1}}
                            value={type}
                            selected={selectedTypes[index]}
                            onChange={() => {
                                const new_list = [...selectedTypes];
                                new_list[index] = !new_list[index];
                                setSelectedTypes(new_list);
                            }}>
                    {buttonIcons[index]}
                    </ToggleButton>
                    </Tooltip>
                )
            }
        </Stack>
        <Stack direction={'column'} sx={{height:'85%', maxHeight:'85%', minHeight:'85%',...scrollable}}>
            {eventsList.filter(
                event => event_types.filter((t, index) => selectedTypes[index]).includes(event.type)
            ).map((event, idx) => (
                <EventCard key={idx} event={event} setTimestamp={props.setTimestamp} />
          ))}
        </Stack>
    </Stack>
}

function ObjectivesEventStack(props: {objectives: ObjectiveKilled[], setTimestamp: (new_timestamp: number) => void}){
return <Stack direction={'column'} sx={{ height:'100%', maxHeight:'100%', width:'325px', ...scrollable}}>
        {props.objectives.map((event, idx) => (
        <ObjectiveCard event={event} setTimestamp={props.setTimestamp} />
      ))}
    </Stack>
}

function PlayerStateComponent(props: {
    timestamp: number;
    summary: CompetitiveGameSummaries;
    selectSummary: (newPlayer: CompetitiveGameSummaries | ScrimGameSummaries) => void;
    selectedSummary: CompetitiveGameSummaries | ScrimGameSummaries | undefined;
    target: targets
}) {
    const context = useReferentialContext();
    const [playerStates, setPlayerStates] = useState<PlayerState[]>([]);

    useEffect(() => {
        setPlayerStates([]);
        const api = context.farsightApi.getSummariesApi(props.target) as ScrimGameSummariesApi | CompetitiveGameSummariesApi;
        api._states({ id: props.summary.gameidplayer }).then((response: PlayerState[]) => {
            setPlayerStates(response);
        });
    }, [props.summary]);

    const playerState = playerStates[props.timestamp];

    const DebugJson = (
    <Box sx={{ maxWidth: 300, whiteSpace: "pre-wrap", fontSize: "0.7rem" }}>
      {JSON.stringify(playerState, null, 2)}
    </Box>
  );

    if (!playerState) {
        return (
            <NeonCard sx={{ height: '20%', maxHeight:'20%', paddingTop:2, paddingBot:2, transition: "transform 0.3s ease-in-out",}}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                    <Skeleton variant="text" width={100} height={30} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                </Stack>
                <Stack direction={'column'} sx={{ paddingLeft:1, paddingRight:1, width:'60%' }}>
                    <Skeleton variant="rectangular" height={7} width="100%" sx={{ marginBottom: '2px' }} />
                    <Skeleton variant="rectangular" height={5} width="100%" sx={{ marginBottom: '1px' }} />
                </Stack>
                <Stack direction={'row'} spacing={1} justifyContent="space-evenly">
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                </Stack>
            </NeonCard>
        );
    }

    return (
        <NeonCard zoom_on_hover={true} sx={{ height: '20%', maxHeight:'20%', cursor:'pointer' }} onClick={() => {
            props.selectSummary(props.summary)
        }}>
            <Stack direction={'row'} sx={{ paddingLeft: 1, paddingRight:1, alignItems:'center', justifyContent:'space-between' }}>
                <Stack direction={'column'}>
                    <Typography variant="h6" sx={{ fontWeight:'bold', width:'130px' }}>{props.summary.player.split(" (")[0]}</Typography>
                    <Typography variant="body2">{playerState.stats.champions_killed} / {playerState.stats.num_deaths} / {playerState.stats.assists} Lvl {playerState.level}</Typography>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <ChampionIcon respawn_timer={playerState.respawn_timer} champion={props.summary.champion} role={props.summary.role} size={30} sx={{width:'30px', height:'30px'}}/>
                    <AbilityIcon ability={playerState.summoner_spell1_name} cooldown={playerState.summoner_spell1_cooldown_remaining} sx={{width:'30px', height:'30px'}} />
                    <AbilityIcon ability={playerState.summoner_spell2_name} cooldown={playerState.summoner_spell2_cooldown_remaining} sx={{width:'30px', height:'30px'}} />
                </Stack>
            </Stack>
            <Box sx={{ paddingLeft:1, paddingRight:1, }}>
                {/* Health and Mana Bars */}
                {/*<Stack direction={'row'} sx={{width:'60%'}}>*/}
                <Stack direction={'row'} sx={{justifyContent:'space-between'}}>
                    <Stack direction={'column'} sx={{  width:'60%', marginBottom:'5px', }}>
                    <Tooltip title={`${playerState.health} / ${playerState.health_max}`}>
                        <LinearProgress sx={{ marginBottom:'2px', height:'7px', flexGrow: 1, backgroundColor: "#111", '& .MuiLinearProgress-bar': { backgroundColor: "#1b5e20" } }} variant="determinate" value={(playerState.health / playerState.health_max) * 100} />
                    </Tooltip>
                    <Tooltip title={`${playerState.primary_ability_resource} / ${playerState.primary_ability_resource_max}`}>
                        <LinearProgress sx={{ height:'5px', flexGrow: 1, backgroundColor: "#111", '& .MuiLinearProgress-bar': { backgroundColor: "#0d47a1" }}} variant="determinate" value={(playerState.primary_ability_resource / playerState.primary_ability_resource_max) * 100} />
                    </Tooltip>
                </Stack>
                    <SmallDetailButton sx={{color:'white', height:'20px', width:'20px', padding:0, gap:0, maxWidth:'20px', minWidth:'20px'}} closeOnOutsideClick={false}
                        content={<Box sx={{ width: "250px", height: '250px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <GoldStatsPieChart goldStats={playerState.gold_stats} total={playerState.total_gold}/>
                    </Box>}>
                        <GoldIcon color={'white'} width={20} height={20}/>
                    </SmallDetailButton>
                </Stack>

                <Stack direction={'row'} spacing={1}>
                    <AbilityIcon ability={playerState.ability1_name} cooldown={playerState.ability1_cooldown_remaining} level={playerState.ability1_level} maxLevel={5}  sx={{width:'30px', height:'30px'}}/>
                    <AbilityIcon ability={playerState.ability2_name} cooldown={playerState.ability2_cooldown_remaining} level={playerState.ability2_level} maxLevel={5}  sx={{width:'30px', height:'30px'}}/>
                    <AbilityIcon ability={playerState.ability3_name} cooldown={playerState.ability3_cooldown_remaining} level={playerState.ability3_level} maxLevel={5}  sx={{width:'30px', height:'30px'}}/>
                    <AbilityIcon ability={playerState.ability4_name} cooldown={playerState.ability4_cooldown_remaining} level={playerState.ability4_level} maxLevel={3}  sx={{width:'30px', height:'30px'}}/>
                    <Stack direction={'column'} sx={{alignItems:'center', justifyContent:'center'}}>
                        <Typography variant={'body2'}>{(playerState.total_gold / 1000).toFixed(1).toString()}k</Typography>
                        <Typography variant={'body2'}>{(playerState.current_gold / 1000).toFixed(1).toString()}k</Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={0.5} >
                    {(playerState.items || []).filter((item, index, self) =>
                        index === self.findIndex(i => i.item_id === item.item_id)
                    ).map((item) => (
                        <ItemIcon
                            cooldown={item.item_cooldown}
                            key={item.item_id}
                            stacks={item.item_stacks}
                            item={context.ddragon_items[item.item_id]?.name || "Unknown Item"}
                            sx={{width:'25px', height:'25px'}} />
                    ))}
                    {/*<SmallDetailButton content={DebugJson} closeOnOutsideClick sx={{height:'10px', width:'10px'}}>*/}
                    {/*    <ArrowDropDown />*/}
                    {/*</SmallDetailButton>*/}
                </Stack>
            </Box>
        </NeonCard>
    );
}
