import {Champion, GameMetrics, League, Player, SoloQAccounts, Team} from "../../farsight-api";
import {Grow, Stack} from "@mui/material";
import MultipleMetricsSelect from "./Shared/MetricsSelect";
import React, {useEffect, useRef} from "react";
import LeaguesSelect, {SingleLeagueSelect} from "./Competitive/LeagueSelect";
import {Dayjs} from "dayjs";
import DatePicker from "./Shared/DateSelect";
import {AppContextProps, targets} from "../../Context";
import {MultiplePatchSelect} from "./PatchSelect";
import RoleSelect, {MultipleRoleSelect} from "./RoleSelect";
import {MultipleChampionSelect, SingleChampionSelect} from "./Shared/ChampionSelect";
import {useReferentialContext} from "../../Context";
import {BlindOrCounter, LaneSwap, OtpOnly, SideSelect} from "./YesNoSelector";
import {TeamSelect} from "./TeamSelect";
import YearSelect, {GameNumbersSelect, PercentageSelect, PhaseSelect, SplitSelect} from "./PeriodSelect";
import ServerSelect from "./ServerSelect";
import PlayerSelect, {ScrimPlayerSelect} from "./PlayerSelect";
import TimestampRangeSelect from "./TimestampRangeSelect";
import {MultipleWardTypeSelect} from "./Shared/WardTypes";
import AccountsSelect from "./SoloQ/AccountSelect";
import LPThresholdSelect from "./SoloQ/LPThresholdSelect";

export function to_parameters_object(props: {
    metrics?: GameMetrics[];
    leagues?: League[];
    league?: League;
    date__gte?: Dayjs;
    date__lt?: Dayjs;
    patches?: string[];
    roles?: string[];
    role?: string;
    champion?: Champion;
    picktype?: boolean;
    team?: Team;
    vs?: Team;
    side?: string;
    year?: number;
    split?: string;
    phases?: string[];
    champions?: Champion[];
    servers?: string[];
    player?: Player;
    target?: targets;
    scrimAccount?: string;
    soloQAccounts?: SoloQAccounts[];
    laneSwap?:boolean;
    lpThreshold?:number;
    gameNumbers?:number[];
    pickrate_gte?:number;
    otpOnly?:boolean;
    playersFromLeagues?:League[];
    ctx: AppContextProps;
}) {
    const to_ret = {} as any;
    to_ret.additional_filters = {};
    if (props.metrics) to_ret.metrics = props.metrics.map((metric) => metric.code).toString();
    if (props.leagues && !props.leagues.map(league => league.league_name).includes('All Leagues')) to_ret.additional_filters.league__in = props.leagues.map((league) => league.league_name).toString();
    if (props.playersFromLeagues && !props.playersFromLeagues.map(league => league.league_name).includes('All Leagues')) {
        const teams_selected = props.ctx.teamsReferential.filter(t =>
            props.playersFromLeagues?.map(l => l.league_name).includes(t.current_league ? t.current_league : 'No')).map(t => t.team_name)
        to_ret.additional_filters.competitive_player__in = props.ctx.playersReferential.filter(p => p.games_played_for_current_team_this_year && p.role === props.role && teams_selected.includes(p.current_team ? p.current_team : 'No')).map(p => p.player).toString();
    }
    if (props.date__gte) to_ret.additional_filters.date__gte = props.date__gte.toISOString();
    if (props.date__lt) to_ret.additional_filters.date__lt = props.date__lt.toISOString();
    if (props.patches) to_ret.additional_filters.patch__in = props.patches.toString();
    if (props.roles) to_ret.additional_filters.role__in = props.roles.toString();
    if (props.role) to_ret.role = props.role;
    if (props.champion) to_ret.champion = props.champion.id;
    if (props.player && props.target !== targets.soloq) to_ret.player = props.player.player;
    if (props.player && props.target === targets.soloq) to_ret.competitive_player = props.player.player;
    if (props.league) to_ret.league = props.league.league_name;
    if (props.team) to_ret.team1 = props.team.team_name;
    if (props.team) to_ret.team = props.team.team_name;
    if (props.vs) to_ret.team2 = props.vs.team_name;
    if (props.side) to_ret.side = props.side;
    if (props.year) to_ret.additional_filters.date__year = props.year;
    if (props.picktype !== null && props.picktype !== undefined) to_ret.is_cp = props.picktype ? 1 : 0;
    if (props.split) to_ret.split = props.split;
    if (props.phases) to_ret.additional_filters.phase__in = props.phases.toString();
    if (props.champions) to_ret.additional_filters.champion__in = props.champions.map((c) => c.id).toString();
    if (props.servers) to_ret.additional_filters.server__in = props.servers.toString();
    if (props.scrimAccount) to_ret.player = props.scrimAccount;
    if (props.soloQAccounts) to_ret.additional_filters.account__in = props.soloQAccounts.map(acc => acc.id);
    if (props.laneSwap !== null && props.laneSwap !== undefined) to_ret.lane_swap = props.laneSwap;
    if (props.lpThreshold !== null && props.lpThreshold !== undefined) to_ret.additional_filters.elo_as_total_lps_during_dame__gte = props.lpThreshold;
    if (props.gameNumbers !== null && props.gameNumbers !== undefined) to_ret.additional_filters.game__game_n__in = props.gameNumbers.toString();
    if (props.pickrate_gte !== null && props.pickrate_gte !== undefined) to_ret.additional_filters.pickrate__gte = props.pickrate_gte.toString();
    if (props.otpOnly !== null && props.otpOnly !== undefined) to_ret.is_played_by_otp = props.otpOnly ? 1 : 0;
    return to_ret;
}

export default function FilterSet(props: {
    paramObject: any;
    setParamObject: any;
    target: targets;
    updateContext?: boolean | undefined;
    metrics?: GameMetrics[];
    setMetrics?: any;
    leagues?: League[];
    setLeagues?: any;
    date__gte?: Dayjs;
    setDateGte?: any;
    date__lt?: Dayjs;
    setDateLt?: any;
    patches?: string[];
    setPatches?: any;
    role?: string;
    setRole?: any;
    champion?: Champion | null;
    setChampion?: any;
    league?: League | null;
    setLeague?: any;
    picktype?: boolean | null;
    setPicktype?: any;
    team?: Team | null;
    setTeam?: any;
    vs?: Team | null;
    setVs?: any;
    side?: string | null;
    setSide?: any;
    roles?: string[];
    setRoles?: any;
    year?: number;
    setYear?: any;
    split?: string | null;
    setSplit?: any;
    phases?: string[];
    setPhases?: any;
    champions?: Champion[];
    setChampions?: any;
    player?: Player|null;
    setPlayer?: any;
    servers?: string[];
    setServers?: any;
    ingameInterval?: number[];
    setIngameInterval?: any;
    wardTypes?: string[];
    setWardTypes?: any;
    scrimAccount?: string|null;
    setScrimAccount?: any;
    soloqAccounts?: SoloQAccounts[];
    setSoloQAccounts?: any;
    laneSwap?: boolean | null;
    setLaneSwap?: any;
    lpThreshold?: number;
    setLPThreshold? : any;
    gameNumbers? : number[];
    setGameNumbers? : React.Dispatch<React.SetStateAction<number[]>>;
    direction?: 'row' | 'column';
    pickrate_gte?: number;
    setPickrateGte?: React.Dispatch<React.SetStateAction<number>>;
    otpOnly?: boolean | null;
    setOtpOnly?: React.Dispatch<React.SetStateAction<boolean|null>>;
    playersFromLeagues?: League[];
    setPlayersFromLeagues?: any;
}) {
    const roleUpdated = useRef(false);

    useEffect(() => {
        if(props.setRole && props.champion && props.champion.main_role != props.role){
            props.setRole(props.champion.main_role);
            roleUpdated.current = true;
        }
    }, [props.champion]);

    useEffect(() => {
        if (roleUpdated.current) {
            // Skip first execution due to role update, reset flag
            roleUpdated.current = false;
            return;
        }

        const new_params_object = to_parameters_object({
            metrics: props.metrics ? props.metrics : undefined,
            leagues: props.leagues ? props.leagues : undefined,
            date__gte: props.date__gte ? props.date__gte : undefined,
            date__lt: props.date__lt ? props.date__lt : undefined,
            patches: props.patches ? props.patches : undefined,
            roles: props.roles ? props.roles : undefined,
            role: props.role ? props.role : undefined,
            picktype: props.picktype !== null && props.picktype !== undefined ? props.picktype : undefined,
            champion: props.champion ? props.champion : undefined,
            team: props.team ? props.team : undefined,
            vs: props.vs ? props.vs : undefined,
            side: props.side ? props.side : undefined,
            league: props.league ? props.league : undefined,
            year: props.year ? props.year : undefined,
            split: props.split ? props.split : undefined,
            phases: props.phases && props.phases.length > 0 ? props.phases : undefined,
            champions: props.champions && props.champions.length > 0 ? props.champions : undefined,
            servers: props.servers && props.servers.length > 0 ? props.servers : undefined,
            player: props.player ? props.player : undefined,
            scrimAccount: props.scrimAccount ? props.scrimAccount : undefined,
            soloQAccounts: props.soloqAccounts && props.soloqAccounts.length > 0  ? props.soloqAccounts: undefined,
            laneSwap: props.laneSwap !== null && props.laneSwap !== undefined ? props.laneSwap : undefined,
            lpThreshold: props.lpThreshold !== null && props.lpThreshold !== undefined && props.lpThreshold !== 0 ? props.lpThreshold : undefined,
            gameNumbers: props.gameNumbers && props.gameNumbers.length > 0 ? props.gameNumbers : undefined,
            pickrate_gte: props.pickrate_gte ? props.pickrate_gte : undefined,
            otpOnly: props.otpOnly !== null && props.otpOnly !== undefined ? props.otpOnly : undefined,
            target: props.target,
            playersFromLeagues: props.playersFromLeagues && props.playersFromLeagues.length > 0 ? props.playersFromLeagues : undefined,
            ctx: ctx
        });
        if (JSON.stringify(new_params_object) !== props.paramObject.watermark) {
            new_params_object.watermark = JSON.stringify(new_params_object);
            props.setParamObject(new_params_object);
        }
    }, [
        props.metrics,
        props.leagues,
        props.date__gte,
        props.date__lt,
        props.patches,
        props.role,
        props.champion,
        props.servers,
        props.picktype,
        props.team,
        props.side,
        props.league,
        props.year,
        props.split,
        props.phases,
        props.champions,
        props.player,
        props.ingameInterval,
        props.scrimAccount,
        props.soloqAccounts,
        props.vs,
        props.laneSwap,
        props.lpThreshold,
        props.gameNumbers,
        props.pickrate_gte,
        props.otpOnly,
        props.playersFromLeagues
    ]);
    console.log(props.updateContext)
    const ctx = useReferentialContext();
    return (
            <Stack direction={props.direction ? props.direction :'row'} sx={{ height: props.direction === 'column' ? '100%' : "55px", justifyContent: "left", width: "100%" }} spacing={2}>
                {props.player !== undefined ? <PlayerSelect value={props.player} onChange={props.setPlayer} referential={ctx.playersReferential}></PlayerSelect> : <></>}
                {props.champion !== undefined ? <SingleChampionSelect value={props.champion} updateValue={props.setChampion} updateContext={props.updateContext} /> : <></>}
                {props.league !== undefined ? <SingleLeagueSelect value={props.league} updateValue={props.setLeague} /> : <></>}
                {props.team !== undefined ? <TeamSelect value={props.team} updateValue={props.setTeam} updateContext={props.updateContext} /> : <></>}
                {props.vs !== undefined ? <TeamSelect value={props.vs} updateValue={props.setVs} updateContext={props.updateContext} /> : <></>}
                {props.leagues ? <LeaguesSelect values={props.leagues} updateValue={props.setLeagues} target={props.target} updateContext={props.updateContext} /> : <></>}
                {props.champions ? <MultipleChampionSelect values={props.champions} updateValue={props.setChampions} /> : <></>}
                {props.date__gte ? <DatePicker value={props.date__gte} onChange={props.setDateGte} label={"From"} /> : <></>}
                {props.date__lt ? <DatePicker value={props.date__lt} onChange={props.setDateLt} label={"Until"} /> : <></>}
                {props.patches ? <MultiplePatchSelect values={props.patches} updateValue={props.setPatches} updateContext={props.updateContext} /> : <></>}
                {props.roles ? <MultipleRoleSelect values={props.roles} updateValue={props.setRoles} /> : <></>}
                {props.role ? <RoleSelect value={props.role} onChange={props.setRole} updateContext={props.updateContext}/> : <></>}
                {props.metrics ? <MultipleMetricsSelect values={props.metrics} updateValue={props.setMetrics} target={props.target} updateContext={props.updateContext} /> : <></>}
                {props.picktype !== undefined ? <BlindOrCounter value={props.picktype} onChange={props.setPicktype} label={"Blind / Counter"} /> : <></>}
                {props.otpOnly !== undefined ? <OtpOnly value={props.otpOnly} onChange={props.setOtpOnly} label={"OTP"} /> : <></>}
                {props.side !== undefined ? <SideSelect value={props.side} onChange={props.setSide} /> : <></>}
                {props.year !== undefined ? <YearSelect value={props.year} onChange={props.setYear} /> : <></>}
                {props.pickrate_gte !== undefined ? <PercentageSelect value={props.pickrate_gte} onChange={props.setPickrateGte} label={'Pickrate'} min={0} max={0.1}/> : <></>}
                {props.split !== undefined ? <SplitSelect value={props.split} onChange={props.setSplit} /> : <></>}
                {props.phases !== undefined ? <PhaseSelect value={props.phases} onChange={props.setPhases} /> : <></>}
                {props.servers ? <ServerSelect values={props.servers} updateValue={props.setServers} /> : <></>}
                {props.ingameInterval ? <TimestampRangeSelect value={props.ingameInterval} onChange={props.setIngameInterval} /> : <></>}
                {props.wardTypes ? <MultipleWardTypeSelect values={props.wardTypes} updateValue={props.setWardTypes} /> : <></>}
                {props.scrimAccount !== undefined ? <ScrimPlayerSelect value={props.scrimAccount} onChange={props.setScrimAccount} /> : <></>}
                {props.soloqAccounts !== undefined && props.player? <AccountsSelect player={props.player} onChange={props.setSoloQAccounts} value={props.soloqAccounts}/> : <></>}
                {props.laneSwap !== undefined ? <LaneSwap value={props.laneSwap} onChange={props.setLaneSwap} label={'Lane Swap'}/> : <></> }
                {props.lpThreshold !== undefined ? <LPThresholdSelect value={props.lpThreshold} onChange={props.setLPThreshold}/> : <></> }
                {props.gameNumbers !== undefined && props.setGameNumbers !== undefined? <GameNumbersSelect value={props.gameNumbers} onChange={props.setGameNumbers}/> : <></> }
                {props.playersFromLeagues ? <LeaguesSelect values={props.playersFromLeagues} updateValue={props.setPlayersFromLeagues} target={props.target} updateContext={false} label={'Players from Leagues'} /> : <></>}
            </Stack>
    );
}
