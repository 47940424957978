import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { SelectChangeEvent } from "@mui/material/Select";
import { Typography } from "@mui/material";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import dayjs from "dayjs";
import duration, {Duration} from "dayjs/plugin/duration";

function valuetext(value: number) {
    return `${new Date(value * 1000).toISOString().substring(14, 19)}`;
}

export default function TimestampRangeSelect(props: { value: any; onChange: any }) {
    const [value, setValue] = useState(props.value);
    const [isFocused, setIsFocused] = useState(false); // Track focus for styling
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    const marks = [
        {
            value: 20 * 60,
            label: valuetext(20 * 60),
        },
        {
            value: 15 * 60,
            label: valuetext(15 * 60),
        },
        {
            value: 10 * 60,
            label: valuetext(10 * 60),
        },
        {
            value: 5 * 60,
            label: valuetext(5 * 60),
        },
        {
            value: 25 * 60,
            label: valuetext(25 * 60),
        },
    ];

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "300px",
                height: "56px",
                borderRadius: 1,
                padding: "12px 16px",
                border: isFocused ? "1px solid white" : "1px solid rgba(255, 255, 255, 0.6)",
                "&:hover": { border: "1px solid white" }, // Thicker border on hover
                "&:focus-within": { border: "1px solid white" }, // Thicker border on focus
            }}
            onMouseEnter={() => setIsFocused(true)}
            onMouseLeave={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            {/* Floating Label like MUI TextField */}
            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    top: isFocused || value ? "-10px" : "50%", // Ensure proper centering
                    left: "12px",
                    transform: isFocused || value ? "none" : "translateY(-50%)", // Align properly
                    fontSize: isFocused || value ? "12px" : "16px",
                    color: isFocused ? "white" : "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "#121212", // Matches MUI TextField behavior
                    padding: "0 4px",
                }}
            >
                Timestamp
            </Typography>
            <Slider
                getAriaLabel={() => "Timestamp range"}
                min={0}
                max={60 * 30}
                step={30}
                value={value}
                onChange={handleChange}
                onChangeCommitted={(event, value) => props.onChange(value)}
                valueLabelDisplay="auto"
                valueLabelFormat={valuetext}
                getAriaValueText={valuetext}
                marks={marks}
                style={{ marginTop: "-15px" }}
            />
        </Box>
    );
}

export function Depth(props: { value: any; onChange: any; label?: string }) {
    const [value, setValue] = useState(props.value);
    const [isFocused, setIsFocused] = useState(false); // Track focus for styling

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "300px",
                height: "56px",
                borderRadius: 1,
                padding: "12px 16px",
                border: isFocused ? "1px solid white" : "1px solid rgba(255, 255, 255, 0.6)",
                "&:hover": { border: "1px solid white" }, // Thicker border on hover
                "&:focus-within": { border: "1px solid white" }, // Thicker border on focus
            }}
            onMouseEnter={() => setIsFocused(true)}
            onMouseLeave={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            {/* Floating Label like MUI TextField */}
            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    top: isFocused || value ? "-10px" : "50%", // Ensure proper centering
                    left: "12px",
                    transform: isFocused || value ? "none" : "translateY(-50%)", // Align properly
                    fontSize: isFocused || value ? "12px" : "16px",
                    color: isFocused ? "white" : "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "#121212", // Matches MUI TextField behavior
                    padding: "0 4px",
                }}
            >
                {props.label ? props.label : "Depth"}
            </Typography>

            {/* Slider */}
            <Slider
                getAriaLabel={() => "Timestamp range"}
                min={1}
                max={6}
                step={1}
                value={value}
                onChange={handleChange}
                onChangeCommitted={props.onChange}
                valueLabelDisplay="auto"
                sx={{
                    color: "white",
                    marginTop: "12px", // Adjust so the slider sits correctly
                    "& .MuiSlider-thumb": { backgroundColor: "white" },
                    "& .MuiSlider-track": { backgroundColor: "white" },
                    "& .MuiSlider-rail": { backgroundColor: "rgba(255, 255, 255, 0.3)" },
                }}
            />
        </Box>
    );
}

export const TimestampSelect = forwardRef((props: { value: number; onChange: any; label?: string, max: number }, ref) => {
    const sliderRef = useRef<HTMLSpanElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    useImperativeHandle(ref, () => ({
        focus: () => {
            // Attempt to select the slider thumb using ARIA
            console.log(sliderRef.current);
            const sliderThumb = sliderRef.current?.querySelector('input[type="range"]') as HTMLElement | null;
            if (sliderThumb) {
                sliderThumb.focus(); // Now this will work!
            }
        }
    }));

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "250px",
                height: "56px",
                borderRadius: 1,
                padding: "12px 16px",
                border: isFocused ? "1px solid white" : "1px solid rgba(255, 255, 255, 0.6)",
                "&:hover": { border: "1px solid white" },
                "&:focus-within": { border: "1px solid white" },
            }}
            onMouseEnter={() => setIsFocused(true)}
            // onMouseLeave={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            // onBlur={() => setIsFocused(false)}
        >
            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "12px",
                    fontSize: "12px",
                    color: isFocused ? "white" : "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "#121212",
                    padding: "0 4px",
                }}
            >
                {valuetext(props.value)}
            </Typography>
            <Slider
                ref={sliderRef}
                getAriaLabel={() => "Timestamp"}
                min={0}
                max={props.max}
                step={1}
                value={props.value}
                onChange={(event: Event, newValue: number | number[]) => props.onChange(newValue as number)}
                valueLabelDisplay="auto"
                valueLabelFormat={valuetext}
                getAriaValueText={valuetext}
                tabIndex={0}
                autoFocus={true}
                aria-selected={true}
                role="slider"
                aria-valuenow={props.value}
                aria-valuemin={0}
                aria-valuemax={props.max}
                aria-activedescendant="slider-thumb" // Ensure correct thumb activation
            />
        </Box>
    );
});
