import React from "react";
import {Box, Button, Typography, Stack, useTheme} from "@mui/material";
import {
  NeonCard,
  NeonCardHeader,
  NeonCardContent,
} from "../../NeonComponents/NeonCards";
import SmallDetailButton from "../Buttons/SmallDetailsButton";
import AbilityIcon from "../Icons/AbilityIcon";
import ChampionIcon from "../Icons/ChampionIcon";
import { ArrowDropDown } from "@mui/icons-material";
import {ObjectiveKilled} from "../../farsight-api";
import {
  AtakhanIcon,
  DragonAirIcon,
  DragonChemtechIcon,
  DragonEarthIcon, DragonFireIcon,
  DragonHextechIcon, DragonWaterIcon, NashorIcon,
  RiftHeraldIcon,
  VoidGrubsIcon
} from "../Icons/SvgIcons";
import {getNeonColor, SIDE_COLORS} from "../../theme";

/************************************************
 * Types
 ************************************************/
interface EventItem {
  type: string; // e.g. CHAMPION_KILL, WARD_EVENT, etc.
  data: any;    // event data
  player: string;
  summary: any;
}

interface EventCardProps {
  event: EventItem;
  setTimestamp?: (ts: number) => void;
}

/************************************************
 * 1) Format event.data.timestamp => mm:ss
 ************************************************/
export function formatTimestamp(seconds: number): string {
  const mm = String(Math.floor(seconds / 60)).padStart(2, "0");
  const ss = String(seconds % 60).padStart(2, "0");
  return `${mm}:${ss}`;
}

/************************************************
 * 2) Convert underscores to capitalized words
 ************************************************/
export function humanize_event_type(str: string) {
  return str
    .split("_")
    .map(
      (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
    )
    .join(" ");
}

/************************************************
 * 3) buildTitle merges your approach:
 * - WARD_EVENT => data.type => e.g. "killed_ward" => "Killed Ward"
 * - FLASH_FORCED => "Flash Forced"
 * - else => humanize(type)
 ************************************************/
function buildTitle(type: string, data: any) {
  if (type === "WARD_EVENT" && data?.type) {
    return humanize_event_type(data.type);
  }
  if (type === "FLASH_FORCED") {
    return "Flash Forced";
  }
  if (type === "SUMMONER_SPELL_USED") {
    return `Used ${data.summoner.replace('Summoner','')}`;
  }
  if (type === "CHAMPION_KILL" && data.participant_got === 'DEATH') {
    return "Died";
  }
  return humanize_event_type(type); // e.g. "Champion Kill", "Building Kill", etc.
}

/************************************************
 * ShowPositionButton => The map icon on far right
 ************************************************/
function ShowPositionButton({
  coords,
  iconOnly = false,
}: {
  coords: [number, number];
  iconOnly?: boolean;
}) {
  const containerSize = 200;
  const posX = Math.round(coords[0] * containerSize);
  const posY = Math.round(coords[1] * containerSize);

  const MapPreview = (
    <Box
      sx={{
        position: "relative",
        width: containerSize,
        height: containerSize,
        overflow: "hidden",
      }}
    >
      {/* Summoner's Rift Map background */}
      <Box
        component="img"
        src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${containerSize},w_${containerSize}/leagueAssets/SummonersRift_awfeps.png`}
        sx={{ width: containerSize, height: containerSize }}
      />
      {/* The point marker */}
      <Box
        sx={{
          position: "absolute",
          top: posY - 5,
          left: posX - 5,
          width: 10,
          height: 10,
          backgroundColor: "red",
          borderRadius: "50%",
          border: "2px solid white",
          pointerEvents: "none",
        }}
      />
    </Box>
  );

  return (
    <SmallDetailButton content={MapPreview} closeOnOutsideClick>
      {iconOnly ? (
        <Box
          component="img"
          src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_20,w_20/leagueAssets/SummonersRift_awfeps.png`}
          sx={{ width: 20, height: 20 }}
        />
      ) : (
        "Show Position"
      )}
    </SmallDetailButton>
  );
}

/************************************************
 * Specialized Cards
 ************************************************/
function ChampionKillCard({ event }: { event: EventItem }) {
  const { data } = event;
  const { position, champion_killer, champion_assists, champion_killed } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      {/* Left content */}
      <Stack direction="row" spacing={1} alignItems="center">
        {champion_killer ? (
          <ChampionIcon champion={champion_killer} role={'TOP_LANE'} disable_link/>
        ) : (
          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            (No direct killer)
          </Typography>
        )}
        {champion_assists && champion_assists.length > 0 && (
          <Stack direction="row" spacing={1} alignItems="center">
            {champion_assists.map((assistID: string, idx: number) => (
              <ChampionIcon key={idx} champion={assistID} role={'TOP_LANE'} disable_link/>
            ))}
          </Stack>
        )}
        <Typography variant="body2">killed</Typography>
        <ChampionIcon champion={champion_killed} role={'TOP_LANE'} disable_link/>
      </Stack>

      {/* Right content: position icon */}
      {position && <ShowPositionButton coords={position} iconOnly />}
    </Stack>
  );
}

function BuildingKillCard({ event }: { event: EventItem }) {
  const { data } = event;
  const { position, building_type, lane } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Typography variant="body2">
        Building: {building_type} - Lane: {lane}
      </Typography>
      {position && <ShowPositionButton coords={position} iconOnly />}
    </Stack>
  );
}

function MonsterKillCard({ event }: { event: EventItem }) {
  const { data } = event;
  const { monster_type, position } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Typography variant="body2">Monster: {monster_type}</Typography>
      {position && <ShowPositionButton coords={position} iconOnly />}
    </Stack>
  );
}

function WardEventCard({ event }: { event: EventItem }) {
  const { data } = event;
  const { ward_type, position } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      {/* left content */}
      <Stack direction="row" spacing={1} alignItems="center">
        <AbilityIcon ability={ward_type} cooldown={0} />
      </Stack>
      {/* right content */}
      {position && <ShowPositionButton coords={position} iconOnly />}
    </Stack>
  );
}

export function SweeperEventCard({ event }: { event: EventItem }) {
  const { data } = event;
  const { position } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      {/* left content */}
      <Stack direction="row" spacing={1} alignItems="center">
        <AbilityIcon ability={'Oracle Lens'} cooldown={0} />
      </Stack>
      {/* right content */}
      {position && <ShowPositionButton coords={position} iconOnly />}
    </Stack>
  );
}


function FlashForcedCard({ event }: { event: EventItem }) {
  const { data } = event;
  const { summoner, champion, position } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      {/* left content */}
      <Stack direction="row" spacing={1} alignItems="center">
        <ChampionIcon champion={champion} role={'TOP_LANE'} disable_link/>
      </Stack>
      {/* right content */}
      {position && <ShowPositionButton coords={position} iconOnly />}
    </Stack>
  );
}

function SummonerSpellUsedCard({ event }: { event: EventItem }) {
  const { data } = event;
  const { summoner, champion, position } = data;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      {/* left content */}
      <Stack direction="row" spacing={1} alignItems="center">
        <AbilityIcon ability={data.summoner}/>
      </Stack>
      {/* right content */}
      {position && <ShowPositionButton coords={position} iconOnly />}
    </Stack>
  );
}

/************************************************
 * Main Aggregator Card
 ************************************************/
export function EventCard({ event, setTimestamp }: EventCardProps) {
  const { type, data } = event;

  const timestampSeconds = Math.floor(data.timestamp || 0);
  const displayedTimestamp = formatTimestamp(timestampSeconds);

  // debug content
  const DebugJson = (
    <Box sx={{ maxWidth: 300, whiteSpace: "pre-wrap", fontSize: "0.7rem" }}>
      {JSON.stringify(data, null, 2)}
    </Box>
  );

  // specialized content
  let specializedContent: React.ReactNode;
  switch (type) {
    case "CHAMPION_KILL":
      specializedContent = <ChampionKillCard event={event} />;
      break;
    case "BUILDING_KILL":
      specializedContent = <BuildingKillCard event={event} />;
      break;
    case "FLASH_FORCED":
      specializedContent = <FlashForcedCard event={event} />;
      break;
    case "MONSTER_KILL":
      specializedContent = <MonsterKillCard event={event} />;
      break;
    case "WARD_EVENT":
      specializedContent = <WardEventCard event={event} />;
      break;
    case "SWEEPER_USED":
      specializedContent = <SweeperEventCard event={event} />;
      break;
    case "SUMMONER_SPELL_USED":
      specializedContent = <SummonerSpellUsedCard event={event} />;
      break;
    default:
      specializedContent = (
        <Typography variant="body2" fontWeight="bold">
          Unknown Event: {type}
        </Typography>
      );
  }

  // final title
  const finalTitle = buildTitle(type, data);

  return (
    <NeonCard
      sx={{
        marginBottom: 1,
        padding: 1,
        height: "135px",
        minHeight: "135px",
        maxHeight: "135px",
        width: "310px",
        cursor:'pointer'
      }}
      zoom_on_hover={true}
      onClick={() => setTimestamp ? setTimestamp(timestampSeconds) : () => true}
    >
      <NeonCardHeader
        sx={{ height: "50%" }}
        subheader={displayedTimestamp}
        title={finalTitle}
        action={
          <Stack direction="row" spacing={1}>
            {/* Debug JSON button => arrow icon just for style */}
            <SmallDetailButton content={DebugJson} closeOnOutsideClick>
              <ArrowDropDown />
            </SmallDetailButton>
          </Stack>
        }
      />

      <NeonCardContent sx={{ padding: 0, height: "50%", width: "100%" }}>
        {specializedContent}
      </NeonCardContent>
    </NeonCard>
  );
}

export function ObjectiveCard({event, setTimestamp}: {event: ObjectiveKilled, setTimestamp: (ts: number) => void;}){
  const timestampSeconds = Math.floor(event.timestamp || 0);
  const displayedTimestamp = formatTimestamp(timestampSeconds);

  let specializedContent: React.ReactNode;
  switch (event.monster_type){
    case "dragon":
      switch (event.sub_type){
        case "air":
          specializedContent = <DragonAirIcon/>;
          break;
        case "chemtech":
          specializedContent = <DragonChemtechIcon/>;
          break;
        case "hextech":
          specializedContent = <DragonHextechIcon/>;
          break;
        case "earth":
          specializedContent = <DragonEarthIcon/>;
          break;
        case "water":
          specializedContent = <DragonWaterIcon/>;
          break;
        case "fire":
          specializedContent = <DragonFireIcon/>;
          break;
        default:
          specializedContent = (
            <Typography variant="body2" fontWeight="bold">
              Unknown Event: {event.monster_type} {event.sub_type}
            </Typography>
          );
      }
      break;
    case "VoidGrub":
      specializedContent = <VoidGrubsIcon/>;
      break;
    case "riftHerald":
      specializedContent = <RiftHeraldIcon/>;
      break;
    case "VoraciousAtakhan":
      specializedContent = <AtakhanIcon color={"orange"}/>;
      break;
    case "Atakhan":
      specializedContent = <AtakhanIcon color={event.sub_type === 'VoraciousAtakhan' ? "orange" : "Black"}/>;
      break;
    case "RuinousAtakhan":
      specializedContent = <AtakhanIcon color={"black"}/>;
      break;
    case "baron":
      specializedContent = <NashorIcon />;
      break;
    case "Baron":
      specializedContent = <NashorIcon/>;
      break;
    default:
      specializedContent = (
        <Typography variant="body2" fontWeight="bold">
          Unknown Event: {event.monster_type} {event.sub_type}
        </Typography>
      );
  }

  const DebugJson = (
    <Box sx={{ maxWidth: 300, whiteSpace: "pre-wrap", fontSize: "0.7rem" }}>
      {JSON.stringify(event, null, 2)}
    </Box>
  );
  const theme = useTheme();
  return <NeonCard
      sx={{
        marginBottom: 1,
        padding: 1,
        height: "135px",
        minHeight: "135px",
        maxHeight: "135px",
        width: "310px",
        "&::before": event.killer_team_id ? {
          content: "''",
          position: "absolute",
          bottom: "10%",
          top:"10%",
          left: "0px",
          width: "3px",
          height: "80%",
          background: event.killer_team_id === 100 ? SIDE_COLORS.Blue : SIDE_COLORS.Red,
          boxShadow: `10px 10px 20px ${event.killer_team_id === 100 ? SIDE_COLORS.Blue : SIDE_COLORS.Red}, 0px 0px 20px ${event.killer_team_id === 100 ? SIDE_COLORS.Blue : SIDE_COLORS.Red}`,
          opacity: 1,
          pointerEvents: "none",
        } : undefined,
      }}
  >
    <NeonCardHeader
        sx={{ height: "50%" }}
        subheader={displayedTimestamp}
        title={event.type?.includes('spawn') ? 'Spawn' : 'Kill'}
        action={
          <Stack direction="row" spacing={1}>
            {/* Debug JSON button => arrow icon just for style */}
            <SmallDetailButton content={DebugJson} closeOnOutsideClick>
              <ArrowDropDown />
            </SmallDetailButton>

            {/* 'Go' button => sets the player's timestamp */}
            <Button
              variant="outlined"
              size="small"
              onClick={() => setTimestamp(timestampSeconds)}
            >
              Go
            </Button>
          </Stack>
        }
      />
      <NeonCardContent sx={{ padding: 0, height: "50%", width: "100%" }}>
        {specializedContent}
      </NeonCardContent>
  </NeonCard>
}
