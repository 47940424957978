import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    Fade,
    Grid,
    Grow,
    Link,
    Paper,
    Slider,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from "dayjs";
import { DraftBlockCompetitive, DraftBlockScrim } from "../../../components/visu_blocks/Drafts";
import Button from "@mui/material/Button";
import DatePicker, { TimePicker } from "../../../components/selectors/Shared/DateSelect";
import {DdragonChampion, targets, useReferentialContext} from "../../../Context";
import {GamesData, GamesDataApi, ScrimsData, ScrimsDataApi, ScrimSession} from "../../../farsight-api";
import WindowContainer from "../../../components/layout/WindowContainer";
import TeamIcon from "../../../components/Icons/TeamIcon";
import {NeonCard, NeonCardHeader} from "../../../NeonComponents/NeonCards";
import PaginatedStack from "../../../components/Utilities/PaginatedStack";
import {Skeleton} from "@mui/lab";
import {ArrowDropDown, CheckBox} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import ChampionIcon from "../../../components/Icons/ChampionIcon";
import {SingleChampionSelect} from "../../../components/selectors/Shared/ChampionSelect";
import {loss_color, scrollable, win_color} from "../../../theme";

export default function ScrimCalendar() {
    const [data, setData] = useState([] as ScrimSession[]);
    const [selected, setSelected] = useState(0);
    const api = useReferentialContext().farsightApi.scrimSession;


    useEffect(() => {
        api.list({ordering:['-date'], additional_filters: {date__gte: dayjs().subtract(6, 'months').toISOString()}}).then(result => setData(result))
        }, []
    )

    return (
        <WindowContainer direction={'row'} spacing={2}>
            <Planning data={data} selected={selected} setSelected={setSelected} />
            <SessionViewer session={data[selected]} />
        </WindowContainer>
    );
}

function Planning(props: {data: ScrimSession[], selected: number, setSelected: any}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const user_team = useReferentialContext().user.team;

    const { data, selected, setSelected } = props;
    return (
        <NeonCard sx={{height: "99%",
                minWidth: "550px",
                maxWidth: '25%',
                "&::after": {},
                padding: '10px'
        }}>
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                width:'100%'
            }}
        >
            <NeonCardHeader title={
                <Stack direction={'row'} sx={{width:'100%',display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                <Typography variant={'h5'}>
                    Calendar
                </Typography>
                <TeamIcon team_name={`${user_team}`}/>
            </Stack>
            } disableTypography={true}/>
            <Stack
                direction={"column"}
                sx={{
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 1,
                    ...scrollable,
                    padding: "5px",
                }}
                spacing={0.5}
            >
                {/*<legend>Calendar</legend>*/}
                {data.map((session: any, index: number) => (
                    <CalendarRow session={session} selected={index === selected} onClick={() => setSelected(index)} index={index} />
                ))}
            </Stack>
        </Stack>
       </NeonCard>
    );
}

function SessionEdit(props: {session: ScrimSession, setSession: React.Dispatch<React.SetStateAction<ScrimSession>>}){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [editSessionDetailsOpen, setEditSessionDetailsOpen] = useState(false);
    // const games_in_fearless = props.session.fearless_bans?.split(',').length / 10
    const [fearless, setFearless] = useState(props.session.is_fearless);
    const [fearlessNotedBans, setFearlessNotedBans] = useState(props.session.fearless_noted_bans);
    const [fearlessNewChampSelected, setFearlessNewChampSelected] = useState(null as null|DdragonChampion);
    const [warmup, setWarmup] = useState(props.session.is_warmup);
    const api = useReferentialContext().farsightApi.scrimSession;

    return <Box >
        <Button sx={{fontWeight:'bold'}} variant={'outlined'} onClick={(event) => {
            setEditSessionDetailsOpen(true);
            setAnchorEl(event.currentTarget);
        }}>
            <Stack direction={'row'}>Edit<ArrowDropDown/>
            </Stack>
        </Button>
        <Menu open={editSessionDetailsOpen} anchorEl={anchorEl} onClick={() => {

        }}>
            <Stack direction={'column'} sx={{width:'300px', padding:'5px'}} spacing={1}>
                <Stack direction={'row-reverse'} sx={{width:'100%'}} spacing={2}>
                    <Button variant={'outlined'} sx={{fontWeight:'bold'}} onClick={() => {
                        setEditSessionDetailsOpen(false);
                        setAnchorEl(null);
                        api.patch({ id: props.session.id.toString(), patched_scrim_session: {
                            fearless_noted_bans: fearlessNotedBans, is_fearless: fearless, is_warmup: warmup
                            }
                        }).then(r => props.setSession(r))
                    }}>Save</Button>
                    <Button variant={'outlined'} sx={{fontWeight:'bold'}} onClick={() => {
                        setEditSessionDetailsOpen(false);
                        setAnchorEl(null);
                    }}>Close</Button>
                </Stack>
            <Stack direction={'row'} sx={{alignItems:'center', width:'100%'}}>
                <Checkbox checked={warmup} onClick={() => setWarmup(!warmup)}/>
                <Typography sx={{padding:'5px'}}>Warmup</Typography>
            </Stack>
            <Stack direction={'row'} sx={{alignItems:'center', width:'100%'}}>
                <Checkbox checked={fearless} onClick={() => setFearless(!fearless)}/>
                <Typography sx={{padding:'5px'}}>Fearless</Typography>
            </Stack>
            {fearless ? <Divider>Fearless Champions</Divider> : undefined}
            {fearless ? <Stack alignSelf={'center'} direction={'row'} sx={{width:'220px', flexWrap: "wrap",alignItems:'space-around', justifyContent:'space-around'}}>{props.session.fearless_bans?.split(',').map(c => <ChampionIcon champion={c} role={'TOP_LANE'}/>)}</Stack> : undefined}
            {fearless ? <Stack direction={'row'} sx={{width:'100%'}} spacing={1}>
                {fearlessNotedBans?.split(',').map(c => <ChampionIcon champion={c} role={'TOP_LANE'}/>)}
                <Button variant={'outlined'} onClick={() => setFearlessNotedBans(undefined)}>Remove</Button>
            </Stack> : undefined}
            {fearless ? <Stack direction={'row'} sx={{width:'100%'}} spacing={2}>
                <SingleChampionSelect value={fearlessNewChampSelected} updateValue={setFearlessNewChampSelected}/>
                <Button variant={'outlined'} onClick={() => {
                    if(fearlessNewChampSelected) {
                        if (!fearlessNotedBans?.includes(fearlessNewChampSelected.name)) {
                            if (fearlessNotedBans) setFearlessNotedBans(fearlessNotedBans + ',' + fearlessNewChampSelected.name)
                            else setFearlessNotedBans(fearlessNewChampSelected.name)
                        }
                    }
                }}>Add to Fearless</Button>
                </Stack>: undefined}
            </Stack>
        </Menu>
    </Box>
}

function CalendarRow(props: { session: ScrimSession; selected: boolean; onClick: any; index: number }) {
    const [session, setSession] = useState(props.session);
    const context = useReferentialContext();
    const series_league = context.leaguesReferential.filter(l => l.league_name === session.official_league)[0];

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: Math.floor(200 * (props.index > 15 ? 0 : props.index + 1)) }}>
            <Stack
                direction={"row"}
                sx={{
                    position: 'relative',
                    minHeight: "75px",
                    maxHeight:'75px',
                    width: "100%",
                    backgroundColor: session.games_won  !== null && session.games_lost !== null && session.games_won  !== undefined && session.games_lost !== undefined? (session.games_won >= session.games_lost ? win_color : loss_color) : '#3b3b3b',
                    alignItems: "center",
                    justifyContent: "space-around",
                    // outline: props.selected ? "2px solid white" : `2px solid ${session.games_won >= session.games_lost ? win_color : loss_color}`,
                    borderRadius: 2,
                    "&:hover": {
                        cursor: "pointer", // Change the cursor to a pointer on hover
                    },
                    "&::before": props.session.is_official_series
                      ? {
                          content: "''",
                          position: "absolute",
                          top: "15%",
                          bottom: "15%",
                          left: "0px",
                          width: '1px',
                          height:'70%',
                            background: '#ffffff',
                          boxShadow: `0px 0px 5px #fff, 0px 0px 20px #fff`,
                        }
                      : {},
                    "&::after": props.selected ? {
                        content: "''",
                        position: "absolute",
                        bottom: "0px",
                        left: "5%",
                        width: "90%",
                        height: "1px",
                        background: '#ffffff',
                        boxShadow: `0px 0px 10px #fff, 0px 0px 20px #fff`,
                        opacity: 1,
                        pointerEvents: "none",
                      } : {},
                }}
                onClick={props.onClick}
            >
                <TeamIcon team_name={`${session.vs}`} />
                {/*<Grow in={true} style={{ transformOrigin: '0 -50 0' }} {...({timeout: Math.floor(200 * (props.index + 1))})}>*/}
                {/*    <Typography>{`${session.vs}`}</Typography>*/}
                {/*</Grow>*/}
                <Stack direction={'column'} sx={{alignItems:'center', justifyContent:'center'}}>
                    <Typography>
                        {dayjs(session.date).format("MMM DD")}
                    </Typography>
                    {dayjs(session.date).format("HH:mm")}
                </Stack>
                <Typography>{`${session.games_won} - ${session.games_lost}`}</Typography>
                <Stack direction={'column'} sx={{width:'40px', height:'90%', alignItems:'center', justifyContent:'center'}}>
                    {session.is_fearless ? <Tooltip title={'Fearless Draft Series'}><Typography>FL</Typography></Tooltip> : undefined}
                    {session.is_warmup ?  <Tooltip title={'Warmup Games'}><Typography>WM</Typography></Tooltip> : undefined}
                    {session.is_official_series && series_league ? <Box component={"img"} src={
                        series_league.logo ? series_league.logo :
                            `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/teamsLogo/Boa.png`} sx={{width:'auto', maxWidth:'40px', maxHeight:'40px', height:'auto'}}/> : undefined}
                </Stack>
                <SessionEdit session={session} setSession={setSession}/>
            </Stack>
        </Grow>
    );
}

function SessionViewer(props: { session: ScrimSession|undefined }) {
    const [data, setData] = useState([] as any[]);
    const farsight_api = useReferentialContext().farsightApi;

    useEffect(() => {
        setData([]);
        if (props.session){
             if (props.session.is_official_series){
                const api = farsight_api.gamesData;
                api.list(
                    {match_id: props.session.official_match_id, ordering:['-date']} as any
                ).then((r: GamesData[]) => {
                    setData(r)
                });
            }
            else {
                const api = farsight_api.scrimsData;
                api.list(
                    {session_1: props.session.id, ordering: ['-date']} as any
                ).then((r: ScrimsData[]) => {
                    const games = r.filter((game: ScrimsData) => game.winner !== '');
                    setData(games);
                });
            }
        }
    }, [props.session]);

    return (
        <Stack direction={"column"} spacing={2} sx={{ width: "100%", height:'99%' }}>
            <GamesOverview games={data} session={props.session} />
            <DraftsGrid games={data} />
        </Stack>
    );
}

function GamesOverview(props: { games: any[]; session: any }) {
    const data = props.games;
    return (
        <NeonCard sx={{
                height: `${props.session ? 5 * 53 + 60 : 500}px`,
                width: "80%",
                maxWidth: '1000px',
                borderRadius: 2,
                alignItems: "center",
                padding: "5px",
            }}>
        <Stack
            direction={"column"}
            sx={{
                height: `100%`,
                width: "100%",
                alignItems: "center",
                ...scrollable,
                overflow: "auto",
            }}
            spacing={"3px"}
        >
            <NeonCardHeader title={'Games'}/>
            {data ? data.map((game: any, index: number) => <GameRow game={game} index={data.length - index - 1} />) : <Typography></Typography>}
        </Stack>
            </NeonCard>
    );
}

function GameRow(props: { game: GamesData; index: number }) {
    const user = useReferentialContext().user
    const api = useReferentialContext().farsightApi;
    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: Math.floor(200 * (props.index + 1)) }}>
            <Stack
                direction={"row"}
                sx={{
                    width: "98%",
                    minHeight: "50px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    display: "flex",
                    backgroundColor: props.game.winner === 'Remake' ? '#3b3b3b' : (props.game.winner === user.team ? win_color : loss_color),
                    borderRadius: 2,
                }}
                spacing={1}
            >
                <Tooltip title={"Click to download replay"} followCursor={true}>
                    <Button
                        variant={"outlined"}
                        onClick={() => api.download_file('Downloads/GameReplay',
                            {game_id:props.game.game_id, target: targets.scrims},
                            `${dayjs(props.game.date).format('MMM DD')} - ${props.game.team1} vs ${props.game.team2} - game ${props.index +1}.rofl`)}>
                        {'Replay'}
                    </Button>
                </Tooltip>
                <Typography
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        ml: 1,
                        mr: 1,
                        width:'30px'
                    }}
                >
                    {props.game.patch}
                </Typography>
                <TeamIcon team_name={props.game.team1}/>
                <Typography
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        ml: 1,
                        mr: 1,

                    }}
                >
                    {props.game.drafting_sim_team1_pred ? `${(props.game.drafting_sim_team1_pred*100).toFixed(2)}%` : ''}
                </Typography>
                <Stack direction={"row"}>
                    {props.game.team1champions.split(",").map((champion: string) => (
                            <ChampionIcon champion={champion} role={'TOP_LANE'}/>
                    ))}
                    <Fade
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        {...{
                            timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                        }}
                    >
                        <Typography
                            sx={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                ml: 1,
                                mr: 1,
                                width:'100px'
                            }}
                        >
                            {props.game.duration}
                        </Typography>
                    </Fade>
                    {props.game.team2champions.split(",").map((champion: string) => (
                        <ChampionIcon champion={champion} role={'TOP_LANE'}/>
                    ))}
                </Stack>
                <Typography
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        ml: 1,
                        mr: 1,
                    }}
                >
                    {props.game.drafting_sim_team2_pred ? `${(props.game.drafting_sim_team2_pred*100).toFixed(2)}%` : ''}
                </Typography>
                <TeamIcon team_name={props.game.team2}/>
                <Tooltip title={props.game.winner === props.game.drafting_sim_predicted_winner ? 'Draft prediction was correct' : 'Draft prediction was incorrect'}>
                    <Checkbox checked={props.game.winner === props.game.drafting_sim_predicted_winner}/>
                </Tooltip>
                {/*<Typography>{`${props.game.team1_id}`}</Typography>*/}
            </Stack>
        </Grow>
    );
}

function DraftsGrid(props: { games: ScrimsData[] }) {
    const user = useReferentialContext().user
    return (
            props.games ? <PaginatedStack
                direction={"row"}
                sx={{
                    maxWidth: `${window.innerWidth - 700}px`,
                    minHeight: '580px',
                    height:'100%',
                    flex: 1,
                    alignItems: "center",
                    justifyContent:'left',
                    borderRadius: 2,
                    width:'100%',
                }}
                spacing={1}
            >
                {props.games.filter(g => g.draft_mode === 'Blind' || g.draft_mode === 'Draft').map((row: any, index: number) =>
                    <Box sx={{width:'350px', height:'100%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <DraftBlockScrim draft={row} team_target={user.team} index={index}/>
                    </Box>)}
            </PaginatedStack>: <Box sx={{
                    maxWidth: `${window.innerWidth - 700}px`,
                    minHeight: '580px',
                    height:'100%',
                    flex: 1,
                    alignItems: "center",
                    justifyContent:'center',
                    borderRadius: 2,
                    width:'100%',
                }}/>
    );
}

function PlanSession(props: { open: boolean; close: any }) {
    const [dateTime, setDateTime] = useState(dayjs() as Dayjs);
    const [time, setTime] = useState(dayjs() as Dayjs);
    const [team, chooseTeam] = useState(undefined as string | undefined);
    const [gamesPlanned, setGamesPlanned] = useState(5);
    function handleClose() {
        props.close();
        return true;
    }

    // @ts-ignore
    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Add Session</DialogTitle>
            <DialogContent sx={{ height: "400px" }}>
                <Stack sx={{ mt: "20px" }} spacing={2}>
                    {/*<TeamSelect getValue={team} onChange={(event:any, newValue:string) => chooseTeam(newValue)}/>*/}
                    <DatePicker value={dateTime} onChange={setDateTime} label={"Date"} />
                    <TimePicker value={time} onChange={setTime} label={"Time"} />
                    <Typography>{`${gamesPlanned} games planned`}</Typography>
                    <Slider value={gamesPlanned} step={1} marks min={1} max={10} onChange={(event: any, newValue: any) => setGamesPlanned(newValue)} aria-label="Year" valueLabelDisplay="auto" />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close}>Cancel</Button>
                <Button onClick={handleClose}>Add Session</Button>
            </DialogActions>
        </Dialog>
    );
}
