/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    GamesData,
    GamesDataAggregate,
    MapState,
    ObjectiveKilled,
    PatchedGamesData,
} from "../models/index";

export interface GamesDataAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    bayes_id?: string;
    best_of?: number;
    drafting_sim_pred_accurate?: boolean;
    drafting_sim_predicted_winner?: string;
    drafting_sim_soloq_v1_pred_accurate?: boolean;
    drafting_sim_soloq_v1_predicted_winner?: string;
    drafting_sim_v3_pred_accurate?: boolean;
    drafting_sim_v3_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    having?: object;
    index_on?: string;
    irelia_parser_version?: number;
    is_fearless?: boolean;
    lane_swap?: boolean;
    league?: string;
    match_id?: string;
    metrics?: Array<string>;
    patch?: string;
    phase?: string;
    pre_game_pred_accurate?: boolean;
    pre_game_predicted_winner?: string;
    recompute_done?: boolean;
    side_selection?: string;
    split?: string;
    team1?: string;
    team1champions?: string;
    team1players?: string;
    team2?: string;
    team2champions?: string;
    team2players?: string;
    winner?: string;
}

export interface GamesDataFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    bayes_id?: string;
    best_of?: number;
    drafting_sim_pred_accurate?: boolean;
    drafting_sim_predicted_winner?: string;
    drafting_sim_soloq_v1_pred_accurate?: boolean;
    drafting_sim_soloq_v1_predicted_winner?: string;
    drafting_sim_v3_pred_accurate?: boolean;
    drafting_sim_v3_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    irelia_parser_version?: number;
    is_fearless?: boolean;
    lane_swap?: boolean;
    league?: string;
    match_id?: string;
    patch?: string;
    phase?: string;
    pre_game_pred_accurate?: boolean;
    pre_game_predicted_winner?: string;
    recompute_done?: boolean;
    side_selection?: string;
    split?: string;
    team1?: string;
    team1champions?: string;
    team1players?: string;
    team2?: string;
    team2champions?: string;
    team2players?: string;
    winner?: string;
}

export interface GamesDataGetRequest {
    id: string;
}

export interface GamesDataListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    bayes_id?: string;
    best_of?: number;
    drafting_sim_pred_accurate?: boolean;
    drafting_sim_predicted_winner?: string;
    drafting_sim_soloq_v1_pred_accurate?: boolean;
    drafting_sim_soloq_v1_predicted_winner?: string;
    drafting_sim_v3_pred_accurate?: boolean;
    drafting_sim_v3_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    irelia_parser_version?: number;
    is_fearless?: boolean;
    lane_swap?: boolean;
    league?: string;
    match_id?: string;
    ordering?: Array<string>;
    patch?: string;
    phase?: string;
    pre_game_pred_accurate?: boolean;
    pre_game_predicted_winner?: string;
    recompute_done?: boolean;
    side_selection?: string;
    split?: string;
    team1?: string;
    team1champions?: string;
    team1players?: string;
    team2?: string;
    team2champions?: string;
    team2players?: string;
    winner?: string;
}

export interface GamesDataMapStatesRequest {
    emh_id: string;
}

export interface GamesDataObjectivesDataRequest {
    emh_id: string;
}

export interface GamesDataPatchRequest {
    id: string;
    patched_games_data?: PatchedGamesData;
}

export interface GamesDataVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    bayes_id?: string;
    best_of?: number;
    drafting_sim_pred_accurate?: boolean;
    drafting_sim_predicted_winner?: string;
    drafting_sim_soloq_v1_pred_accurate?: boolean;
    drafting_sim_soloq_v1_predicted_winner?: string;
    drafting_sim_v3_pred_accurate?: boolean;
    drafting_sim_v3_predicted_winner?: string;
    game_id?: string;
    grid_series_id?: number;
    irelia_parser_version?: number;
    is_fearless?: boolean;
    lane_swap?: boolean;
    league?: string;
    match_id?: string;
    patch?: string;
    phase?: string;
    pre_game_pred_accurate?: boolean;
    pre_game_predicted_winner?: string;
    recompute_done?: boolean;
    side_selection?: string;
    split?: string;
    team1?: string;
    team1champions?: string;
    team1players?: string;
    team2?: string;
    team2champions?: string;
    team2players?: string;
    winner?: string;
}

/**
 *
 */
export class GamesDataApi extends runtime.BaseAPI {
    /**
     */
    async gamesDataAggregateRaw(
        requestParameters: GamesDataAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GamesDataAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling gamesDataAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling gamesDataAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["best_of"] != null) {
            queryParameters["best_of"] = requestParameters["best_of"];
        }

        if (requestParameters["drafting_sim_pred_accurate"] != null) {
            queryParameters["drafting_sim_pred_accurate"] =
                requestParameters["drafting_sim_pred_accurate"];
        }

        if (requestParameters["drafting_sim_predicted_winner"] != null) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters["drafting_sim_predicted_winner"];
        }

        if (requestParameters["drafting_sim_soloq_v1_pred_accurate"] != null) {
            queryParameters["drafting_sim_soloq_v1_pred_accurate"] =
                requestParameters["drafting_sim_soloq_v1_pred_accurate"];
        }

        if (
            requestParameters["drafting_sim_soloq_v1_predicted_winner"] != null
        ) {
            queryParameters["drafting_sim_soloq_v1_predicted_winner"] =
                requestParameters["drafting_sim_soloq_v1_predicted_winner"];
        }

        if (requestParameters["drafting_sim_v3_pred_accurate"] != null) {
            queryParameters["drafting_sim_v3_pred_accurate"] =
                requestParameters["drafting_sim_v3_pred_accurate"];
        }

        if (requestParameters["drafting_sim_v3_predicted_winner"] != null) {
            queryParameters["drafting_sim_v3_predicted_winner"] =
                requestParameters["drafting_sim_v3_predicted_winner"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["grid_series_id"] != null) {
            queryParameters["grid_series_id"] =
                requestParameters["grid_series_id"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["irelia_parser_version"] != null) {
            queryParameters["irelia_parser_version"] =
                requestParameters["irelia_parser_version"];
        }

        if (requestParameters["is_fearless"] != null) {
            queryParameters["is_fearless"] = requestParameters["is_fearless"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pre_game_pred_accurate"] != null) {
            queryParameters["pre_game_pred_accurate"] =
                requestParameters["pre_game_pred_accurate"];
        }

        if (requestParameters["pre_game_predicted_winner"] != null) {
            queryParameters["pre_game_predicted_winner"] =
                requestParameters["pre_game_predicted_winner"];
        }

        if (requestParameters["recompute_done"] != null) {
            queryParameters["recompute_done"] =
                requestParameters["recompute_done"];
        }

        if (requestParameters["side_selection"] != null) {
            queryParameters["side_selection"] =
                requestParameters["side_selection"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1champions"] != null) {
            queryParameters["team1champions"] =
                requestParameters["team1champions"];
        }

        if (requestParameters["team1players"] != null) {
            queryParameters["team1players"] = requestParameters["team1players"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2champions"] != null) {
            queryParameters["team2champions"] =
                requestParameters["team2champions"];
        }

        if (requestParameters["team2players"] != null) {
            queryParameters["team2players"] = requestParameters["team2players"];
        }

        if (requestParameters["winner"] != null) {
            queryParameters["winner"] = requestParameters["winner"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: GamesDataAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GamesDataAggregate>> {
        const response = await this.gamesDataAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async gamesDataFieldValuesRaw(
        requestParameters: GamesDataFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling gamesDataFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling gamesDataFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["best_of"] != null) {
            queryParameters["best_of"] = requestParameters["best_of"];
        }

        if (requestParameters["drafting_sim_pred_accurate"] != null) {
            queryParameters["drafting_sim_pred_accurate"] =
                requestParameters["drafting_sim_pred_accurate"];
        }

        if (requestParameters["drafting_sim_predicted_winner"] != null) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters["drafting_sim_predicted_winner"];
        }

        if (requestParameters["drafting_sim_soloq_v1_pred_accurate"] != null) {
            queryParameters["drafting_sim_soloq_v1_pred_accurate"] =
                requestParameters["drafting_sim_soloq_v1_pred_accurate"];
        }

        if (
            requestParameters["drafting_sim_soloq_v1_predicted_winner"] != null
        ) {
            queryParameters["drafting_sim_soloq_v1_predicted_winner"] =
                requestParameters["drafting_sim_soloq_v1_predicted_winner"];
        }

        if (requestParameters["drafting_sim_v3_pred_accurate"] != null) {
            queryParameters["drafting_sim_v3_pred_accurate"] =
                requestParameters["drafting_sim_v3_pred_accurate"];
        }

        if (requestParameters["drafting_sim_v3_predicted_winner"] != null) {
            queryParameters["drafting_sim_v3_predicted_winner"] =
                requestParameters["drafting_sim_v3_predicted_winner"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["grid_series_id"] != null) {
            queryParameters["grid_series_id"] =
                requestParameters["grid_series_id"];
        }

        if (requestParameters["irelia_parser_version"] != null) {
            queryParameters["irelia_parser_version"] =
                requestParameters["irelia_parser_version"];
        }

        if (requestParameters["is_fearless"] != null) {
            queryParameters["is_fearless"] = requestParameters["is_fearless"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pre_game_pred_accurate"] != null) {
            queryParameters["pre_game_pred_accurate"] =
                requestParameters["pre_game_pred_accurate"];
        }

        if (requestParameters["pre_game_predicted_winner"] != null) {
            queryParameters["pre_game_predicted_winner"] =
                requestParameters["pre_game_predicted_winner"];
        }

        if (requestParameters["recompute_done"] != null) {
            queryParameters["recompute_done"] =
                requestParameters["recompute_done"];
        }

        if (requestParameters["side_selection"] != null) {
            queryParameters["side_selection"] =
                requestParameters["side_selection"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1champions"] != null) {
            queryParameters["team1champions"] =
                requestParameters["team1champions"];
        }

        if (requestParameters["team1players"] != null) {
            queryParameters["team1players"] = requestParameters["team1players"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2champions"] != null) {
            queryParameters["team2champions"] =
                requestParameters["team2champions"];
        }

        if (requestParameters["team2players"] != null) {
            queryParameters["team2players"] = requestParameters["team2players"];
        }

        if (requestParameters["winner"] != null) {
            queryParameters["winner"] = requestParameters["winner"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: GamesDataFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.gamesDataFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async gamesDataGetRaw(
        requestParameters: GamesDataGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GamesData>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling gamesDataGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: GamesDataGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GamesData> {
        const response = await this.gamesDataGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async gamesDataListRaw(
        requestParameters: GamesDataListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<GamesData>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["best_of"] != null) {
            queryParameters["best_of"] = requestParameters["best_of"];
        }

        if (requestParameters["drafting_sim_pred_accurate"] != null) {
            queryParameters["drafting_sim_pred_accurate"] =
                requestParameters["drafting_sim_pred_accurate"];
        }

        if (requestParameters["drafting_sim_predicted_winner"] != null) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters["drafting_sim_predicted_winner"];
        }

        if (requestParameters["drafting_sim_soloq_v1_pred_accurate"] != null) {
            queryParameters["drafting_sim_soloq_v1_pred_accurate"] =
                requestParameters["drafting_sim_soloq_v1_pred_accurate"];
        }

        if (
            requestParameters["drafting_sim_soloq_v1_predicted_winner"] != null
        ) {
            queryParameters["drafting_sim_soloq_v1_predicted_winner"] =
                requestParameters["drafting_sim_soloq_v1_predicted_winner"];
        }

        if (requestParameters["drafting_sim_v3_pred_accurate"] != null) {
            queryParameters["drafting_sim_v3_pred_accurate"] =
                requestParameters["drafting_sim_v3_pred_accurate"];
        }

        if (requestParameters["drafting_sim_v3_predicted_winner"] != null) {
            queryParameters["drafting_sim_v3_predicted_winner"] =
                requestParameters["drafting_sim_v3_predicted_winner"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["grid_series_id"] != null) {
            queryParameters["grid_series_id"] =
                requestParameters["grid_series_id"];
        }

        if (requestParameters["irelia_parser_version"] != null) {
            queryParameters["irelia_parser_version"] =
                requestParameters["irelia_parser_version"];
        }

        if (requestParameters["is_fearless"] != null) {
            queryParameters["is_fearless"] = requestParameters["is_fearless"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pre_game_pred_accurate"] != null) {
            queryParameters["pre_game_pred_accurate"] =
                requestParameters["pre_game_pred_accurate"];
        }

        if (requestParameters["pre_game_predicted_winner"] != null) {
            queryParameters["pre_game_predicted_winner"] =
                requestParameters["pre_game_predicted_winner"];
        }

        if (requestParameters["recompute_done"] != null) {
            queryParameters["recompute_done"] =
                requestParameters["recompute_done"];
        }

        if (requestParameters["side_selection"] != null) {
            queryParameters["side_selection"] =
                requestParameters["side_selection"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1champions"] != null) {
            queryParameters["team1champions"] =
                requestParameters["team1champions"];
        }

        if (requestParameters["team1players"] != null) {
            queryParameters["team1players"] = requestParameters["team1players"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2champions"] != null) {
            queryParameters["team2champions"] =
                requestParameters["team2champions"];
        }

        if (requestParameters["team2players"] != null) {
            queryParameters["team2players"] = requestParameters["team2players"];
        }

        if (requestParameters["winner"] != null) {
            queryParameters["winner"] = requestParameters["winner"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: GamesDataListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<GamesData>> {
        const response = await this.gamesDataListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async gamesDataMapStatesRaw(
        requestParameters: GamesDataMapStatesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<MapState>>> {
        if (requestParameters["emh_id"] == null) {
            throw new runtime.RequiredError(
                "emh_id",
                'Required parameter "emh_id" was null or undefined when calling gamesDataMapStates().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/{emh_id}/map_states`.replace(
                    `{${"emh_id"}}`,
                    encodeURIComponent(String(requestParameters["emh_id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async map_states(
        requestParameters: GamesDataMapStatesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<MapState>> {
        const response = await this.gamesDataMapStatesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async gamesDataObjectivesDataRaw(
        requestParameters: GamesDataObjectivesDataRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ObjectiveKilled>>> {
        if (requestParameters["emh_id"] == null) {
            throw new runtime.RequiredError(
                "emh_id",
                'Required parameter "emh_id" was null or undefined when calling gamesDataObjectivesData().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/{emh_id}/objectives`.replace(
                    `{${"emh_id"}}`,
                    encodeURIComponent(String(requestParameters["emh_id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async objectives_data(
        requestParameters: GamesDataObjectivesDataRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ObjectiveKilled>> {
        const response = await this.gamesDataObjectivesDataRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async gamesDataPatchRaw(
        requestParameters: GamesDataPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<GamesData>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling gamesDataPatch().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["patched_games_data"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: GamesDataPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<GamesData> {
        const response = await this.gamesDataPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async gamesDataVariableDistributionRaw(
        requestParameters: GamesDataVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling gamesDataVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling gamesDataVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling gamesDataVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling gamesDataVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["best_of"] != null) {
            queryParameters["best_of"] = requestParameters["best_of"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["drafting_sim_pred_accurate"] != null) {
            queryParameters["drafting_sim_pred_accurate"] =
                requestParameters["drafting_sim_pred_accurate"];
        }

        if (requestParameters["drafting_sim_predicted_winner"] != null) {
            queryParameters["drafting_sim_predicted_winner"] =
                requestParameters["drafting_sim_predicted_winner"];
        }

        if (requestParameters["drafting_sim_soloq_v1_pred_accurate"] != null) {
            queryParameters["drafting_sim_soloq_v1_pred_accurate"] =
                requestParameters["drafting_sim_soloq_v1_pred_accurate"];
        }

        if (
            requestParameters["drafting_sim_soloq_v1_predicted_winner"] != null
        ) {
            queryParameters["drafting_sim_soloq_v1_predicted_winner"] =
                requestParameters["drafting_sim_soloq_v1_predicted_winner"];
        }

        if (requestParameters["drafting_sim_v3_pred_accurate"] != null) {
            queryParameters["drafting_sim_v3_pred_accurate"] =
                requestParameters["drafting_sim_v3_pred_accurate"];
        }

        if (requestParameters["drafting_sim_v3_predicted_winner"] != null) {
            queryParameters["drafting_sim_v3_predicted_winner"] =
                requestParameters["drafting_sim_v3_predicted_winner"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["grid_series_id"] != null) {
            queryParameters["grid_series_id"] =
                requestParameters["grid_series_id"];
        }

        if (requestParameters["irelia_parser_version"] != null) {
            queryParameters["irelia_parser_version"] =
                requestParameters["irelia_parser_version"];
        }

        if (requestParameters["is_fearless"] != null) {
            queryParameters["is_fearless"] = requestParameters["is_fearless"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pre_game_pred_accurate"] != null) {
            queryParameters["pre_game_pred_accurate"] =
                requestParameters["pre_game_pred_accurate"];
        }

        if (requestParameters["pre_game_predicted_winner"] != null) {
            queryParameters["pre_game_predicted_winner"] =
                requestParameters["pre_game_predicted_winner"];
        }

        if (requestParameters["recompute_done"] != null) {
            queryParameters["recompute_done"] =
                requestParameters["recompute_done"];
        }

        if (requestParameters["side_selection"] != null) {
            queryParameters["side_selection"] =
                requestParameters["side_selection"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1champions"] != null) {
            queryParameters["team1champions"] =
                requestParameters["team1champions"];
        }

        if (requestParameters["team1players"] != null) {
            queryParameters["team1players"] = requestParameters["team1players"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2champions"] != null) {
            queryParameters["team2champions"] =
                requestParameters["team2champions"];
        }

        if (requestParameters["team2players"] != null) {
            queryParameters["team2players"] = requestParameters["team2players"];
        }

        if (requestParameters["winner"] != null) {
            queryParameters["winner"] = requestParameters["winner"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/GamesData/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: GamesDataVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.gamesDataVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
