import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Checkbox, Chip, FormControl, Stack, Tooltip, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {Player, SoloQAccounts} from "../../../farsight-api";
import {useReferentialContext} from "../../../Context";

export default function AccountsSelect(props: { player: Player; value: SoloQAccounts[]; onChange: any }) {
    const [rows, setRows] = useState([] as any[]);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(props.value);
    const api = useReferentialContext().farsightApi.soloqAccount

    useEffect(() => {
        setLoading(true);
        setValues([]);
        setRows([]);
        api.list({player: props.player.player, ordering: ['-elo_as_total_lps']}).then(r => {setRows(r); return r}).then(r => {
            setValues(r);
            props.onChange(r);
        });
    }, [props.player]);

    const handleClose = () => {
        props.onChange(values);
    };

    const handleChipClick = (option: any) => {
        const updatedValues = values.filter((value: any) => value.account !== option.account);
        setValues(updatedValues);
        props.onChange(updatedValues);
    };

    return (
        <FormControl sx={{ width: "400px" }}>
            <Autocomplete
                limitTags={1}
                disableCloseOnSelect
                id="combo-box-demo"
                options={rows}
                multiple
                value={values}
                onChange={(event: any, newValue: any) => {
                    setValues(newValue);
                }}
                onClose={handleClose}
                renderInput={(params) => {
                    return <TextField {...params} label="Account" />;
                }}
                renderOption={(props, option, { selected }) => {
                    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
                    const checkedIcon = <CheckBoxIcon fontSize="small" />;
                    return (
                        <Tooltip title={`Most recent game : ${option.most_recent_game}`} placement="left">
                            <li {...props}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                <Stack
                                    direction={"row"}
                                    sx={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{width:'35%'}}>{option.account_name}</Typography>
                                    {option.elo ? (
                                        <Stack
                                            direction={"row"}
                                            sx={{
                                                height: "40px",
                                                width: "45%",
                                                justifyContent: "left",
                                                display: "flex",
                                            }}
                                        >
                                            <Box component={"img"} src={`../icons/emblem-${option.elo.split(" ")[0].toLowerCase()}.png`} sx={{ transform: "scale(2)" }} />
                                            <Typography
                                                sx={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >{`${option.elo.split(" ")[2].toLowerCase()} LP`}</Typography>
                                        </Stack>
                                    ) : (
                                        <Typography sx={{width: '40%'}}>{""}</Typography>
                                    )}
                                    <Typography sx={{width: '20%', justifyContent: 'right', display: 'flex'}}>{option.server}</Typography>
                                </Stack>
                            </li>
                        </Tooltip>
                    );
                }}
                ListboxProps={{ className: "scrollable" }}
                getOptionLabel={(option: SoloQAccounts) => {
                    return option.account_name;
                }}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    const limitTags = 1;
                    return (
                        <>
                            {value.slice(0, limitTags).map((option, index) => {
                                return <Chip label={option.account_name} onDelete={() => handleChipClick(option)} />;
                            })}

                            {numTags > limitTags && ` +${numTags - limitTags}`}
                        </>
                    );
                }}
            />
        </FormControl>
    );
}
