import React, { useContext, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import "leaflet/dist/leaflet.css";
import dayjs, { Dayjs } from "dayjs";
import SpecificGameSelector from "../../../../components/selectors/Competitive/SpecificGameSelector";
import {Team} from "../../../../farsight-api";
import {targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import {WardMapDraws} from "../../2_Scouting/7_MacroScouting/WardMaps";
import MapDrawer from "../../../../components/maps/map_drawer";
import HeatMapDrawer from "../../../../components/maps/heatmap_drawer";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function Hitmap_kills(props: {target: targets}) {
    const [selectedGames, setSelectedGames] = useState([] as string[]);

    const [team, setTeam] = useState<Team | null>(useReferentialContext().selectedTeam);
    const [from, setFrom] = useState<Dayjs>(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState<Dayjs>(dayjs());
    const [roles, setRoles] = useState<string[]>(["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"]);
    const [wardTypes, setWardTypes] = useState<string[]>([]);
    const [ingameInterval, setIngameInterval] = useState([0,600]);

    const updateSelection = (gameSelected: any[]) => {
        setSelectedGames(gameSelected.map(g => g.game_id));
    };

    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                team={team}
                setTeam={setTeam}
                roles={roles}
                setRoles={setRoles}
                ingameInterval={ingameInterval}
                setIngameInterval={setIngameInterval}
                target={props.target}
            />

            <Stack
                direction={"row"}
                sx={{
                    height: "85%",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                }}
                spacing={1}
            >
                {team ?
                    <SpecificGameSelector team={team} updateSelection={updateSelection} from={from} until={until} include_bayes={false} side_select={true} target={props.target} single={false} />
                    : <></>
                }
                {paramObject.team && team ?
                    <KillsHitmapDraw
                        team={team}
                        game_ids={selectedGames}
                        target={props.target}
                        roles={roles}
                        ingameInterval={ingameInterval}
                    /> : <></>}
            </Stack>
        </WindowContainer>
    );
}

function KillsHitmapDraw(props: {game_ids: string[], roles: string[], team: Team, target: targets, ingameInterval: number[]}) {
    const [data, setData] = useState<any>({});
    const [eventTypes, setEventTypes] = useState(['kills','assists'])
    let map_url = "../map tiles/0/0/0.png";
    const api = useReferentialContext().farsightApi.maps
    useEffect(() => {
        setData([]);
        api.events({
            team: props.team.team_name,
            games: [props.game_ids.toString()],
            roles: [props.roles.toString()],
            target: props.target,
            event_types: ['deaths'],
            ig_from: props.ingameInterval[0],
            ig_until: props.ingameInterval[1],
        }).then(data => setData(data))
        // fetch(api_url + "kill_maps/?format=json&from=" + gamesFrom + "&team1=" + team1 + "&team2=" + team2 + "&until=" + gamesUntil + "&ig_from=" + timeRange[0] + "&ig_until=" + timeRange[1]).then(response => response.json()).then(data => setRows(data));
    }, [props]);
    return (
        <Grid
            container
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&::-webkit-scrollbar": {
                    width: "0.2em",
                },
                "&::-webkit-scrollbar-track": {
                    background: "#e1e1e1e",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                },
            }}
        >
            <Select multiple={true} value={eventTypes} onChange={e => setEventTypes(e.target.value as string[])} sx={{width:'400px'}}>
                <MenuItem value={'kills'}>Kills</MenuItem>
                <MenuItem value={'assists'}>Assists</MenuItem>
                <MenuItem value={'deaths'}>Deaths</MenuItem>
            </Select>
            <MapDrawer points={data.points ? data.points : []} />
            <HeatMapDrawer points={data.points ? data.points.filter((event: { [x: string]: string; }) => event["wardType"] !== "unknown") : []} />
        </Grid>
    );
}