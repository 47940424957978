import {Fade, LinearProgress, Stack, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export default function AbilityIcon(props: { ability: string; cooldown?: number; level?: number, maxLevel?: number, sx?: any, hideTooltip?:boolean }) {
    const isOnCooldown = props.cooldown && props.cooldown > 0;

    let abilityName = props.ability;
    if (abilityName.includes("SummonerSmite")) abilityName = "summonerSmite";
    if (abilityName.includes("SummonerTeleport")) abilityName = "summonerTeleport";

    return (
        <Stack direction={'row'} sx={{...props.sx, width:props.sx?.width ? props.sx?.width + 5 : '45px'}}>
        <Tooltip title={props.hideTooltip ? null : props.ability} followCursor={true} hidden={props.hideTooltip}>
            <Box sx={{ position: "relative", display: "inline-block", height:'40px', ...props.sx, width:props.sx?.width ? props.sx?.width + 5 : '45px' }}>
                <Box
                    component={"img"}
                    sx={{
                        filter: isOnCooldown ? "grayscale(100%) brightness(50%)" : "none",
                        height:'40px', width:'40px',
                        ...props.sx,
                    }}
                    src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_40,w_40/v1/leagueAssets/${abilityName}.png`}
                />
                {isOnCooldown ? (
                    <Typography
                        variant="caption"
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            fontWeight: "bold",
                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                            padding: "2px 4px",
                            borderRadius: "4px",
                        }}
                    >
                        {props.cooldown?.toFixed(1)}s
                    </Typography>
                ) : null}
            </Box>
        </Tooltip>
            {props.level && props.maxLevel ? <Tooltip title={`Ability level ${props.level}`} followCursor={true}><LinearProgress
                sx={{
                    // width: '100%',
                    // height: 5,
                    height:'100%',
                    width: 5,
                    backgroundColor: "#fff",
                    '& .MuiLinearProgress-bar': {backgroundColor: "#333", transform: `translateY(-${(props.level / props.maxLevel) * 100}%)!important`}
                }}
                variant="determinate"
                value={(props.level / props.maxLevel) * 100}
            /></Tooltip> : undefined}
        </Stack>
    );
}
