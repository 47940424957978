import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
    Avatar,
    Checkbox,
    CircularProgress,
    createTheme,
    CssBaseline,
    Grid, LinearProgress,
    Link,
    Paper,
    ThemeProvider,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Configuration, ConfigurationParameters, DefaultConfig, Login, LoginApi } from "./farsight-api";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href="https://mui.com/">
                Farsight Alteration
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const theme = createTheme();

export default function LoginPage(props: { setToken: (token: Login) => void }) {
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        let data: any = new FormData(e.currentTarget);
        const username = data.get("username");
        const password = data.get("password");
        const api = new LoginApi();
        return api.get({ username: username, password: password, token: "gimme" }).then((user) => {
            props.setToken(user);
            localStorage.setItem('farsightUser',JSON.stringify(user));
        }).catch(e =>
            setLoading(false)
        )
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: "url(https://cdna.artstation.com/p/assets/images/images/070/781/134/large/emile-blachon-fini.jpg?1703500793)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            mt: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={(submit) => {
                                console.log("submitting");
                                setLoading(true);
                                handleSubmit(submit);
                            }}
                            sx={{ mt: 1 }}
                        >
                            <TextField margin="normal" required fullWidth id="username" label="Username" name="username" autoComplete="username" autoFocus />
                            <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                            {loading ? <LinearProgress sx={{mt: 3, mb: 2, width:'100%'}}/> : <Button
                                // loading={loading}
                                type="submit"
                                variant="contained"
                                sx={{mt: 3, mb: 2, width:'100%'}}
                            >
                                Sign In
                            </Button>}
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
