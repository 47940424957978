import {Stack} from "@mui/material";
import React, {useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import FilterSet from "../../../components/selectors/Filterset";
import {targets} from "../../../Context";
import TableDisplayGrid, {rowStyle} from "../../../components/datagrids/shared/TableDisplayGrid";
import {useReferentialContext} from "../../../Context";
import WindowContainer from "../../../components/layout/WindowContainer";

export default function ScrimOpponents() {
    const [state, setState] = useState({
        from: dayjs().subtract(3, "month"),
        until: dayjs(),
    });

    const [from, setFrom] = useState(dayjs().subtract(90, "days"))
    const [until, setUntil] = useState(dayjs())
    const [metrics, setMetrics] = useState(useReferentialContext().selectedMetrics)

    const [paramObject, setParamObject] = useState({} as any);

    const api = useReferentialContext().farsightApi.scrimGameSummaries;

    const request_parameters = {
        ...paramObject,
        team1: useReferentialContext().user.team,
        groupby: 'team2',
        metrics: metrics.map((metric) => metric.code).toString(),
        ordering: "games",
        format: "json",
    };

    return (
        <WindowContainer spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.scrims}
                        date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil} metrics={metrics} setMetrics={setMetrics}/>
            {paramObject.additional_filters ? <TableDisplayGrid
                display_all={false}
                request={() => api.aggregate(request_parameters)}
                rowStyle={rowStyle.none}
                disp_columns={['team2']}
                sortBy={"games"}
                paramObject={request_parameters}
                metrics={metrics}
            /> : <></>}
        </WindowContainer>
    );
}
