/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    PatchedScenario,
    Scenario,
    ScenarioAggregate,
} from "../models/index";

export interface ScenarioAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    having?: object;
    index_on?: string;
    metrics?: Array<string>;
    name?: string;
    owner?: string;
    prep?: number;
    scenario_category?: string;
    scenario_side?: string;
    team1?: string;
    team1ban1?: string;
    team1ban2?: string;
    team1ban3?: string;
    team1ban4?: string;
    team1ban5?: string;
    team1pick1?: string;
    team1pick2?: string;
    team1pick3?: string;
    team1pick4?: string;
    team1pick5?: string;
    team2?: string;
    team2ban1?: string;
    team2ban2?: string;
    team2ban3?: string;
    team2ban4?: string;
    team2ban5?: string;
    team2pick1?: string;
    team2pick2?: string;
    team2pick3?: string;
    team2pick4?: string;
    team2pick5?: string;
}

export interface ScenarioCreateRequest {
    id: string;
    scenario: Omit<Scenario, "id">;
}

export interface ScenarioDeleteRequest {
    id: string;
}

export interface ScenarioFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    name?: string;
    owner?: string;
    prep?: number;
    scenario_category?: string;
    scenario_side?: string;
    team1?: string;
    team1ban1?: string;
    team1ban2?: string;
    team1ban3?: string;
    team1ban4?: string;
    team1ban5?: string;
    team1pick1?: string;
    team1pick2?: string;
    team1pick3?: string;
    team1pick4?: string;
    team1pick5?: string;
    team2?: string;
    team2ban1?: string;
    team2ban2?: string;
    team2ban3?: string;
    team2ban4?: string;
    team2ban5?: string;
    team2pick1?: string;
    team2pick2?: string;
    team2pick3?: string;
    team2pick4?: string;
    team2pick5?: string;
}

export interface ScenarioGetRequest {
    id: string;
}

export interface ScenarioListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    name?: string;
    ordering?: Array<string>;
    owner?: string;
    prep?: number;
    scenario_category?: string;
    scenario_side?: string;
    team1?: string;
    team1ban1?: string;
    team1ban2?: string;
    team1ban3?: string;
    team1ban4?: string;
    team1ban5?: string;
    team1pick1?: string;
    team1pick2?: string;
    team1pick3?: string;
    team1pick4?: string;
    team1pick5?: string;
    team2?: string;
    team2ban1?: string;
    team2ban2?: string;
    team2ban3?: string;
    team2ban4?: string;
    team2ban5?: string;
    team2pick1?: string;
    team2pick2?: string;
    team2pick3?: string;
    team2pick4?: string;
    team2pick5?: string;
}

export interface ScenarioPatchRequest {
    id: string;
    patched_scenario?: Omit<PatchedScenario, "id">;
}

export interface ScenarioVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    name?: string;
    owner?: string;
    prep?: number;
    scenario_category?: string;
    scenario_side?: string;
    team1?: string;
    team1ban1?: string;
    team1ban2?: string;
    team1ban3?: string;
    team1ban4?: string;
    team1ban5?: string;
    team1pick1?: string;
    team1pick2?: string;
    team1pick3?: string;
    team1pick4?: string;
    team1pick5?: string;
    team2?: string;
    team2ban1?: string;
    team2ban2?: string;
    team2ban3?: string;
    team2ban4?: string;
    team2ban5?: string;
    team2pick1?: string;
    team2pick2?: string;
    team2pick3?: string;
    team2pick4?: string;
    team2pick5?: string;
}

/**
 *
 */
export class ScenarioApi extends runtime.BaseAPI {
    /**
     */
    async scenarioAggregateRaw(
        requestParameters: ScenarioAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScenarioAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling scenarioAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling scenarioAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["prep"] != null) {
            queryParameters["prep"] = requestParameters["prep"];
        }

        if (requestParameters["scenario_category"] != null) {
            queryParameters["scenario_category"] =
                requestParameters["scenario_category"];
        }

        if (requestParameters["scenario_side"] != null) {
            queryParameters["scenario_side"] =
                requestParameters["scenario_side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1ban1"] != null) {
            queryParameters["team1ban1"] = requestParameters["team1ban1"];
        }

        if (requestParameters["team1ban2"] != null) {
            queryParameters["team1ban2"] = requestParameters["team1ban2"];
        }

        if (requestParameters["team1ban3"] != null) {
            queryParameters["team1ban3"] = requestParameters["team1ban3"];
        }

        if (requestParameters["team1ban4"] != null) {
            queryParameters["team1ban4"] = requestParameters["team1ban4"];
        }

        if (requestParameters["team1ban5"] != null) {
            queryParameters["team1ban5"] = requestParameters["team1ban5"];
        }

        if (requestParameters["team1pick1"] != null) {
            queryParameters["team1pick1"] = requestParameters["team1pick1"];
        }

        if (requestParameters["team1pick2"] != null) {
            queryParameters["team1pick2"] = requestParameters["team1pick2"];
        }

        if (requestParameters["team1pick3"] != null) {
            queryParameters["team1pick3"] = requestParameters["team1pick3"];
        }

        if (requestParameters["team1pick4"] != null) {
            queryParameters["team1pick4"] = requestParameters["team1pick4"];
        }

        if (requestParameters["team1pick5"] != null) {
            queryParameters["team1pick5"] = requestParameters["team1pick5"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2ban1"] != null) {
            queryParameters["team2ban1"] = requestParameters["team2ban1"];
        }

        if (requestParameters["team2ban2"] != null) {
            queryParameters["team2ban2"] = requestParameters["team2ban2"];
        }

        if (requestParameters["team2ban3"] != null) {
            queryParameters["team2ban3"] = requestParameters["team2ban3"];
        }

        if (requestParameters["team2ban4"] != null) {
            queryParameters["team2ban4"] = requestParameters["team2ban4"];
        }

        if (requestParameters["team2ban5"] != null) {
            queryParameters["team2ban5"] = requestParameters["team2ban5"];
        }

        if (requestParameters["team2pick1"] != null) {
            queryParameters["team2pick1"] = requestParameters["team2pick1"];
        }

        if (requestParameters["team2pick2"] != null) {
            queryParameters["team2pick2"] = requestParameters["team2pick2"];
        }

        if (requestParameters["team2pick3"] != null) {
            queryParameters["team2pick3"] = requestParameters["team2pick3"];
        }

        if (requestParameters["team2pick4"] != null) {
            queryParameters["team2pick4"] = requestParameters["team2pick4"];
        }

        if (requestParameters["team2pick5"] != null) {
            queryParameters["team2pick5"] = requestParameters["team2pick5"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: ScenarioAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScenarioAggregate>> {
        const response = await this.scenarioAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scenarioCreateRaw(
        requestParameters: ScenarioCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling scenarioCreate().',
            );
        }

        if (requestParameters["scenario"] == null) {
            throw new runtime.RequiredError(
                "scenario",
                'Required parameter "scenario" was null or undefined when calling scenarioCreate().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["scenario"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async create(
        requestParameters: ScenarioCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Scenario> {
        const response = await this.scenarioCreateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scenarioDeleteRaw(
        requestParameters: ScenarioDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling scenarioDelete().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete(
        requestParameters: ScenarioDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.scenarioDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async scenarioFieldValuesRaw(
        requestParameters: ScenarioFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling scenarioFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling scenarioFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["prep"] != null) {
            queryParameters["prep"] = requestParameters["prep"];
        }

        if (requestParameters["scenario_category"] != null) {
            queryParameters["scenario_category"] =
                requestParameters["scenario_category"];
        }

        if (requestParameters["scenario_side"] != null) {
            queryParameters["scenario_side"] =
                requestParameters["scenario_side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1ban1"] != null) {
            queryParameters["team1ban1"] = requestParameters["team1ban1"];
        }

        if (requestParameters["team1ban2"] != null) {
            queryParameters["team1ban2"] = requestParameters["team1ban2"];
        }

        if (requestParameters["team1ban3"] != null) {
            queryParameters["team1ban3"] = requestParameters["team1ban3"];
        }

        if (requestParameters["team1ban4"] != null) {
            queryParameters["team1ban4"] = requestParameters["team1ban4"];
        }

        if (requestParameters["team1ban5"] != null) {
            queryParameters["team1ban5"] = requestParameters["team1ban5"];
        }

        if (requestParameters["team1pick1"] != null) {
            queryParameters["team1pick1"] = requestParameters["team1pick1"];
        }

        if (requestParameters["team1pick2"] != null) {
            queryParameters["team1pick2"] = requestParameters["team1pick2"];
        }

        if (requestParameters["team1pick3"] != null) {
            queryParameters["team1pick3"] = requestParameters["team1pick3"];
        }

        if (requestParameters["team1pick4"] != null) {
            queryParameters["team1pick4"] = requestParameters["team1pick4"];
        }

        if (requestParameters["team1pick5"] != null) {
            queryParameters["team1pick5"] = requestParameters["team1pick5"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2ban1"] != null) {
            queryParameters["team2ban1"] = requestParameters["team2ban1"];
        }

        if (requestParameters["team2ban2"] != null) {
            queryParameters["team2ban2"] = requestParameters["team2ban2"];
        }

        if (requestParameters["team2ban3"] != null) {
            queryParameters["team2ban3"] = requestParameters["team2ban3"];
        }

        if (requestParameters["team2ban4"] != null) {
            queryParameters["team2ban4"] = requestParameters["team2ban4"];
        }

        if (requestParameters["team2ban5"] != null) {
            queryParameters["team2ban5"] = requestParameters["team2ban5"];
        }

        if (requestParameters["team2pick1"] != null) {
            queryParameters["team2pick1"] = requestParameters["team2pick1"];
        }

        if (requestParameters["team2pick2"] != null) {
            queryParameters["team2pick2"] = requestParameters["team2pick2"];
        }

        if (requestParameters["team2pick3"] != null) {
            queryParameters["team2pick3"] = requestParameters["team2pick3"];
        }

        if (requestParameters["team2pick4"] != null) {
            queryParameters["team2pick4"] = requestParameters["team2pick4"];
        }

        if (requestParameters["team2pick5"] != null) {
            queryParameters["team2pick5"] = requestParameters["team2pick5"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: ScenarioFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.scenarioFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scenarioGetRaw(
        requestParameters: ScenarioGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling scenarioGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: ScenarioGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Scenario> {
        const response = await this.scenarioGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scenarioListRaw(
        requestParameters: ScenarioListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<Scenario>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["prep"] != null) {
            queryParameters["prep"] = requestParameters["prep"];
        }

        if (requestParameters["scenario_category"] != null) {
            queryParameters["scenario_category"] =
                requestParameters["scenario_category"];
        }

        if (requestParameters["scenario_side"] != null) {
            queryParameters["scenario_side"] =
                requestParameters["scenario_side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1ban1"] != null) {
            queryParameters["team1ban1"] = requestParameters["team1ban1"];
        }

        if (requestParameters["team1ban2"] != null) {
            queryParameters["team1ban2"] = requestParameters["team1ban2"];
        }

        if (requestParameters["team1ban3"] != null) {
            queryParameters["team1ban3"] = requestParameters["team1ban3"];
        }

        if (requestParameters["team1ban4"] != null) {
            queryParameters["team1ban4"] = requestParameters["team1ban4"];
        }

        if (requestParameters["team1ban5"] != null) {
            queryParameters["team1ban5"] = requestParameters["team1ban5"];
        }

        if (requestParameters["team1pick1"] != null) {
            queryParameters["team1pick1"] = requestParameters["team1pick1"];
        }

        if (requestParameters["team1pick2"] != null) {
            queryParameters["team1pick2"] = requestParameters["team1pick2"];
        }

        if (requestParameters["team1pick3"] != null) {
            queryParameters["team1pick3"] = requestParameters["team1pick3"];
        }

        if (requestParameters["team1pick4"] != null) {
            queryParameters["team1pick4"] = requestParameters["team1pick4"];
        }

        if (requestParameters["team1pick5"] != null) {
            queryParameters["team1pick5"] = requestParameters["team1pick5"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2ban1"] != null) {
            queryParameters["team2ban1"] = requestParameters["team2ban1"];
        }

        if (requestParameters["team2ban2"] != null) {
            queryParameters["team2ban2"] = requestParameters["team2ban2"];
        }

        if (requestParameters["team2ban3"] != null) {
            queryParameters["team2ban3"] = requestParameters["team2ban3"];
        }

        if (requestParameters["team2ban4"] != null) {
            queryParameters["team2ban4"] = requestParameters["team2ban4"];
        }

        if (requestParameters["team2ban5"] != null) {
            queryParameters["team2ban5"] = requestParameters["team2ban5"];
        }

        if (requestParameters["team2pick1"] != null) {
            queryParameters["team2pick1"] = requestParameters["team2pick1"];
        }

        if (requestParameters["team2pick2"] != null) {
            queryParameters["team2pick2"] = requestParameters["team2pick2"];
        }

        if (requestParameters["team2pick3"] != null) {
            queryParameters["team2pick3"] = requestParameters["team2pick3"];
        }

        if (requestParameters["team2pick4"] != null) {
            queryParameters["team2pick4"] = requestParameters["team2pick4"];
        }

        if (requestParameters["team2pick5"] != null) {
            queryParameters["team2pick5"] = requestParameters["team2pick5"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: ScenarioListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<Scenario>> {
        const response = await this.scenarioListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scenarioPatchRaw(
        requestParameters: ScenarioPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling scenarioPatch().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["patched_scenario"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: ScenarioPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Scenario> {
        const response = await this.scenarioPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scenarioVariableDistributionRaw(
        requestParameters: ScenarioVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling scenarioVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling scenarioVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling scenarioVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling scenarioVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["owner"] != null) {
            queryParameters["owner"] = requestParameters["owner"];
        }

        if (requestParameters["prep"] != null) {
            queryParameters["prep"] = requestParameters["prep"];
        }

        if (requestParameters["scenario_category"] != null) {
            queryParameters["scenario_category"] =
                requestParameters["scenario_category"];
        }

        if (requestParameters["scenario_side"] != null) {
            queryParameters["scenario_side"] =
                requestParameters["scenario_side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team1ban1"] != null) {
            queryParameters["team1ban1"] = requestParameters["team1ban1"];
        }

        if (requestParameters["team1ban2"] != null) {
            queryParameters["team1ban2"] = requestParameters["team1ban2"];
        }

        if (requestParameters["team1ban3"] != null) {
            queryParameters["team1ban3"] = requestParameters["team1ban3"];
        }

        if (requestParameters["team1ban4"] != null) {
            queryParameters["team1ban4"] = requestParameters["team1ban4"];
        }

        if (requestParameters["team1ban5"] != null) {
            queryParameters["team1ban5"] = requestParameters["team1ban5"];
        }

        if (requestParameters["team1pick1"] != null) {
            queryParameters["team1pick1"] = requestParameters["team1pick1"];
        }

        if (requestParameters["team1pick2"] != null) {
            queryParameters["team1pick2"] = requestParameters["team1pick2"];
        }

        if (requestParameters["team1pick3"] != null) {
            queryParameters["team1pick3"] = requestParameters["team1pick3"];
        }

        if (requestParameters["team1pick4"] != null) {
            queryParameters["team1pick4"] = requestParameters["team1pick4"];
        }

        if (requestParameters["team1pick5"] != null) {
            queryParameters["team1pick5"] = requestParameters["team1pick5"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team2ban1"] != null) {
            queryParameters["team2ban1"] = requestParameters["team2ban1"];
        }

        if (requestParameters["team2ban2"] != null) {
            queryParameters["team2ban2"] = requestParameters["team2ban2"];
        }

        if (requestParameters["team2ban3"] != null) {
            queryParameters["team2ban3"] = requestParameters["team2ban3"];
        }

        if (requestParameters["team2ban4"] != null) {
            queryParameters["team2ban4"] = requestParameters["team2ban4"];
        }

        if (requestParameters["team2ban5"] != null) {
            queryParameters["team2ban5"] = requestParameters["team2ban5"];
        }

        if (requestParameters["team2pick1"] != null) {
            queryParameters["team2pick1"] = requestParameters["team2pick1"];
        }

        if (requestParameters["team2pick2"] != null) {
            queryParameters["team2pick2"] = requestParameters["team2pick2"];
        }

        if (requestParameters["team2pick3"] != null) {
            queryParameters["team2pick3"] = requestParameters["team2pick3"];
        }

        if (requestParameters["team2pick4"] != null) {
            queryParameters["team2pick4"] = requestParameters["team2pick4"];
        }

        if (requestParameters["team2pick5"] != null) {
            queryParameters["team2pick5"] = requestParameters["team2pick5"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Scenario/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: ScenarioVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.scenarioVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
