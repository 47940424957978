import { Marker } from "react-leaflet";
import React from "react";
import L from "leaflet";
import {
  DragonAirIcon,
  DragonChemtechIcon, DragonEarthIcon, DragonFireIcon,
  DragonHextechIcon, DragonWaterIcon,
  RiftHeraldIcon,
  VoidGrubsIcon
} from "../../Icons/SvgIcons";
import { renderToStaticMarkup } from "react-dom/server";
import dayjs from "dayjs";
import {Typography} from "@mui/material";

// Fixed Nashor's Pit Position
const NASHOR_PIT_POSITION: [number, number] = [0.33, 0.7];
const DRAGON_PIT_POSITION: [number, number] = [0.67, 0.295];

interface WrappedObjectiveProps {
  cooldown?: number;
  containerSize: number;
}

export const VoidGrubsMarker: React.FC<WrappedObjectiveProps> = ({ cooldown = 0, containerSize }) => {
  return (
    <ObjectiveMarker
      icon={<VoidGrubsIcon />}
      position={NASHOR_PIT_POSITION}
      cooldown={cooldown}
      containerSize={containerSize}
    />
  );
};

export const RiftHeraldMarker: React.FC<WrappedObjectiveProps> = ({ cooldown = 0, containerSize }) => {
  return (
    <ObjectiveMarker
      icon={<RiftHeraldIcon />}
      position={NASHOR_PIT_POSITION}
      cooldown={cooldown}
      containerSize={containerSize}
    />
  );
};

export const DragonMarker: React.FC<{cooldown?: number, containerSize: number, sub_type: string}> = ({ cooldown = 0, containerSize, sub_type }) => {
  let icon: React.ReactNode;
  switch (sub_type) {
    case "air":
      icon = <DragonAirIcon/>;
      break;
    case "chemtech":
      icon = <DragonChemtechIcon/>;
      break;
    case "hextech":
      icon = <DragonHextechIcon/>;
      break;
    case "earth":
      icon = <DragonEarthIcon/>;
      break;
    case "water":
      icon = <DragonWaterIcon/>;
      break;
    case "fire":
      icon = <DragonFireIcon/>;
      break;
    default:
      icon = (
          <Typography variant="body2" fontWeight="bold">
            Unknown Dragon: {sub_type}
          </Typography>
      );
  }
  return (
    <ObjectiveMarker
      icon={icon}
      position={DRAGON_PIT_POSITION}
      cooldown={cooldown}
      containerSize={containerSize}
    />
  );
}

interface ObjectiveMarkerProps {
  icon: React.ReactNode; // SVG Component
  position: [number, number]; // Position on the map
  cooldown?: number; // Cooldown in seconds (optional)
  containerSize: number;
}

const ObjectiveMarker: React.FC<ObjectiveMarkerProps> = ({
  icon,
  position,
  cooldown = 0,
  containerSize,
}) => {
  const isOnCooldown = cooldown > 0;
  const formattedCooldown = dayjs.duration(cooldown, "seconds").format("mm:ss");

  // Generate the icon with grayscale effect when on cooldown
  const iconHtml = renderToStaticMarkup(
    <div style={{ position: "relative", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div style={{ filter: isOnCooldown ? "grayscale(100%) brightness(50%)" : "none" }}>
        {icon}
      </div>

      {/* Cooldown overlay */}
      {isOnCooldown && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            padding: "2px 6px",
            borderRadius: "6px",
            minWidth: "35px",
            textAlign: "center",
          }}
        >
          <span style={{ color: "white", fontWeight: "bold", fontSize: "12px" }}>
            {formattedCooldown}
          </span>
        </div>
      )}
    </div>
  );

  // Create a Leaflet DivIcon
  const customIcon = new L.DivIcon({
    className: "custom-objective-marker",
    html: iconHtml,
    iconSize: [40, 40], // Adjusted size
  });

  return <Marker position={[position[1] * containerSize, position[0] * containerSize]} icon={customIcon}/>;
};

export default ObjectiveMarker;
