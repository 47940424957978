import {
    darken, Divider,
    Grow,
    Link, ListItemIcon,
    ListItemText,
    MenuList,
    Paper,
    Stack,
    styled, SxProps,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import React, {useContext, useRef, useState} from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import {targets, useReferentialContext} from "../../Context";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import TeamIcon from "../Icons/TeamIcon";
import Menu from "@mui/material/Menu";
import {ArrowDropDown} from "@mui/icons-material";
import {GamesData} from "../../farsight-api";
import ChampionIcon from "../Icons/ChampionIcon";
import {NeonCard } from "../../NeonComponents/NeonCards";
import {getNeonColor, loss_color, scrollable, win_color} from "../../theme";
import SmallDetailButton from "../Buttons/SmallDetailsButton";
import ToPngButton from "../Buttons/ToPngButton";

const loose_neon = '#df3838';
const win_neon = '#56b445';
interface DraftParams {
    draft: any;
    team_target: string;
    index: number;
}

function export_draft_excel(draft: any, index: number, team_target: string){
    const rows = [
        `Game ${index + 1}\t\t\t\t\t${draft.winner === team_target ? 'Win' : 'Loose'}`,
        `${draft.team1}\t\t${draft.winner === draft.team1 ? 1 : 0}\t${draft.team2}\t\t${draft.winner === draft.team2 ? 1 : 0}`,
        `${draft.team1ban1}\t\t\t\t\t${draft.team2ban1}`
    ]
    console.log(rows)
    navigator.clipboard.writeText(rows.join("\n"));

}

const DraftTurn = styled(Paper)(({theme}) => ({
    // backgroundColor: darken('#ff726f',0.2),
    backgroundColor: "#340C0C",
    // color: 'white',
    borderRadius: 0,
    textTransform: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    width: "50%",
})) as typeof Paper;

export function DraftBlockScrim(props: DraftParams) {
    const { draft } = props;
    const gridRef = useRef(null);
    const context = useReferentialContext();
    const theme = useTheme();

    const handleCapture = () => {
      if (gridRef.current) {
          // @ts-ignore
          html2canvas(gridRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: '#000000',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    return (
        <Box ref={gridRef}>
            <NeonCard  sx={{ width: "350px", height:'530px', borderRadius:0,
                "&::after": {
                    content: "''",
                    position: "absolute",
                    top: "5%",
                    left: "0px",
                    width: "2px",
                    height: "60px",
                    background: draft.winner === props.team_target ? win_neon : loose_neon,
                    boxShadow: `0px 0px 20px  ${draft.winner === props.team_target ? win_neon : loose_neon}, 0px 0px 30px ${draft.winner === props.team_target ? win_neon : loose_neon}`,
                    opacity: 1,
                    pointerEvents: "none",
                  },
            }}>
                {/*<NeonCardHeader title={dayjs(draft.date).format("MMMM D HH:mm")}/>*/}
                {/*<NeonCardActions>*/}
                {/*    <Button variant={"outlined"} sx={{height:'80%'}} onClick={() => handleCapture()}>PNG</Button>*/}
                {/*</NeonCardActions>*/}
                <Stack sx={{ borderRadius: 0, justifyContent: 'space-around', display: 'flex', alignItems:'center' }} direction={'row'}>
                    {dayjs(draft.date).format("MMMM D HH:mm")}
                    <Button variant={"outlined"} sx={{height:'80%'}} onClick={() => handleCapture()}>PNG</Button>
                </Stack>
                <Stack
                    sx={{
                        borderRadius: 0,
                        justifyContent: "space-evenly",
                        display: "flex",
                        height: "40px",
                        width:'100%'
                    }}
                    direction={'row'}
                >
                    <TeamIcon team_name={draft.team1}/>
                    <TeamIcon team_name={draft.team2}/>
                </Stack>
                <Stack direction={"row"} >
                    <Typography sx={{width:'50%', textAlign:'center'}}>{`Patch ${draft.patch}`}</Typography>
                    <Typography sx={{width:'50%', textAlign:'center'}}>{draft.winner === 'Remake'? 'Remake' : draft.winner === props.team_target ? "Win" : "Loss"}</Typography>
                </Stack>
                <Stack sx={{ width: "100%", borderRadius: "0px" }} direction={'row'}>
                    <Tooltip title={context.user.draft_model_displayed}>
                        <Stack
                            textAlign={"center"}
                            justifyContent={"space-evenly"}
                            sx={{
                                width: "350px",
                                alignItems: "center",
                                display: "flex",
                                border: `2px solid ${draft[`${context.user.draft_model_displayed}_predicted_winner`] === draft.winner ? win_neon : loose_neon}`,
                            }}
                            direction={"row"}
                            spacing={1}
                        >
                            <Typography>{`${(draft[`${context.user.draft_model_displayed}_team1_pred`] * 100).toFixed(2)}%`}</Typography>
                            <Typography>{`${(draft[`${context.user.draft_model_displayed}_team2_pred`] * 100).toFixed(2)}%`}</Typography>
                        </Stack>
                    </Tooltip>
                </Stack>
                <DraftBody draft={draft} team_target={props.team_target} index={props.index} />
            </NeonCard>
        </Box>
    );
}

function FearlessDisplay(props: {game: GamesData, sx?: SxProps}){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const ddragon_champions = useReferentialContext().ddragon_champions;

    return <SmallDetailButton closeOnOutsideClick sx={props.sx}
            content={props.game.fearless_bans !== '' ? <MenuList sx={{width: '200px'}}>
                        <Stack direction={'column'} sx={{width: '200px', height: "100%", ...scrollable}}>
                            <ListItemText sx={{textAlign:'center'}}>Fearless Bans</ListItemText>
                            {
                                props.game.fearless_bans?.split(',').map(b =>
                                    <ListItemText>
                                        <Stack direction={'row'} sx={{alignItems:'center', justifyContent: 'flex-start', display:'flex'}} spacing={2}>
                                            <ChampionIcon champion={b} role={'TOP_LANE'}/>
                                        <Typography>{ddragon_champions[b].name}</Typography>
                                        </Stack>
                                    </ListItemText>
                                )
                            }
                        </Stack>
            </MenuList> : <Typography>First game in series</Typography>}>
    <ArrowDropDown />
    </SmallDetailButton>

}

export function DraftBlockCompetitive(props: DraftParams) {
    const { draft } = props;
    const gridRef = useRef(null);
    const context = useReferentialContext();
    const theme = useTheme();

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: (props.index + 1) * 300 }}>
        <Box ref={gridRef}>
            <NeonCard sx={{ width: "350px", height:'530px', borderRadius:0, mt:2,
                "&::after": {
                    content: "''",
                    position: "absolute",
                    top: "5%",
                    left: "0px",
                    width: "2px",
                    height: "60px",
                    background: draft.winner === props.team_target ? win_neon : loose_neon,
                    boxShadow: `0px 0px 20px  ${draft.winner === props.team_target ? win_neon : loose_neon}, 0px 0px 30px ${draft.winner === props.team_target ? win_neon : loose_neon}`,
                    opacity: 1,
                    pointerEvents: "none",
                  },
            }}>
                <Stack sx={{ borderRadius: 0, justifyContent: 'space-around', display: 'flex', alignItems:'center', height:'35px'}} direction={'row'}>
                    {dayjs(draft.date).format("MMMM D HH:mm")}
                    <ToPngButton elementReference={gridRef} sx={{height:'25px'}}/>
                    {draft.is_fearless ? <FearlessDisplay game={props.draft} sx={{height:'25px'}}/> : undefined}
                </Stack>
                <Tooltip title={draft.side_selection ? draft.side_selection === draft.team1 ? `${draft.team1} chose Blue Side` : `${draft.team2} chose Red Side` : 'Unknown Side Selection'}>
                    <Stack
                        sx={{
                            borderRadius: 0,
                            justifyContent: "space-around",
                            display: "flex",
                            height: "40px",
                            width:'100%',
                            paddingLeft: 5,
                            paddingRight: 5,
                            "&::after": draft.side_selection ? {
                                content: "''",
                                position: "absolute",
                                top: "5%",
                                left: draft.side_selection === draft.team1 ? 30 : undefined,
                                right: draft.side_selection === draft.team2 ? 30 : undefined,
                                width: "2px",
                                height: "40px",
                                background: getNeonColor(theme),
                                boxShadow: `0px 0px 20px  ${getNeonColor(theme)}, 0px 0px 30px ${getNeonColor(theme)}`,
                                opacity: 1,
                                pointerEvents: "none",
                              } : undefined,

                        }}
                        direction={'row'}
                    >
                        <TeamIcon team_name={draft.team1}/>
                        <TeamIcon team_name={draft.team2}/>
                    </Stack>
                </Tooltip>
                <Stack direction={"row"} sx={{alignItems:'center', justifyContent:'center'}}>
                    <Typography sx={{width:'50%', textAlign:'center', textJustify:'center', alignItems:'center'}}>{`Patch ${draft.patch}`}</Typography>
                    <Typography sx={{width:'50%', textAlign:'center', textJustify:'center', alignItems:'center'}}>Win : <TeamIcon team_name={draft.winner} sx={{width:'20px', height:'20px'}}/></Typography>
                </Stack>
                <Stack sx={{ width: "100%", borderRadius: "0px" }} direction={'row'}>
                    <Tooltip title={context.user.draft_model_displayed}>
                        <Stack
                            textAlign={"center"}
                            justifyContent={"space-evenly"}
                            sx={{
                                width: "350px",
                                alignItems: "center",
                                display: "flex",
                                border: `2px solid ${draft[`${context.user.draft_model_displayed}_predicted_winner`] === draft.winner ? win_neon : loose_neon}`,
                            }}
                            direction={"row"}
                            spacing={1}
                        >
                            <Typography>{`${(draft[`${context.user.draft_model_displayed}_team1_pred`] * 100).toFixed(2)}%`}</Typography>
                            <Typography>{`${(draft[`${context.user.draft_model_displayed}_team2_pred`] * 100).toFixed(2)}%`}</Typography>
                        </Stack>
                    </Tooltip>
                </Stack>
                <DraftBody draft={draft} team_target={props.team_target} index={props.index} />
            </NeonCard>
        </Box>
        </Grow>
    );
}

export default function DraftBody(props: DraftParams) {
    const { draft } = props;
    const { ddragon_champions } = useReferentialContext();
    return (
        <Stack textAlign={"center"} justifyContent={"space-evenly"} sx={{ width: "350px" }}>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban1 && draft.team1ban1 !== "None" ? ddragon_champions[draft.team1ban1].name : "None"}</DraftTurn>
                {draft.team1ban1 !== "None" && draft.team1ban1 !== null && draft.team1ban1 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban1}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban1 !== "None" && draft.team2ban1 !== null && draft.team2ban1 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban1}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban1 && draft.team2ban1 !== "None" ? ddragon_champions[draft.team2ban1].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban2 && draft.team1ban2 !== "None" ? ddragon_champions[draft.team1ban2].name : "None"}</DraftTurn>
                {draft.team1ban2 !== "None" && draft.team1ban2 !== null && draft.team1ban2 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban2}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban2 !== "None" && draft.team2ban2 !== null && draft.team2ban2 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban2}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban2 && draft.team2ban2 !== "None" ? ddragon_champions[draft.team2ban2].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban3 && draft.team1ban3 !== "None" ? ddragon_champions[draft.team1ban3].name : "None"}</DraftTurn>
                {draft.team1ban3 !== "None" && draft.team1ban3 !== null && draft.team1ban3 ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban3}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban3 !== "None" && draft.team2ban3 !== null && draft.team2ban3 ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban3}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban3 && draft.team2ban3 !== "None" ? ddragon_champions[draft.team2ban3].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#1C2633" }}>{draft.team1pick1}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick1}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick1}.png`} />
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team2pick1] ? ddragon_champions[draft.team2pick1].name : "???"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team1pick2].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick2}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick2}.png`} />
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team2pick2].name}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#223E11" }}>{ddragon_champions[draft.team1pick3].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick3}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick3}.png`} />
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team2pick3].name}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban4 && draft.team1ban4 !== "None" ? ddragon_champions[draft.team1ban4].name : "None"}</DraftTurn>
                {draft.team1ban4 !== "None" && draft.team1ban4 !== null && draft.team1ban4 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban4}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban4 !== "None" && draft.team2ban4 !== null && draft.team2ban4 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban4}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban4 && draft.team2ban4 !== "None" ? ddragon_champions[draft.team2ban4].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn>{draft.team1ban5 && draft.team1ban5 !== "None" ? ddragon_champions[draft.team1ban5].name : "None"}</DraftTurn>
                {draft.team1ban5 !== "None" && draft.team1ban5 !== null && draft.team1ban5 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1ban5}.png`} />
                ) : (
                    <Box sx={{ width: "52px", height: "40px" }} />
                )}
                {draft.team2ban5 !== "None" && draft.team2ban5 !== null && draft.team2ban5 !== "" ? (
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2ban5}.png`} />
                ) : (
                    <Box sx={{ width: "51px", height: "40px" }} />
                )}
                <DraftTurn>{draft.team2ban5 && draft.team2ban5 !== "None" ? ddragon_champions[draft.team2ban5].name : "None"}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team1pick4].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick4}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick4}.png`} />
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team2pick4].name}</DraftTurn>
            </Stack>
            <Stack direction={"row"}>
                <DraftTurn sx={{ backgroundColor: "#5A4D5A" }}>{ddragon_champions[draft.team1pick5].name}</DraftTurn>
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team1pick5}.png`} />
                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${draft.team2pick5}.png`} />
                <DraftTurn sx={{ backgroundColor: "#1C2633" }}>{ddragon_champions[draft.team2pick5].name}</DraftTurn>
            </Stack>
        </Stack>
    );
}
