import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import MultipleMetricsSelect from "../selectors/Shared/MetricsSelect";
import { GameMetrics } from "../../farsight-api";
import {DdragonChampion, targets, useReferentialContext} from "../../Context";
import TableDisplayGrid, { rowStyle } from "../datagrids/shared/TableDisplayGrid";
import {SingleChampionSelect} from "../selectors/Shared/ChampionSelect";

export default function ChampionRunesSimple(props: {paramObject: any, target: targets}) {
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().getMetrics(['performance','relative_performance','teamwise_adjusted_relative_performance','games','winrate','gd8','csd8','gd15','csd15','exp_winrate','exp_bin_winrate'], props.target));
    const [directMatchup, setDirectMatchup] = useState<DdragonChampion|null>(null);

    const request_parameters = {
        ...props.paramObject,
        groupby: "keystone,subtree",
        metrics: metrics.map((metric) => metric.code).toString(),
        ordering: "performance",
        format: "json",
    };
    if(directMatchup){
        request_parameters.direct_matchup = directMatchup.id;
        request_parameters.watermark = request_parameters.watermark + directMatchup.id;
    }

    return (
        <Stack sx={{ width: "100%", height: "88%" }} spacing={3}>
                <Stack
                    direction={"row"}
                    sx={{
                        height: "50px",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={props.target} updateContext={false} />
                    <SingleChampionSelect value={directMatchup} updateValue={(newValue) => setDirectMatchup(newValue)} label={'Direct Matchup'} updateContext={false}/>
                </Stack>
            <TableDisplayGrid display_all={false} request={() => api.aggregate(request_parameters)} rowStyle={rowStyle.none} disp_columns={["keystone", "subtree"]} sortBy={"games"} paramObject={request_parameters} metrics={metrics} />
        </Stack>
    );
}
