import {targets, useReferentialContext} from "../../Context";
import {Fade, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";


export default function ItemIcon(props: { item: string; cooldown?: number; sx?: any, stacks?: number }) {
    const isOnCooldown = props.cooldown && props.cooldown > 0;
    const dispStacks = props.stacks && props.stacks >= 0;
    return (
        <Tooltip title={props.item} followCursor={true}>
            <Box sx={{ position: "relative", display: "inline-block" }}>
                <Box
                    component={"img"}
                    sx={{
                        filter: isOnCooldown ? "grayscale(100%) brightness(50%)" : "none",
                        ...props.sx,
                    }}
                    src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_40,w_40/v1/leagueAssets/${props.item}.png`}
                />
                {isOnCooldown ? (
                    <Typography
                        variant="caption"
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            fontWeight: "bold",
                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                            padding: "2px 4px",
                            borderRadius: "4px",
                        }}
                    >
                        {props.cooldown?.toFixed(1)}s
                    </Typography>
                ) : undefined}
                {!isOnCooldown && dispStacks ? (
                    <Typography
                        variant="caption"
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            fontWeight: "bold",
                            padding: "2px 4px",
                            borderRadius: "4px",
                        }}
                    >
                        {props.stacks}
                    </Typography>
                ) : undefined}
            </Box>
        </Tooltip>
    );
}