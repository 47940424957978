import React, { useEffect, useState } from "react";
import { Grow } from "@mui/material";
import Box from "@mui/material/Box";
import { Bar } from "react-chartjs-2";
import dayjs, {Dayjs} from "dayjs";
import {useReferentialContext} from "../../Context";
import {Player} from "../../farsight-api";



function generateDateRange(startDate: Dayjs, endDate: Dayjs) {
    const dates = [];
    let currentDate = dayjs(startDate);

    while (currentDate.isBefore(dayjs(endDate))) {
        dates.push(currentDate);
        currentDate = currentDate.add(1, 'day');
    }
    return dates;
}

function calculateRollingAverage(values: number[], window: number) {
    const rollingAverages = [];

    for (let i = 0; i < values.length; i++) {
        let sum = 0;
        let count = 0;

        for (let j = i - window; j <= i + window; j++) {
            if (j >= 0 && j < values.length) {
                sum += values[j];
                count++;
            }
        }

        const average = count > 0 ? sum / count : 0;
        rollingAverages.push(average);
    }

    return rollingAverages;
}

export default function GamesPerDay(props: {paramObject: any, player: Player, from:Dayjs, until:Dayjs}) {
    const [rows, setRows] = useState([] as any[]);
    const api = useReferentialContext().farsightApi.soloqGameSummaries

    useEffect(() => {

        const request_parameters = {
            ...props.paramObject,
            groupby: 'date__date',
            metrics: ['games','winrate','performance','relative_performance'].toString(),
            ordering: "date__date",
            format: "json"
        }
        console.log(request_parameters);
        api.aggregate(request_parameters).then(result => {
            console.log(result);
            setRows(result);
        });
    }, [props.paramObject.watermark]);

    const options = {
        responsive: true,
        lineTension: 0.3,
    };

    const date_range = generateDateRange(props.from, props.until)
    const game_values = date_range.map(date => {
            const filtered = rows.filter(row => dayjs(row.date__date).format("dddd, MMM DD") === date.format("dddd, MMM DD"));
            if(filtered.length>0) return filtered[0].games
            return 0
        })
    const rolling_average = calculateRollingAverage(game_values, 7);

    const data = {
        // labels:   rows.map((row: any) => dayjs(row.date__date).format("dddd, MMM DD")),
        labels:  date_range.map(date => date.format("dddd, MMM DD")),
        datasets: [
            {
                label: "Rolling average",
                data: rolling_average,
                backgroundColor: "rgba(42,114,190,0.8)",
                borderColor: "rgba(42,114,190,0.8)",
                type: 'line'
            },
            {
                label: "Games",
                data: game_values,
                backgroundColor: "rgba(190, 82, 42, 0.8)",
            },
        ],
    };

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                {/* @ts-ignore*/}
                <Bar options={options} data={data} />
            </Box>
        </Grow>
    );
}
