import Tab from "@mui/material/Tab";
import TeamIcon from "../Icons/TeamIcon";
import ChampionIcon from "../Icons/ChampionIcon";
import React from "react";
import {CLOUDINARY_BASE_URL, CLOUDINARY_RESIZE_PARAMS, NeonTabProps, NeonTabs} from "../../NeonComponents/NeonTabs";
import {TabProps} from "@mui/material";


interface NeonTabPropsWithChampion extends NeonTabProps {
  champion?: string;
}

export function NeonTabWithChampion({ label, asset, team_logo, champion, ...props }: NeonTabPropsWithChampion) {
  return (
    <Tab
      label={label.toUpperCase()}
      sx={{ textTransform: "uppercase" }}
      icon={
        asset ? (
          <img
            src={`${CLOUDINARY_BASE_URL}${CLOUDINARY_RESIZE_PARAMS}/v1/leagueAssets/${asset}.png`}
            alt={label}
            style={{
              margin: 0,
              padding: 0,
              verticalAlign: "middle",
              display: "block",
            }}
          />
        ) : team_logo ? <TeamIcon team_name={team_logo} sx={{width:'25px', height:'25px'}}/> :
            champion ? <ChampionIcon champion={champion} sx={{width:'25px', height:'25px'}} size={25} disable_link={true} role={'TOP_LANE'}/> : undefined
      }
      iconPosition="start"
      {...props} // ✅ Ensure all props (including `value`) are passed properly
    />
  );
}