import {targets} from "../../Context";
import {DdragonChampion, useReferentialContext} from "../../Context";
import React, {useState} from "react";
import {GameMetrics} from "../../farsight-api";
import {Grow, Stack} from "@mui/material";
import MultipleMetricsSelect from "../selectors/Shared/MetricsSelect";
import TableDisplayGrid, {rowStyle} from "../datagrids/shared/TableDisplayGrid";
import {SingleChampionSelect} from "../selectors/Shared/ChampionSelect";
import {BlindOrCounter} from "../selectors/YesNoSelector";


export default function ChampionsStats(props: { paramObject: any; target: targets}) {
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().defaultMetrics(props.target));
    const [matchup, setMatchup] = useState<DdragonChampion|null>(null);

    const request_parameters = {
        ...props.paramObject,
        groupby: 'champion',
        metrics: metrics.map((metric) => metric.code).toString(),
        ordering: "-games",
        format: "json",
    };
    if(matchup){
        request_parameters.direct_matchup = matchup.id;
        request_parameters.watermark = JSON.stringify(request_parameters);
    }

    return (
        <Stack direction={"column"} sx={{ height: "100%", width: "100%" }} spacing={2}>
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction={"row"}
                    sx={{
                        height: "50px",
                        justifyContent: "left",
                        width: "100%",
                    }}
                    spacing={2}
                >
                    <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={props.target} updateContext={false} />
                    <SingleChampionSelect value={matchup} updateValue={setMatchup} label={"Direct matchup"}/>
                </Stack>
            </Grow>
            <Stack direction={"row"} sx={{ height: "100%", width: "100%" }} spacing={1}>
                <TableDisplayGrid
                    display_all={false}
                    request={() => api.aggregate(request_parameters)}
                    rowStyle={rowStyle.none}
                    disp_columns={['champion']}
                    sortBy={"games"}
                    paramObject={request_parameters}
                    metrics={metrics}
                />
            </Stack>
        </Stack>
    );
}