import React, {useContext, useState} from "react";
import dayjs from "dayjs";
import {Stack} from "@mui/material";
import DatePicker from "../../../components/selectors/Shared/DateSelect";
import Box from "@mui/material/Box";
import OffseasonShortlist from "../../../components/datagrids/Offseason/OffseasonShortlist";
import OffseasonShortlistSelect from "../../../components/selectors/Offseason/OffseasonShortlistSelect";
import WindowContainer from "../../../components/layout/WindowContainer";
import {OffSeasonShortlist} from "../../../farsight-api";
import FilterSet from "../../../components/selectors/Filterset";
import {targets, useReferentialContext} from "../../../Context";
import ServerSelect from "../../../components/selectors/ServerSelect";

export default function Shortlists() {
    const [from, setFrom] = useState(dayjs("2025-01-15"));
    const [until, setUntil] = useState(dayjs());
    const [shortlist, setShorlist] = useState(null as OffSeasonShortlist|null);
    const [paramObject, setParamObject] = useState({} as any);
    const [servers, setServers] = useState(['EUW1','KR'])

    return (
        <WindowContainer spacing={2}> 
            <OffseasonShortlistSelect onChange={(event: any, newValue: any) => setShorlist(newValue)} value={shortlist} />
            <Stack direction={'row'}>
                <FilterSet paramObject={paramObject}
                       setParamObject={setParamObject}
                       target={targets.competitive}
                       date__gte={from}
                       setDateGte={setFrom} date__lt={until} setDateLt={setUntil}/>
                <ServerSelect values={servers} updateValue={setServers}/>
            </Stack>

            <Box sx={{ width: "100%", height: "100%" }}>
                <OffseasonShortlist from={from} until={until} shortlist={shortlist} servers={servers}/>
            </Box>
        </WindowContainer>
    );
}
