import React, { useEffect, useState } from "react";
import {Grid, Stack, Typography} from "@mui/material";
import MapDrawer from "../../../../components/maps/map_drawer";
import {targets} from "../../../../Context";
import dayjs, { Dayjs } from "dayjs";
import HeatMapDrawer from "../../../../components/maps/heatmap_drawer";
import FilterSet from "../../../../components/selectors/Filterset";
import {ChampionsQueueSummariesSweeperUsedEventsInner, CompetitiveGameSummaries, Team} from "../../../../farsight-api";
import { useReferentialContext } from "../../../../Context";
import SpecificGameSelector from "../../../../components/selectors/Competitive/SpecificGameSelector";
import WindowContainer from "../../../../components/layout/WindowContainer";
import {NeonTab, NeonTabs} from "../../../../NeonComponents/NeonTabs";
import Box from "@mui/material/Box";
import TabPanel from "../../../../components/tabs_switch/tabs_panel";
import SweeperHeatMapRender from "../../../../components/maps/SweeperHeatMapRender";
import {EventCard, formatTimestamp, SweeperEventCard} from "../../../../components/Cards/EventCards";
import {loss_color, scrollable, win_color} from "../../../../theme";
import {NeonRow} from "../../../../NeonComponents/NeonRow";
import ChampionIcon from "../../../../components/Icons/ChampionIcon";
import TeamIcon from "../../../../components/Icons/TeamIcon";
import {Skeleton} from "@mui/lab";
import {NeonCard, NeonCardHeader} from "../../../../NeonComponents/NeonCards";
import AbilityIcon from "../../../../components/Icons/AbilityIcon";
import {NeonButton} from "../../../../NeonComponents/Buttons";

export default function WardMaps(props: {target : targets}) {
    const [selectedGames, setSelectedGames] = useState([] as string[]);

    const [team, setTeam] = useState<Team | null>(useReferentialContext().selectedTeam);
    const [from, setFrom] = useState<Dayjs>(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState<Dayjs>(dayjs());
    const [roles, setRoles] = useState<string[]>(["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"]);
    const [wardTypes, setWardTypes] = useState<string[]>(['control','yellowTrinket','blueTrinket','sight']);
    const [ingameInterval, setIngameInterval] = useState([0,600]);
    const [tab, setTab] = useState(0);

    const updateSelection = (gameSelected: any[]) => {
        setSelectedGames(gameSelected.map(g => g.game_id));
    };

    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                team={team}
                setTeam={setTeam}
                roles={roles}
                setRoles={setRoles}
                ingameInterval={ingameInterval}
                setIngameInterval={setIngameInterval}
                wardTypes={wardTypes}
                setWardTypes={setWardTypes}
                target={props.target}
            />

            <Stack
                direction={"row"}
                sx={{
                    height: "85%",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start"
                }}
                spacing={1}
            >
                {paramObject.team && team ?
                    <SpecificGameSelector team={team} updateSelection={updateSelection} from={from} until={until} include_bayes={true} side_select={true} target={props.target} single={false} />
                    : <></>
                }
                <Stack direction={'column'} sx={{alignItems:'top',justifyContent:'top', height:'100%', width:'100%'}}>
                <NeonTabs value={tab} onChange={(event, newValue) => setTab(newValue)} >
                    <NeonTab label={'Wards'}/>
                    <NeonTab label={'Sweepers'}/>
                </NeonTabs>
                    <TabPanel value={tab} index={0}>
                        {paramObject.team && team ?
                            <WardMapDraws
                                team={team}
                                game_ids={selectedGames}
                                target={props.target}
                                roles={roles}
                                ingameInterval={ingameInterval}
                                wardTypes={wardTypes}
                                /> : <></>
                        }
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Box sx={{width:'100%', height:'100%'}}>
                        {paramObject.team1 && team ?
                            <SweepersMapRender
                                team={team}
                                game_ids={selectedGames}
                                target={props.target}
                                roles={roles}
                                ingameInterval={ingameInterval}
                                wardTypes={wardTypes}
                                /> : <></>
                        }
                        </Box>
                    </TabPanel>
                    </Stack>
            </Stack>
        </WindowContainer>
    );
}

export function SweepersMapRender(props: {
    game_ids: string[],
    roles: string[],
    team: Team,
    target: targets,
    ingameInterval: number[],
    wardTypes?: string[]
}){
    const api = useReferentialContext().farsightApi;
    const [summaries, setSummaries] = useState([] as CompetitiveGameSummaries[]);
    const [sweepingPositions, setSweepingPositions] = useState([] as [number, number][]);
    const [sweeperEvents, setSweeperEvents] = useState([] as {
        event: ChampionsQueueSummariesSweeperUsedEventsInner, summary: CompetitiveGameSummaries
    }[]);
    const [selectedSweeperEvents, setSelectedSweeperEvents] = useState([] as {
        event: ChampionsQueueSummariesSweeperUsedEventsInner, summary: CompetitiveGameSummaries
    }[]);
    useEffect(() => {
        api.competitiveGameSummaries.list(
            {team1: props.team.team_name, additional_filters: {game_id__in: [props.game_ids.toString()]}}
        ).then(r => {
            setSummaries(r);
        })
    }, [props.game_ids, props.team]);

    useEffect(() => {
        const new_sweeper_events = summaries.flatMap(
            s => s.sweeper_used_events ? s.sweeper_used_events.filter(sweeper_event =>
                sweeper_event.timestamp > props.ingameInterval[0] &&
                sweeper_event.timestamp < props.ingameInterval[1] &&
                props.roles.includes(s.role)).map( filtered_se => {return {event: filtered_se, summary: s}}
            ): []
        );
        setSweeperEvents(new_sweeper_events);
        setSelectedSweeperEvents(new_sweeper_events);
    }, [props.roles, props.wardTypes, props.ingameInterval, summaries]);

    useEffect(() => {
        setSweepingPositions(
            selectedSweeperEvents.flatMap(sweeper_event => sweeper_event.summary.position_history ? sweeper_event.summary.position_history?.slice(sweeper_event.event.timestamp, sweeper_event.event.timestamp + sweeper_event.event.duration) as [number, number][] : [])
        )
    }, [selectedSweeperEvents]);

    return <Stack direction={'row'} sx={{height:'98%', maxHeight:'98%', minHeight:'98%', padding:1}}>
        <NeonCard sx={{
                height: '100%',
                width: "100%",
                maxWidth: '400px',
                minWidth: '400px',
                borderRadius: 2,
                alignItems: "center",
                padding: "5px",
            }}>
                <NeonCardHeader title={`${sweeperEvents.length} Sweepers `}
                                action={
                    <Stack direction={'row'} sx={{width:'40px', alignItems:'center', justifyContent:'center'}}>
                        <AbilityIcon ability={'Oracle Lens'} sx={{width:'40px', height:'40px'}}/>
                    </Stack>}
                                subheader={`from ${formatTimestamp(props.ingameInterval[0])} to ${formatTimestamp(props.ingameInterval[1])} in ${props.game_ids.length} games`}
                />
            <Stack direction={'row'} sx={{width:'100%', alignItems:'space-evenly', justifyContent:'space-evenly', mb:1}}>
                <NeonButton variant={'outlined'} sx={{padding:1}} onClick={() => setSelectedSweeperEvents(sweeperEvents)}>Select all</NeonButton>
                <NeonButton variant={'outlined'} sx={{padding:1}} onClick={() => setSelectedSweeperEvents([])}>Unselect all</NeonButton>
            </Stack>
            <Stack direction={'column'}
                sx={{
                    overflowY:'auto',
                    display: "flex",
                    height: "82%",
                    width: "100%",
                    padding: 1,
                    ...scrollable
                }}
            >
            {
                sweeperEvents.map(s_e => <NeonRow
                    left_glow={selectedSweeperEvents.includes(s_e)} onClick={() => {
                        if(selectedSweeperEvents.includes(s_e)) {
                            setSelectedSweeperEvents(selectedSweeperEvents.filter(nse => nse !== s_e))
                        }else{
                            setSelectedSweeperEvents([...selectedSweeperEvents, s_e])
                        }
                }}
                    direction={'row'} sx={{backgroundColor: s_e.summary.win ? win_color : loss_color}}>
                    <ChampionIcon champion={s_e.summary.champion} disable_link={true} role={s_e.summary.role}/>
                    <TeamIcon team_name={s_e.summary.team2}/>
                    <Typography sx={{minWidth:'150px', maxWidth:'150px', alignItems:'left'}} variant={'h6'}>{s_e.summary.player}</Typography>
                    <Typography  sx={{minWidth:'50px', maxWidth:'50px', alignItems:'left'}} variant={'h6'}>{formatTimestamp(s_e.event.timestamp)}</Typography>
                </NeonRow>)
            }
            </Stack>
        </NeonCard>
        <Box sx={{height:'100%', width:'80%'}}>
            { sweepingPositions.length > 0 ?
                <SweeperHeatMapRender sweeping_positions={sweepingPositions}/> : <Skeleton/>
            }
        </Box>
    </Stack>
}

export function WardMapDraws(props: {
    game_ids: string[],
    roles: string[],
    team: Team,
    target: targets,
    ingameInterval: number[],
    points?: boolean,
    heat?:boolean,
    wardTypes?: string[]
}) {
    const points = props.points === undefined ? true : props.points;
    const heat = props.heat === undefined ? true : props.heat;

    const api = useReferentialContext().farsightApi.maps
    const [data, setData] = useState<any>({});
    const [tab, setTab] = useState(0);
    console.log(props.wardTypes)
    useEffect(() => {
        setData([]);
        api.events({
            team: props.team.team_name,
            games: [props.game_ids.toString()],
            roles: [props.roles.toString()],
            target: props.target,
            event_types: ['wards'],
            ig_from: props.ingameInterval[0],
            ig_until: props.ingameInterval[1],
        }).then((d: any) => {
            setData(d);
            console.log(d.points.filter((event: { [x: string]: string; }) => event["wardType"] !== "unknown" && (props.wardTypes ? props.wardTypes.includes(event.wardType) : true)))
        })
    }, [props.game_ids, props.roles, props.team, props.target, props.ingameInterval]);
    return (
        <Stack
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
            }} direction={'column'}
        >
            <NeonTabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                <NeonTab label={'Points'}/>
                <NeonTab label={'Heat'}/>
            </NeonTabs>
            <TabPanel value={tab} index={0}>
                <MapDrawer points={data.points ? data.points.filter((event: { [x: string]: string; }) => event["wardType"] !== "unknown" && (props.wardTypes ? props.wardTypes.includes(event.wardType) : true)) : []}/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
            {heat ? <HeatMapDrawer
                points={data.points ?
                    data.points.filter((event: { [x: string]: string; }) => event["wardType"] !== "unknown" && (props.wardTypes ? props.wardTypes.includes(event.wardType) : true))
                    : []}/> : <></>}
            </TabPanel>
        </Stack>
    );
}
