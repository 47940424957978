import {styled} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import {getNeonColor} from "../theme";


export const NeonAppBar = styled(AppBar)( ({ theme }) => (
    { width: "100%"
        , background:"rgba(20, 20, 30, 0.95)",
        "&::after": {
              content: "''",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "100%",
              height: "1px", // Thinner glowing band
              background: getNeonColor(theme), // Solid neon glow without blur
              boxShadow: `0px 0px 10px ${getNeonColor(theme)}`, // Glowing effect similar to selected tabs
              opacity: 1,
              pointerEvents: "none",
            },}
));