import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { intToOrdinal } from "../styled_datagrid";
import { Checkbox, Fade, FormControl, FormGroup, Grow, Paper, Stack, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import RoleSelect from "../../selectors/RoleSelect";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import {GameMetrics, Player} from "../../../farsight-api";
import {targets, useReferentialContext} from "../../../Context";



export default function RankedStatsVs(props: {paramObject: any, metrics: GameMetrics[], target: targets}) {
    const [playerStats, setPlayerStats] = useState<Record<string, any[]>>({
        'TOP_LANE': [],
        'JUNGLE': [],
        'MID_LANE': [],
        'BOT_LANE': [],
        'UTILITY': [],
    });
    const context = useReferentialContext();
    const [role, setRole] = useState('BOT_LANE');
    const [selected, setSelected] = useState(props.metrics.map((metric: any) => false) as boolean[]);
    const [player1, setPlayer1] = useState(undefined as undefined | string);
    const [player2, setPlayer2] = useState(undefined as undefined | string);
    const [player3, setPlayer3] = useState(undefined as undefined | string);
    const [player4, setPlayer4] = useState(undefined as undefined | string);
    const [player5, setPlayer5] = useState(undefined as undefined | string);

    useEffect(() => {
        const api = context.farsightApi.getSummariesApi(props.target);

        api.aggregate({
            ...props.paramObject,
            metrics:[props.metrics.map(m => m.code)].toString(),
            groupby: ['player','role'].toString(),
            aggregates: [props.metrics.map(m => `rank__${m.code}__role`)].toString(),
            index_on: 'role',
            ordering: 'player'
            // @ts-ignore
        }).then(r => setPlayerStats(r));
    }, [props.paramObject.watermark, props.metrics]);

    const rows_on_role = playerStats[role];

    function handleSelectionChange(index: number) {
        let ns = selected.map((item: boolean) => item);
        ns[index] = !ns[index];
        setSelected(ns);
    }

    return (
        <Stack direction={"column"} sx={{ alignItems: "top", height: "100%", width: "100%" }} spacing={2}>
            <Stack direction={"row"} spacing={2}>
                <RoleSelect value={role} onChange={setRole} />
                {
                    <Button
                        variant={"outlined"}
                        onClick={() =>
                            export_selection_excel(
                                rows_on_role,
                                props.metrics,
                                selected,
                                // @ts-ignore
                                [player1, player2, player3, player4, player5].filter((p: string | undefined) => p !== undefined),
                                true
                            )
                        }
                    >
                        Export for Excel
                    </Button>
                }
            </Stack>
            {playerStats[role].length > 0 ? (
                <Stack
                    direction={"row"}
                    sx={{
                        height: "90%",
                        width: "100%",
                        justifyContent: "left",
                        display: "flex",
                    }}
                    spacing={2}
                >
                    <RowSelection metrics={props.metrics} selection={selected} onChange={handleSelectionChange} />
                    <RankedStatsPlayer rows={rows_on_role} metrics={props.metrics} selected={selected} player={player1} onChange={(event: any, newValue: string) => setPlayer1(newValue)} />
                    <RankedStatsPlayer rows={rows_on_role} metrics={props.metrics} selected={selected} player={player2} onChange={(event: any, newValue: string) => setPlayer2(newValue)} />
                    <RankedStatsPlayer rows={rows_on_role} metrics={props.metrics} selected={selected} player={player3} onChange={(event: any, newValue: string) => setPlayer3(newValue)} />
                    <RankedStatsPlayer rows={rows_on_role} metrics={props.metrics} selected={selected} player={player4} onChange={(event: any, newValue: string) => setPlayer4(newValue)} />
                    <RankedStatsPlayer rows={rows_on_role} metrics={props.metrics} selected={selected} player={player5} onChange={(event: any, newValue: string) => setPlayer5(newValue)} />
                </Stack>
            ) : (
                <></>
            )}
        </Stack>
    );
}



export function DisplayStatsH2H(props: {paramObject: any, metrics: GameMetrics[], player1: Player, player2: Player}) {
    const [playerStats, setPlayerStats] = useState<Record<string, any>>({});
    const context = useReferentialContext();
    const [selected, setSelected] = useState(props.metrics.map((metric: any) => false) as boolean[]);


    useEffect(() => {
        const api = context.farsightApi.competitiveGameSummaries;
        const param_object = JSON.parse(JSON.stringify(props.paramObject));

        param_object.additional_filters.player__in = [[props.player1.player, props.player2.player]]
        param_object.additional_filters.direct_opponent__in = [[props.player1.player, props.player2.player]]

        api.aggregate({
            ...param_object,
            metrics:[props.metrics.map(m => m.code)].toString(),
            groupby: ['player'].toString(),
            index_on: 'player',
            ordering: 'player'
            // @ts-ignore
        }).then(r => setPlayerStats(r));
    }, [props.paramObject.watermark, props.metrics, props.player1, props.player2]);


    function handleSelectionChange(index: number) {
        let ns = selected.map((item: boolean) => item);
        ns[index] = !ns[index];
        setSelected(ns);
    }

    return playerStats ? (
        <Stack direction={"column"} sx={{ alignItems: "top", height: "100%", width: "100%" }} spacing={2}>
            <Stack direction={"row"} spacing={2}>
                {
                    <Button
                        variant={"outlined"}
                        onClick={() =>
                            export_selection_excel(
                            [playerStats[props.player1.player][0], playerStats[props.player2.player][0]],
                                props.metrics,
                                selected,
                                // @ts-ignore
                                [props.player1.player, props.player2.player].filter((p: string | undefined) => p !== undefined),
                                false
                            )
                        }
                    >
                        Export for Excel
                    </Button>
                }
            </Stack>
            {playerStats[props.player1.player] ? (
                <Stack
                    direction={"row"}
                    sx={{
                        height: "90%",
                        width: "100%",
                        justifyContent: "left",
                        display: "flex",
                    }}
                    spacing={2}
                >
                    <RowSelection metrics={props.metrics} selection={selected} onChange={handleSelectionChange} />
                    <Stack direction={'column'}>
                        <Paper
                            sx={{
                                borderRadius: 0,
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-around",
                                display: "flex",
                            }}
                        >
                            <Typography
                                sx={{
                                    alignText: "center",
                                    width: "100%",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                {`${props.player1.player}`}
                            </Typography>
                        </Paper>
                        <StatsDisplay stats={playerStats[props.player1.player][0]} metrics={props.metrics} selected={selected}  rank={false}/>
                    </Stack>
                    <Stack direction={'column'}>
                        <Paper
                            sx={{
                                borderRadius: 0,
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-around",
                                display: "flex",
                            }}
                        >
                            <Typography
                                sx={{
                                    alignText: "center",
                                    width: "100%",
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                {`${props.player2.player}`}
                            </Typography>
                        </Paper>
                        <StatsDisplay stats={playerStats[props.player2.player][0]} metrics={props.metrics} selected={selected}  rank={false}/>
                    </Stack>

                </Stack>
            ) : (
                <></>
            )}
        </Stack>
    ) : <></>;
}

export function DisplayPlayerStats(props: {metrics: GameMetrics[], stats: any[]}) {
    const context = useReferentialContext();
    const [selected, setSelected] = useState(props.metrics.map((metric: any) => false) as boolean[]);


    function handleSelectionChange(index: number) {
        let ns = selected.map((item: boolean) => item);
        ns[index] = !ns[index];
        setSelected(ns);
    }



    return props.stats ? (
        <Stack direction={"column"} sx={{ alignItems: "top", height: "100%", width: "100%" }} spacing={2}>
            <Stack direction={"row"} spacing={2}>
                {
                    <Button
                        variant={"outlined"}
                        onClick={() =>
                            export_selection_excel(
                                props.stats,
                                props.metrics,
                                selected,
                                // @ts-ignore
                                [props.player1.player, props.player2.player].filter((p: string | undefined) => p !== undefined),
                                false
                            )
                        }
                    >
                        Export for Excel
                    </Button>
                }
            </Stack>
            <Stack
                direction={"row"}
                sx={{
                    height: "90%",
                    width: "100%",
                    justifyContent: "left",
                    display: "flex",
                }}
                spacing={2}
            >
                <RowSelection metrics={props.metrics} selection={selected} onChange={handleSelectionChange} />
            {props.stats.map(p_stats => <Stack direction={'column'}>
                <Paper
                    sx={{
                        borderRadius: 0,
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-around",
                        display: "flex",
                    }}
                >
                    <Typography
                        sx={{
                            alignText: "center",
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        {`${p_stats.player}`}
                    </Typography>
                </Paper>
                <StatsDisplay stats={p_stats} metrics={props.metrics} selected={selected}  rank={false}/>
            </Stack>)}


                </Stack>
            ) : (
                <></>
            )
        </Stack>
    ) : <></>;
}

const renderValue = {
    rating: (value: any) => Math.round(value * 100) / 100,
    percentage: (value: any) =>
        value !== undefined && value !== null
            ? value.toLocaleString("en", {
                  style: "percent",
                  minimumFractionDigits: 1,
              })
            : "",
    short_int: (value: any) => value,
    string:( value:any) => value,
};

function export_selection_markdown(rows: any[], metrics: any[], selected: boolean[], players: string[]) {
    const selected_metrics = [] as any[];
    for (let i = 0; i < selected.length; i++) {
        if (selected[i]) selected_metrics.push(metrics[i]);
    }

    const header_row = "| |" + players.join("|") + "|";
    const second_row = "|:--- |" + players.map((p: any) => ":---").join("|") + "|";
    const to_ret = [header_row, second_row];

    for (let i = 0; i < selected_metrics.length; i++) {
        const metric = selected_metrics[i];
        const stats = players.map((p: string) => {
            const player_stats = rows.filter((row: any) => row.player === p)[0];
            // @ts-ignore
            return `${renderValue[metric.type](player_stats[metric.code])} (${intToOrdinal(player_stats[`rank_${metric.code}`])})`;
        });
        const data_row = `|${metric.label}|` + stats.join("|") + "|";
        to_ret.push(data_row);
    }
    navigator.clipboard.writeText(to_ret.join("\n"));
}

function export_selection_excel(rows: any[], metrics: any[], selected: boolean[], players: string[], rank: boolean) {
    console.log(rows);
    const selected_metrics = [] as any[];
    for (let i = 0; i < selected.length; i++) {
        if (selected[i]) selected_metrics.push(metrics[i]);
    }

    const header_row = "\t" + players.join("\t\t");
    const to_ret = [header_row];

    for (let i = 0; i < selected_metrics.length; i++) {
        const metric = selected_metrics[i];
        const stats = players.map((p: string) => {
            const player_stats = rows.filter((row: any) => row.player === p)[0];
            // @ts-ignore
            if(rank) return `${renderValue[metric.type](player_stats[metric.code])}\t${intToOrdinal(player_stats[`rank__${metric.code}__role`])}`;
            // @ts-ignore
            return `${renderValue[metric.type](player_stats[metric.code])}`
        });
        const data_row = `${metric.label}\t` + stats.join("\t") + "\t";
        to_ret.push(data_row);
    }
    navigator.clipboard.writeText(to_ret.join("\n"));
}

function RankedStatsPlayer(props: { rows: any[]; metrics: GameMetrics[]; selected: boolean[]; player: string | undefined; onChange: any }) {
    const [player_id, setPlayer] = useState(undefined as string | undefined);
    const [player_stats, setPlayerStats] = useState(undefined as any | undefined);
    const players_in_tab = props.rows.map((row: any) => row.player);
    useEffect(() => {
        if (player_id) {
            const ps = props.rows.filter((row: any) => row.player === player_id);
            if (ps) setPlayerStats(ps[0]);
        }
    }, [props]);

    return (
        <Stack direction={"column"} sx={{ width: "300px" }}>
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                <Autocomplete
                    value={props.player}
                    onChange={(event: any, newValue: any) => {
                        props.onChange(event, newValue);
                        const ps = newValue ? props.rows.filter((row: any) => row.player === newValue)[0] : undefined;
                        setPlayerStats(ps);
                    }}
                    options={players_in_tab}
                    renderInput={(params) => <TextField {...params} label="Player" />}
                    ListboxProps={{ className: "scrollable" }}
                    sx={{ mb: "50px" }}
                />
            </Grow>
            {player_stats ? (
                <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 1000 }}>
                    <Paper
                        sx={{
                            borderRadius: 0,
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-around",
                            display: "flex",
                        }}
                    >
                        <Typography
                            sx={{
                                alignText: "center",
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            {`${props.player}`}
                        </Typography>
                    </Paper>
                </Grow>
            ) : (
                <></>
            )}
            <StatsDisplay stats={player_stats} metrics={props.metrics} selected={props.selected} rank={true}/>
        </Stack>
    );
}

function StatsDisplay(props: {stats: any, metrics: GameMetrics[], selected: boolean[], rank: boolean}){
    console.log(props.stats);
    return props.stats ? (
        <Stack direction={"column"} sx={{ width: "300px" }}>
            {props.metrics.map((metric: GameMetrics, index: number) => {
            // @ts-ignore
            return (
                <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                    <Paper
                        sx={{
                            borderRadius: 0,
                            marginTop: "1px",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-around",
                            display: "flex",
                            outline: props.selected[index] ? "1px solid white" : "",
                            // border: props.selected[index] ? '1px solid white' : '1px solid black'
                        }}
                    >
                        <Typography
                            sx={{
                                alignText: "center",
                                width: "33%",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            {
                                // @ts-ignore
                                `${metric.short_name}`
                            }
                        </Typography>
                        <Typography
                            sx={{
                                alignText: "center",
                                width: "33%",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            {
                                // @ts-ignore
                                `${renderValue[metric.type](props.stats[metric.code])}`
                            }
                        </Typography>
                        {props.rank ? <Typography
                            sx={{
                                alignText: "center",
                                width: "33%",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            {
                                // @ts-ignore
                                `(${intToOrdinal(props.stats[`rank__${metric.code}__role`])})`
                            }
                        </Typography> : <></>}
                    </Paper>
                </Grow>
            );
        }
        ) }</Stack>
    ) : (
        <></>
    )
}

function RowSelection(props: { metrics: GameMetrics[]; selection: boolean[]; onChange: any }) {
    const [selection, setSelection] = useState(props.selection);
    useEffect(() => {
        setSelection(props.selection);
    }, [props]);

    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    display: "flex",
                    height: "100%",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                        width: "0.2em",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#e1e1e1e",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}
                component="fieldset"
            >
                <FormControl
                    sx={{
                        m: 1,
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: 1,
                    }}
                >
                    <FormLabel
                        sx={{
                            alignItems: "center",
                            justifyContent: "space-around",
                            display: "flex",
                            width: "100%",
                            mb: 1,
                        }}
                    >
                        Stats selection
                    </FormLabel>
                    <FormGroup>
                        {props.metrics.map((metric: GameMetrics, index: number) => (
                            <Tooltip title={metric.desc} placement={"top"} arrow followCursor={true}>
                                <Fade
                                    in={true}
                                    style={{ transformOrigin: "0 0 0" }}
                                    {...{
                                        timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),
                                    }}
                                >
                                    <FormControlLabel
                                        sx={{ width: "100%" }}
                                        control={
                                            <Checkbox
                                                checked={props.selection[index]}
                                                onChange={(params: any) => props.onChange(index)}
                                                name={index.toString()}
                                                sx={{
                                                    color: "white",
                                                    "&.Mui-checked": {
                                                        color: "white",
                                                        fill: "white",
                                                    },
                                                }}
                                            />
                                        }
                                        label={metric.short_name}
                                    />
                                </Fade>
                            </Tooltip>
                        ))}
                    </FormGroup>
                </FormControl>
            </Box>
        </Grow>
    );
}
