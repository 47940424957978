import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grow,
    Paper,
    PaperProps,
    Stack,
    Typography
} from "@mui/material";
import {targets, useReferentialContext} from "../../Context";
import {Player} from "../../farsight-api";
import {ChartsReferenceLine, LineChart} from "@mui/x-charts";
import Draggable from 'react-draggable';
import Button from "@mui/material/Button";
import PerformanceScoresRadar from "../../page/Shared/ScoreRadar";
import TableDisplayGrid, {rowStyle} from "../datagrids/shared/TableDisplayGrid";

export default function CompetitivePlayerEvolution(props: {player: Player, year?: number|undefined}) {
    const [rows, setRows] = useState([] as any[]);
    const [radarsOpen, setRadarsOpen] = useState([] as any[]);
    const api = useReferentialContext().farsightApi.competitiveGameSummaries;


    useEffect(() => {
        api.aggregate(
            {
                player:props.player.player,
                groupby: ['team1,league,split,phase'],
                ordering: ['date__max'],
                additional_filters:{
                    date__year: props.year
                },
                metrics:['performance,relative_performance,games,winrate,teamwise_adjusted_relative_performance'],
                aggregates:['date__max'],
                having: {games__gte: 2}
            }
        ).then(result => {
            setRadarsOpen(result.map(() => false))
            setRows(result.map((row: any, i: number) => {
                return {
                    ...row,
                    label: `${row.team1}\n${row.split ? row.split : ''} ${row.league} ${row.phase ? row.phase : ''}\n${row.games} games,${(row.winrate === true ? 100 : row.winrate * 100).toFixed(1)}% WR`,
                    i: i
                }
            }
        ))});
    }, [props.player, props.year]);

    const clickHandler = (
        event: any, params: any
    ) => {
        const n_array = radarsOpen.map(i => i);
        n_array[params.dataIndex] = true;
        setRadarsOpen(n_array)
    }

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box sx={{ width: "100%", height: "100%" }}>
                {rows.map((r, i) => <DraggableSplitRadar label={r.label} open={radarsOpen[i]}
                                                         handleClose={() => {
                    const n_array = radarsOpen.map(i => i);
                    n_array[i] = false;
                    setRadarsOpen(n_array);
                }} league={r.league} player={props.player} split={r.split} year={props.year} team={r.team1} phase={r.phase}/>)}
                <LineChart  xAxis={[{ data: rows.map((r: any, i:number) => r.label), scaleType:'band'}]} dataset={rows} sx={{cursor:'pointer'}}
                            yAxis={[{max: 115, min:-60}]} grid={{horizontal: true, vertical: true}}
                              onAxisClick={(event, d) => clickHandler(event,d)}
                           series={[{
                              label: 'Performance',
                              dataKey: 'performance',
                           },
                           {
                              label: 'Relative Performance',
                              dataKey: 'relative_performance',
                           },{
                              label: 'Teamwise Relative Performance',
                              dataKey: 'teamwise_adjusted_relative_performance',
                           },
                ]}>
                    <ChartsReferenceLine y={0} label="Global Average" labelAlign="end" />
                </LineChart>
            </Box>
        </Grow>
    );
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function DraggableSplitRadar(props: {label: string, player: Player, team:string, split?:string, league:string,phase?:string,year:number|undefined, open: boolean, handleClose: () => void}){
    const radar_params = {
                    additional_filters: {date__year:props.year},
                    player: props.player.player, league:props.league, team1:props.team,
                } as any;

    const tierlist_params = {
                    additional_filters: {date__year:props.year},
                    league:props.league, role:props.player.role,
                    groupby: ['player,team1'],
                    ordering:['-performance'],
                    metrics:['games,winrate,performance,relative_performance,teamwise_adjusted_relative_performance']
                } as any;
    if(props.split) {
        tierlist_params.split = props.split;
        radar_params.split = props.split;
    }
    if(props.phase) {
        tierlist_params.phase = props.phase;
        radar_params.phase = props.phase;
    }
    tierlist_params.watermark = JSON.stringify(tierlist_params)
    radar_params.watermark = JSON.stringify(radar_params)
    const ctx = useReferentialContext()
    const api = ctx.farsightApi.competitiveGameSummaries;

    return (
         <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="draggable-dialog-title"
            PaperComponent={PaperComponent}
            fullWidth={false}
            maxWidth={'xl'}
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {props.label}
            </DialogTitle>
        <DialogContent sx={{ width:'1500px', height:'700px'}}>
            <Stack direction={'row'} sx={{width:'100%', height:'100%'}}>
                <Box sx={{width:'40%', height:'100%'}}>
                    <TableDisplayGrid
                        display_all={false}
                        request={() => api.aggregate(tierlist_params)}
                        rowStyle={rowStyle.none}
                        disp_columns={["player", "team1","games", "winrate", "performance", "relative_performance","teamwise_adjusted_relative_performance"]}
                        sortBy={"performance"}
                        paramObject={tierlist_params}
                    />
                </Box>
                <PerformanceScoresRadar target={targets.competitive} paramObject={radar_params}/>
            </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
        </Dialog>
  );
}
