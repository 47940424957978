import {Fade, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {targets, useReferentialContext} from "../../Context";


export default function ChampionIcon(props: {champion: string, role:string, sx? :any, size?: number, respawn_timer?:number, disable_link?: boolean}){
    const isRespawning = props.respawn_timer && props.respawn_timer > 0;

    const ctx = useReferentialContext();
    let details_page = ctx.currentTarget === targets.competitive ? 1 : ctx.currentTarget === targets.soloq ? 18 : 1
    return <Tooltip title={props.disable_link ? null : `Check ${ctx.ddragon_champions[props.champion].name} stats for ${ctx.currentTarget}`} followCursor={true}>
        <Box sx={{ position: "relative", display: "inline-block", height:`${props.size ? props.size : 40}px`, width:`${props.size ? props.size : 40}px`, ...props.sx }}>
            <Box component={"img"}
                 src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${props.size ? props.size : 40},w_${props.size ? props.size : 40}/championAssets/${props.champion}.png`}
            onClick={() => {
                if(!props.disable_link) {
                    ctx.setSelectedRole(props.role);
                    ctx.setSelectedChampion(ctx.championsReferential.filter(c => c.id === props.champion)[0]);
                    ctx.setCurrentTab(details_page);
                }
            }} sx={{filter: isRespawning ? "grayscale(100%) brightness(50%)" : "none", cursor: 'pointer', ...props.sx}}/>
                {isRespawning ? (
                    <Typography
                        variant="caption"
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            fontWeight: "bold",
                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                            padding: "2px 4px",
                            borderRadius: "4px",
                        }}
                    >
                        {props.respawn_timer?.toFixed(1)}s
                    </Typography>
                ) : null}
            </Box>
            </Tooltip>
}