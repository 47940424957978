import React, {useEffect, useState} from "react";
import {Grid, Grow, Paper, Stack, styled} from "@mui/material";
import dayjs from "dayjs";
import {DraftBlockCompetitive, DraftBlockScrim} from "../../../../components/visu_blocks/Drafts";
import TabPanel from "../../../../components/tabs_switch/tabs_panel";
import DraftingMetagameBlock from "../../../../components/visu_blocks/DraftingMetagame";
import FilterSet from "../../../../components/selectors/Filterset";
import {GamesData, Team} from "../../../../farsight-api";
import {targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import {NeonTab, NeonTabs} from "../../../../NeonComponents/NeonTabs";
import {scrollable} from "../../../../theme";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import {NeonCard, NeonCardContent, NeonCardHeader} from "../../../../NeonComponents/NeonCards";

export default function TeamDrafts() {
    const [tab, setTab] = useState(0);
    const [paramObject, setParamObject] = useState({} as any);
    const [gamesFrom, setGamesFrom] = useState(dayjs().subtract(3, "month"));
    const [team, setTeam] = useState(null as Team | null);
    const [side, setSide] = useState(null as string | null);
    const [gameNumbers, setGameNumbers] = useState([] as number[]);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject}
                       setParamObject={setParamObject}
                       target={targets.competitive}
                       updateContext={false}
                       date__gte={gamesFrom}
                       setDateGte={setGamesFrom}
                       team={team}
                       setTeam={setTeam}
                       side={side}
                       setSide={setSide}
                       gameNumbers={gameNumbers}
                       setGameNumbers={setGameNumbers}
            />
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <NeonTabs value={tab} aria-label="basic tabs example" onChange={(event, newvalue) => setTab(newvalue)} sx={{ height: "50px" }}>
                    <NeonTab label="Stats" />
                    <NeonTab label="History"  />
                    <NeonTab label="Priorities"  />
                </NeonTabs>
            </Grow>
            <TabPanel value={tab} index={0}>
                {paramObject.team1 ? <DraftingMetagameBlock paramObject={paramObject} target={targets.competitive} /> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Grid
                    container
                    sx={{
                        mt: 5,
                        mb: 5,
                        height: "50px",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    {paramObject.team1 ? <DraftsBlock paramObject={paramObject} target={targets.competitive} /> : <></>}
                </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <PrioritiesTab paramObject={paramObject}/>
            </TabPanel>
        </WindowContainer>
    );
}

function PrioritiesTab(props: {paramObject:any}){
    const context = useReferentialContext();
    const metrics = context.getMetrics(['pickturn','teamwise_pickturn','winrate','games','performance','relative_performance','game_number'], targets.competitive)
    const request_parameters = {
        ...props.paramObject,
        groupby: 'champion',
        metrics: metrics.map((metric) => metric.code).toString(),
        ordering: "-games",
        format: "json",
        aggregates: ['game__game_n__avg']
    }
    const request_parameters2 = {
        ...props.paramObject,
        team1: undefined,
        team2: props.paramObject.team1,
        groupby: 'champion',
        metrics: metrics.map((metric) => metric.code).toString(),
        ordering: "-games",
        format: "json",
    }
    const api = context.farsightApi.getSummariesApi(targets.competitive);
    return <Stack direction={'row'} sx={{height:'90%', width:'100%'}} spacing={2}>
        <NeonCard sx={{height:'100%', width:'50%'}}>
            <NeonCardHeader title={'Played'}/>
            <NeonCardContent sx={{width:'100%', height:'89%', flexDirection:'column', padding:1}}>
                <TableDisplayGrid
                        display_all={false}
                        request={() => api.aggregate(request_parameters)}
                        rowStyle={rowStyle.none}
                        disp_columns={['champion']}
                        sortBy={"games"}
                        paramObject={request_parameters}
                        metrics={metrics}/>
            </NeonCardContent>
        </NeonCard>
        <NeonCard sx={{height:'100%', width:'50%'}}>
            <NeonCardHeader title={'Faced'}/>
            <NeonCardContent sx={{width:'100%', height:'89%', flexDirection:'column', padding:1}}>
            <TableDisplayGrid
                        display_all={false}
                        request={() => api.aggregate(request_parameters2)}
                        rowStyle={rowStyle.none}
                        disp_columns={['champion']}
                        sortBy={"games"}
                        paramObject={request_parameters2}
                        metrics={metrics}/>
                </NeonCardContent>
        </NeonCard>
    </Stack>
}

// export function DraftsSummary(props: { from: typeof dayjs; team: string; target: string }) {
//     const [rows, setRows] = useState({ B1: [], "B2-3": [], R5: [] });
//     useEffect(() => {
//         FarsightApi.get_request("RoleDraftingMeta", props).then((data) => {
//             setRows(data);
//         });
//     }, [props]);
//
//     return (
//         <Stack direction={"row"} sx={{ width: "100%", height: "250px" }} justifyContent={"space-evenly"}>
//             <Stack direction={"column"}>
//                 <Stack direction={"row"}>
//                     <DraftSummaryCell
//                         sx={{
//                             backgroundColor: "#224298",
//                             color: "#FAF9F6",
//                             width: "50px",
//                         }}
//                     >
//                         Role
//                     </DraftSummaryCell>
//                     <DraftSummaryCell sx={{ backgroundColor: "#224298", color: "#FAF9F6" }}>B1</DraftSummaryCell>
//                     <DraftSummaryCell sx={{ backgroundColor: "#224298", color: "#FAF9F6" }}>B2-3</DraftSummaryCell>
//                     <DraftSummaryCell sx={{ backgroundColor: "#224298", color: "#FAF9F6" }}>B4-5</DraftSummaryCell>
//                 </Stack>
//                 <Stack direction={"column"}>
//                     {rows["B1"].map((row: any) => (
//                         <DraftSummaryRow row={row}></DraftSummaryRow>
//                     ))}
//                     {rows["B2-3"].map((row: any) => (
//                         <DraftSummaryRow row={row}></DraftSummaryRow>
//                     ))}
//                 </Stack>
//             </Stack>
//             <Stack direction={"column"}>
//                 <Stack direction={"row"}>
//                     <DraftSummaryCell
//                         sx={{
//                             backgroundColor: "#A52A2A",
//                             color: "#FAF9F6",
//                             width: "50px",
//                         }}
//                     >
//                         Role
//                     </DraftSummaryCell>
//                     <DraftSummaryCell sx={{ backgroundColor: "#A52A2A", color: "#FAF9F6" }}>R1-2</DraftSummaryCell>
//                     <DraftSummaryCell sx={{ backgroundColor: "#A52A2A", color: "#FAF9F6" }}>R3</DraftSummaryCell>
//                     <DraftSummaryCell sx={{ backgroundColor: "#A52A2A", color: "#FAF9F6" }}>R4</DraftSummaryCell>
//                     <DraftSummaryCell sx={{ backgroundColor: "#A52A2A", color: "#FAF9F6" }}>R5</DraftSummaryCell>
//                 </Stack>
//                 {rows["R5"].map((row: any) => (
//                     <DraftSummaryRow row={row}></DraftSummaryRow>
//                 ))}
//             </Stack>
//         </Stack>
//     );
// }

function DraftSummaryRow(props: { row: any }) {
    console.log(props.row);
    return (
        <Stack direction={"row"}>
            <DraftSummaryCell sx={{ width: "50px" }}>
                <img src={"https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/" + props.row.role + ".png"} width="40" height="40" />
            </DraftSummaryCell>
            <DraftSummaryCell>
                {props.row["winrate"].toLocaleString("en", {
                    style: "percent",
                })}
            </DraftSummaryCell>
        </Stack>
    );
}

const DraftSummaryCell = styled(Paper)(() => ({
    borderRadius: 0,
    textTransform: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    width: "100px",
    height: "40px",
})) as typeof Paper;

export function DraftsBlock(props: { paramObject: any; target: targets, side?:string|null }) {
    const api = useReferentialContext().farsightApi.getDataApi(props.target);
    const [rows, setRows] = useState([] as GamesData[]);
    const user = useReferentialContext().user;

    useEffect(() => {
        const request_parameters = {
            ...props.paramObject,
            ordering: "-date",
            additional_filters: {
                ...props.paramObject.additional_filters,
                team1pick1__isnull: "False",
            },
        };
        console.log(request_parameters)
        if(props.paramObject.additional_filters.game__game_n__in){
            request_parameters.additional_filters.game_n__in = request_parameters.additional_filters.game__game_n__in
            delete request_parameters.additional_filters.game__game_n__in
        }
        api.list(request_parameters).then((result: any) => {
            if (result.length > 0) {
                setRows(result);
            }
        });
    }, [props.paramObject.watermark]);

    return (
        <Stack
            direction={"row"}
            sx={{
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "row",
                flex: 1,
                ...scrollable
            }}
            alignItems={"flex-start"}
            justifyContent={"space-evenly"}
            alignSelf={"space-evenly"}
        >
            {rows.filter(r => {
                if(props.paramObject.side === undefined && (props.side === undefined || props.side === null)) return true;
                if(props.paramObject.side === 'Blue' || props.side === 'Blue') return r.team1 === props.paramObject.team1;
                if(props.paramObject.side === 'Red' || props.side === 'Red') return r.team2 === props.paramObject.team1;

            }).map((row: any, index: number) => {
                if(props.target === targets.competitive) return <DraftBlockCompetitive draft={row} team_target={props.paramObject.team ? props.paramObject.team : row.team1} index={index} />
                if(props.target === targets.scrims) return <DraftBlockScrim draft={row} team_target={user.team} index={index} />
            }
            )}
        </Stack>
    );
}
