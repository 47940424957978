import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import {GoldStats} from "../../farsight-api";
import {PieCenterLabel} from "./ChampionsPie";

const COLORS = ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#BA68C8", "#FF7043", "#26A69A", "#EC407A"];

const GoldStatsPieChart = ({ goldStats, total }: { goldStats: GoldStats, total: number }) => {

    const data = Object.entries(goldStats)
        .filter(([_, value]) => value > 0)
        .map(([key, value], index) => ({
            label: key.replace(/_/g, " "),
            value,
            color: COLORS[index % COLORS.length]
        }))
        .sort((a, b) => b.value - a.value); // Sort in descending order

    if (data.length === 0) return <Typography variant="body2">No gold data available</Typography>;

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <PieChart
                series={[{
                    type: "pie",
                    innerRadius: 50,
                    outerRadius: 100,
                    data,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    arcLabel: (item) => `${item.label}`,
                    arcLabelMinAngle: 20,
                }]}
                margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                slotProps={{
                    legend: {
                        hidden: true,
                        itemMarkWidth: 20,
                        itemMarkHeight: 2,
                        markGap: 5,
                        itemGap: 10,
                        labelStyle: {
                            fontSize: 14,
                            fill: "blue",
                        },
                    },
                }}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontWeight: "bold",
                        whiteSpace: "pre-line", // Ensures the line break is properly rendered
                    },
                }}
                width={300}
                height={300}
            ><PieCenterLabel>{`${total}`}</PieCenterLabel></PieChart>
        </Box>
    );
};

export default GoldStatsPieChart;
