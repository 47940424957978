import {Box, Grid, Grow, Stack} from "@mui/material";
import OverviewDetailed from "../selectors/OverviewDetailed";
import RoleSelect from "../selectors/RoleSelect";
import React, {useEffect, useState} from "react";
import MultipleMetricsSelect from "../selectors/Shared/MetricsSelect";
import {CompetitiveGameSummaries, CompetitiveGameSummariesAggregate, GameMetrics} from "../../farsight-api";
import { targets } from "../../Context";
import TableDisplayGrid, { rowStyle } from "../datagrids/shared/TableDisplayGrid";
import { useReferentialContext } from "../../Context";
import ChampionsPie from "../Charts/ChampionsPie";
import {NeonTab, NeonTabs} from "../../NeonComponents/NeonTabs";
import {fetchAndJoin} from "../../utils";

export const roles_to_number: { [target: string]: number } = {
    TOP_LANE: 1,
    JUNGLE: 2,
    MID_LANE: 3,
    BOT_LANE: 4,
    UTILITY: 5,
};

export default function SynergiesMatchups(props: { paramObject: any; target: targets; type: string }) {
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [matchupRole, setMatchupRole] = useState<string>(props.paramObject.role);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().metricsReferential.filter(m => ["performance", "relative_performance", "games", "winrate",'gd15','csd15','kp15'].includes(m.code)));
    const [tab, setTab] = useState(["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"].indexOf(props.paramObject.role));
    const c_col = `${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[matchupRole]}`;
    const pie_request_parameters = {
        ...props.paramObject,
        groupby: c_col,
        metrics:  ["games", "winrate"].toString(),
        ordering: "performance",
        format: "json",
        include_in_performance: 1
    };
    pie_request_parameters[c_col] = props.paramObject.champion;
    delete pie_request_parameters.champion;

    return (
        <Stack direction={"column"} sx={{ height: "100%", width: "100%" }} spacing={2}>
                <NeonTabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                <NeonTab label="Top" asset={'TOP_LANE'} />
                <NeonTab label="Jungle" asset={'JUNGLE'} />
                <NeonTab label="Mid" asset={'MID_LANE'} />
                <NeonTab label="ADC" asset={'BOT_LANE'} />
                <NeonTab label="Support" asset={'UTILITY'}/>
            </NeonTabs>
            <Stack direction={"row"} sx={{ height: "90%", width: "100%" }} spacing={1}>
                <Stack direction={"row"} sx={{ height: "100%", width: "70%" }} spacing={1}>
                    <SynergyMatchupTab paramObject={{
                        ...props.paramObject,
                        groupby: `${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"][tab]]}`,
                        metrics: metrics.map((metric) => metric.code).toString(),
                        ordering: "performance",
                        format: "json",
                        watermark: props.paramObject.watermark + tab
                    }}
                   target={props.target}
                   type={props.type}
                   mu_role={["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"][tab]}
                   metrics={metrics}/>


                </Stack>
                <Box sx={{width: '30%'}}>
                    <ChampionsPie target={props.target} paramObject={pie_request_parameters}/>
                </Box>
            </Stack>
        </Stack>
    );
}

export function SynergyMatchupTab(props: {paramObject: any, target: targets, type: string, mu_role: string, metrics: GameMetrics[]}){
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [requestData, setRequestData] = useState(null as any);
    let metrics_display = ['champion'];
    for(const i in props.metrics){
        const metric = props.metrics[i];
        metrics_display.push(metric.code);
    }
    for(const i in props.metrics){
        const metric = props.metrics[i];
        metrics_display.push(`${props.type}_${metric.code}`);
    }
    const [metricsDisplay, setMetricsDisplay] = useState(metrics_display);


    const champ_col = `${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[props.mu_role]}`;
    const [request_params, set_request_params] = useState(props.paramObject);


    useEffect(() => {
        const request_parameters = {
            ...props.paramObject,
            groupby: champ_col,
            metrics: props.metrics.map((metric) => metric.code).toString(),
            ordering: "performance",
            format: "json",
            include_in_performance: 1
        };
        const request_2_parameters = JSON.parse(JSON.stringify(request_parameters));
        delete request_2_parameters.champion;
        const role = request_2_parameters.role;
        delete request_2_parameters.role;
        request_2_parameters[`${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[role]}`] = props.paramObject.champion;
        request_2_parameters.groupby = 'champion';
        request_2_parameters.role = props.mu_role;
        console.log(request_2_parameters.is_cp)
        if(request_parameters.is_cp === 1) request_2_parameters.is_cp = 0;
        if(request_parameters.is_cp === 0) request_2_parameters.is_cp = 1;

        const champ_request = api.aggregate(request_parameters);
        const matchup_request = api.aggregate(request_2_parameters);
        request_parameters.watermark = JSON.stringify(request_parameters) + JSON.stringify(request_2_parameters)
        set_request_params(request_parameters)

        setRequestData(fetchAndJoin(() => champ_request as Promise<CompetitiveGameSummariesAggregate[]>,
            () => matchup_request  as Promise<CompetitiveGameSummariesAggregate[]>,
            (item:any) => item[`${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[props.mu_role]}`],
            (item:any) => item.champion,
            'full')
            .then(r =>
                r.map(r => {
                    console.log(r);
                    const to_ret = {champion: r.id} as any;

                    for(const i in props.metrics){
                        const metric = props.metrics[i];
                        // @ts-ignore
                        to_ret[metric.code] = r.left ? r.left[metric.code] : null;
                        // @ts-ignore
                        to_ret[`${props.type}_${metric.code}`] = r.right ? r.right[metric.code] : null;
                    }
                    return to_ret
                }
            )))

    }, [props.paramObject.watermark, props.type, props.mu_role, props.metrics])


    let columnGroupingModel = [
        {
            groupId: 'Stats of ' + props.paramObject.champion,
            children: [{ field: "champion" }, ...props.metrics.map(m => {
                return {
                    field: m.code
                }})],
            headerClassName: "header",
        },
        {
            groupId: 'Stats of ' + props.type,
            children: props.metrics.map(m => {
                return {
                    field: `${props.type}_${m.code}`
                }}),
            headerClassName: "header",
        },
    ];

    return (requestData ?
    <TableDisplayGrid
        display_all={false}
        request={() => requestData}
        rowStyle={rowStyle.none}
        disp_columns={metricsDisplay}
        sortBy={"games"}
        paramObject={request_params}
        metrics={[]}
        grouping_model={columnGroupingModel}
    /> : <></> )

}
