import {useEffect, useRef, useState} from "react";
import {DraftPreps, PriorityPyramid, Scenario} from "../../../farsight-api";
import TierListMaker from "../../../components/Tierlists/TierlistMaker";
import WindowContainer from "../../../components/layout/WindowContainer";
import {NeonTab, NeonTabs} from "../../../NeonComponents/NeonTabs";
import TabPanel from "../../../components/tabs_switch/tabs_panel";
import DraftPrepSelector from "../../../components/selectors/DraftPrep/DraftPrepSelector";
import {Alert, Snackbar, Stack, Typography} from "@mui/material";
import {useReferentialContext} from "../../../Context";
import EditableDraft from "../../../components/visu_blocks/EditableDraft";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import EditableDraftScenario from "../../../components/visu_blocks/EditableDraft";
import CompactTierlistDisplay from "../../../components/visu_blocks/CompactTierlist";
import PyramidSelector from "../../../components/selectors/DraftPrep/PyramidSelector";
import {scrollable} from "../../../theme";
import {NeonTabWithChampion} from "../../../components/tabs_switch/NeonTabWithChampion";


export default function DraftPrep(){
    const [draftPrep, setDraftPrep] = useState(null as DraftPreps|null);
    const [tab, setTab] = useState(0);
    return <WindowContainer spacing={1}>
        <DraftPrepSelector draftPrep={draftPrep} setDraftPrep={setDraftPrep}/>
        <NeonTabs value={tab} onChange={(event, nv) => setTab(nv)}>
            <NeonTab label={'Tierlists'}/>
            <NeonTab label={'Scenarios'}/>
        </NeonTabs>
        <TabPanel value={tab} index={0}>
            <TierListMaker/>
        </TabPanel>
        <TabPanel value={tab} index={1}>
            {draftPrep ? <ScenariosEditor prep={draftPrep}/> : <Typography variant={'h6'}>Add a prep to start editing</Typography>}
        </TabPanel>
    </WindowContainer>
}

export function ScenariosEditor(props: { prep: DraftPreps }) {
  const ctx = useReferentialContext();
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [pyramid, setPyramid] = useState<PriorityPyramid | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [tab, setTab] = useState(0);


  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    const onWheel = (e: WheelEvent) => {
      // Prevent default vertical scroll
      e.preventDefault();
      // Scroll horizontally instead
      el.scrollLeft += e.deltaY;
    };

    el.addEventListener('wheel', onWheel, { passive: false });

    // Cleanup
    return () => {
      el.removeEventListener('wheel', onWheel);
    };
  }, []);


  useEffect(() => {
    ctx.farsightApi.draftScenarios
      .list({ prep: props.prep.id })
      .then(setScenarios)
      .catch(() => setError("Failed to fetch scenarios"));

    if(props.prep.team_pyramid){
      ctx.farsightApi.priorityPyramid
        .get({ id: props.prep.team_pyramid.toString()  })
        .then((res) => setPyramid(res || null))
        .catch(() => setError("Failed to fetch pyramid"));
    }
  }, [props.prep]);

  const handleCreateScenario = async () => {
    try {
      const newScenario = await ctx.farsightApi.draftScenarios.create({
        id: "new",
        scenario: {
          prep: props.prep.id,
          name: `Game ${scenarios.length + 1}`,
          game_number: scenarios.length + 1,
          team1: props.prep.owner,
          team2: props.prep.prep_vs,
          scenario_side: scenarios[0] ? scenarios[0].scenario_side : 'Blue',
          scenario_category: "No category",
        } as any,
      });
      setScenarios((prev) => [...prev, newScenario]);
    } catch {
      setError("Failed to create scenario");
    }
  };

  const handleUpdateScenario = (updated: Scenario) => {
    setScenarios((prev) =>
      prev.map((s) => (s.id === updated.id ? updated : s))
    );
  };

  const handleDeleteScenario = (id: number) => {
    setScenarios((prev) => prev.filter((s) => s.id !== id));
  };

  const handleCopyScenario = (copied: Scenario) => {
    setScenarios((prev) => [...prev, copied]);
  };

  const categories_list = Array.from(new Set(scenarios.map(item => item.scenario_category)));

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1 }}>
          <Stack direction={'column'}>
              <Box mb={1.5}>
                <PyramidSelector pyramid={pyramid} setPyramid={setPyramid} disable_edit_create={true}/>
              </Box>
              {pyramid && <CompactTierlistDisplay pyramid={pyramid} />}
          </Stack>
        <Box sx={{ overflowX: "auto", flexGrow: 1 }}>
          <Stack direction={'row'} sx={{alignItems:'space-between',justifyContent:'space-between', width:'100%'}}>
              <NeonTabs value={tab} onChange={(e, nv) => setTab(nv)}>
                  {categories_list.map(cat => <NeonTabWithChampion label={cat} champion={ctx.championsReferential.find(champ =>
                        cat.includes(champ.name) || cat.includes(champ.id)
                      )?.name}/>)}
              </NeonTabs>
              <Button variant="contained" onClick={handleCreateScenario}>
                Create Scenario
              </Button>
          </Stack>
          <Stack ref={scrollRef} direction="row" spacing={1} sx={{ height:'90%', overflowX: 'auto', ...scrollable }}>
            {scenarios.filter(sc => sc.scenario_category === categories_list[tab]).map((s) => (
              <EditableDraftScenario
                key={s.id}
                scenario={s}
                onUpdate={handleUpdateScenario}
                onDelete={handleDeleteScenario}
                onCopy={handleCopyScenario}
              />
            ))}
          </Stack>
        </Box>
      </Box>

      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

