import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";
import { ArrowDropDown } from "@mui/icons-material";
import { Theme } from "@mui/material";
import React from "react";

interface MenuButtonProps extends ButtonProps {
  label: string;
  icon: React.ElementType;
}

export const MenuButton = styled(
  ({ label, icon: IconComponent, ...props }: MenuButtonProps) => {
    return (
      <Button {...props}>
        {IconComponent && <IconComponent />} {/* Renders the provided icon */}
        {label}
        <ArrowDropDown />
      </Button>
    );
  }
)(({ theme: Theme }) => ({
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  gap: "8px", // Adds spacing between elements
}));

export const NeonButton = styled(Button)(({ theme: Theme }) => ({
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    // gap: "8px", // Adds spacing between elements
    height:'36.5px',
    paddingLeft:0,
    paddingRight:0
}))
