import {targets, useReferentialContext} from "../../../../Context";
import {CircularProgress, Grid, Link, Stack, Typography} from "@mui/material";
import SpecificGameSelector from "../../../../components/selectors/Competitive/SpecificGameSelector";
import {GamesData, ObjectiveKilled, ScrimsData, Team} from "../../../../farsight-api";
import {Dayjs} from "dayjs";
import React, {useEffect, useRef, useState} from "react";
import {NeonTab, NeonTabs} from "../../../../NeonComponents/NeonTabs";
import html2canvas from "html2canvas";
import {NeonCard, NeonCardHeader, NeonSkeletonCard} from "../../../../NeonComponents/NeonCards";
import ChampionIcon from "../../../../components/Icons/ChampionIcon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TabPanel from "../../../../components/tabs_switch/tabs_panel";
import PaginatedStack from "../../../../components/Utilities/PaginatedStack";
import ToPngButton from "../../../../components/Buttons/ToPngButton";

function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}


export default function MapOpeningsBlock(props: {target: targets, team: Team, from: Dayjs, until: Dayjs}){
    const [gameSelected, setGameSelected] = useState<any|null>(null);
    const [gamesData, setGamesData] = useState(null as null|GamesData|ScrimsData);
    const [tab, setTab] = useState(0);
    const context = useReferentialContext();
    const [objectivesData, setObjectivesData] = useState([] as ObjectiveKilled[]);
    const [panelTab, setPanelTab] = useState(0);
    console.log(tab);

    useEffect(() => {
        setObjectivesData([]);
        if(gameSelected) {
            context.farsightApi.getDataApi(props.target).list({game_id: gameSelected.game_id}).then(r => {
                setGamesData(r[0]);
                return r[0]
            }).then(
                (r: any) => context.farsightApi.gamesData.objectives_data({emh_id: r.emh_id ? r.emh_id : r.game_id}).then(r => setObjectivesData(r))
            )

        }
    }, [gameSelected]);

    return <Stack direction={'row'} sx={{width:'100%', height:'100%'}}>
        <SpecificGameSelector team={props.team} updateSelection={(r: any[]) => setGameSelected(r[0])} from={props.from} until={props.until} side_select={false} target={props.target} single={true}/>
        <Stack direction={'column'} sx={{height:'100%', width:'100%'}}>
        {gameSelected && gameSelected.player_TOP_LANE ? <NeonTabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
            <NeonTab label={gameSelected.player_TOP_LANE} asset={'TOP_LANE'}></NeonTab>
            <NeonTab label={gameSelected.player_JUNGLE} asset={'JUNGLE'}></NeonTab>
            <NeonTab label={gameSelected.player_MID_LANE} asset={'MID_LANE'}></NeonTab>
            <NeonTab label={gameSelected.player_BOT_LANE} asset={'BOT_LANE'}></NeonTab>
            <NeonTab label={gameSelected.player_UTILITY} asset={'UTILITY'}></NeonTab>
        </NeonTabs> : undefined}
        {gameSelected ? <NeonTabs value={panelTab} onChange={(event, newValue) => setPanelTab(newValue)}>
            <NeonTab label={'Game Phases'}/>
            <NeonTab label={'VoidGrubs'}/>
            <NeonTab label={'Drakes'}/>
        </NeonTabs> : undefined}
        <TabPanel value={panelTab} index={0}>
        {
            gamesData ? <Stack direction={'row'} sx={{justifyContent:'space-around'}}>
                <MapOpening endpoint={'PathingOnOpeningTimestamp'}
                            match={gamesData}
                            target={props.target}
                            params_url={'ig_from=300&ig_until=480'}
                            team={props.team.team_name} role={['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}
                            title={`${gameSelected[`player_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]} Openings`}
                            subheader={`From 5:00 to 8:00`}
                            champion={gameSelected[`champion_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]}

                />
                <MapOpening endpoint={'PathingOnOpeningTimestamp'}
                            match={gamesData}
                            target={props.target}
                            params_url={'ig_from=480&ig_until=960'}
                            team={props.team.team_name} role={['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}
                            title={`${gameSelected[`player_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]} Openings`}
                            subheader={`From 8:00 to 16:00`}
                            champion={gameSelected[`champion_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]}/></Stack> : undefined
        }
        </TabPanel>
        <TabPanel value={panelTab} index={1}>
            <Stack direction={'row'} sx={{justifyContent:'space-around'}}>
                {gamesData ? objectivesData.filter(r => r.monster_type === 'VoidGrub' && r.type === 'epic_monster_spawn').map((obj, index) =>
                    <MapOpening endpoint={'PathingOnOpeningTimestamp'}
                        match={gamesData}
                        target={props.target}
                        params_url={`ig_from=${obj.timestamp-180}&ig_until=${obj.timestamp+20}`}
                        team={props.team.team_name} role={['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}
                        title={`${gameSelected[`player_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]} Openings`}
                        subheader={`3 mins before VoidGrubs n${index+1} ${formatTime(obj.timestamp)} spawn`}
                        champion={gameSelected[`champion_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]}/>) : undefined}
            </Stack>
        </TabPanel>
        <TabPanel value={panelTab} index={2}>
            <PaginatedStack direction={'row'} sx={{justifyContent:'space-around', alignItems: 'center', height:'100%'}}>
                {gamesData && objectivesData.length > 0 ? objectivesData.filter(r => r.monster_type === 'dragon' && r.type === 'epic_monster_spawn').map((obj, index) =>
                    <MapOpening endpoint={'PathingOnOpeningTimestamp'}
                        match={gamesData}
                        target={props.target}
                        params_url={`ig_from=${obj.timestamp-180}&ig_until=${obj.timestamp+20}`}
                        team={props.team.team_name} role={['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}
                        title={`${gameSelected[`player_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]} Openings`}
                        subheader={`3 mins before Drake n${index+1} ${formatTime(obj.timestamp)} spawn`} sx={{width:'430px'}}
                        champion={gameSelected[`champion_${['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]}`]}/>) : [1,2].map(i => <NeonSkeletonCard sx={{width:'430px', height:'550px'}}/>)}
            </PaginatedStack>
        </TabPanel>
        </Stack>
    </Stack>
}

export function MapOpening(props: {match: GamesData|ScrimsData,
    team: string,
    role: string,
    params_url: string,
    endpoint: string,
    target: targets,
    title: string,
    subheader: string,
    champion: string,
    sx?: any}){
    const api = useReferentialContext().farsightApi;
    const gridRef = useRef(null);
    console.log(gridRef)

    const ctx = useReferentialContext();

    return (<Box ref={gridRef}>
            <NeonCard
                sx={{width:'430px', height:'550px', mt:3, padding:'10px'}}
            >
                <NeonCardHeader title={props.title} subheader={props.subheader} action={<ChampionIcon champion={props.champion} role={props.role}/>}/>
                    <Box
                        component={"img"}
                        src={`${api.url}/Maps/${props.endpoint}?role=${props.role}&id=${props.match.game_id}&target=${props.target}&team=${props.team}&${props.params_url}`}
                        sx={{
                            // width: props.sx?.width ? props.sx?.width : "512px",
                            // height: props.sx?.width ? props.sx?.width : "512px",
                            width:'400px',
                            height:'400px',
                            borderRadius: 2,
                        }}
                        // style={{ opacity: 0.85 }}
                    />
                    <Stack
                        direction={"row"}
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                        spacing={1}
                    >
                        <Stack
                            direction={"row"}
                            sx={{
                                alignItems: "center",
                                justifyContent: "left",
                                width: "100%",
                            }}
                            spacing={1}
                        >
                            <Typography>{`${props.match.patch}`}</Typography>
                        </Stack>
                        {props.match.vod_game_start ? (
                            <Link href={props.match.vod_game_start} target="_blank">
                                <Button variant={"outlined"}>VOD</Button>
                            </Link>
                        ) : (
                            <></>
                        )}
                        {props.match.replay_file ? (
                            <Button
                                variant={"outlined"}
                                onClick={() =>

                                    ctx.farsightApi.download_file('Downloads/GameReplay',
                                {game_id:props.match.game_id, target: targets.competitive},
                                        `${props.match.game_id} - ${props.match.team1} vs ${props.match.team2}`,
                                    )
                                }
                            >
                                Replay
                            </Button>
                        ) : (
                            <></>
                        )}
                        <ToPngButton elementReference={gridRef}/>
                    </Stack>
            </NeonCard>
        </Box>)
}