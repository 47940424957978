import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useReferentialContext} from "../../Context";
import Button from "@mui/material/Button";
import TeamIcon from "../Icons/TeamIcon";
import ChampionIcon from "../Icons/ChampionIcon";
import ItemIcon from "../Icons/ItemIcon";
import dayjs from "dayjs";
import {NeonDialog, NeonDialogActions, NeonDialogContent, NeonDialogTitle} from "../../NeonComponents/NeonDialog";
import {NeonTab, NeonTabs} from "../../NeonComponents/NeonTabs";


export default function GameDetailsDialog(props: {open: boolean, setOpen: (o: boolean) => void, game_id: string}) {
    console.log(useReferentialContext().currentTarget);
    const api = useReferentialContext().farsightApi.getSummariesApi(useReferentialContext().currentTarget);
    const [gameRows, setGameRows] = useState(undefined as any[] | undefined);
    const [tab, setTab] = useState(0);
    useEffect(() => {
        if(props.open){
            api.list({
                game: props.game_id,
                ordering: ['participant_id']
            }).then(r => {
                console.log(r);
                setGameRows(r)
            });
        }
    }, [props.game_id, props.open]);


    return <NeonDialog open={props.open}
                   onClose={() => props.setOpen(false)}
                   fullWidth={true} maxWidth={'xl'}>
        <NeonDialogTitle>
            {props.game_id}
        </NeonDialogTitle>
        <NeonDialogContent>
            <NeonTabs value={tab} onChange={(event, newValue) => {setTab(newValue);}} sx={{mb:1}}>
                <NeonTab label={'Overview'}/>
                <NeonTab label={'Graphs'}/>
                <NeonTab label={'Review'}/>
            </NeonTabs>
            {
                gameRows ? <Stack direction={'column'}>
                        <DetailsHeader first_row={gameRows[0]}></DetailsHeader>
                        <GameDetails gameRows={gameRows}/>
                    </Stack>
                    : <CircularProgress/>
            }
        </NeonDialogContent>
        <NeonDialogActions>
            <Button variant={'outlined'} onClick={() => props.setOpen(false)}>Close</Button>
        </NeonDialogActions>
    </NeonDialog>
}

function DetailsHeader(props: {first_row: any}){
    return <Stack direction={'row'} sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-around'}}>
        <TeamIcon team_name={props.first_row.team1}/>
        <Typography>{props.first_row.patch}</Typography>
        <Typography>{props.first_row.duration}</Typography>
        <Typography>{props.first_row.league}</Typography>
        <Typography>{props.first_row.split}</Typography>
        <Typography>{dayjs(props.first_row.date).format(dayjs(props.first_row.date).year() === dayjs().year() ? "MMM DD\nHH:mm" : "MMM DD YYYY\nHH:mm")}</Typography>
        <TeamIcon team_name={props.first_row.team2}/>
    </Stack>
}

function GameDetails(props: {gameRows: any[]}){
    return <Stack direction={'row'} sx={{width:'100%', alignItems:'space-around', display:'flex', justifyContent:'space-around'}}>
        <Stack direction={'column'} sx={{width:'530px'}}>
            <PlayerDetails row={props.gameRows[0]} side={'blue'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[1]} side={'blue'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[2]} side={'blue'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[3]} side={'blue'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[4]} side={'blue'}/>
        </Stack>
        <Diffs blueSideRows={props.gameRows}/>
        <Stack direction={'column'} sx={{width:'530px'}}>
            <PlayerDetails row={props.gameRows[5]} side={'red'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[6]} side={'red'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[7]} side={'red'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[8]} side={'red'}/>
            <Divider/>
            <PlayerDetails row={props.gameRows[9]} side={'red'}/>
        </Stack>
    </Stack>
}

function PlayerDetails(props: {row: any, side:string}){
    return <Stack direction={props.side === 'blue' ? 'row' : 'row-reverse'} sx={{width:'530px', height:'100px', alignItems:'center', justifyContent:'left', display:'flex'}} spacing={1}>
        <Typography sx={{width:'100px', alignItems:'center', justifyContent:props.side === 'blue' ? 'right' : 'left', display:'flex'}}>{`${props.row.player.split(' (')[0]}`}</Typography>
        <Box sx={{width:'60px', height:'60px',}}>
            <ChampionIcon champion={props.row.champion} role={props.row.role} size={60}/>
        </Box>
        <Stack direction={'column'} sx={{width:'220px', alignItems:'center', display:'flex', justifyContent:'center'}}>
            <Typography>{`${props.row.kills} / ${props.row.deaths} / ${props.row.assists} - ${(props.row.kp * 100).toFixed(1)}% KP`}</Typography>
            <Typography>{`${props.row.dpm} DPM -  ${props.row.cspm.toFixed(1)} CSPM`}</Typography>
            {props.row.performance && props.row.relative_performance ?
                <Typography>{`${props.row.performance.toFixed(1)}P(${props.row.relative_performance > 0 ? '+' : ''}${props.row.relative_performance.toFixed(1)})`}</Typography> : undefined}
            {
                props.row.performance_lane ? <Typography>{`${props.row.performance_lane.toFixed(1)}PL(${props.row.relative_performance_lane > 0 ? '+' : ''}${props.row.relative_performance_lane.toFixed(1)})`}</Typography> : <></>}
        </Stack>
        <ItemIcon item={props.row.boots}/>
        <Stack direction={'column'} sx={{width:'140px', alignItems:'center', display:'flex', justifyContent:'center'}} spacing={1}>
            <Stack direction={props.side === 'blue' ? 'row' : 'row-reverse'} spacing={1}>
                <ItemIcon item={props.row.item1}/>
                <ItemIcon item={props.row.item2}/>
                <ItemIcon item={props.row.item3}/>
            </Stack>
            <Stack direction={props.side === 'blue' ? 'row' : 'row-reverse'} spacing={1}>
                <ItemIcon item={props.row.item4}/>
                <ItemIcon item={props.row.item5}/>
                <ItemIcon item={props.row.item6}/>
            </Stack>
        </Stack>
    </Stack>
}

function Diffs(props:{blueSideRows: any[]}){
    return <Stack direction={'column'}>
        <RoleDiffs blueSidePlayerRow={props.blueSideRows[0]}/>
        <Divider/>
        <RoleDiffs blueSidePlayerRow={props.blueSideRows[1]}/>
        <Divider/>
        <RoleDiffs blueSidePlayerRow={props.blueSideRows[2]}/>
        <Divider/>
        <RoleDiffs blueSidePlayerRow={props.blueSideRows[3]}/>
        <Divider/>
        <RoleDiffs blueSidePlayerRow={props.blueSideRows[4]}/>
    </Stack>
}

function RoleDiffs(props: {blueSidePlayerRow: any}){
    return <Stack direction={'column'} sx={{width:'200px', height:'100px', alignItems:'center', justifyContent:'center', display:'flex'}}>
        <Typography sx={{width:'100%', display:'flex', justifyContent:props.blueSidePlayerRow.gd15 > 0 ? 'left' : 'right'}}>{`+${Math.abs(props.blueSidePlayerRow.gd15).toFixed(0)} GD@15`}</Typography>
        <Typography sx={{width:'100%', display:'flex', justifyContent:props.blueSidePlayerRow.csd15 > 0 ? 'left' : 'right'}}>{`+${Math.abs(props.blueSidePlayerRow.csd15).toFixed(0)} CSD@15`}</Typography>
        <Typography sx={{width:'100%', display:'flex', justifyContent:props.blueSidePlayerRow.xpd15 > 0 ? 'left' : 'right'}}>{`+${Math.abs(props.blueSidePlayerRow.xpd15).toFixed(0)} XPD@15`}</Typography>
    </Stack>
}