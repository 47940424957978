import Box from "@mui/material/Box";


export default function CombinedIcons(props:{main_icon: React.ReactNode, secondary_icon: React.ReactNode, width?: number, height?: number}) {
    return <Box
        sx={{position: "relative", display: "inline-block", width: `${props.width ? props.width : 40}px`, height: `${props.height ? props.height : 40}px`}}>
        {/* Main Ability Icon */}
        {props.main_icon}

        {/* Small Overlay Icon (Bottom Right) */}
        <Box sx={{
            position: "absolute",
            bottom: 0,
            right: -3,
        }}>
            {props.secondary_icon}
        </Box>
    </Box>
}