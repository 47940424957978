/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    PatchedPriorityPyramidItem,
    PriorityPyramidItem,
    PriorityPyramidItemAggregate,
} from "../models/index";

export interface PriorityPyramidItemAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    champion?: string;
    having?: object;
    index_on?: string;
    metrics?: Array<string>;
    pyramid?: number;
    role?: string;
    tier?: string;
}

export interface PriorityPyramidItemCreateRequest {
    id: string;
    priority_pyramid_item: Omit<PriorityPyramidItem, "id">;
}

export interface PriorityPyramidItemDeleteRequest {
    id: string;
}

export interface PriorityPyramidItemFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    champion?: string;
    pyramid?: number;
    role?: string;
    tier?: string;
}

export interface PriorityPyramidItemGetRequest {
    id: string;
}

export interface PriorityPyramidItemListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    champion?: string;
    ordering?: Array<string>;
    pyramid?: number;
    role?: string;
    tier?: string;
}

export interface PriorityPyramidItemPatchRequest {
    id: string;
    patched_priority_pyramid_item?: Omit<PatchedPriorityPyramidItem, "id">;
}

export interface PriorityPyramidItemVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    champion?: string;
    pyramid?: number;
    role?: string;
    tier?: string;
}

/**
 *
 */
export class PriorityPyramidItemApi extends runtime.BaseAPI {
    /**
     */
    async priorityPyramidItemAggregateRaw(
        requestParameters: PriorityPyramidItemAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<PriorityPyramidItemAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling priorityPyramidItemAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling priorityPyramidItemAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["pyramid"] != null) {
            queryParameters["pyramid"] = requestParameters["pyramid"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["tier"] != null) {
            queryParameters["tier"] = requestParameters["tier"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: PriorityPyramidItemAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<PriorityPyramidItemAggregate>> {
        const response = await this.priorityPyramidItemAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidItemCreateRaw(
        requestParameters: PriorityPyramidItemCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<PriorityPyramidItem>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidItemCreate().',
            );
        }

        if (requestParameters["priority_pyramid_item"] == null) {
            throw new runtime.RequiredError(
                "priority_pyramid_item",
                'Required parameter "priority_pyramid_item" was null or undefined when calling priorityPyramidItemCreate().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["priority_pyramid_item"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async create(
        requestParameters: PriorityPyramidItemCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<PriorityPyramidItem> {
        const response = await this.priorityPyramidItemCreateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidItemDeleteRaw(
        requestParameters: PriorityPyramidItemDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidItemDelete().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete(
        requestParameters: PriorityPyramidItemDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.priorityPyramidItemDeleteRaw(
            requestParameters,
            initOverrides,
        );
    }

    /**
     */
    async priorityPyramidItemFieldValuesRaw(
        requestParameters: PriorityPyramidItemFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling priorityPyramidItemFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling priorityPyramidItemFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["pyramid"] != null) {
            queryParameters["pyramid"] = requestParameters["pyramid"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["tier"] != null) {
            queryParameters["tier"] = requestParameters["tier"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: PriorityPyramidItemFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.priorityPyramidItemFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidItemGetRaw(
        requestParameters: PriorityPyramidItemGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<PriorityPyramidItem>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidItemGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: PriorityPyramidItemGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<PriorityPyramidItem> {
        const response = await this.priorityPyramidItemGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidItemListRaw(
        requestParameters: PriorityPyramidItemListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<PriorityPyramidItem>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["pyramid"] != null) {
            queryParameters["pyramid"] = requestParameters["pyramid"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["tier"] != null) {
            queryParameters["tier"] = requestParameters["tier"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: PriorityPyramidItemListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<PriorityPyramidItem>> {
        const response = await this.priorityPyramidItemListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidItemPatchRaw(
        requestParameters: PriorityPyramidItemPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<PriorityPyramidItem>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling priorityPyramidItemPatch().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["patched_priority_pyramid_item"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: PriorityPyramidItemPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<PriorityPyramidItem> {
        const response = await this.priorityPyramidItemPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async priorityPyramidItemVariableDistributionRaw(
        requestParameters: PriorityPyramidItemVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling priorityPyramidItemVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling priorityPyramidItemVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling priorityPyramidItemVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling priorityPyramidItemVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["pyramid"] != null) {
            queryParameters["pyramid"] = requestParameters["pyramid"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["tier"] != null) {
            queryParameters["tier"] = requestParameters["tier"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/PriorityPyramidItem/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: PriorityPyramidItemVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.priorityPyramidItemVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
