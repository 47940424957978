import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grow } from "@mui/material";
import {targets, useReferentialContext} from "../../Context";
import {HighlightScope, pieArcLabelClasses, PieChart} from "@mui/x-charts";
import {PieCenterLabel} from "./ChampionsPie";

export default function DamageComposition(props: {paramObject: any, target: targets}) {
    const [r, setR] = useState({} as any);
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target)
    const [highlighted, setHighlighted] = useState('item');
    const [faded, setFaded] = useState('global');

    useEffect(() => {
        setR({});
        api.aggregate(
            {
                ...props.paramObject,
                is_build_ordered: 1,
                groupby: ['champion'],
                ordering: ['champion'],
                aggregates: ['magic_damages__sum,physical_damages__sum,true_damages__sum,damages__sum']
            }
        ).then((r: any) => {
            const obj = {
                magic_damages : r[0].magic_damages__sum,
                physical_damages : r[0].physical_damages__sum,
                true_damages : r[0].true_damages__sum,
                total_damages : r[0].damages__sum
            };
            setR(obj)
        })

    }, [props.paramObject.watermark]);

    return (
        <Grow in={true} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 2000 }}>
            <Box sx={{width:'100%', height:'100%'}}>
                <PieChart
                    series={[
                        {
                            type: 'pie',
                            innerRadius: 70,
                            outerRadius: 120,
                            data: [{color: '#0DA3BE', label: `AP`, value: r.magic_damages / r.total_damages},
                                {color: '#C35037', label: `AD`, value: r.physical_damages / r.total_damages},
                                {color: 'white',label: `True`, value: r.true_damages / r.total_damages}],
                            highlighted: { additionalRadius: 10 },
                            highlightScope: {
                                highlighted,
                                faded,
                            } as HighlightScope,
                            paddingAngle: 2,
                            cornerRadius: 5,
                            arcLabel: (item) => `${(item.value * 100).toFixed(1)}% ${item.label}`,
                            arcLabelMinAngle: 20,
                        },
                    ]}
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    slotProps={{
                        legend: {
                            hidden: true,
                            itemMarkWidth: 20,
                            itemMarkHeight: 2,
                            markGap: 5,
                            itemGap: 10,
                            labelStyle: {
                                fontSize: 14,
                                fill: 'blue',
                            },
                        },

                    }}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'white',
                            fontWeight: 'bold',
                        },
                    }}
                ><PieCenterLabel>Dmg. Comp.</PieCenterLabel></PieChart>
            </Box>
        </Grow>
    );
}
