import React from "react";
import { Box } from "@mui/material";

const iconNames = [
    "Atakhan", "DragonAir", "DragonChemtech", "DragonEarth", "DragonFire", "DragonHextech", "DragonWater",
    "Elder", "Gold", "KDA", "Kills", "Nashor", "RiftHerald", "Tower", "Tower2", "VoidGrubs"
];

const hexToFilter = (hex: string) => {
    let r = 0, g = 0, b = 0;

    if (hex.startsWith("#")) {
        const bigint = parseInt(hex.slice(1), 16);
        r = (bigint >> 16) & 255;
        g = (bigint >> 8) & 255;
        b = bigint & 255;
    }

    r /= 255;
    g /= 255;
    b /= 255;

    const invert = (1 - (r * 0.299 + g * 0.587 + b * 0.114)) * 100;
    const sepia = (r + g + b) / 3 * 100;
    const hueRotate = ((Math.atan2(b - g, r - (g + b) / 2) * 180) / Math.PI + 360) % 360;
    const brightness = ((r + g + b) / 3) * 100;
    const contrast = 100 + ((r - g) * 50);

    return `invert(${invert.toFixed(0)}%) sepia(${sepia.toFixed(0)}%) hue-rotate(${hueRotate.toFixed(0)}deg) brightness(${brightness.toFixed(0)}%) contrast(${contrast.toFixed(0)}%)`;
};

const getColorFilter = (color: string) => {
    if (color.startsWith("#")) {
        return hexToFilter(color);
    }

    const colorMap: Record<string, string> = {
        red: "invert(21%) sepia(86%) saturate(7472%) hue-rotate(1deg) brightness(105%) contrast(113%)",
        blue: "invert(30%) sepia(89%) saturate(2569%) hue-rotate(199deg) brightness(89%) contrast(103%)",
        green: "invert(53%) sepia(48%) saturate(382%) hue-rotate(86deg) brightness(91%) contrast(88%)",
        yellow: "invert(86%) sepia(21%) saturate(423%) hue-rotate(359deg) brightness(102%) contrast(102%)",
        white: "invert(100%) sepia(0%) saturate(7500%) hue-rotate(0deg) brightness(100%) contrast(100%)",
        black: "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)"
    };

    return colorMap[color] || "none";
};

const icons = iconNames.reduce((acc, name) => {
    acc[`${name}Icon`] = (props: { sx?: any }) => (
        <Box
            component={"img"}
            src={`/icons/svg/${name}.svg`}
            alt={name}
            sx={{
                width: 40,
                height: 40,
                filter: props.sx?.color ? getColorFilter(props.sx.color) : "none",
                // onError: (e) => (e.currentTarget.style.display = "none"), // Hide missing icons
                ...props.sx,
            }}
        />
    );
    return acc;
}, {} as Record<string, React.FC<{ sx?: any }>>);

export const {
    DragonAirIcon, DragonChemtechIcon, DragonEarthIcon, DragonFireIcon, DragonHextechIcon,
    DragonWaterIcon, ElderIcon, KDAIcon, TowerIcon
} = icons;

export const VoidGrubsIcon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#800080",
  width = 40,
  height = 40
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 902 851.89"
    width={width}
    height={height}
  >
    <g>
      <path fill={color} d="M451,205l182,129-108,142,126,99-112,110-88-88v254.89c111,.11,195-20.89,271-93.89,76.46-73.44,105-232,105-232l75-29-59-56s46.71-256.26-17.22-311c-109.78-94-240.78-4-240.78-4L451,0v205Z"/>
      <path fill={color} d="M451,205l-182,129,108,142-126,99,112,110,88-88v254.89c-111,.11-195-20.89-271-93.89-76.46-73.44-105-232-105-232L0,497l59-56S12.29,184.74,76.22,130c109.78-94,240.78-4,240.78-4L451,0v205Z"/>
    </g>
  </svg>
);

export const GoldIcon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#FFD700", // Default to gold
  width = 40,
  height = 40
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 897.17 775.53"
    width={width}
    height={height}
  >
    <path
      fill={color}
      d="M191.51,324.82s-14.31-73.34,0-130.08S235.77,0,563.82,0c195,0,305.9,103.57,323,195,23,123,3.67,210.4-19.14,244.61-22.8,34.21-54.13,61.88-90.89,80.28,0,0-98.97,238.1-324.97,253.1-144.93,9.62-305-6-385-86-177-162,44.67-362.18,124.69-362.18ZM386.58,649.98c171.96,0,195.08-130.03,195.08-130.03,0,0-145.84,5.05-219.84-12.95s-105.27-52.14-105.27-52.14c0,0-130.03-47.59-130.03,65.04-.05,67.52,105.15,130.03,260.07,130.03v.05Z"
    />
  </svg>
);

export const RiftHeraldIcon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#800080",
  width = 40,
  height = 40,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 782.45 970.55" width={width} height={height}>
    <path
      fill={color}
      d="M391.18,281.19c-112.66,0-204,91.34-204,204s91.34,204,204,204,204-91.34,204-204-91.34-204-204-204ZM391.18,627.83l-37.89-142.63,37.89-142.63,37.89,142.63-37.89,142.63ZM351.47,0v223.15h0c-127.48,19.15-225.23,129.23-225.23,262.12s97.75,242.97,225.23,262.12v223.15l-38.3-68.36c-23.23-41.47-59.62-100.84-102.92-120.24C85.35,725.91,0,628.08,0,485.19S85.35,244.47,210.33,188.6c43.3-19.32,79.69-78.77,102.92-120.24L351.47,0ZM430.99,0v223.15h0c127.48,19.15,225.23,129.23,225.23,262.12s-97.75,242.97-225.23,262.12v223.15l38.3-68.36c23.23-41.47,59.62-100.84,102.92-120.24,124.9-55.87,210.25-153.79,210.25-296.59s-85.35-240.72-210.33-296.59c-43.3-19.32-79.69-78.77-102.92-120.24L430.99,0Z"
    />
  </svg>
);

export const AtakhanIcon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#000000",
  width = 40,
  height = 40,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 942.03 873.6" width={width} height={height}>
    <path
      fill={color}
      d="M471.02,0v324s77.4-79.6,76.71-162S471.02,0,471.02,0ZM581.03,280.6s-9-50,24-93,79-99,104-75-9,75-9,75c0,0,46,21,69,57s28,60,23,84-22,48-22,48c0,0-23-74-39-97-16.68-23.98-77-47-77-47l-73,48ZM471.02,462.6c37,0,63.02-5,105.02-42s96-88,96-88c0,0,39,26,57,51s20,98,20,98c0,0,53-53,101-77s92-14,92-14c0,0-43,127-85,179s-91.38,94.89-121,129c-33,38-44,175-72,175-26,0-42-45-44-76-1.76-27.24,9-97,9-97,0,0-34.29,40.97-56,56-26,18-50.03,34.57-102.02,35.28v-329.28ZM585.03,527.6c-33,25-66,53-50,85s66-5,97-29,55-75,42-86c-12.21-10.34-56,5-89,30Z"
    />
    <path
      fill={color}
      d="M471.02,0v324s-77.4-79.6-76.71-162S471.02,0,471.02,0ZM361,280.6s9-50-24-93-79-99-104-75,9,75,9,75c0,0-46,21-69,57s-28,60-23,84,22,48,22,48c0,0,23-74,39-97s77-47,77-47l73,48ZM471.02,462.6c-37,0-63.02-5-105.02-42s-96-88-96-88c0,0-39,26-57,51s-20,98-20,98c0,0-53-53-101-77S0,390.6,0,390.6c0,0,43,127,85,179s91.38,94.89,121,129c33,38,44,175,72,175,26,0,42-45,44-76,1.76-27.24-9-97-9-97,0,0,34.29,40.97,56,56,26,18,50.03,34.57,102.02,35.28v-329.28ZM357,527.6c33,25,66,53,50,85s-66-5-97-29-55-75-42-86c12.21-10.34,56,5,89,30Z"
    />
  </svg>
);

export const NashorIcon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#800080",
  width = 40,
  height = 40,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 753.78 935.48" width={width} height={height}>
    <path
      fill={color}
      d="M626.69,98.11C630.2,47.91,688.92.1,688.92.1c-95.86-2.55-155.86,44.14-194.58,109.48l117.45,151.24-34.42,73.31-200.4-200.4-200.4,200.4-34.42-73.31,117.45-151.24C220.72,44.24,160.72-2.45,64.86.1c0,0,58.73,47.81,62.23,98.01L0,248.63l113.31,458.09,63.11,128.53,100.24,100.24v-142.47l100.24,100.24,100.24-100.24v142.47l100.24-100.24,63.11-128.53,113.31-458.09s-127.09-150.52-127.09-150.52ZM188.69,649.26c-17.85-17.85-24.86-89.64-24.86-89.64,0,0,71.79,7.01,89.64,24.86,17.85,17.85,24.86,89.64,24.86,89.64,0,0-71.79-7.01-89.64-24.86ZM376.89,753.96c-21.99,0-39.92-17.85-39.92-39.92s17.85-39.92,39.92-39.92,39.92,17.85,39.92,39.92-17.85,39.92-39.92,39.92ZM376.89,606.16c-33.23,0-60.16-26.93-60.16-60.16s26.93-60.16,60.16-60.16,60.16,26.93,60.16,60.16-26.93,60.16-60.16,60.16ZM565.1,649.34c-2.15,2.15-5.02,4.06-8.45,5.9.72,3.11,1.59,6.14,2.31,9.24,4.62,20.72,8.61,41.67,10.28,63.35-11.16-18.65-20-38.01-28.21-57.61-1.04-2.55-1.99-5.18-2.95-7.73-27.25,8.29-62.63,11.71-62.63,11.71,0,0,7.01-71.79,24.86-89.64,2.31-2.31,5.66-4.46,9.56-6.45-2.63-9.56-5.02-19.2-7.25-28.92-4.62-20.72-8.61-41.67-10.28-63.35,11.16,18.65,19.92,38.09,28.13,57.61,3.67,8.92,7.09,17.85,10.36,26.85,26.69-7.49,59.12-10.68,59.12-10.68,0,0-7.01,71.79-24.86,89.64v.08Z"
    />
  </svg>
);

export const Tower2Icon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#202020",
  width = 40,
  height = 40,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 572 889.33" width={width} height={height}>
    <path
      fill={color}
      d="M286,111.11l107.11,107.11-107.11,106.89v224.22l286-210.67v-59.06h-138.5l66.5-66.5L286,0v111.11ZM501,424.11l-97,465.22-118-.22v-222l215-243Z"
    />
    <path
      fill={color}
      d="M286,111.11l-107.11,107.11,107.11,106.89v224.22L0,338.67v-59.06h138.5l-66.5-66.5L286,0v111.11ZM71,424.11l97,465.22,118-.22v-222l-215-243Z"
    />
  </svg>
);

export const InhibitorIcon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#808080",
  width = 40,
  height = 40,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width={width} height={height}>
    {/* Outer Ring */}
    <circle cx="50" cy="50" r="40" stroke={color} strokeWidth="8" fill="none" />

    {/* Rotated Square (Diamond) */}
    <rect
      x="35"
      y="35"
      width="30"
      height="30"
      fill={color}
      transform="rotate(45 50 50)"
    />
  </svg>
);

export const KillsIcon: React.FC<{ color?: string; width?: number; height?: number }> = ({
  color = "#FF0000",
  width = 40,
  height = 40,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 922.31 922.31" width={width} height={height}>
    <path
      fill={color}
      d="M922.31,92.23L830.08,0l-237.17,230.58-171.29-164.7-92.23,92.23,144.93,151.52L0,724.67v197.64h197.64l415.04-474.33,144.93,144.93,98.82-98.82-164.7-164.7,230.58-237.17Z"
    />
  </svg>
);