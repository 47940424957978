import {CompetitiveGameSummaries, MapState, WardOnMap} from "../../farsight-api";
import React, {useEffect, useRef, useState} from "react";
import {useThemeContext} from "../../themeContext";
import {Dialog, IconButton, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ImageOverlay, MapContainer} from "react-leaflet";
import {CRS} from "leaflet";
import {InhibitorMarker, TurretMarker} from "./MapStateMarkers/TurretMarker";
import PlayerMarker from "./MapStateMarkers/PlayerMarker";
import WardMarker from "./MapStateMarkers/WardMarker";
import {DragonMarker, RiftHeraldMarker, VoidGrubsMarker} from "./MapStateMarkers/ObjectivesMarker";
import ToPngButton from "../Buttons/ToPngButton";
import SmallDetailButton from "../Buttons/SmallDetailsButton";
import {scrollable} from "../../theme";
import {ArrowDropDown, Close} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {NeonButton} from "../../NeonComponents/Buttons";
import {NeonDialog} from "../../NeonComponents/NeonDialog";
import Toolbar from "@mui/material/Toolbar";
import {NeonAppBar} from "../../NeonComponents/NeonAppBar";
import WindowContainer from "../layout/WindowContainer";


export default function RiftKitDialog(props: {summaries: CompetitiveGameSummaries[], map_state?: MapState | undefined, timestamp: number}){
    const [open, setOpen] = useState(false);

    return <Box>
    <NeonButton sx={{padding:2}} onClick={() => setOpen(true)} variant={'outlined'}>Open in Riftkit</NeonButton>
        <NeonDialog fullScreen open={open}
                   sx={{width:'100%', height:'100%'}}>
            <NeonAppBar position="relative" >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen(false)}
                  aria-label="close"
                >
                  <Close onClick={() => setOpen(false)}/>
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Farsight Riftkit
                </Typography>
              </Toolbar>
            </NeonAppBar>
            <WindowContainer spacing={2} sx={{height:'90%', width:'100%', alignItems:'center', justifyContent:'center'}} onClick={() => 1}>
            {props.map_state ? <RiftKitPanel map_state={props.map_state} summaries={props.summaries}
                         timestamp={props.timestamp} open={open}/> : null}
            </WindowContainer>
        </NeonDialog>
        </Box>

}

export function RiftKitPanel(props: {summaries: CompetitiveGameSummaries[], map_state: MapState, timestamp: number, open: boolean}){
    const [wardsUpBlue, setWardsUpBlue] = useState(props.map_state.wards_up_blue);
    const [wardsUpRed, setWardsUpRed] = useState(props.map_state.wards_up_red);
    return <RiftKitMap map_state={props.map_state} summaries={props.summaries}
                         timestamp={props.timestamp} open={props.open} wardsUpBlue={wardsUpBlue} wardsUpRed={wardsUpRed}/>
}
function RiftKitMap(props:{
    summaries: CompetitiveGameSummaries[],
    map_state: MapState,
    timestamp: number,
    wardsUpBlue: WardOnMap[],
    wardsUpRed: WardOnMap[],
    open: boolean
}){
    const {summaries, map_state, timestamp} = props;
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState(null as number | null);
    const theme = useThemeContext();

    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.contentRect) {
                        const size = Math.floor(Math.min(entry.contentRect.width, entry.contentRect.height));
                        setContainerSize(size+1);
                    }
                }
            });
            resizeObserver.observe(containerRef.current);
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [props.open]);

    const map_url = `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${containerSize},w_${containerSize}/leagueAssets/SummonersRift_awfeps.png`;

    return (
        <Stack direction={'column'} sx={{height:'100%', width:'100%', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
            <Box
                ref={containerRef}
                sx={{width: '100%',
                    height: '100%', justifyContent: 'center', alignItems: 'center', display:'flex',
                }}
            >
                {containerSize ? <MapContainer
                center={[containerSize / 2, containerSize / 2]}
                scrollWheelZoom={false}
                zoom={0}
                dragging={false}
                minZoom={0}
                maxZoom={0}
                attributionControl={false}
                zoomControl={false}
                doubleClickZoom={false}
                crs={CRS.Simple}
                bounds={[
                    [0, 0],
                    [containerSize, containerSize],
                ]}
            >
                <ImageOverlay
                    url={map_url}
                    bounds={[
                        [0, 0],
                        [containerSize, containerSize],
                    ]}
                >
                    {map_state?.buildings_up_blue.map(
                        building_id => building_id.includes('TOWER') ? <TurretMarker turret_id={building_id} containerSize={containerSize} side={'Blue'}/> : <InhibitorMarker inhibitor_id={building_id} containerSize={containerSize} side={'Blue'}/>
                    )}
                    {map_state?.buildings_up_red.map(
                        building_id => building_id.includes('TOWER') ? <TurretMarker turret_id={building_id} containerSize={containerSize} side={'Red'}/> : <InhibitorMarker inhibitor_id={building_id} containerSize={containerSize} side={'Red'}/>
                    )}
                    {
                        props.summaries.map(summary => {
                            return map_state?.players_dead.includes(summary.participant_id as number) ? null : <PlayerMarker display_sweeper summary={summary} containerSize={containerSize} timestamp={props.timestamp} draggable={true}/>
                        })
                    }
                    {props.wardsUpBlue.map(w => <WardMarker event={w} containerSize={containerSize} draggable={true}/>)}
                    {props.wardsUpRed.map(w => <WardMarker event={w} containerSize={containerSize} draggable={true}/>)}
                    {map_state?.grubs_spawning_in >= 0 && props.timestamp <= 14*60 && map_state.blue_grubs + map_state.red_grubs < 6? <VoidGrubsMarker cooldown={map_state?.grubs_spawning_in} containerSize={containerSize}/> : null}
                    {map_state?.herald_spawning_in !== undefined && map_state?.herald_spawning_in >= 0 && (props.timestamp >= 14*60 || map_state.blue_grubs + map_state.red_grubs >= 6) && props.timestamp < 25*60 ? <RiftHeraldMarker cooldown={map_state?.herald_spawning_in} containerSize={containerSize}/> : null}
                    {map_state?.dragon_spawning_in !== undefined && map_state?.dragon_spawning_in >= 0 ? <DragonMarker cooldown={map_state?.dragon_spawning_in} containerSize={containerSize} sub_type={map_state?.dragon_type}/> : null}
                </ImageOverlay>
                </MapContainer> : <></> }
        </Box>
            <Stack direction={'row'}>
                    <ToPngButton elementReference={containerRef}/>
                    <SmallDetailButton content={
                        <Box sx={{ maxWidth: 300, whiteSpace: "pre-wrap", fontSize: "0.7rem", maxHeight:'70vh', ...scrollable }}>
                        {JSON.stringify(map_state, null, 2)}
                    </Box>}>
                        <ArrowDropDown/>
                    </SmallDetailButton>
                </Stack>
        </Stack>
    )
}