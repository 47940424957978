import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useReferentialContext } from "../../Context";
import { Champion, PriorityPyramid } from "../../farsight-api";

const tiers = ["S", "A", "B", "C"] as const;
type Tier = typeof tiers[number];

const roles = ["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY", "FLEX"] as const;
type Role = typeof roles[number];

const tierColors: Record<Tier, string> = {
  S: "#FF5959",
  A: "#FF7F50",
  B: "#6495ED",
  C: "#1E90FF",
};

const CompactTierlistDisplay: React.FC<{ pyramid: PriorityPyramid }> = ({ pyramid }) => {
  const { farsightApi, championsReferential } = useReferentialContext();
  const [tierList, setTierList] = useState<Record<Tier, Record<Role, Champion[]>> | null>(null);

  useEffect(() => {
    const load = async () => {
      const initial: Record<Tier, Record<Role, Champion[]>> = {
        S: {}, A: {}, B: {}, C: {}
      } as any;
      roles.forEach(role => {
        tiers.forEach(tier => {
          initial[tier][role] = [];
        });
      });

      const items = await farsightApi.priorityPyramidItem.list({ pyramid: pyramid.id });
      for (const item of items) {
        const champ = championsReferential.find(c => c.id === item.champion);
        if (champ && tiers.includes(item.tier as Tier) && roles.includes(item.role as Role)) {
          initial[item.tier as Tier][item.role as Role].push(champ);
        }
      }
      setTierList(initial);
    };
    load();
  }, [pyramid]);

  if (!tierList) {
    return <Box display="flex" justifyContent="center" p={2}><CircularProgress /></Box>;
  }

  const renderRoleIcon = (role: Role) => {
    if (role === "FLEX") {
      return <Typography variant="caption" fontWeight={500}>Flex</Typography>;
    }
    return (
      <Avatar
        src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_20,w_20/v1/leagueAssets/${role}.png`}
        sx={{ width: 20, height: 20, margin: "0 auto" }}
      />
    );
  };

  return (
    <Box width={380}>
      <Box>
        {tiers.map(tier => (
          <Paper key={tier} sx={{ backgroundColor: tierColors[tier], mb: 1, p: 1 }}>
            <Typography variant="subtitle1" fontWeight={600} mb={1}>{`Tier ${tier}`}</Typography>
            <Grid container spacing={0.5} columns={roles.length}>
              {roles.map(role => (
                <Grid item xs={1} key={role} sx={{ borderLeft: "1px solid rgba(255,255,255,0.2)" }}>
                  <Box display="flex" justifyContent="center" mb={0.5}>
                    {renderRoleIcon(role)}
                  </Box>
                  <Box display="flex" flexWrap="wrap" gap={0.5} justifyContent="center">
                    {tierList[tier][role].map(champ => (
                      <Avatar
                        key={champ.id}
                        alt={champ.name}
                        src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_40,w_40/v1/championAssets/${champ.id}.png`}
                        sx={{ width: 24, height: 24 }}
                      />
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default CompactTierlistDisplay;
