import {targets, useReferentialContext} from "../../../Context";
import React, {useState} from "react";
import {League, Team} from "../../../farsight-api";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import {Grid, Grow, Stack} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import DraftingMetagameBlock from "../../../components/visu_blocks/DraftingMetagame";
import {DraftsBlock} from "../../Competitive/2_Scouting/8_DraftScouting/TeamDrafts";
import dayjs from "dayjs";
import {SideSelect} from "../../../components/selectors/YesNoSelector";
import {NeonTab, NeonTabs} from "../../../NeonComponents/NeonTabs";


export default function DraftingMetagameScrims() {
    const context = useReferentialContext();

    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [from, setFrom] = useState(dayjs().subtract(2, "weeks"));
    const [team, setTeam] = useState(null as Team|null);
    const [paramObject, setParamObject] = useState({} as any);
    const [tab, setTab] = useState(0);
    const [side, setSide] = useState(null as string | null)

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject}
                       target={targets.competitive} updateContext={false} date__gte={from} setDateGte={setFrom} patches={patches} setPatches={setPatches} team={team} setTeam={setTeam}/>
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <NeonTabs value={tab} onChange={(event, newvalue) => setTab(newvalue)} sx={{ height: "50px" }}>
            <NeonTab label="Stats" {...a11yProps(0)} />
            <NeonTab label="History" {...a11yProps(1)} />
            <NeonTab label="Priorities" {...a11yProps(2)} />
            </NeonTabs>
            </Grow>
            <TabPanel value={tab} index={0}>
                {paramObject.additional_filters? <DraftingMetagameBlock target={targets.scrims} paramObject={paramObject}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Grid
                    container
            sx={{
                mt: 5,
                    mb: 5,
                    height: "50px",
                    justifyContent: "flex-start",
                    width: "100%",
            }}
            spacing={1}
                >
                    {paramObject.additional_filters ? <Stack direction={'column'} spacing={1}>
                        <SideSelect value={side} onChange={setSide}/>
                        <DraftsBlock paramObject={paramObject} target={targets.scrims} side={side}/>
                    </Stack> : <></>}
            </Grid>
            </TabPanel>
    </WindowContainer>
);
}