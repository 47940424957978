import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel, IconButton, Stack,
} from "@mui/material";
import {Champion, DraftPreps, Team} from "../../../farsight-api";
import {useReferentialContext} from "../../../Context";
import {TeamSelect} from "../TeamSelect";
import {NeonDialog, NeonDialogActions, NeonDialogContent, NeonDialogTitle} from "../../../NeonComponents/NeonDialog";
import ChampionIcon from "../../Icons/ChampionIcon";
import {SingleChampionSelect} from "../Shared/ChampionSelect";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  draftPrep: DraftPreps | null;
  setDraftPrep: (prep: DraftPreps | null) => void;
}

const DraftPrepSelector: React.FC<Props> = ({ draftPrep, setDraftPrep }) => {
  const { farsightApi } = useReferentialContext();
  const [draftPreps, setDraftPreps] = useState<DraftPreps[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newPrepVs, setNewPrepVs] = useState<Team | null>(null);
  const [isFearless, setIsFearless] = useState(false);
  const [addFearlessBan, setAddFearlessBan] = useState(null as Champion|null);

  useEffect(() => {
    const fetchPreps = async () => {
      try {
        const data = await farsightApi.draftPreps.list();
        setDraftPreps(data);
        if (!draftPrep && data.length > 0) {
          setDraftPrep(data[0]);
        }
      } catch {
        setError("Failed to fetch draft preps");
      }
    };
    fetchPreps();
  }, []);

  const handleCreate = async () => {
    if (!newName.trim() || !newPrepVs) return;
    try {
      const created = await farsightApi.draftPreps.create({
        id: "new",
        draft_preps: {
          name: newName,
          prep_vs: newPrepVs.team_name,
          is_fearless: isFearless,
        } as any,
      });
      setDraftPreps((prev) => [...prev, created]);
      setDraftPrep(created);
      setNewName("");
      setNewPrepVs(null);
      setIsFearless(false);
      setDialogOpen(false);
    } catch {
      setError("Failed to create draft prep");
    }
  };

  const handleDelete = async () => {
    if (!draftPrep) return;
    try {
      await farsightApi.draftPreps.delete({ id: draftPrep.id.toString() });
      setDraftPreps((prev) => prev.filter((p) => p.id !== draftPrep.id));
      setDraftPrep(null);
    } catch {
      setError("Failed to delete draft prep");
    }
  };

  const handleChange = (id: string) => {
    const selected = draftPreps.find((p) => p.id === Number(id));
    if (selected) setDraftPrep(selected);
  };

  return (
    <Box display="flex" alignItems="space-between" justifyContent="space-between" gap={2} mb={2}>
      <Stack direction={'row'} spacing={1}>
      <Select
        value={draftPrep?.id || ""}
        onChange={(e) => handleChange(String(e.target.value))}
        displayEmpty
        sx={{ minWidth: 200 }}
      >
        {draftPreps.map((p) => (
          <MenuItem key={p.id} value={p.id}>
            {p.name}
          </MenuItem>
        ))}
      </Select>

      <Button variant="contained" onClick={() => setDialogOpen(true)}>
        Create
      </Button>
      <Button
        variant="outlined"
        color="error"
        onClick={handleDelete}
        disabled={!draftPrep}
      >
        Delete
      </Button>
      </Stack>
      <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
        {
          draftPrep?.fearless_bans.map((c: string) => <Box
                          sx={{ position: "relative", display: "inline-block" }}
                        >
                          <ChampionIcon champion={c} role={'TOP_LANE'} disable_link />
                          <IconButton
                            size="small"
                            onClick={() => {
                              farsightApi.draftPreps.patch({
                                id: draftPrep.id.toString(),
                                patched_draft_preps: {fearless_bans:draftPrep?.fearless_bans?.filter((fb: string) => fb !== c)}
                              }).then(r => setDraftPrep(r));
                            }}
                            sx={{
                              position: "absolute",
                              top: -6,
                              right: -6,
                              backgroundColor: "transparent",
                              zIndex: 1,
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>
          )
        }
      <SingleChampionSelect label={'Add Fearless Ban'} value={addFearlessBan} updateValue={(nv) => {
        // setAddFearlessBan(nv);
        if(draftPrep && nv){
          const nfb = [...draftPrep?.fearless_bans, nv?.id];
          farsightApi.draftPreps.patch({
            id: draftPrep.id.toString(),
            patched_draft_preps: {fearless_bans:nfb}
          }).then(r => setDraftPrep(r));
        }
      }}/>
      </Stack>

      <NeonDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{ sx: { overflow: "visible" } }}
      >
        <NeonDialogTitle>Create Draft Prep</NeonDialogTitle>
        <NeonDialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Draft Prep Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <Box mt={2}>
            <TeamSelect
              label="Prep VS"
              value={newPrepVs}
              updateValue={setNewPrepVs}
              updateContext={false}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isFearless}
                onChange={(e) => setIsFearless(e.target.checked)}
              />
            }
            label="Fearless Mode"
            sx={{ mt: 2 }}
          />
        </NeonDialogContent>
        <NeonDialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreate} variant="contained">
            Create
          </Button>
        </NeonDialogActions>
      </NeonDialog>

      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DraftPrepSelector;