import {Card, CardActions, CardContent, CardHeader, colors, Stack, Theme, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {targets, useReferentialContext} from "../../../Context";
import dayjs, {Dayjs} from "dayjs";
import Proximities from "../../../components/Charts/Proximities";
import WindowContainer from "../../../components/layout/WindowContainer";
import ForwardPercentages from "../../../components/Charts/ForwardPercentage";
import DatePicker from "../../../components/selectors/Shared/DateSelect";
import {
    DurationRadialChart,
    GoldDiffRadial,
    ValueRadialChart,
    WinratesRadial
} from "../../../components/Charts/RadialCharts";
import PaginatedStack from "../../../components/Utilities/PaginatedStack";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {Skeleton} from "@mui/lab";
import {TeamSelect} from "../../../components/selectors/TeamSelect";
import {Team} from "../../../farsight-api";
import Button from "@mui/material/Button";
import {Icecream} from "@mui/icons-material";
import {fetchAndJoin, JoinedItem} from "../../../utils";
import {float} from "html2canvas/dist/types/css/property-descriptors/float";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {NeonTab, NeonTabs} from "../../../NeonComponents/NeonTabs";
import {
    NeonCard,
    NeonCardActions,
    NeonCardContent,
    NeonCardHeader,
    NeonMainStatCard
} from "../../../NeonComponents/NeonCards";
import SwipeableViews from "react-swipeable-views";

export default function DashboardMain(){
    const user_team = useReferentialContext().user.team;
    const farsight = useReferentialContext().farsightApi;

    const [team, setTeam] = useState(useReferentialContext().teamsReferential.filter(t => t.team_name === user_team)[0]);
    console.log(team);
    console.log(user_team);
    const [competitive, setCompetitive] = useState(undefined as any);
    const [scrims, setScrims] = useState(undefined as any);
    const [teamScrims, setTeamScrims] = useState(undefined as any);
    const [teamCompetitive, setTeamCompetitive] = useState(undefined as any);
    const [scrimsLastWeek, setScrimsLastWeek] = useState(undefined as any);
    const [scrimsLastMonth, setScrimsLastMonth] = useState(undefined as any);
    const [scrimsLast3Month, setScrimsLast3Month] = useState(undefined as any);
    const [scrimsLast2Week, setScrimsLast2Week] = useState(undefined as any);
    const [scrimsCurrentYear, setScrimsCurrentYear] = useState(undefined as any);

    const [tab, setTab] = useState(0);

    const [competitive_date_from, set_competitive_date_from] = useState(dayjs().subtract(30, "days"));
    const [competitive_date_until, set_competitive_date_until] = useState(dayjs());
    const [scrims_date_from, set_scrims_date_from] = useState(dayjs().subtract(30, "days"));
    const [scrims_date_until, set_scrims_date_until] = useState(dayjs());
    const [team_vs, set_team_vs] = useState(null as Team|null);

    const aggregates = [
            'kills__sum__per_minute',
            'deaths__sum__per_minute' ,
            'participant_id__avg' ,
            'dpm__avg' ,
            'gd15__sum__per_game' ,
            'gd25__sum__per_game' ,
            'wards_placed__sum__per_minute' ,
            'vision_wards_bought__sum__per_minute' ,
            'duration__avg__in_wins' ,
            'duration__avg__in_losses' ,
            'solo_kills__sum__per_game',
            'solo_deaths__sum__per_game'
        ]
    const paramObject = {
            team1:team ? team.team_name : user_team,
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate'],
        }

    const placeholder_result = {
            'kills__sum__per_minute' : undefined,
            'deaths__sum__per_minute' : undefined,
            'participant_id__avg' : undefined,
            'dpm__avg' : undefined,
            'gd15__sum__per_game' : undefined,
            'gd25__sum__per_game' : undefined ,
            'wards_placed__sum__per_minute' : undefined,
            'vision_wards_bought__sum__per_minute' : undefined,
            'duration__avg__in_wins' : undefined,
            'duration__avg__in_losses' : undefined,
            'solo_kills__sum__per_game': undefined,
            'solo_deaths__sum__per_game': undefined,
            'games' :0,
            'winrate' : 0
    }


    useEffect(() => {
        const team_aggregates = [[
                'killed_first_drake__sum__per_game',
                'killed_first_nashor__sum__per_game',
                'killed_first_grubs__sum__per_game',
                'grublings_killed__sum__per_game',
                'grublings_killed__sum',
                'grublings_conceded__sum',
                'killed_rift_herald__sum__per_game',
                'destroyed_first_turret__sum__per_game',
                'got_first_blood__sum__per_game',
                'nashors_killed__sum',
                'nashors_conceded__sum',
                'dragons_killed__sum',
                'dragons_conceded__sum',
                'killed_atakhan__sum__per_game',
                'got_feat_of_strength__sum__per_game'
            ].join(',')];
        farsight.getTeamSummariesApi(targets.scrims).aggregate(
            {
            team1:team ? team.team_name : user_team,
            additional_filters : {
                date__gte:scrims_date_from.toISOString(),
                date__lte:scrims_date_until.toISOString(),
            },
            groupby: ['team1'],
            aggregates: team_aggregates,
            ordering: ['team1'],
            metrics: ['winrate,games'],
        }
        ).then(r => setTeamScrims(r[0]));
        farsight.getTeamSummariesApi(targets.competitive).aggregate(
            {
            team1:team ? team.team_name : user_team,
            additional_filters : {
                date__lte: competitive_date_until.toISOString(),
                date__gte:competitive_date_from.toISOString()
            },
            groupby: ['team1'],
            aggregates: team_aggregates,
            ordering: ['team1'],
            metrics: ['winrate,games'],
        }
        ).then(r => setTeamCompetitive(r[0] ? r[0] : undefined));
        farsight.getSummariesApi(targets.competitive).aggregate({
            team1:team ? team.team_name : user_team,
            additional_filters : {
                date__gte:competitive_date_from.toISOString(),
                date__lte:competitive_date_until.toISOString(),
            },
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate,games,leagues'],
        }).then(r => {
            setCompetitive(r[0] ? r[0] : undefined);
        })

        farsight.getSummariesApi(targets.scrims).aggregate({
            team1:team ? team.team_name : user_team,
            additional_filters : {
                date__gte:scrims_date_from.toISOString(),
                date__lte:scrims_date_until.toISOString(),
            },
            groupby: ['team1'],
            aggregates: [aggregates.join(',')],
            ordering: ['participant_id__avg'],
            metrics: ['winrate,games'],
        }).then(r => {
            setScrims(r[0] ? r[0] : undefined);
        })
        // farsight.getSummariesApi(targets.scrims).aggregate({
        //     team1:user_team,
        //     additional_filters : {date__gte:scrims_date_from.toISOString()},
        //     groupby: ['player'],
        //     aggregates: [['gd8','xpd8','csd8','forward_percentage_pre15'].join(',')],
        //     ordering: ['participant_id__avg'],
        //     metrics: ['winrate,games'],
        // }).then(r => {
        //     setPlayerScrimStats(r[0] ? r : undefined);
        // })

    }, [scrims_date_from, competitive_date_from, team, scrims_date_until, competitive_date_until]);
    const reference_day = dayjs();
    useEffect(() => {
        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(1, 'week').toISOString()}
        }).then(r => {setScrimsLastWeek(r[0] ? r[0] : placeholder_result);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(2, 'week').toISOString()}
        }).then(r => {setScrimsLast2Week(r[0] ? r[0] : placeholder_result);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(1, 'month').toISOString()}
        }).then(r => {setScrimsLastMonth(r[0] ? r[0] : placeholder_result);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:reference_day.subtract(3, 'month').toISOString()}
        }).then(r => {setScrimsLast3Month(r[0] ? r[0] : placeholder_result);})

        farsight.getSummariesApi(targets.scrims).aggregate({...paramObject, additional_filters : {date__gte:dayjs(`${dayjs().year()-1}-12-12`).toISOString()}
        }).then(r => {setScrimsCurrentYear(r[0] ? r[0] : placeholder_result);})
        console.log('Querying')
    }, [team]);
    function ColumnHeader(props:{title: string}){
        return <Stack direction={'row'} sx={{display: 'flex', align:'center', justify:'center'}}>
                <Typography variant="h5" sx={{width: '33%', display:'flex', justifyContent:'left'}}>{props.title}</Typography>
                <Typography variant="h5" sx={{width: '33%', display:'flex', justifyContent:'right'}}>{'Scrims'}</Typography>
                <Typography variant="h5" sx={{width: '33%', display:'flex', justifyContent:'right'}}>{'Competitive'}</Typography>
            </Stack>
    }
    // put card text in bold

    return <WindowContainer direction={'column'} spacing={0} sx={{alignItems:'flex-start', justifyContent:'flex-start', display:'flex'}}>
        {
            scrims && scrimsLastWeek && scrimsLast2Week && scrimsLastMonth && scrimsLast3Month && scrimsCurrentYear ?
                <PaginatedStack direction={'row'} sx={{width:'100%', alignItems:'center', justifyContent:'left', display:'flex', height:'15%', minHeight:'250px'}} spacing={1}>
                    <NeonCard sx={{width:'250px', height:'240px'}}>
                        <CardContent sx={{width:'250px', height:'240px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                            <Stack direction={'column'} sx={{maxWidth:'100%'}} spacing={1}>
                                <DatePicker onChange={set_competitive_date_from} value={competitive_date_from} label={'Competitive From'}/>
                                <DatePicker onChange={set_scrims_date_from} value={scrims_date_from} label={'Scrims From'}/>
                                <TeamSelect value={team} updateValue={setTeam} updateContext={false}/>
                            </Stack>
                        </CardContent>
                    </NeonCard>
                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.winrate * 100).toFixed(1)}% WR (${scrims.games} G)`} sx={{height:'70px', mb:0}} subheader={`Since ${scrims_date_from.format('MMM DD')}`} titleTypographyProps={{fontWeight:'bold'}}/>
                    <Box sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <WinratesRadial values={[scrimsLastWeek.winrate, scrimsLast2Week.winrate, scrimsLastMonth.winrate, scrimsLast3Month.winrate, scrimsCurrentYear.winrate]}
                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </Box>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${(competitive.winrate * 100).toFixed(0)}% WR in officials (${(competitive.winrate - scrims.winrate)>0? '+' : ''}${((competitive.winrate - scrims.winrate) * 100).toFixed(0)}%)` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions >
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.gd15__sum__per_game)>0? '+' : ''}${(scrims.gd15__sum__per_game).toFixed(0)} GD@15`} sx={{height:'70px', mb:0}} subheader={`Since ${scrims_date_from.format('MMM DD')}`} titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <GoldDiffRadial values={[scrimsLastWeek.gd15__sum__per_game, scrimsLast2Week.gd15__sum__per_game, scrimsLastMonth.gd15__sum__per_game, scrimsLast3Month.gd15__sum__per_game, scrimsCurrentYear.gd15__sum__per_game]}
                                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${competitive.gd15__sum__per_game.toFixed(0)} in officials (${(competitive.gd15__sum__per_game - scrims.gd15__sum__per_game)>0? '+' : ''}${((competitive.gd15__sum__per_game - scrims.gd15__sum__per_game)).toFixed(0)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions >
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.gd25__sum__per_game)>0? '+' : ''}${(scrims.gd25__sum__per_game).toFixed(1)} GD@25`} sx={{height:'70px', mb:0}} subheader={`Average since ${scrims_date_from.format('MMM DD')}`} titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <GoldDiffRadial values={[scrimsLastWeek.gd25__sum__per_game, scrimsLast2Week.gd25__sum__per_game, scrimsLastMonth.gd25__sum__per_game, scrimsLast3Month.gd25__sum__per_game, scrimsCurrentYear.gd25__sum__per_game]}
                                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${competitive.gd25__sum__per_game.toFixed(0)} in officials (${(competitive.gd25__sum__per_game - scrims.gd25__sum__per_game)>0? '+' : ''}${((competitive.gd25__sum__per_game - scrimsLastWeek.gd25__sum__per_game)).toFixed(0)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions >
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.solo_kills__sum__per_game).toFixed(2)} Solokills`} sx={{height:'70px', mb:0}} subheader={`Average /g since ${scrims_date_from.format('MMM DD')}`} titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.solo_kills__sum__per_game, scrimsLast2Week.solo_kills__sum__per_game, scrimsLastMonth.solo_kills__sum__per_game, scrimsLast3Month.solo_kills__sum__per_game, scrimsCurrentYear.solo_kills__sum__per_game]}
                                        labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={1.5} maxValue={3}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${competitive.solo_kills__sum__per_game.toFixed(2)} in officials (${(competitive.solo_kills__sum__per_game - scrims.solo_kills__sum__per_game)>0? '+' : ''}${((competitive.solo_kills__sum__per_game - scrims.solo_kills__sum__per_game)).toFixed(2)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.solo_deaths__sum__per_game).toFixed(2)} Solodeaths`} sx={{height:'70px', mb:0}} subheader={`Average /g since ${scrims_date_from.format('MMM DD')}`}  titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.solo_deaths__sum__per_game, scrimsLast2Week.solo_deaths__sum__per_game, scrimsLastMonth.solo_deaths__sum__per_game, scrimsLast3Month.solo_deaths__sum__per_game, scrimsCurrentYear.solo_deaths__sum__per_game]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={1.5} maxValue={3}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${competitive.solo_deaths__sum__per_game.toFixed(2)} in officials (${(competitive.solo_deaths__sum__per_game - scrims.solo_deaths__sum__per_game)>0? '+' : ''}${((competitive.solo_deaths__sum__per_game - scrims.solo_deaths__sum__per_game)).toFixed(2)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${new Date(scrims.duration__avg__in_wins / 1000).toISOString().substring(14, 19)}`} sx={{height:'70px', mb:0}} subheader={`Avg win time since ${scrims_date_from.format('MMM DD')}`}  titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <DurationRadialChart values={[scrimsLastWeek.duration__avg__in_wins, scrimsLast2Week.duration__avg__in_wins, scrimsLastMonth.duration__avg__in_wins, scrimsLast3Month.duration__avg__in_wins, scrimsCurrentYear.duration__avg__in_wins]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${`${new Date(competitive.duration__avg__in_wins / 1000).toISOString().substring(14, 19)}`} in officials` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${new Date(scrims.duration__avg__in_losses / 1000).toISOString().substring(14, 19)}`} sx={{height:'70px', mb:0}} subheader={`Avg l. duration since ${scrims_date_from.format('MMM DD')}`} titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <DurationRadialChart values={[scrimsLastWeek.duration__avg__in_losses, scrimsLast2Week.duration__avg__in_losses, scrimsLastMonth.duration__avg__in_losses, scrimsLast3Month.duration__avg__in_losses, scrimsCurrentYear.duration__avg__in_losses]}
                                             labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${`${new Date(competitive.duration__avg__in_losses / 1000).toISOString().substring(14, 19)}`} in officials` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.vision_wards_bought__sum__per_minute*5).toFixed(2)} Pink Wards`} sx={{height:'70px', mb:0}} subheader={`Bought /min since ${scrims_date_from.format('MMM DD')}`}  titleTypographyProps={{fontWeight:'bold'}}/>
                    <NeonCardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.vision_wards_bought__sum__per_minute*5, scrimsLast2Week.vision_wards_bought__sum__per_minute*5, scrimsLastMonth.vision_wards_bought__sum__per_minute*5, scrimsLast3Month.vision_wards_bought__sum__per_minute*5, scrimsCurrentYear.vision_wards_bought__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={0.7} maxValue={1.3}/>
                    </NeonCardContent>
                    <NeonCardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${(competitive.vision_wards_bought__sum__per_minute*5).toFixed(2)} in officials (${(competitive.vision_wards_bought__sum__per_minute - scrims.vision_wards_bought__sum__per_minute)>0? '+' : ''}${(((competitive.vision_wards_bought__sum__per_minute - scrims.vision_wards_bought__sum__per_minute))*5).toFixed(2)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </NeonCardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.kills__sum__per_minute*5).toFixed(2)} Kills`} sx={{height:'70px', mb:0}} subheader={`Average /min since ${scrims_date_from.format('MMM DD')}`}  titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.kills__sum__per_minute*5, scrimsLast2Week.kills__sum__per_minute*5, scrimsLastMonth.kills__sum__per_minute*5, scrimsLast3Month.kills__sum__per_minute*5, scrimsCurrentYear.kills__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={0.5} maxValue={1}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${(competitive.kills__sum__per_minute*5).toFixed(2)} in officials (${(competitive.kills__sum__per_minute - scrims.kills__sum__per_minute)>0? '+' : ''}${(((competitive.kills__sum__per_minute - scrims.kills__sum__per_minute))*5).toFixed(2)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.deaths__sum__per_minute*5).toFixed(2)} Deaths`} sx={{height:'70px', mb:0}} subheader={`Average /min since ${scrims_date_from.format('MMM DD')}`}  titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.deaths__sum__per_minute*5, scrimsLast2Week.deaths__sum__per_minute*5, scrimsLastMonth.deaths__sum__per_minute*5, scrimsLast3Month.deaths__sum__per_minute*5, scrimsCurrentYear.deaths__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={0.2} maxValue={1}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${(competitive.deaths__sum__per_minute*5).toFixed(2)} in officials (${(competitive.deaths__sum__per_minute - scrims.deaths__sum__per_minute)>0? '+' : ''}${(((competitive.deaths__sum__per_minute - scrims.deaths__sum__per_minute))*5).toFixed(2)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.dpm__avg*5).toFixed(0)} Damages`} sx={{height:'70px', mb:0}} subheader={`Average /min since ${scrims_date_from.format('MMM DD')}`} titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.dpm__avg*5, scrimsLast2Week.dpm__avg*5, scrimsLastMonth.dpm__avg*5, scrimsLast3Month.dpm__avg*5, scrimsCurrentYear.dpm__avg*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={3000} maxValue={3500}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${(competitive.dpm__avg*5).toFixed(0)} in officials (${(competitive.dpm__avg - scrims.dpm__avg)>0? '+' : ''}${(((competitive.dpm__avg - scrims.dpm__avg))*5).toFixed(0)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>

                <NeonCard sx={{width:'250px', height:'240px'}}>
                    <CardHeader title={`${(scrims.wards_placed__sum__per_minute*5).toFixed(2)} Wards`} sx={{height:'70px', mb:0}} subheader={`Placed /min since ${scrims_date_from.format('MMM DD')}`}  titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <ValueRadialChart values={[scrimsLastWeek.wards_placed__sum__per_minute*5, scrimsLast2Week.wards_placed__sum__per_minute*5, scrimsLastMonth.wards_placed__sum__per_minute*5, scrimsLast3Month.wards_placed__sum__per_minute*5, scrimsCurrentYear.wards_placed__sum__per_minute*5]}
                                          labels={['Last Week', 'Last 2 Weeks', 'Last Month', 'Last 3 Month', 'Full Year']} minValue={2.5} maxValue={3.4}/>
                    </CardContent>
                    <CardActions sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                        {competitive ? `${(competitive.wards_placed__sum__per_minute*5).toFixed(2)} in officials (${(competitive.wards_placed__sum__per_minute - scrims.wards_placed__sum__per_minute)>0? '+' : ''}${(((competitive.wards_placed__sum__per_minute - scrims.wards_placed__sum__per_minute))*5).toFixed(2)})` : `No official games since ${competitive_date_from.format('MMM DD')}`}
                    </CardActions>
                </NeonCard>
                    <NeonCard sx={{width:'250px', height:'240px'}}>
                        <CardContent sx={{width:'250px', height:'240px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                            <Stack direction={'column'} sx={{maxWidth:'100%'}} spacing={1}>
                                <DatePicker onChange={set_competitive_date_until} value={competitive_date_until} label={'Competitive Until'}/>
                                <DatePicker onChange={set_scrims_date_until} value={scrims_date_until} label={'Scrims Until'}/>
                            </Stack>
                        </CardContent>
                    </NeonCard>
            </PaginatedStack> : <Stack direction={'row'} sx={{width:'100%', alignItems:'center', justifyContent:'left', display:'flex', height:'250px'}} spacing={1}>
                <NeonCard sx={{width:'250px', height:'240px'}}>
                        <CardContent sx={{width:'250px', height:'240px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                            <Stack direction={'column'} sx={{maxWidth:'100%'}} spacing={1}>
                                <DatePicker onChange={set_competitive_date_from} value={competitive_date_from} label={'Competitive From'}/>
                                <DatePicker onChange={set_scrims_date_from} value={scrims_date_from} label={'Scrims From'}/>
                                <TeamSelect value={team} updateValue={setTeam} updateContext={false}/>
                            </Stack>
                        </CardContent>
                    </NeonCard>
                {[1, 2, 3, 4, 5, 6].map(i => <Card sx={{width:'250px', height:'240px'}}>
                    <CardHeader sx={{height:'70px', mb:0}} title={'Loading...'}/>
                    <CardContent sx={{width:'250px', height:'125px', alignItems:'center', justifyContent:'center', display:'flex', mt:0}}>
                        <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                    </CardContent>
                </Card>)}
            </Stack>
        }
        <Stack direction={'row'} sx={{minHeight:'69%', width:'100%'}} spacing={1}>
                <NeonTabs value={tab} orientation="vertical" onChange={(event, newValue) => {setTab(newValue);}} sx={{ height: "100%", width:'200px', minWidth:'200px' }}>
                    <NeonTab label="Objective Stats" {...a11yProps(0)} sx={{fontWeight:'bold'}}/>
                    <NeonTab label="Lane Stats" {...a11yProps(1)} sx={{fontWeight:'bold'}} />
                    <NeonTab label="Economy" {...a11yProps(2)} sx={{fontWeight:'bold'}} />
                </NeonTabs>
            <Stack direction={'row'} sx={{width:'100%', height:'100%'}}>
            <TabPanel value={tab} index={0}>
                <Stack direction={'row'} sx={{width:'100%', maxWidth:'100%', height:'100%', alignItems:'center', justifyContent:'center', display:'flex', paddingTop:'55px'}}>
                    {/*[
                'killed_first_drake__sum__per_game',
                'killed_first_grubs__sum__per_game',
                'grublings_killed__sum__per_game',
                'killed_rift_herald__sum__per_game',
                'destroyed_first_turret__sum__per_game',
                'nashors_killed__sum',
                'nashors_conceded__sum',
                'dragons_killed__sum',
                'dragons_conceded__sum'
            ]*/}
                    <Stack direction={'column'} sx={{width:'20%',height:'100%'}} spacing={1}>
                        <MainStatCard title={`First Drake%`} background={'#823c107f'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} format_stat={(data: any) => (data.killed_first_drake__sum__per_game * 100).toFixed(1) + '%'} use_gradient={true}/>
                        <MainStatCard title={`Drakes / Game`} background={'#823c107f'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} format_stat={(data: any) => (data.dragons_killed__sum / data.games).toFixed(2)}/>
                        <MainStatCard title={`Drakes%`} background={'#823c107f'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} format_stat={(data: any) => (100 * data.dragons_killed__sum / (data.dragons_conceded__sum + data.dragons_killed__sum)).toFixed(1) + '%'} use_gradient={true}/>
                    </Stack>
                    <Stack direction={'column'} sx={{width:'20%',height:'100%',}} spacing={1}>
                        <MainStatCard title={`First Grubs%`} background={'#9a159e7f'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} format_stat={(data: any) => (data.killed_first_grubs__sum__per_game * 100).toFixed(1) + '%'} use_gradient={true}/>
                        <MainStatCard title={`Grubs / Game`} background={'#9a159e7f'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} format_stat={(data: any) => (data.grublings_killed__sum__per_game).toFixed(1)}/>
                        <MainStatCard title={`Grubs%`} background={'#9a159e7f'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} format_stat={(data: any) => (data.grublings_killed__sum / (data.grublings_killed__sum + data.grublings_conceded__sum) * 100).toFixed(1) + '%'} use_gradient={true}/>
                    </Stack>
                    <Stack direction={'column'} sx={{width:'20%',height:'100%',}} spacing={1}>
                        <MainStatCard title={`Heralds / Game`} background={'#4637cc7f'} format_stat={(data: any) => (data.killed_rift_herald__sum__per_game * 100).toFixed(1)  + '%'} competitive_stats={teamCompetitive} scrims_stats={teamScrims}/>
                    </Stack>
                    <Stack direction={'column'} sx={{width:'20%',height:'100%',}} spacing={1}>
                        <MainStatCard title={`First Nashor%`} background={'#7916c47f'} format_stat={(data: any) => (data.killed_first_nashor__sum__per_game * 100).toFixed(1) + '%'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} use_gradient={true}/>
                        <MainStatCard title={`Nashor / Game`} background={'#7916c47f'} format_stat={(data: any) => (data.nashors_killed__sum / data.games).toFixed(2)} competitive_stats={teamCompetitive} scrims_stats={teamScrims}/>
                        <MainStatCard title={`Nashor%`} background={'#7916c47f'} format_stat={(data: any) => (data.nashors_killed__sum / (data.nashors_killed__sum + data.nashors_conceded__sum)*100).toFixed(1) + '%'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} use_gradient={true}/>
                    </Stack>
                    <Stack direction={'column'} sx={{width:'20%',height:'100%',}} spacing={1}>
                        <MainStatCard title={`First Turret%`} format_stat={(data: any) => (data.destroyed_first_turret__sum__per_game*100).toFixed(1) + '%'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} use_gradient={true}/>
                        <MainStatCard title={`FB%`} format_stat={(data: any) => (data.got_first_blood__sum__per_game*100).toFixed(1) + '%'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} use_gradient={true}/>
                        <MainStatCard title={`Atakhan%`} format_stat={(data: any) => (data.killed_atakhan__sum__per_game*100).toFixed(1) + '%'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} use_gradient={true}/>
                        <MainStatCard title={`FoS%`} format_stat={(data: any) => (data.got_feat_of_strength__sum__per_game*100).toFixed(1) + '%'} competitive_stats={teamCompetitive} scrims_stats={teamScrims} use_gradient={true}/>
                    </Stack>
                </Stack>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <IndividualLaneStatsPanels competitive_from={competitive_date_from} scrims_from={scrims_date_from}/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <IndividualGdifStatsPanels competitive_from={competitive_date_from} scrims_from={scrims_date_from}/>
            </TabPanel>
            </Stack>
        </Stack>

    </WindowContainer>

}

function MainStatCard(props: {title: string, competitive_stats?: any,
    scrims_stats?: any,
    icon?: JSX.Element,
    format_stat: (stat_obj: any) => string,
    background?: string
    use_gradient?: boolean
    is_delta?: boolean
}){
    // Parse percentage string (e.g., "75.0%") into a numeric value between 0 and 1


  // Interpolate color with added saturation
  const interpolateColor = (v: string) => {
    const numericValue = parseFloat(v.replace('%', '')) / 100;
    const clampedValue = Math.max(0, Math.min(numericValue, 1));
    // const red = Math.round(200 + 55 * (1 - clampedValue)); // Slightly less pure red
    // const green = Math.round(100 + 155 * clampedValue);    // Slightly less pure green
    // const blue = Math.round(50 * (1 - clampedValue));      // Add a touch of blue for depth
    return clampedValue >= 0.5 ? 'lightgreen' : 'red';
  };
    return <NeonMainStatCard sx={{width:'300px', height:'125px', backgroundColor:props.background}}>
                            <CardHeader title={props.title} sx={{height:'50px', textDecoration: 'underline', mb:0}} titleTypographyProps={{fontWeight:'bold', textDecoration: 'underline'}}/>
                            <Box sx={{width:'300px', height:'75px', alignItems:'center', justifyContent:'center', display:'flex', m:0, padding:0}}>
                                <Stack direction={'row'} sx={{width:'100%'}}>
                                    <Stack direction={'column'} sx={{width:'10%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                        {props.icon ? props.icon : <Icecream onClick={() => console.log('test')} sx={{cursor: 'pointer'}}/>}
                                    </Stack>
                                    <Stack direction={'column'} sx={{width:'45%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                                        <Typography variant={'h6'} sx={{fontStyle: 'italic', fontWeight:'bold'}}>Scrims</Typography>
                                        <Stack direction={'row'} sx={{alignItems:'center',justifyContent:'center', display:'flex'}}>
                                            {props.is_delta && props.scrims_stats ? (parseFloat(props.format_stat(props.scrims_stats)) > 0 ? <KeyboardDoubleArrowUpIcon sx={{color: 'lightgreen'}}/> :<KeyboardDoubleArrowDownIcon sx={{color: 'red'}}/>) : <></>}
                                            <Typography variant={'h6'} sx={{fontWeight:'bold', color: props.use_gradient && props.scrims_stats? interpolateColor(props.format_stat(props.scrims_stats)) : props.is_delta && props.scrims_stats ? (parseFloat(props.format_stat(props.scrims_stats)) > 0 ? 'lightgreen' : 'red') : 'inherit'}}>{props.scrims_stats ? `${props.is_delta && parseFloat(props.format_stat(props.scrims_stats))>0.5 ? '+' : '' }${props.format_stat(props.scrims_stats)}` : 'No games'}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={'column'} sx={{width:'45%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                                        <Typography variant={'h6'} sx={{fontStyle: 'italic',fontWeight:'bold'}}>Competitive</Typography>
                                            <Typography variant={'h6'} sx={{fontWeight:'bold', color: props.use_gradient && props.competitive_stats? interpolateColor(props.format_stat(props.competitive_stats)) : props.is_delta && props.competitive_stats ? (parseFloat(props.format_stat(props.competitive_stats)) > 0 ? 'lightgreen' : 'red') : 'inherit'}}>{props.competitive_stats ? `${props.is_delta && parseFloat(props.format_stat(props.competitive_stats))>0.5 ? '+' : '' }${props.format_stat(props.competitive_stats)}` : 'No games'}</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                            <CardActions sx={{justifyContent:'left', alignItems:'left', display:'flex'}}>
                                <Button>Details</Button>
                            </CardActions>
                        </NeonMainStatCard>
}

function IndividualLaneStatsPanels(props: {competitive_from: Dayjs, scrims_from: Dayjs}){
    const ctx = useReferentialContext();
    const [tab, setTab] = useState(0);
    // const [individualStatsCompetitive, setIndividualStatsCompetitive] = useState([] as any[]);
    // const [individualStatsScrims, setIndividualStatsScrims] = useState([] as any[]);
    const [individualStats, setIndividualStats] = useState([] as any[]);

    useEffect(() => {
        const metrics = ['gd8,xpd8,csd8,forward_percentage_pre_15,kp10,deaths8,first_blood_victim,first_blood_participant,item1_purchase_timer'];
        const aggregates = ['role__max,kills_from_jungle_pre10__avg,deaths_to_jungle_pre10__avg,flashes_used_pre6__avg,participant_id__avg,solokills_direct_opponent_pre10__avg,solodeaths_direct_opponent_pre10__avg,flashes_forced_pre6__avg,flashes_forced_pre14__avg'];
        const competitive_request = ctx.farsightApi.competitiveGameSummaries.aggregate({
            additional_filters: {
                date__gte: props.competitive_from.toISOString(),
            },
            team1: ctx.user.team,
            metrics: metrics,
            aggregates: aggregates,
            ordering: ['participant_id__avg'],
            groupby: ['player']
        })
        const scrim_request = ctx.farsightApi.scrimGameSummaries.aggregate({
            additional_filters: {
                date__gte: props.scrims_from.toISOString(),
            },
            team1: ctx.user.team,
            metrics: metrics,
            aggregates: aggregates,
            ordering: ['participant_id__avg'],
            groupby: ['player']
        })
        fetchAndJoin(() => scrim_request, () => competitive_request, (item:any) => item.player.toUpperCase(), (item:any) => item.player.split(' (')[0].toUpperCase(), 'full').then(
            r => setIndividualStats(r)
        )
    }, [props.competitive_from, props.scrims_from]);

    return <Stack direction={'column'} sx={{width:'100%', height:'100%'}} spacing={1}>
        <NeonTabs value={tab} onChange={(event, newValue) => {setTab(newValue);}} sx={{ width:'100%' }}>
            {
                individualStats.map((player_stats, index: number) => {
                    return <NeonTab label={player_stats.id} asset={player_stats.left.role__max}/>
                })
            }
        </NeonTabs>
        {
            individualStats.map((player_stats, index: number) => {
                return <TabPanel value={tab} index={index}><IndividualLaneStats player_stats_scrim={player_stats.left} player_stats_competitive={player_stats.right}/></TabPanel>
            })
        }

    </Stack>
}

function IndividualLaneStats(props: {player_stats_scrim: any, player_stats_competitive?: any}){
    return <Stack direction={'row'} sx={{width:'100%', height:'100%'}}>
        <Stack direction={'column'} sx={{width:'20%',height:'100%'}} spacing={1}>
            <MainStatCard title={`GD@8`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.gd8)?.toFixed(1)} is_delta={true} background={'#008B8B80'}/>
            <MainStatCard title={`XPD@8`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.xpd8)?.toFixed(1)} is_delta={true} background={'#008B8B80'}/>
            <MainStatCard title={`CSD@8`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.csd8)?.toFixed(1)} is_delta={true} background={'#008B8B80'}/>
            <MainStatCard title={`First Item`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => `${new Date(parseFloat(data.item1_purchase_timer) * 1000).toISOString().substring(14, 19)}`} background={'#008B8B80'}/>
        </Stack>
        <Stack direction={'column'} sx={{width:'20%',height:'100%'}} spacing={1}>
            <MainStatCard title={`FWD%@15 / Game`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.forward_percentage_pre_15*100)?.toFixed(1)+ '%'}/>
            <MainStatCard title={`SK@10 / Game`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.solokills_direct_opponent_pre10__avg)?.toFixed(2)}/>
            <MainStatCard title={`SD@10 / Game`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.solodeaths_direct_opponent_pre10__avg)?.toFixed(2)}/>
        </Stack>
        <Stack direction={'column'} sx={{width:'20%',height:'100%'}} spacing={1}>
            <MainStatCard title={`Flashes Used Pre 6`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => data.flashes_used_pre6__avg?.toFixed(2)} background={'#8B8000'}/>
            <MainStatCard title={`FF Pre 6 Per Game`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => data.flashes_forced_pre6__avg?.toFixed(2)} background={'#8B8000'}/>
            <MainStatCard title={`FF Pre 14 Per Game`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => data.flashes_forced_pre14__avg?.toFixed(2)} background={'#8B8000'}/>
        </Stack>
        <Stack direction={'column'} sx={{width:'20%',height:'100%'}} spacing={1}>
            <MainStatCard title={`FB%`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.first_blood_participant*100)?.toFixed(2) + '%'} background={'#1C2633'}/>
            <MainStatCard title={`Kills w Jungle Pre10`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => data.kills_from_jungle_pre10__avg?.toFixed(2)}  background={'#1C2633'}/>
            <MainStatCard title={`K+A@10`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => data.kp10?.toFixed(2)}  background={'#1C2633'}/>
        </Stack>
        <Stack direction={'column'} sx={{width:'20%',height:'100%'}} spacing={1}>
            <MainStatCard title={`FD%`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.first_blood_victim*100)?.toFixed(2) + '%'} background={"#2B1818"}/>
            <MainStatCard title={`Deaths to Jungle Pre10`}
                          competitive_stats={props.player_stats_competitive}
                          scrims_stats={props.player_stats_scrim}
                          format_stat={(data: any) => data.deaths_to_jungle_pre10__avg?.toFixed(2)}  background={"#2B1818"}/>
            <MainStatCard title={`Deaths@8`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => data.deaths8?.toFixed(2)} background={"#2B1818"} />
        </Stack>
    </Stack>
}

function IndividualGdifStatsPanels(props: {competitive_from: Dayjs, scrims_from: Dayjs}){
    const ctx = useReferentialContext();
    const [tab, setTab] = useState(0);
    // const [individualStatsCompetitive, setIndividualStatsCompetitive] = useState([] as any[]);
    // const [individualStatsScrims, setIndividualStatsScrims] = useState([] as any[]);
    const [individualStats, setIndividualStats] = useState([] as any[]);

    useEffect(() => {
        const metrics = ['gd8,gd15,gd20,gd25,gd30,games,ahead8,ahead15,ahead20,ahead25,ahead30'];
        const competitive_request = ctx.farsightApi.competitiveGameSummaries.aggregate({
            additional_filters: {
                date__gte: props.competitive_from.toISOString(),
            },
            team1: ctx.user.team,
            metrics: metrics,
            aggregates: ['participant_id__avg,role__max'],
            ordering: ['participant_id__avg'],
            groupby: ['player']
        })
        const scrim_request = ctx.farsightApi.scrimGameSummaries.aggregate({
            additional_filters: {
                date__gte: props.scrims_from.toISOString(),
            },
            team1: ctx.user.team,
            metrics: metrics,
            aggregates: ['participant_id__avg,role__max'],
            ordering: ['participant_id__avg'],
            groupby: ['player']
        })
        fetchAndJoin(() => scrim_request, () => competitive_request, (item:any) => item.player.toUpperCase(), (item:any) => item.player.split(' (')[0].toUpperCase(), 'full').then(
            r => setIndividualStats(r)
        )
    }, [props.competitive_from, props.scrims_from]);

    return <Stack direction={'column'} sx={{width:'100%', height:'100%'}}>
        <NeonTabs value={tab} onChange={(event, newValue) => {setTab(newValue);}} sx={{ width:'100%' }}>
            <NeonTab label={ctx.user.team} team_logo={ctx.user.team}/>
            {
                individualStats.map((player_stats, index: number) => {
                    return <NeonTab label={player_stats.id} asset={player_stats.left.role__max}/>
                })
            }
        </NeonTabs>

        <TabPanel value={tab} index={0}><TeamGdifStats competitive_date_from={props.competitive_from} scrims_date_from={props.scrims_from}/></TabPanel>
        {
            individualStats.map((player_stats, index: number) => {
                return <TabPanel value={tab} index={index+1}><IndividualGdifStats player_stats_scrim={player_stats.left} player_stats_competitive={player_stats.right} competitive_date_from={props.competitive_from} scrims_date_from={props.scrims_from}/></TabPanel>
            })
        }
    </Stack>
}

function TeamGdifStats(props: {scrims_date_from:Dayjs, competitive_date_from:Dayjs}){
    const ctx = useReferentialContext();

    const [when_ahead_at_8_scrims, setWhenAheadAt8Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_15_scrims, setWhenAheadAt15Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_20_scrims, setWhenAheadAt20Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_25_scrims, setWhenAheadAt25Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_30_scrims, setWhenAheadAt30Scrims] = useState(undefined as any|undefined);

    const [when_behind_at_8_scrims, setWhenBehindAt8Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_15_scrims, setWhenBehindAt15Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_20_scrims, setWhenBehindAt20Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_25_scrims, setWhenBehindAt25Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_30_scrims, setWhenBehindAt30Scrims] = useState(undefined as any|undefined);
    const [scrim_stats, setScrimStats] = useState(undefined as any|undefined);

    const [when_ahead_at_8_competitive, setWhenAheadAt8competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_15_competitive, setWhenAheadAt15competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_20_competitive, setWhenAheadAt20competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_25_competitive, setWhenAheadAt25competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_30_competitive, setWhenAheadAt30competitive] = useState(undefined as any|undefined);

    const [when_behind_at_8_competitive, setWhenBehindAt8competitive] = useState(undefined as any|undefined);
    const [when_behind_at_15_competitive, setWhenBehindAt15competitive] = useState(undefined as any|undefined);
    const [when_behind_at_20_competitive, setWhenBehindAt20competitive] = useState(undefined as any|undefined);
    const [when_behind_at_25_competitive, setWhenBehindAt25competitive] = useState(undefined as any|undefined);
    const [when_behind_at_30_competitive, setWhenBehindAt30competitive] = useState(undefined as any|undefined);
    const [competitive_stats, setcompetitivetats] = useState(undefined as any|undefined);

    useEffect(() => {
        const scrim_request_params = {
            additional_filters: {date__gte:props.scrims_date_from.toISOString()},
            team1: ctx.user.team,
            metrics: ['winrate,games,ahead_15'],
            ordering: ['team1'],
            groupby: ['team1']
        }



        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, metrics:['gd8,gd10,gd14,gd15,gd20,gd25,gd30,games,winrate,ahead8,ahead10,ahead14,ahead15,ahead20,ahead25,ahead30']}).then(r => setScrimStats(r[0]));

        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd8__gte:0}}).then(r => {setWhenAheadAt8Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd15__gte:0}}).then(r => {setWhenAheadAt15Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd20__gte:0}}).then(r => {setWhenAheadAt20Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd25__gte:0}}).then(r => {setWhenAheadAt25Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd30__gte:0}}).then(r => {setWhenAheadAt30Scrims(r[0] ? r[0] : undefined);})

        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd8__lte:0}}).then(r => {setWhenBehindAt8Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd15__lte:0}}).then(r => {setWhenBehindAt15Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd20__lte:0}}).then(r => {setWhenBehindAt20Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd25__lte:0}}).then(r => {setWhenBehindAt25Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimTeamGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd30__lte:0}}).then(r => {setWhenBehindAt30Scrims(r[0] ? r[0] : undefined);})

        const competitive_request_params = {
            additional_filters: {date__gte:props.competitive_date_from.toISOString()},
            team1: ctx.user.team,
            metrics: ['winrate,games'],
            ordering: ['team1'],
            groupby: ['team1']
        }

        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, metrics:['gd8,gd10,gd14,gd15,gd20,gd25,gd30,games,winrate,ahead8,ahead10,ahead14,ahead15,ahead20,ahead25,ahead30']}).then(r => setcompetitivetats(r[0]));

        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd8__gte:0}}).then(r => {setWhenAheadAt8competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd15__gte:0}}).then(r => {setWhenAheadAt15competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd20__gte:0}}).then(r => {setWhenAheadAt20competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd25__gte:0}}).then(r => {setWhenAheadAt25competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd30__gte:0}}).then(r => {setWhenAheadAt30competitive(r[0] ? r[0] : undefined);})

        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd8__lte:0}}).then(r => {setWhenBehindAt8competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd15__lte:0}}).then(r => {setWhenBehindAt15competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd20__lte:0}}).then(r => {setWhenBehindAt20competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd25__lte:0}}).then(r => {setWhenBehindAt25competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveTeamGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd30__lte:0}}).then(r => {setWhenBehindAt30competitive(r[0] ? r[0] : undefined);})

    }, [props.scrims_date_from, props.competitive_date_from])

    const column_style = {width:'20%',padding:1, boxSizing:'border-box', height:'100%', alignItems:'center', display:'flex', justifyContent:'center', borderRadius:1}

    return <Stack direction={'row'} sx={{width:'100%', height:'100%', alignItems:'center', display:'flex', justifyContent:'center'}} spacing={1}>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@8`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => data.gd8 ? (data.gd8).toFixed(1) : '?'} is_delta={true}/>
            <MainStatCard title={`Ahead@8 %`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.ahead8 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@8`} competitive_stats={when_ahead_at_8_competitive} scrims_stats={when_ahead_at_8_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@8`} competitive_stats={when_behind_at_8_competitive} scrims_stats={when_behind_at_8_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@15`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.gd15).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@15 %`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.ahead15 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@15`} competitive_stats={when_ahead_at_15_competitive} scrims_stats={when_ahead_at_15_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@15`} competitive_stats={when_behind_at_15_competitive} scrims_stats={when_behind_at_15_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@20`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.gd20).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@20 %`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.ahead20 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@20`} competitive_stats={when_ahead_at_20_competitive} scrims_stats={when_ahead_at_20_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@20`} competitive_stats={when_behind_at_20_competitive} scrims_stats={when_behind_at_20_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true}  background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@25`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.gd25).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@25 %`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.ahead25 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@25`} competitive_stats={when_ahead_at_25_competitive} scrims_stats={when_ahead_at_25_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@25`} competitive_stats={when_behind_at_25_competitive} scrims_stats={when_behind_at_25_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true}  background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@30`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.gd30).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@30 %`} competitive_stats={competitive_stats} scrims_stats={scrim_stats} format_stat={(data: any) => (data.ahead30 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@30`} competitive_stats={when_ahead_at_30_competitive} scrims_stats={when_ahead_at_30_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@30`} competitive_stats={when_behind_at_30_competitive} scrims_stats={when_behind_at_30_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#7916c47f'}/>
        </Stack>
    </Stack>
}

function IndividualGdifStats(props: {scrims_date_from:Dayjs, competitive_date_from:Dayjs, player_stats_scrim: any, player_stats_competitive?: any}){
    const [when_ahead_at_8_scrims, setWhenAheadAt8Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_15_scrims, setWhenAheadAt15Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_20_scrims, setWhenAheadAt20Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_25_scrims, setWhenAheadAt25Scrims] = useState(undefined as any|undefined);
    const [when_ahead_at_30_scrims, setWhenAheadAt30Scrims] = useState(undefined as any|undefined);

    const [when_behind_at_8_scrims, setWhenBehindAt8Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_15_scrims, setWhenBehindAt15Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_20_scrims, setWhenBehindAt20Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_25_scrims, setWhenBehindAt25Scrims] = useState(undefined as any|undefined);
    const [when_behind_at_30_scrims, setWhenBehindAt30Scrims] = useState(undefined as any|undefined);

    const [when_ahead_at_8_competitive, setWhenAheadAt8competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_15_competitive, setWhenAheadAt15competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_20_competitive, setWhenAheadAt20competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_25_competitive, setWhenAheadAt25competitive] = useState(undefined as any|undefined);
    const [when_ahead_at_30_competitive, setWhenAheadAt30competitive] = useState(undefined as any|undefined);

    const [when_behind_at_8_competitive, setWhenBehindAt8competitive] = useState(undefined as any|undefined);
    const [when_behind_at_15_competitive, setWhenBehindAt15competitive] = useState(undefined as any|undefined);
    const [when_behind_at_20_competitive, setWhenBehindAt20competitive] = useState(undefined as any|undefined);
    const [when_behind_at_25_competitive, setWhenBehindAt25competitive] = useState(undefined as any|undefined);
    const [when_behind_at_30_competitive, setWhenBehindAt30competitive] = useState(undefined as any|undefined);


    const ctx = useReferentialContext();


    useEffect(() => {
        const scrim_request_params = {
            additional_filters: {date__gte:props.scrims_date_from.toISOString()},
            player: props.player_stats_scrim.player,
            metrics: ['winrate,games'],
            ordering: ['player'],
            groupby: ['player']
        }

        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd8__gte:0}}).then(r => {setWhenAheadAt8Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd15__gte:0}}).then(r => {setWhenAheadAt15Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd20__gte:0}}).then(r => {setWhenAheadAt20Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd25__gte:0}}).then(r => {setWhenAheadAt25Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd30__gte:0}}).then(r => {setWhenAheadAt30Scrims(r[0] ? r[0] : undefined);})

        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd8__lte:0}}).then(r => {setWhenBehindAt8Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd15__lte:0}}).then(r => {setWhenBehindAt15Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd20__lte:0}}).then(r => {setWhenBehindAt20Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd25__lte:0}}).then(r => {setWhenBehindAt25Scrims(r[0] ? r[0] : undefined);})
        ctx.farsightApi.scrimGameSummaries.aggregate({...scrim_request_params, additional_filters:{...scrim_request_params.additional_filters, gd30__lte:0}}).then(r => {setWhenBehindAt30Scrims(r[0] ? r[0] : undefined);})

        if(props.player_stats_competitive){
            const competitive_request_params = {
            additional_filters: {date__gte:props.competitive_date_from.toISOString()},
            player: props.player_stats_competitive.player,
            metrics: ['winrate,games'],
            ordering: ['player'],
            groupby: ['player']
        }

        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd8__gte:0}}).then(r => {setWhenAheadAt8competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd15__gte:0}}).then(r => {setWhenAheadAt15competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd20__gte:0}}).then(r => {setWhenAheadAt20competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd25__gte:0}}).then(r => {setWhenAheadAt25competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd30__gte:0}}).then(r => {setWhenAheadAt30competitive(r[0] ? r[0] : undefined);})

        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd8__lte:0}}).then(r => {setWhenBehindAt8competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd15__lte:0}}).then(r => {setWhenBehindAt15competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd20__lte:0}}).then(r => {setWhenBehindAt20competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd25__lte:0}}).then(r => {setWhenBehindAt25competitive(r[0] ? r[0] : undefined);})
        ctx.farsightApi.competitiveGameSummaries.aggregate({...competitive_request_params, additional_filters:{...competitive_request_params.additional_filters, gd30__lte:0}}).then(r => {setWhenBehindAt30competitive(r[0] ? r[0] : undefined);})
        }


    }, [])

    const column_style = {width:'20%',padding:1, boxSizing:'border-box', height:'90%', alignItems:'center', display:'flex', justifyContent:'center', borderRadius:1}

    return <Stack direction={'row'} sx={{width:'100%', height:'100%', alignItems:'center', display:'flex', justifyContent:'center'}} spacing={1}>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@8`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.gd8).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@8 %`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.ahead8 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@8`} competitive_stats={when_ahead_at_8_competitive} scrims_stats={when_ahead_at_8_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@8`} competitive_stats={when_behind_at_8_competitive} scrims_stats={when_behind_at_8_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@15`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.gd15).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`WR when Ahead@15`} competitive_stats={when_ahead_at_15_competitive} scrims_stats={when_ahead_at_15_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`Ahead@15 %`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.ahead15 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@15`} competitive_stats={when_ahead_at_15_competitive} scrims_stats={when_ahead_at_15_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@15`} competitive_stats={when_behind_at_15_competitive} scrims_stats={when_behind_at_15_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@20`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.gd20).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@20 %`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.ahead20 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@20`} competitive_stats={when_ahead_at_20_competitive} scrims_stats={when_ahead_at_20_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@20`} competitive_stats={when_behind_at_20_competitive} scrims_stats={when_behind_at_20_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true}  background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@25`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.gd25).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@25 %`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.ahead25 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@25`} competitive_stats={when_ahead_at_25_competitive} scrims_stats={when_ahead_at_25_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@25`} competitive_stats={when_behind_at_25_competitive} scrims_stats={when_behind_at_25_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true}  background={'#7916c47f'}/>
        </Stack>
        <Stack direction={'column'} sx={column_style} spacing={1}>
            <MainStatCard title={`GD@30`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.gd30).toFixed(1)} is_delta={true}/>
            <MainStatCard title={`Ahead@30 %`} competitive_stats={props.player_stats_competitive} scrims_stats={props.player_stats_scrim} format_stat={(data: any) => (data.ahead30 * 100).toFixed(1) + '%'}/>
            <MainStatCard title={`WR when Ahead@30`} competitive_stats={when_ahead_at_30_competitive} scrims_stats={when_ahead_at_30_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#008B8B80'}/>
            <MainStatCard title={`WR when Behind@30`} competitive_stats={when_behind_at_30_competitive} scrims_stats={when_behind_at_30_scrims} format_stat={(data: any) => data.winrate !== undefined ? (data.winrate * 100).toFixed(1) + '%' : 'No games'} use_gradient={true} background={'#7916c47f'}/>
        </Stack>
    </Stack>
}