import html2canvas from "html2canvas";
import React, {useState} from "react";
import {Alert, Snackbar, SxProps} from "@mui/material";
import {NeonButton} from "../../NeonComponents/Buttons";


export default function ToPngButton(props: {elementReference: React.MutableRefObject<null>, sx?: SxProps}){
    const [toast, setToast] = useState({
        open: false,
        message: "",
        severity: "success", // Options: success, error, warning, info
      });
    const handleToastClose = () => setToast((prevToast: any) => ({ ...prevToast, open: false }));


    const handleCapture = () => {
        console.log(props.elementReference)
      if (props.elementReference.current) {
          html2canvas(props.elementReference.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: '#000000',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]).then(r => setToast({
                    open: true,
                    message: `Image saved to clipboard`,
                    severity: "success",
                  }));
                // alert('Image copied to clipboard!');
              } catch (err) {
                setToast({
                    open: true,
                    message: `Export to PNG failed`,
                    severity: "error",
                  })
              }
            }, 'image/png');
          })
      }
    }

    return <NeonButton sx={props.sx} variant={'outlined'} onClick={() => handleCapture()}>PNG
        <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleToastClose} severity={toast.severity as any} variant="filled">
          {toast.message}
        </Alert>
      </Snackbar>
    </NeonButton>
}