import { styled } from "@mui/material/styles";
import { Stack, StackProps } from "@mui/material";

export const NeonRow = styled(
  ({
    bottom_glow,
    left_glow,
    zoom_on_hover,
    ...props
  }: StackProps & { bottom_glow?: boolean; left_glow?: boolean; zoom_on_hover?: boolean }) => {
    return (
      <Stack
        {...props}
        sx={{
          "&::after": bottom_glow
            ? {
                content: "''",
                position: "absolute",
                bottom: "0px",
                left: "5%",
                width: "90%",
                height: "1px",
                background: "#ffffff",
                boxShadow: `0px 0px 10px #fff, 0px 0px 20px #fff`,
                opacity: 1,
                pointerEvents: "none",
              }
            : {},
          "&::before": left_glow
            ? {
                content: "''",
                position: "absolute",
                top: "15%",
                bottom: "15%",
                left: "0px",
                width: "1px",
                height: "70%",
                background: "#ffffff",
                boxShadow: `0px 0px 5px #fff, 0px 0px 20px #fff`,
              }
            : {},
          "&:hover": zoom_on_hover
            ? {
                transform: "scale(1.03)",
                transition: "transform 0.3s ease-in-out",
                cursor: "pointer", // Change the cursor to a pointer on hover
              }
            : {},
        }}
      >
        {props.children}
      </Stack>
    );
  }
)(({ theme }) => ({
  position: "relative",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-around",
  borderRadius: 2,
    cursor:'pointer'
}));
