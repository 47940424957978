import React, { useState, ReactNode, useMemo } from "react";
import {Drawer, Stack} from "@mui/material";
import { NeonButton } from "./Buttons";
import { styled } from "@mui/system";

const generateBlurPosition = (seed: string) => {
  const hash = seed.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const randomFactor = Math.random() * 50;
  return {
    top: `${(hash % 50) + randomFactor}px`,
    left: `${(hash % 50) + randomFactor}px`,
  };
};

const DrawerContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  padding:5,
  position: "relative",
  background: theme.palette.background.paper,
  boxShadow: "0px 0px 30px rgba(25, 25, 35, 0.95)",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  "&::after": {
    content: "''",
    position: "absolute",
    right: "0px",
    top: "0px",
    width: "2px",
    height: "100%",
    background: theme.palette.primary.main,
    boxShadow: `0px 0px 10px ${theme.palette.primary.main}, 0px 0px 20px ${theme.palette.primary.main}`,
    opacity: 1,
    pointerEvents: "none",
  },
  "& .neon-glow": {
    position: "absolute",
    width: "250px",
    height: "250px",
    background: theme.palette.primary.main,
    opacity: 0.08,
    filter: "blur(50px)",
    borderRadius: "50%",
    pointerEvents: "none",
    zIndex: 0,
  },
}));

type NeonDrawerProps = {
  children: ReactNode;
  width?: string;
  forceOpen?: boolean;
  buttonLabel: string
};

const NeonDrawer: React.FC<NeonDrawerProps> = ({ children, width = "20vw", forceOpen, buttonLabel}) => {
  const [open, setOpen] = useState(false);
  const blurPosition = useMemo(() => generateBlurPosition("neon-drawer"), []);

  return (
    <>
      <NeonButton sx={{padding:2}} onClick={() => setOpen(true)} variant={'outlined'}>{buttonLabel}</NeonButton>
      <Drawer anchor="left" open={forceOpen ? forceOpen : open} onClose={() => setOpen(false)}
        PaperProps={{ style: { width, minWidth: '500px' } }}>
        <DrawerContainer>
          <div className="neon-glow" style={{ top: blurPosition.top, left: blurPosition.left }} />
          {children}
        </DrawerContainer>
      </Drawer>
    </>
  );
};

export default NeonDrawer;
