import {targets, useReferentialContext} from "../../../Context";
import NeonDrawer from "../../../NeonComponents/NeonDrawer";
import {GamesData, League, ScrimsData, Team} from "../../../farsight-api";
import React, {SetStateAction, useEffect, useState} from "react";
import FilterSet from "../Filterset";
import dayjs from "dayjs";
import {loss_color, scrollable, win_color} from "../../../theme";
import {Stack, Tooltip} from "@mui/material";
import {NeonRow} from "../../../NeonComponents/NeonRow";
import TeamIcon from "../../Icons/TeamIcon";
import Box from "@mui/material/Box";
import ChampionIcon from "../../Icons/ChampionIcon";

export function DrawerGameSelectorScrims(props: {
    selectedGame: ScrimsData|null,
    setSelectedGame: React.Dispatch<SetStateAction<ScrimsData|null>>,
}){
    const context = useReferentialContext();
    const [paramObject, setParamObject] = useState({ordering: '-date'} as any);
    const [team, setTeam] = useState(context.teamsReferential.filter(t => t.team_name === context.user.team)[0] as Team|null);
    const [date_from, set_date_from] = useState(dayjs().subtract(3, "month"))
    const [date_until, set_date_until] = useState(dayjs())
    const [games, setGames] = useState([] as ScrimsData[]);

    useEffect(() => {
        if(paramObject.watermark){
            context.farsightApi.scrimsData.list({...paramObject, ordering:'-date'}).then(r => setGames(r));
        }
    }, [paramObject.watermark, props.selectedGame]);
    const roles = ['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY']

    return <NeonDrawer forceOpen={props.selectedGame === null} buttonLabel={'Change Game'}>
        <Box sx={{height:'300px', mt: '30px'}}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive}
        team={team} setTeam={setTeam} date__gte={date_from} setDateGte={set_date_from} date__lt={date_until} setDateLt={set_date_until}
        direction={'column'}/>
        </Box>

        <Stack direction={'column'}
                sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    padding: 1,
                    ...scrollable
                }}
            >
                        {games.map((game: ScrimsData) => (
                                <NeonRow zoom_on_hover={true} sx={{
                                            backgroundColor: team ?
                                                (game.winner === team.team_name ? win_color  : loss_color) :
                                                (game.winner === game.team1 ? win_color : loss_color),
                                            alignItems: "center",
                                            width: "100%",
                                            display: "flex",
                                            cursor: 'pointer'
                                        }} onClick={(event) => props.setSelectedGame(game)} direction={'row'}
                                         left_glow={props.selectedGame?.game_id === game.game_id}>
                                    <TeamIcon team_name={game.team1}/>
                                    <TeamIcon team_name={game.team2}/>
                                    {game.patch}
                                    <Stack direction={'row'}>
                                    {
                                        team ?
                                            (game.team1 === team.team_name ? game.team1champions.split(',').map((c, index) => <ChampionIcon champion={c} role={roles[index]}/>)
                                                : game.team2champions.split(',').map((c, index) => <ChampionIcon champion={c} role={roles[index]}/>)): undefined
                                    }
                                    </Stack>
                                    </NeonRow>
                        ))}
            </Stack>

    </NeonDrawer>

}
export default function DrawerGameSelectorCompetitive(props: {
    selectedGame: GamesData|null,
    setSelectedGame: React.Dispatch<SetStateAction<GamesData|null>>,
}){
    const context = useReferentialContext();
    const [paramObject, setParamObject] = useState({ordering: '-date'} as any);
    const [team, setTeam] = useState(context.selectedTeam as Team|null);
    const [date_from, set_date_from] = useState(dayjs().subtract(3, "month"))
    const [date_until, set_date_until] = useState(dayjs())
    const [leagues, setLeagues] = useState(context.leaguesReferential.filter(l => l.league_name === 'All Leagues') as League[]);
    const [games, setGames] = useState([] as GamesData[]);


    useEffect(() => {
        if(paramObject.watermark){
            context.farsightApi.gamesData.list({...paramObject, ordering:'-date'}).then(r => setGames(r));
        }
    }, [paramObject.watermark, props.selectedGame]);
    const roles = ['TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY']

    return <NeonDrawer forceOpen={props.selectedGame === null} buttonLabel={'Change Game'}>
        <Box sx={{height:'300px', mt: '30px'}}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive}
        team={team} setTeam={setTeam} date__gte={date_from} setDateGte={set_date_from} date__lt={date_until} setDateLt={set_date_until}
        leagues={leagues} setLeagues={setLeagues} direction={'column'}/>
        </Box>

        <Stack direction={'column'}
                sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    padding: 1,
                    ...scrollable
                }}
            >
                        {games.map((game: GamesData) => (
                                <NeonRow zoom_on_hover={true} sx={{
                                            backgroundColor: team ?
                                                (game.winner === team.team_name ? win_color  : loss_color) :
                                                (game.winner === game.team1 ? win_color : loss_color),
                                            alignItems: "center",
                                            width: "100%",
                                            display: "flex",
                                            cursor: 'pointer'
                                        }} onClick={(event) => props.setSelectedGame(game)} direction={'row'}
                                         left_glow={props.selectedGame?.game_id === game.game_id}>
                                    <TeamIcon team_name={game.team1}/>
                                    <TeamIcon team_name={game.team2}/>
                                    {game.patch}
                                    <Stack direction={'row'}>
                                    {
                                        team ?
                                            (game.team1 === team.team_name ? game.team1champions.split(',').map((c, index) => <ChampionIcon champion={c} role={roles[index]}/>)
                                                : game.team2champions.split(',').map((c, index) => <ChampionIcon champion={c} role={roles[index]}/>)): undefined
                                    }
                                    </Stack>
                                    </NeonRow>
                        ))}
            </Stack>

    </NeonDrawer>
}