import {CompetitiveGameSummaries, MapState, ScrimGameSummaries} from "../../farsight-api";
import React, {useEffect, useRef, useState} from "react";
import {useThemeContext} from "../../themeContext";
import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ImageOverlay, MapContainer} from "react-leaflet";
import {CRS} from "leaflet";
import {InhibitorMarker, TurretMarker} from "./MapStateMarkers/TurretMarker";
import PlayerMarker from "./MapStateMarkers/PlayerMarker";
import WardMarker from "./MapStateMarkers/WardMarker";
import {DragonMarker, RiftHeraldMarker, VoidGrubsMarker} from "./MapStateMarkers/ObjectivesMarker";
import ToPngButton from "../Buttons/ToPngButton";
import SmallDetailButton from "../Buttons/SmallDetailsButton";
import {scrollable} from "../../theme";
import {ArrowDropDown} from "@mui/icons-material";
import {HeatmapLayer} from "react-leaflet-heatmap-layer-v3/lib";
import Slider from "@mui/material/Slider";

export default function SweeperHeatMapRender(props: {
    sweeping_positions: [number, number][],
}){
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState(null as number | null);
    const theme = useThemeContext();
    const [maxGradientPositions, setMaxGradientPositions] = useState<number>(Math.round(props.sweeping_positions.length / 100));
    const [updating, setUpdating] = useState(false);
    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.contentRect) {
                        const size = Math.floor(Math.min(entry.contentRect.width, entry.contentRect.height));
                        setContainerSize(size+1);
                    }
                }
            });
            resizeObserver.observe(containerRef.current);
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

    useEffect(() => {
        if(props.sweeping_positions.length > 0){
            setMaxGradientPositions(Math.round(props.sweeping_positions.length / 100));
        }
    }, [props.sweeping_positions]);

    const map_url = `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${containerSize},w_${containerSize}/leagueAssets/SummonersRift_awfeps.png`;

    return (
        <Stack spacing={1} direction={'column'} sx={{height:'100%', width:'100%', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',}}>
            <Box
                ref={containerRef}
                sx={{
                    width: '100%',
                    height: '100%', justifyContent: 'center', alignItems: 'center'
                }}
            >
                {containerSize ? <MapContainer
                center={[containerSize / 2, containerSize / 2]}
                scrollWheelZoom={false}
                zoom={0}
                dragging={false}
                minZoom={0}
                maxZoom={0}
                attributionControl={false}
                zoomControl={false}
                doubleClickZoom={false}
                crs={CRS.Simple}
                bounds={[
                    [0, 0],
                    [containerSize, containerSize],
                ]}
            >
                <ImageOverlay
                    url={map_url}
                    bounds={[
                        [0, 0],
                        [containerSize, containerSize],
                    ]}
                >
                    {updating ? undefined : <HeatmapLayer
                        points={props.sweeping_positions.map((s_p) => [s_p[0] * containerSize, s_p[1] * containerSize, "test"])}
                        // gradient={{0.2: 'yellow', 0.4: 'orange', 0.6: 'red', 0.8: 'purple'}}
                        intensityExtractor={(m: any) => 1}
                        longitudeExtractor={(m: any) => m[0]}
                        latitudeExtractor={(m: any) => m[1]}
                        radius={30}
                        blur={30}
                        max={maxGradientPositions}
                        minOpacity={0.4}
                    />}

                </ImageOverlay>
                </MapContainer> : <></> }
        </Box>
            <Stack direction={'row'} sx={{width:'100%', justifyContent:'space-around', height:'70px'}}>
                <Stack direction={'row'}>
                    {/*<Typography variant={'h6'}>{`${props.sweeping_positions.length / 1000}`}</Typography>*/}
                    <GradientMaximumSlider value={maxGradientPositions} onChange={(newValue) => {
                        setMaxGradientPositions(newValue);
                        setUpdating(true);
                        setTimeout(() => {
                            setUpdating(false)
                        }, 50);
                    }} maximum={props.sweeping_positions.length} />
                </Stack>
                <Stack direction={'row'}>
                    <ToPngButton elementReference={containerRef}/>
                </Stack>
                    {/*<SmallDetailButton content={*/}
                    {/*    <Box sx={{ maxWidth: 300, whiteSpace: "pre-wrap", fontSize: "0.7rem", maxHeight:'70vh', ...scrollable }}>*/}
                    {/*    {JSON.stringify(map_state, null, 2)}*/}
                    {/*</Box>}>*/}
                    {/*    <ArrowDropDown/>*/}
                    {/*</SmallDetailButton>*/}
                </Stack>
        </Stack>
    )
}


function GradientMaximumSlider(props: { value: number; onChange: (nv: number) => void; maximum: number }) {
    const [value, setValue] = useState(props.value);
    const [isFocused, setIsFocused] = useState(false); // Track focus for styling
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "350px",
                height: "56px",
                borderRadius: 1,
                zIndex: 1000,
                padding: "12px 16px",
                border: isFocused ? "1px solid white" : "1px solid rgba(255, 255, 255, 0.6)",
                "&:hover": { border: "1px solid white" }, // Thicker border on hover
                "&:focus-within": { border: "1px solid white" }, // Thicker border on focus
            }}
            onMouseEnter={() => setIsFocused(true)}
            onMouseLeave={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            {/* Floating Label like MUI TextField */}
            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    top: isFocused || value ? "-10px" : "50%", // Ensure proper centering
                    left: "12px",
                    transform: isFocused || value ? "none" : "translateY(-50%)", // Align properly
                    fontSize: isFocused || value ? "12px" : "16px",
                    color: isFocused ? "white" : "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "#121212", // Matches MUI TextField behavior
                    padding: "0 4px",
                }}
            >
                {`Gradient Maximum : ${value}`}
            </Typography>
            <Slider aria-label="Year"
                    getAriaValueText={(value) => `${value}`}
                    valueLabelDisplay="auto" step={1}
                    marks={false} min={1} max={props.maximum}
                    onChange={handleChange}
                    onChangeCommitted={(event, value) => props.onChange(value as number)} value={value}
            />
        </Box>
    );
}