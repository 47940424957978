import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

export const win_color = "#1C2633";
export const loss_color = "#2B1818";

export const SIDE_COLORS = {
  Red: "#8B0000",  // Dark Red
  Blue: "#1E3A5F", // Deep Navy Blue
} as any;

export const scrollable = {
    overflow:'auto',
    "&::-webkit-scrollbar": {
        width: "2px",
        boxShadow: `
      0px 10px 30px rgba(0, 0, 0, 0.6), 
      0px 4px 15px rgba(0, 0, 0, 0.4), 
      inset 0px 0px 10px rgba(0, 0, 0, 0.3)
    `,
    },
    "&::-webkit-scrollbar-track": {
        background: "#e1e1e1e",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: '#ffffff',

    },
    "&::-webkit-scrollbar-thumb:hover": {
        background: "#ffffff",
    },
};

const getDesignTokens = (mode: PaletteMode) => ({
    // components: {
    //     MuiPaper: {
    //         styleOverrides: {
    //             root: {
    //                 // background: 'rgba(20, 20, 30, 1)', // Remove unwanted MUI styles
    //                 backgroundColor: 'rgba(20, 20, 30, 0.95)', // Apply custom background
    //             },
    //         },
    //     },
    // },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#90caf9',
          },
          secondary: {
            main: '#dc004e',
          },
          background: {
            default: '#ffffff',
            paper: '#f4f6f8',
          },
          text: {
            primary: '#000000',
            secondary: '#555555',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#ffffff',
          },
          secondary: {
            main: '#f48fb1',
          },
          background: {
            default: 'rgba(20, 20, 30, 0.95)',
            paper: "rgba(20, 20, 30, 0.95)",
          },
          text: {
            primary: '#ffffff',
            secondary: '#bbbbbb',
          },
        }),
  },
  typography: {
    fontFamily: "'Poppins', sans-serif", // Replace with your desired font
  },
    components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(20, 20, 30, 0.95)",
        },
        arrow: {
          color: "rgba(20, 20, 30, 0.95)",
        },
      },
    },
  },
});

export const createAppTheme = (mode: PaletteMode) => createTheme(getDesignTokens(mode));

export const getNeonColor = (theme: any) => theme.palette.primary.main;
