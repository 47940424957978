import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import StyledDatagrid, { ColumnTypes } from "../styled_datagrid";
import {DdragonChampion, useReferentialContext} from "../../../Context";
import {Box, Fade} from "@mui/material";

export default function BestBlindCompetitiveGrid(props: { data: any[]; champions_to_beat: DdragonChampion[]; role_to_beat: string }) {
    let grid_columns: GridColDef[] = [
        {
            headerName: "Champion",
            field: "champion",
            width: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${params.value}.png`} />,
        },
        {
            headerName: "Role",
            field: "role",
            minWidth: 100,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                params.value ? <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                    {<Box component={"img"}
                          src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/leagueAssets/${params.value}.png`}/>}
                </Fade> : <></>
            )
        },
    ];
    let champion_columns: GridColDef[][] = props.champions_to_beat.map((champion: DdragonChampion) => {
        return [
            ColumnTypes.short_int(`Games_against_${champion.id}`, "#", {
                cellClassName: "border-left",
            }),
            ColumnTypes.percentage(`Winrate_against_${champion.id}`, "WR"),
            ColumnTypes.rating(`Performance_against_${champion.id}`, "P."),
            ColumnTypes.rating(`Relative_Performance_against_${champion.id}`, "RP."),
        ];
    });
    let champ_columns = champion_columns.flat();

    let total_columns: GridColDef[] = [
        ColumnTypes.short_int(`Total_Games`, "#", {
            cellClassName: "border-left",
        }),
        ColumnTypes.rating("Wheighted_Average_Performance", "P."),
        ColumnTypes.rating("Wheighted_Average_Relative_Performance", "RP."),
        ColumnTypes.percentage("Total_Winrate", "WR."),
    ];
    let ban_columns: GridColDef[][] = Array.from({ length: Math.min(3, props.champions_to_beat.length - 1) }, (v, k) => k + 1).map((i: number) => {
        return [
            {
                headerName: `Ban`,
                field: `Ban number ${i}`,
                width: 50,
                align: "center",
                headerAlign: "center",
                flex: 1,
                renderCell: (params) => <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${params.value}.png`} />,
                cellClassName: "border-left",
            },
            {
                headerName: `#`,
                field: `Games with ${i} bans`,
                width: 50,
                align: "center",
                flex: 1,
                headerAlign: "center",
            },
            {
                field: `Performance with ${i} bans`,
                headerName: `P.`,
                width: 70,
                align: "center",
                flex: 1,
                headerAlign: "center",
                renderCell: (params: any) => Math.round(params.value * 100) / 100,
            },
            {
                field: `Relative Performance with ${i} bans`,
                headerName: `RP.`,
                width: 70,
                align: "center",
                flex: 1,
                headerAlign: "center",
                renderCell: (params: any) => Math.round(params.value * 100) / 100,
            },
            {
                field: `Winrate with ${i} bans`,
                headerName: `WR.`,
                width: 70,
                align: "center",
                flex: 1,
                headerAlign: "center",
                renderCell: (params: any) => {
                    if (params.value)
                        return params.value.toLocaleString("en", {
                            style: "percent",
                        });
                    return "";
                },
            },
        ];
    });

    grid_columns = [...grid_columns, ...champ_columns, ...total_columns, ...ban_columns.flat()];

    let columnGroupingModel = [
        {
            groupId: "Total",
            children: [{ field: "Total_Games" }, { field: "Wheighted_Average_Performance" }, { field: "Wheighted_Average_Relative_Performance" }],
            headerClassName: "header",
        },
    ];
    const championGroupingModel = props.champions_to_beat.map((champion: DdragonChampion) => {
        return {
            groupId: `Vs ${champion.name}`,
            children: [{ field: `Winrate_against_${champion.id}` }, { field: `Performance_against_${champion.id}` }, { field: `Relative_Performance_against_${champion.id}` }, { field: `Games_against_${champion.id}` }],
            headerClassName: "header",
        };
    });
    const bansGroupingModel = Array.from({ length: Math.min(3, props.champions_to_beat.length - 1) }, (v, k) => k + 1).map((i: number) => {
        return {
            groupId: `With ${i} bans`,
            children: [{ field: `Ban number ${i}` }, { field: `Games with ${i} bans` }, { field: `Performance with ${i} bans` }, { field: `Relative Performance with ${i} bans` }, { field: `Winrate with ${i} bans` }],
            headerClassName: "header",
        };
    });
    columnGroupingModel = [...columnGroupingModel, ...championGroupingModel, ...bansGroupingModel];

    return (
        <StyledDatagrid
            rowHeight={60}
            sx={{
                width: "100%",
                "& .header": {
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                },
                "& .border-left": {
                    borderLeft: "solid grey",
                    borderWidth: "2px",
                },
            }}
            getRowId={(row) => props.data.indexOf(row)}
            // initialState={{
            //     sorting: {
            //         sortModel: [{ field: "Wheighted_Average_Performance", sort: "desc" }],
            //     },
            //     filter: {
            //         filterModel: {
            //             items: [{ columnField: 'Total_Games', operatorValue: '>', value: '10' }],
            //         },
            //     },
            // }}
            columns={grid_columns}
            rows={props.data}
            hideFooter={true}
            columnGroupingModel={columnGroupingModel}
            // experimentalFeatures={{ columnGrouping: true }}
        />
    );
}
