import React, { useState } from "react";
import { Box, Tooltip, ClickAwayListener, SxProps } from "@mui/material";
import { NeonButton } from "../../NeonComponents/Buttons";

interface SmallDetailButtonProps {
  children: React.ReactNode;
  content: React.ReactNode;
  sx?: SxProps;
  closeOnOutsideClick?: boolean;
  /**
   * Optional tooltip placement. Defaults to 'bottom'.
   * You can try 'top', 'bottom-start', 'bottom-end', etc.
   */
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

/**
 * A button that toggles a Material UI Tooltip.
 * - The rest of the page remains interactable.
 * - Clicking outside closes the tooltip only if closeOnOutsideClick is true.
 * - Clicking the button again also closes the tooltip if it's open.
 */
const SmallDetailButton = ({
  children,
  content,
  sx,
  closeOnOutsideClick = true,
  placement = "bottom",
}: SmallDetailButtonProps) => {
  const [open, setOpen] = useState(false);

  // Toggle the tooltip by clicking the button.
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  // If user clicks away outside the tooltip, close only if closeOnOutsideClick
  const handleClickAway = () => {
    if (closeOnOutsideClick) {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Tooltip
          open={open}
          title={
            <Box sx={{ p: 1, pointerEvents: "auto",  }}>
              {content}
            </Box>
          }
          arrow
          placement={placement}
          // These disable the "hover/focus/touch" behavior so we manually control open/close.
          disableFocusListener
          disableHoverListener
          disableTouchListener
          // Make the tooltip content clickable:
          componentsProps={{
            tooltip: {
              sx: { pointerEvents: "auto" },
            },
          }}
          // PopperProps let you tweak the position logic, e.g. flipping if needed.
          PopperProps={{
            modifiers: [
              {
                name: "flip",
                enabled: true, // allow Popper to flip the tooltip if there's not enough space
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true, // try to prevent overflow on the altAxis (e.g., top/bottom)
                  // or add rootBoundary, padding, etc. if needed
                },
              },
            ],
          }}
        >
          <NeonButton onClick={handleToggle} sx={sx} variant="outlined">
            {children}
          </NeonButton>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default SmallDetailButton;
