import {Circle, CircleMarker, Marker, Popup} from "react-leaflet";
import { Paper, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { Icon } from "leaflet";
import dayjs from "dayjs";
import StyledPopup from "../StyledPopup";
import AnimatedCircle, { getRandomDuration } from "../Markers";
import ChampionIcon from "../../Icons/ChampionIcon";
import ItemIcon from "../../Icons/ItemIcon";

const iconRed = new Icon({
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
});

const iconBlue = new Icon({
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
});

function getIcon(event: any) {
    console.log(event.side);
    if (event.side === "Blue") {
        return iconBlue;
    }
    return iconRed;
}

interface WardPlacedMarkerProps {
    event: any;
    containerSize: number;
}

export default function WardPlacedMarker({ event, containerSize = 750 }: WardPlacedMarkerProps) {
    console.log(event);
    console.log(event.side);

    if (event.ward_type !== "unknown")
        return (
            <CircleMarker
                key={`${event.player}-${event.timestamp}`}
                center={[event.position[1] * containerSize, event.position[0] * containerSize]}
                radius={10}
                color={event.side} // Ensure event.side is always correct
                // duration={getRandomDuration(0.5, 1.5)}
            >
                <StyledPopup autoPan={false}>
                    <Paper
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            flexDirection: "column",
                            display: "flex",
                            height: 40,
                            zIndex: 1000,
                            m: "-5px",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            component={"div"}
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                display: "flex",
                            }}
                            spacing={1}
                        >
                            <ChampionIcon champion={event.champion} role={'TOP_LANE'} />
                            <ItemIcon item={event.ward_item}/>
                            <Typography
                                align="center"
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                {event.player.split(" (")[0] + "\n" + dayjs.duration(event["timestamp"], "seconds").format("m:ss")}
                            </Typography>
                        </Stack>
                    </Paper>
                </StyledPopup>
            </CircleMarker>
        );
    else return null;
}
