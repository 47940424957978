import React, {useEffect, useRef, useState} from "react";
import { ImageOverlay, MapContainer, Popup, TileLayer, Marker, Rectangle, Circle } from "react-leaflet";
import { Icon, CRS } from "leaflet";
import Button from "@mui/material/Button";
import 'leaflet/dist/leaflet.css';
import "./Map.css";
import Box from "@mui/material/Box";
import WardPlacedMarker from "./map_icons/ward_placed";
import CampClearedMarker from "./map_icons/killed_camp";
import KillMarker from "./map_icons/kill";
import AnimatedCircle, { getRandomDuration } from "./Markers";
import html2canvas from "html2canvas";
import {useThemeContext} from "../../themeContext";

var summoners_rift = "https://static.wikia.nocookie.net/leagueoflegends/images/0/04/Summoner%27s_Rift_Minimap.png/revision/latest";

interface MapDrawerParams {
    points: any[];
    positions?: any[];
    wards?: any[];
}
function renderSwitch(row: any, containerSize: number) {
    switch (row.type) {
        case "WARD_PLACED":
            return <WardPlacedMarker  event={row} containerSize={containerSize} />
        case "KILLED_ANCIENT":
            return CampClearedMarker(row);
        case "KILL":
            return KillMarker(row);
        case "ASSIST":
            return KillMarker(row);
        case "DEATH":
            return KillMarker(row);
        default:
            return <></>;
    }
}

function renderPosition(state: any, containerSize: number){
    return <Marker
        position={[state.position[1] * containerSize, state.position[0] * containerSize]}
        icon={
            new Icon({
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                iconUrl: `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${state.champion}.png`
            })
        }
        draggable={false}
    >
    </Marker>
}

function renderRectangle(row: any, containerSize: number) {
    switch (row["type"]) {
        case "KILLED_ANCIENT":
            return (
                <Rectangle
                    bounds={[
                        [row["position"][1] * containerSize - 22, row["position"][0] * 750 - 22],
                        [row["position"][1] * containerSize + 22, row["position"][0] * 750 + 22],
                    ]}
                    color={row["side"]}
                ></Rectangle>
            );
        case "KILL_HITMAP":
            return <AnimatedCircle center={[row["position"][1] * containerSize, row["position"][0] * containerSize]} radius={10} color={row["side"] === "blue" ? "#5A4D5A" : "#2B1818"} duration={getRandomDuration(0.5, 2)} />;
        default:
            return <></>;
    }
}

export default function MapDrawer(props: MapDrawerParams) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = useState(null as number | null); // Default to 750x750
    const theme = useThemeContext();
    const handleCapture = () => {
      if (containerRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary


          // @ts-ignore
          html2canvas(chartRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: theme.mode === 'dark' ? '#000000' : '#F7F4EF',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.contentRect) {
                        const size = Math.floor(Math.min(entry.contentRect.width, entry.contentRect.height));
                        setContainerSize(size+1);
                    }
                }
            });

            resizeObserver.observe(containerRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

    const map_url = `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${containerSize},w_${containerSize}/leagueAssets/SummonersRift_awfeps.png`;


    return (
            <Box
                ref={containerRef}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {containerSize ? <MapContainer
                center={[containerSize / 2, containerSize / 2]}
                scrollWheelZoom={false}
                zoom={0}
                dragging={false}
                minZoom={0}
                maxZoom={0}
                attributionControl={false}
                zoomControl={false}
                doubleClickZoom={false}
                crs={CRS.Simple}
                bounds={[
                    [0, 0],
                    [containerSize, containerSize],
                ]}
            >
                <ImageOverlay
                    url={map_url}
                    bounds={[
                        [0, 0],
                        [containerSize, containerSize],
                    ]}
                />
                    {props.points.map((row) => renderSwitch(row, containerSize))}
                    {props.points.map((row) => renderRectangle(row, containerSize))}
                    {props.positions ? props.positions.map((pos) => renderPosition(pos, containerSize)) : <></>}
                    {props.wards?.map((w, index) => (
    <WardPlacedMarker key={index} event={w} containerSize={containerSize} />
))}
                </MapContainer> : <></> }
        </Box>
    );
}
