import React, {useEffect, useRef, useState} from "react";
import {Box, Grow, lighten, Stack} from "@mui/material";
import StyledDatagrid, { ColumnTypes, field_name_to_column } from "../styled_datagrid";
import {
    GridColDef,
    GridColumnGroupingModel,
    gridFilteredSortedRowIdsSelector, GridFooterContainer,
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    gridPaginatedVisibleSortedGridRowEntriesSelector, gridPaginatedVisibleSortedGridRowIdsSelector, GridPagination,
    GridSlotsComponentsProps,
    GridToolbar, GridToolbarExport,
    useGridApiContext, useGridApiRef,
    useGridSelector
} from "@mui/x-data-grid";
import { GameMetricsApi, GameMetrics } from "../../../farsight-api";
import { GridRowClassNameParams } from "@mui/x-data-grid/models/params";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import {useThemeContext} from "../../../themeContext";
import {loss_color, win_color} from "../../../theme";

export enum rowStyle {
    none,
    outcome,
    prediction,
    win
}

const styleToComponent = {
    [rowStyle.none]: (params: GridRowClassNameParams) => "None",
    [rowStyle.outcome]: (params: GridRowClassNameParams) => {
        return `rowStyle--${params.row.winner === params.row.team1 ? "Win" : "Loss"}`;
    },
    [rowStyle.prediction]: (params: GridRowClassNameParams) => {
        return `rowStyle--${params.row.winner === params.row.drafting_sim_predicted_winner ? "Win" : "Loss"}`;
    },
    [rowStyle.win]: (params: GridRowClassNameParams) => {
        return `rowStyle--${params.row.win? "Win" : "Loss"}`;
    },
};

function getColumnsFromProperties(display_all: boolean, metrics?: GameMetrics[], disp_columns?: string[], result?: any[]) {
    let new_columns: GridColDef[] =
        display_all && result !== undefined && result.length > 0
            ? Object.entries(result[0])
                  .map(([key, value]) => field_name_to_column[key])
                  .filter((col) => col !== undefined && display_all)
            : (disp_columns ? disp_columns : []).map((col) => {
                  const existing_definition = field_name_to_column[col];
                  return existing_definition ? existing_definition : ColumnTypes.string(col, col);
              });
    if (!display_all && metrics !== undefined && metrics.length > 0) {
        const metrics_columns = metrics
            ? (metrics.map((metric: GameMetrics) => field_name_to_column[metric.code] !== undefined && metric.type === 'string' ? field_name_to_column[metric.code] :
                  ColumnTypes[metric.type ? metric.type : 'string'](metric.code, metric.short_name, {
                      description: metric.desc,
                  }),
              ) as GridColDef [])
            : ([] as GridColDef []);
        new_columns = [...new_columns, ...metrics_columns];
        // console.log(new_columns)
    }
    return new_columns;
}

export default function TableDisplayGrid(props: {
    request: () => any;
    display_all: boolean;
    disp_columns?: string[];
    custom_columns?: GridColDef [];
    metrics?: GameMetrics[];
    rowStyle: rowStyle;
    sortBy: string;
    paramObject: any;
    hideFooter?: boolean;
    grouping_model?: GridColumnGroupingModel;
    groupByMetricsGroup?: boolean
}) {
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const gridRef = useRef(null);
    const datagridref = useGridApiRef();
    const [hideFooter, setHideFooter] = useState(props.hideFooter);
    const theme = useThemeContext();

    // console.log(props.disp_columns);
    const [columns, setColumns] = useState<GridColDef []>(getColumnsFromProperties(props.display_all, props.metrics, props.disp_columns));

    const initialState = {
        sorting: {
            sortModel: [{ field: props.sortBy, sort: "desc" }],
        },
    } as GridInitialStateCommunity;
    const grouping_model = props.grouping_model ?? undefined;

    // if(props.groupByMetricsGroup && !grouping_model){
    //     grouping_model = [
    //     {
    //         groupId: 'Stats of ' + props.paramObject.champion,
    //         children: [{ field: "champion" }, ...props.metrics.map(m => {
    //             return {
    //                 field: m.code
    //             }})],
    //         headerClassName: "header",
    //     },
    //     {
    //         groupId: 'Stats of ' + props.type,
    //         children: props.metrics.map(m => {
    //             return {
    //                 field: `${props.type}_${m.code}`
    //             }}),
    //         headerClassName: "header",
    //     },
    // ];
    // }

    useEffect(() => {
        setLoading(true);
        // setRows([]);
        props.request().then((result: any[]) => {
            if (result) {
                const new_columns = getColumnsFromProperties(props.display_all, props.metrics, props.disp_columns, result);
                setColumns(new_columns);
                setLoading(false);
                setRows(result);
                console.log(result);
            } else {
                setLoading(false);
                setRows([]);
            }
        });
    }, [props.paramObject.watermark, props.metrics]);

    const handleCapture = () => {
      // setHideFooter(true);
      if (gridRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary

          const rows_in_tab = gridFilteredSortedRowIdsSelector(datagridref).length
          const rows_displayed = gridPageSizeSelector(datagridref);
          const rows_to_shot = rows_in_tab > rows_displayed ? rows_displayed : rows_in_tab

          // @ts-ignore
          html2canvas(gridRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: theme.mode === 'light' ? '#F7F4EF' : '#000000',
              height: datagridref.current ? (50* rows_to_shot ) + 60 : 100
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                alert('Copied image to clipboard')
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error(err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
            // setHideFooter(false);
          })

      }
    }


    return (
        // <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    "& .border-right": {
                        borderRight: "solid grey",
                        borderWidth: "2px",
                    },
                }}
                ref={gridRef}
            >
                {/*@ts-ignore*/}
                <StyledDatagrid
                    apiRef={datagridref}
                    autoPageSize
                    rowHeight={50}
                    getRowId={(row) => rows.indexOf(row)}
                    initialState={initialState}
                    // experimentalFeatures={{ columnGrouping: true }}
                    columnGroupingModel={props.grouping_model}
                    sx={{
                        width: "100%",
                        height:'100%',
                        "& .rowStyle--Win": {
                            backgroundColor: win_color,
                            opacity: 0.8,
                            "&:hover": {
                                backgroundColor: lighten(win_color, 0.1),
                            },
                            "&.Mui-selected": {
                                backgroundColor: lighten(win_color, 0.1),
                                "&:hover": {
                                    backgroundColor: lighten(win_color, 0.1),
                                },
                            },
                        },
                        "& .rowStyle--Loss": {
                            backgroundColor: loss_color,
                            opacity: 0.8,
                            "&:hover": {
                                backgroundColor: lighten(loss_color, 0.1),
                            },
                            "&.Mui-selected": {
                                backgroundColor: lighten(loss_color, 0.1),
                                "&:hover": {
                                    backgroundColor: lighten(loss_color, 0.1),
                                },
                            },
                        },
                    }}
                    columns={[...columns, ...(props.custom_columns ? props.custom_columns : [])]}
                    rows={rows}
                    hideFooter={hideFooter}
                    loading={loading}
                    // rowBuffer={3}
                    pagination
                    getRowClassName={styleToComponent[props.rowStyle]}
                    slots={{
                        footer: FooterExporter as any,
                    }}
                    slotProps={{
                        footer: {
                            data: rows,
                            columns: props.rowStyle === rowStyle.win ? [...columns, ColumnTypes.win('win','Win')] : columns,
                            handleCapture: handleCapture
                        } as any,
                    }}
                />
            </Box>
        // </Grow>
    );
}

export function FooterExporter(props: {data: any[], columns: GridColDef[], handleCapture: any}) {
    const shit_thing = {sx: {overflow:'wrap'}} as any;
    return (
        <GridFooterContainer sx={{ alignItems: "space-between" }}>
            <Stack direction={"row"} spacing={1}>
                {/*<GridToolbarExport*/}
                {/*    csvOptions={{*/}
                {/*        fileName: "Shortlist stats",*/}
                {/*    }}*/}
                {/*/>*/}
                <Button onClick={() => clipboard_data_excel(props.data, props.columns)}>Excel</Button>
                <Button onClick={() => props.handleCapture()}>PNG</Button>
            </Stack>
            <Box sx={{width:'100%', overflow:'wrap', display:'flex', alignItems:'right', justifyContent:'right'}}>
                <GridPagination {...shit_thing} />
            </Box>
        </GridFooterContainer>
    );
}

function clipboard_data_excel(data: any[], columns: GridColDef[]){
    console.log(columns)
    let to_ret = [
        columns.map(c => c.headerName).join('\t')
    ]
    let data_fields = data.map(e => {
            let row_values = [] as string[];
            for(const i in columns){
                row_values.push(e[columns[i].field] ? e[columns[i].field].toString() : null);
            }
            return row_values.join('\t')
        })
    to_ret = to_ret.concat(data_fields);
    console.log(to_ret)
    navigator.clipboard.writeText(to_ret.join("\n"));
}