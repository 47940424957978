import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import {
    Avatar,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    Paper,
    Stack,
    styled,
    Switch,
    Typography,
    useTheme
} from "@mui/material";
import ProfileButton from "./ProfileButton";
import MenuItem from "@mui/material/MenuItem";
import {
    ArrowDropDown, Assessment, CalendarMonth, Checklist, Construction, CurrencyExchange, Details,
    EmojiEvents, FindInPage, Lightbulb, LocationOn,
    Logout,
    Loop, Newspaper, Person,
    PersonAdd, PersonSearch, Podcasts, RoundaboutLeft, Search,
    Settings,
    ShowChart, Summarize, Visibility
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import GameReviewer from "../../page/Shared/ReviewGame";
import DarkModeToggle from "../selectors/DarkModeSwitch";
import {useThemeContext} from "../../themeContext";
import MapIcon from '@mui/icons-material/Map';
import TeamIcon from "../Icons/TeamIcon";
import {useReferentialContext} from "../../Context";
import {getNeonColor} from "../../theme";
import {NeonAppBar} from "../../NeonComponents/NeonAppBar";
import SearchEverything from "../selectors/SearchEverything";

const BoldButton = styled(Button)(({ theme }) => ({
    fontWeight:'bold'
}));

export default function Header(props: { changePage: any; current: number; logout: () => void}) {
    const [menuOpen, setMenuOpen] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const context = useReferentialContext();
    const handleClickCompetitive = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "competitive");
    };
    const handleClickSoloQ = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "soloq");
    };
    const handleClickChampionsQ = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "championsq");
    };

    const handleClickOffseason = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "offseason");
    };
    const handleClickScrim = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(menuOpen ? null : "scrims");
    };
    const handleClose: any = () => {
        setAnchorEl(null);
        setMenuOpen(null);
    };

    const wrapChangePage = (newValue: number) => {
        props.changePage(undefined, newValue);
        handleClose();
    };
    const theme = useTheme();
    // onClick={event => props.changePage(event, 0)}
    return (
        <NeonAppBar position="relative" sx={{ height: "10%"}}>
            <Toolbar sx={{ width: "100%" }}>
                <Stack
                    direction={"row"}
                    sx={{
                        position: "left",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems:'center'
                    }}
                    spacing={1}
                >
                    <ProfileButton logout={props.logout} />
                    <TeamIcon team_name={context.user.team} sx={{ width: 32, height: 32 }}/>
                    <Button sx={{fontWeight:'bold'}}  onClick={(event) => handleClickCompetitive(event)} aria-controls={menuOpen === "competitive" ? "competitive-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "competitive" ? "true" : undefined}>
                        <Stack direction={'row'}>
                            <EmojiEvents sx={{mr:1}}/>
                            Competitive
                            <ArrowDropDown/>
                        </Stack>
                    </Button>
                    <Button sx={{fontWeight:'bold'}} onClick={(event) => handleClickSoloQ(event)} aria-controls={menuOpen === "soloq" ? "soloq-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "soloq" ? "true" : undefined}>
                        <Loop sx={{mr:1}}/>
                        SoloQ
                        <ArrowDropDown/>
                    </Button>
                    <Button sx={{fontWeight:'bold'}} onClick={(event) => handleClickScrim(event)} aria-controls={menuOpen === "scrims" ? "scrims-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "scrims" ? "true" : undefined}>
                        <CalendarMonth sx={{mr:1}}/>
                        Scrims
                        <ArrowDropDown/>
                    </Button>
                    <Button sx={{fontWeight:'bold'}} onClick={(event) => handleClickChampionsQ(event)} aria-controls={menuOpen === "championsq" ? "championsq-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "championsq" ? "true" : undefined}>
                        ChampionsQ
                        <ArrowDropDown/>
                    </Button>
                    <Button  sx={{fontWeight:'bold'}} onClick={(event) => handleClickOffseason(event)} aria-controls={menuOpen === "offseason" ? "offseason-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen === "offseason" ? "true" : undefined}>
                        <ShowChart sx={{mr:1}}/>
                        Offseason
                        <ArrowDropDown/>
                    </Button>
                </Stack>
                <Stack direction={"row"} sx={{ position: "right", justifyContent: "flex-end", alignItems:'center' }}>
                    <SearchEverything/>
                    {/*<DarkModeToggle />*/}
                </Stack>
                <Menu anchorEl={anchorEl} id="competitive-menu" open={menuOpen === "competitive"} onClick={handleClose}>
                    {/*<Paper*/}
                    {/*    sx={{*/}
                    {/*        height:'100%',*/}
                    {/*        width: "800px",*/}
                    {/*        zIndex: 289,*/}
                    {/*        opacity: 1,*/}
                    {/*        position: "relative",*/}
                    {/*    }}*/}
                    {/*>*/}
                        <Stack direction={"column"} sx={{ width: "800px" }}>
                            <Divider textAlign="left">
                                <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                    <Assessment sx={{mr:1}}/>
                                    Metagame
                                </Stack>
                            </Divider>
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                            <Stack direction={"column"} sx={{ width: "25%"}}>
                                <Divider textAlign="center">
                                    <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                        <PersonSearch sx={{mr:1}}/>
                                        Champions
                                    </Stack>
                                </Divider>
                                <BoldButton onClick={() => wrapChangePage(0)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}><Checklist sx={{mr:1}}/>Tierlists</BoldButton>
                                <BoldButton onClick={() => wrapChangePage(1)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}><Details sx={{mr:1}}/>Details</BoldButton>
                            </Stack>
                            <Stack direction={"column"} sx={{ width: "25%"}}>
                                <Divider textAlign="center">
                                    <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                        <Search sx={{mr:1}}/>
                                        Patterns
                                    </Stack>
                                </Divider>
                                <BoldButton onClick={() => wrapChangePage(46)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}><Person sx={{mr:1}}/>Champion</BoldButton>
                                <BoldButton onClick={() => wrapChangePage(47)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}>Draft</BoldButton>
                            </Stack>
                            <Stack direction={"column"} sx={{ width: "25%"}}>
                                <Divider textAlign="center">
                                    <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                        <Summarize sx={{mr:1}}/>
                                        Drafts
                                    </Stack>
                                </Divider>
                                <BoldButton onClick={() => wrapChangePage(2)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}>Overview</BoldButton>
                                <BoldButton onClick={() => wrapChangePage(16)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}>Sim</BoldButton>
                                <BoldButton onClick={() => wrapChangePage(45)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}>Sim Perfs</BoldButton>
                            </Stack>
                                <Stack direction={"column"} sx={{ width: "25%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <Checklist sx={{mr:1}}/>
                                            Algorithms
                                        </Stack>
                                    </Divider>
                                    <BoldButton onClick={() => wrapChangePage(3)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}>Botlane Matchups</BoldButton>
                                    <BoldButton disabled={true} onClick={() => wrapChangePage(4)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}>Meta Breakers</BoldButton>
                                    <BoldButton onClick={() => wrapChangePage(8)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}>Best Blinds</BoldButton>
                                </Stack>
                            </Stack>
                            <Divider textAlign="left">
                                <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                    <FindInPage sx={{mr:1}}/>
                                    Scouting
                                </Stack>
                            </Divider>
                            <Stack direction={"row"} sx={{ width: "100%", align:'center',display:'flex', justify:'center' }}>
                                <Stack direction={"column"} sx={{ width: "33%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <Construction sx={{mr:1}}/>
                                            Tools
                                        </Stack>
                                    </Divider>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(14)}>Scouting Summary</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(10)}>Team History</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(15)}>Drafts</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(48)}>Draft Preparation</BoldButton>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "33%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <MapIcon sx={{mr:1}}/>
                                            Maps/Graphs
                                        </Stack>
                                    </Divider>
                                    <BoldButton onClick={() => wrapChangePage(11)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}><Lightbulb sx={{mr:1}}/>Ward Maps</BoldButton>
                                    <BoldButton onClick={() => wrapChangePage(12)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}><RoundaboutLeft sx={{mr:1}}/>Jungle Pathings</BoldButton>
                                    <BoldButton onClick={() => wrapChangePage(54)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}><LocationOn sx={{mr:1}}/>Position Heatmap</BoldButton>
                                    <BoldButton onClick={() => wrapChangePage(41)} sx={{justifyContent:'left', display:'flex', alignItems:'left'}}><CurrencyExchange sx={{mr:1}}/>Gold Graphs</BoldButton>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "33%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <Visibility sx={{mr:1}}/>
                                            Reviews
                                        </Stack>
                                    </Divider>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(52)}>Gameplay Elements</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(55)}>Teamfights</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(59)}>Reviewer</BoldButton>                                </Stack>
                                <Stack direction={"column"} sx={{ width: "33%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <PersonSearch sx={{mr:1}}/>
                                            Players
                                        </Stack>
                                    </Divider>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(6)}>Players Tierlists</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(5)}>Player Details</BoldButton>
                                </Stack>
                            </Stack>
                            <Divider textAlign="left">
                                <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                    <Podcasts sx={{mr:1}}/>
                                    Content
                                </Stack>
                            </Divider>
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "33%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <Newspaper sx={{mr:1}}/>
                                            News
                                        </Stack>
                                    </Divider>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(30)}>League History</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(40)}>Team stats</BoldButton>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "33%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <MapIcon sx={{mr:1}}/>
                                            Maps
                                        </Stack>
                                    </Divider>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(9)}>Kills Hitmap</BoldButton>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "33%"}}>
                                    <Divider textAlign="center">
                                        <Stack direction={'row'} sx={{justify:'center',align:'center',display:'flex'}}>
                                            <Checklist sx={{mr:1}}/>
                                            Stat Assets
                                        </Stack>
                                    </Divider>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(23)}>Stats Vs</BoldButton>
                                    <BoldButton sx={{justifyContent:'left', display:'flex', alignItems:'left'}} onClick={() => wrapChangePage(39)}>Stats H2H</BoldButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    {/*</Paper>*/}
                </Menu>
                <Menu anchorEl={anchorEl} id="soloq-menu" open={menuOpen === "soloq"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Metagame
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(17)}>Champions Tierlists</Button>
                                    <Button onClick={() => wrapChangePage(18)}>Champions Details</Button>
                                    <Button onClick={() => wrapChangePage(37)}>League Champions</Button>
                                    <Button onClick={() => wrapChangePage(38)}>1st Items</Button>
                                    <Button onClick={() => wrapChangePage(49)}>Ratings Evolution</Button>
                                    {/*<Button onClick={() => wrapChangePage(2)}>Drafting</Button>*/}
                                    {/*<Button onClick={() => wrapChangePage(3)}>Botlane Matchups</Button>*/}
                                    {/*<Button onClick={() => wrapChangePage(4)}>Meta Breakers</Button>*/}
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Scouting
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(19)}>Player Summary</Button>
                                    <Button onClick={() => wrapChangePage(7)}>Player Details</Button>
                                    <Button onClick={() => wrapChangePage(20)}>Player Monitoring</Button>
                                    <Button onClick={() => wrapChangePage(50)}>League Players</Button>
                                    <Button onClick={() => wrapChangePage(53)}>Team Summaries</Button>
                                </Stack>

                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Players
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(21)}>Players Tierlists</Button>
                                    {/*<Button onClick={() => wrapChangePage(5)}>Player Details</Button>*/}
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Monitoring
                                    </Paper>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
                <Menu anchorEl={anchorEl} id="offseason-menu" open={menuOpen === "offseason"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Specific Analysis
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(22)}>Specific player</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Suggestions
                                    </Paper>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Shortlists
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(26)}>Saved Shortlists</Button>
                                    <Button onClick={() => wrapChangePage(28)}>Shortlist Stats</Button>
                                </Stack>
                                {/*<Stack direction={'column'} sx={{width:'100%'}}>*/}
                                {/*    <Paper sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>Monitoring</Paper>*/}
                                {/*</Stack>*/}
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
                <Menu anchorEl={anchorEl} id="scrims-menu" open={menuOpen === "scrims"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Planning
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(24)}>Calendar</Button>
                                    <Button onClick={() => wrapChangePage(27)}>Opponents</Button>
                                    <Button onClick={() => wrapChangePage(32)}>History</Button>
                                    <Button onClick={() => wrapChangePage(43)}>Dashboard</Button>
                                    <Button onClick={() => wrapChangePage(51)}>Drafts</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Metagame
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(29)}>Champions Ratings</Button>
                                    <Button onClick={() => wrapChangePage(25)}>Champions Details</Button>
                                    <Button onClick={() => wrapChangePage(34)}>Team Champions</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Monitoring
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(31)}>Player Details</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Gameplay
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(33)}>Review Game</Button>
                                    <Button onClick={() => wrapChangePage(36)}>Hitmap Kills</Button>
                                    <Button onClick={() => wrapChangePage(42)}>Jungle Pathings</Button>
                                    <Button onClick={() => wrapChangePage(44)}>Ward Maps</Button>

                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
                <Menu anchorEl={anchorEl} id="championsq-menu" open={menuOpen === "championsq"} onClick={handleClose}>
                    <Grid
                        container
                        sx={{
                            width: "800px",
                            zIndex: 289,
                            opacity: 1,
                            position: "relative",
                        }}
                    >
                        <Paper
                            sx={{
                                width: "800px",
                                zIndex: 289,
                                opacity: 1,
                                position: "relative",
                            }}
                        >
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Metagame
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(57)}>Champion Ratings</Button>
                                    <Button onClick={() => wrapChangePage(35)}>Champions Details</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Players
                                    </Paper>
                                    <Button onClick={() => wrapChangePage(58)}>Player Details</Button>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Monitoring
                                    </Paper>
                                </Stack>
                                <Stack direction={"column"} sx={{ width: "100%" }}>
                                    <Paper
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        Gameplay
                                    </Paper>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Menu>
            </Toolbar>
        </NeonAppBar>
    );
}
