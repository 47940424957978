/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    Champion,
    ChampionAggregate,
} from "../models/index";

export interface ChampionAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    bot_lane_proportion_competitive?: number;
    bot_lane_proportion_soloq?: number;
    damage_type?: string;
    having?: object;
    id?: string;
    index_on?: string;
    jungle_proportion_competitive?: number;
    jungle_proportion_soloq?: number;
    main_role?: string;
    metrics?: Array<string>;
    mid_lane_proportion_competitive?: number;
    mid_lane_proportion_soloq?: number;
    name?: string;
    secondary_role?: string;
    top_lane_proportion_competitive?: number;
    top_lane_proportion_soloq?: number;
    utility_proportion_competitive?: number;
    utility_proportion_soloq?: number;
}

export interface ChampionFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    bot_lane_proportion_competitive?: number;
    bot_lane_proportion_soloq?: number;
    damage_type?: string;
    id?: string;
    jungle_proportion_competitive?: number;
    jungle_proportion_soloq?: number;
    main_role?: string;
    mid_lane_proportion_competitive?: number;
    mid_lane_proportion_soloq?: number;
    name?: string;
    secondary_role?: string;
    top_lane_proportion_competitive?: number;
    top_lane_proportion_soloq?: number;
    utility_proportion_competitive?: number;
    utility_proportion_soloq?: number;
}

export interface ChampionGetRequest {
    id: string;
}

export interface ChampionListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    bot_lane_proportion_competitive?: number;
    bot_lane_proportion_soloq?: number;
    damage_type?: string;
    id?: string;
    jungle_proportion_competitive?: number;
    jungle_proportion_soloq?: number;
    main_role?: string;
    mid_lane_proportion_competitive?: number;
    mid_lane_proportion_soloq?: number;
    name?: string;
    ordering?: Array<string>;
    secondary_role?: string;
    top_lane_proportion_competitive?: number;
    top_lane_proportion_soloq?: number;
    utility_proportion_competitive?: number;
    utility_proportion_soloq?: number;
}

export interface ChampionVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    bot_lane_proportion_competitive?: number;
    bot_lane_proportion_soloq?: number;
    damage_type?: string;
    id?: string;
    jungle_proportion_competitive?: number;
    jungle_proportion_soloq?: number;
    main_role?: string;
    mid_lane_proportion_competitive?: number;
    mid_lane_proportion_soloq?: number;
    name?: string;
    secondary_role?: string;
    top_lane_proportion_competitive?: number;
    top_lane_proportion_soloq?: number;
    utility_proportion_competitive?: number;
    utility_proportion_soloq?: number;
}

/**
 *
 */
export class ChampionApi extends runtime.BaseAPI {
    /**
     */
    async championAggregateRaw(
        requestParameters: ChampionAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ChampionAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling championAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling championAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["bot_lane_proportion_competitive"] != null) {
            queryParameters["bot_lane_proportion_competitive"] =
                requestParameters["bot_lane_proportion_competitive"];
        }

        if (requestParameters["bot_lane_proportion_soloq"] != null) {
            queryParameters["bot_lane_proportion_soloq"] =
                requestParameters["bot_lane_proportion_soloq"];
        }

        if (requestParameters["damage_type"] != null) {
            queryParameters["damage_type"] = requestParameters["damage_type"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["jungle_proportion_competitive"] != null) {
            queryParameters["jungle_proportion_competitive"] =
                requestParameters["jungle_proportion_competitive"];
        }

        if (requestParameters["jungle_proportion_soloq"] != null) {
            queryParameters["jungle_proportion_soloq"] =
                requestParameters["jungle_proportion_soloq"];
        }

        if (requestParameters["main_role"] != null) {
            queryParameters["main_role"] = requestParameters["main_role"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["mid_lane_proportion_competitive"] != null) {
            queryParameters["mid_lane_proportion_competitive"] =
                requestParameters["mid_lane_proportion_competitive"];
        }

        if (requestParameters["mid_lane_proportion_soloq"] != null) {
            queryParameters["mid_lane_proportion_soloq"] =
                requestParameters["mid_lane_proportion_soloq"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["secondary_role"] != null) {
            queryParameters["secondary_role"] =
                requestParameters["secondary_role"];
        }

        if (requestParameters["top_lane_proportion_competitive"] != null) {
            queryParameters["top_lane_proportion_competitive"] =
                requestParameters["top_lane_proportion_competitive"];
        }

        if (requestParameters["top_lane_proportion_soloq"] != null) {
            queryParameters["top_lane_proportion_soloq"] =
                requestParameters["top_lane_proportion_soloq"];
        }

        if (requestParameters["utility_proportion_competitive"] != null) {
            queryParameters["utility_proportion_competitive"] =
                requestParameters["utility_proportion_competitive"];
        }

        if (requestParameters["utility_proportion_soloq"] != null) {
            queryParameters["utility_proportion_soloq"] =
                requestParameters["utility_proportion_soloq"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Champion/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: ChampionAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ChampionAggregate>> {
        const response = await this.championAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async championFieldValuesRaw(
        requestParameters: ChampionFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling championFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling championFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bot_lane_proportion_competitive"] != null) {
            queryParameters["bot_lane_proportion_competitive"] =
                requestParameters["bot_lane_proportion_competitive"];
        }

        if (requestParameters["bot_lane_proportion_soloq"] != null) {
            queryParameters["bot_lane_proportion_soloq"] =
                requestParameters["bot_lane_proportion_soloq"];
        }

        if (requestParameters["damage_type"] != null) {
            queryParameters["damage_type"] = requestParameters["damage_type"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["jungle_proportion_competitive"] != null) {
            queryParameters["jungle_proportion_competitive"] =
                requestParameters["jungle_proportion_competitive"];
        }

        if (requestParameters["jungle_proportion_soloq"] != null) {
            queryParameters["jungle_proportion_soloq"] =
                requestParameters["jungle_proportion_soloq"];
        }

        if (requestParameters["main_role"] != null) {
            queryParameters["main_role"] = requestParameters["main_role"];
        }

        if (requestParameters["mid_lane_proportion_competitive"] != null) {
            queryParameters["mid_lane_proportion_competitive"] =
                requestParameters["mid_lane_proportion_competitive"];
        }

        if (requestParameters["mid_lane_proportion_soloq"] != null) {
            queryParameters["mid_lane_proportion_soloq"] =
                requestParameters["mid_lane_proportion_soloq"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["secondary_role"] != null) {
            queryParameters["secondary_role"] =
                requestParameters["secondary_role"];
        }

        if (requestParameters["top_lane_proportion_competitive"] != null) {
            queryParameters["top_lane_proportion_competitive"] =
                requestParameters["top_lane_proportion_competitive"];
        }

        if (requestParameters["top_lane_proportion_soloq"] != null) {
            queryParameters["top_lane_proportion_soloq"] =
                requestParameters["top_lane_proportion_soloq"];
        }

        if (requestParameters["utility_proportion_competitive"] != null) {
            queryParameters["utility_proportion_competitive"] =
                requestParameters["utility_proportion_competitive"];
        }

        if (requestParameters["utility_proportion_soloq"] != null) {
            queryParameters["utility_proportion_soloq"] =
                requestParameters["utility_proportion_soloq"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Champion/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: ChampionFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.championFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async championGetRaw(
        requestParameters: ChampionGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Champion>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling championGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Champion/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: ChampionGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Champion> {
        const response = await this.championGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async championListRaw(
        requestParameters: ChampionListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<Champion>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["bot_lane_proportion_competitive"] != null) {
            queryParameters["bot_lane_proportion_competitive"] =
                requestParameters["bot_lane_proportion_competitive"];
        }

        if (requestParameters["bot_lane_proportion_soloq"] != null) {
            queryParameters["bot_lane_proportion_soloq"] =
                requestParameters["bot_lane_proportion_soloq"];
        }

        if (requestParameters["damage_type"] != null) {
            queryParameters["damage_type"] = requestParameters["damage_type"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["jungle_proportion_competitive"] != null) {
            queryParameters["jungle_proportion_competitive"] =
                requestParameters["jungle_proportion_competitive"];
        }

        if (requestParameters["jungle_proportion_soloq"] != null) {
            queryParameters["jungle_proportion_soloq"] =
                requestParameters["jungle_proportion_soloq"];
        }

        if (requestParameters["main_role"] != null) {
            queryParameters["main_role"] = requestParameters["main_role"];
        }

        if (requestParameters["mid_lane_proportion_competitive"] != null) {
            queryParameters["mid_lane_proportion_competitive"] =
                requestParameters["mid_lane_proportion_competitive"];
        }

        if (requestParameters["mid_lane_proportion_soloq"] != null) {
            queryParameters["mid_lane_proportion_soloq"] =
                requestParameters["mid_lane_proportion_soloq"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["secondary_role"] != null) {
            queryParameters["secondary_role"] =
                requestParameters["secondary_role"];
        }

        if (requestParameters["top_lane_proportion_competitive"] != null) {
            queryParameters["top_lane_proportion_competitive"] =
                requestParameters["top_lane_proportion_competitive"];
        }

        if (requestParameters["top_lane_proportion_soloq"] != null) {
            queryParameters["top_lane_proportion_soloq"] =
                requestParameters["top_lane_proportion_soloq"];
        }

        if (requestParameters["utility_proportion_competitive"] != null) {
            queryParameters["utility_proportion_competitive"] =
                requestParameters["utility_proportion_competitive"];
        }

        if (requestParameters["utility_proportion_soloq"] != null) {
            queryParameters["utility_proportion_soloq"] =
                requestParameters["utility_proportion_soloq"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Champion/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: ChampionListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<Champion>> {
        const response = await this.championListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async championVariableDistributionRaw(
        requestParameters: ChampionVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling championVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling championVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling championVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling championVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bot_lane_proportion_competitive"] != null) {
            queryParameters["bot_lane_proportion_competitive"] =
                requestParameters["bot_lane_proportion_competitive"];
        }

        if (requestParameters["bot_lane_proportion_soloq"] != null) {
            queryParameters["bot_lane_proportion_soloq"] =
                requestParameters["bot_lane_proportion_soloq"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["damage_type"] != null) {
            queryParameters["damage_type"] = requestParameters["damage_type"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["jungle_proportion_competitive"] != null) {
            queryParameters["jungle_proportion_competitive"] =
                requestParameters["jungle_proportion_competitive"];
        }

        if (requestParameters["jungle_proportion_soloq"] != null) {
            queryParameters["jungle_proportion_soloq"] =
                requestParameters["jungle_proportion_soloq"];
        }

        if (requestParameters["main_role"] != null) {
            queryParameters["main_role"] = requestParameters["main_role"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["mid_lane_proportion_competitive"] != null) {
            queryParameters["mid_lane_proportion_competitive"] =
                requestParameters["mid_lane_proportion_competitive"];
        }

        if (requestParameters["mid_lane_proportion_soloq"] != null) {
            queryParameters["mid_lane_proportion_soloq"] =
                requestParameters["mid_lane_proportion_soloq"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["secondary_role"] != null) {
            queryParameters["secondary_role"] =
                requestParameters["secondary_role"];
        }

        if (requestParameters["top_lane_proportion_competitive"] != null) {
            queryParameters["top_lane_proportion_competitive"] =
                requestParameters["top_lane_proportion_competitive"];
        }

        if (requestParameters["top_lane_proportion_soloq"] != null) {
            queryParameters["top_lane_proportion_soloq"] =
                requestParameters["top_lane_proportion_soloq"];
        }

        if (requestParameters["utility_proportion_competitive"] != null) {
            queryParameters["utility_proportion_competitive"] =
                requestParameters["utility_proportion_competitive"];
        }

        if (requestParameters["utility_proportion_soloq"] != null) {
            queryParameters["utility_proportion_soloq"] =
                requestParameters["utility_proportion_soloq"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Champion/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: ChampionVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.championVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
