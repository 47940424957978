import {CompetitiveGameSummaries, MapState, PlayerState, ScrimGameSummaries} from "../../farsight-api";
import React, {useEffect, useRef, useState} from "react";
import {useThemeContext} from "../../themeContext";
import Box from "@mui/material/Box";
import {ImageOverlay, MapContainer} from "react-leaflet";
import {CRS} from "leaflet";
import WardPlacedMarker from "./map_icons/ward_placed";
import {NeonCard} from "../../NeonComponents/NeonCards";
import {CircularProgress, Stack} from "@mui/material";
import ToPngButton from "../Buttons/ToPngButton";
import SmallDetailButton from "../Buttons/SmallDetailsButton";
import {ArrowDropDown} from "@mui/icons-material";
import {scrollable} from "../../theme";
import PlayerMarker from "./MapStateMarkers/PlayerMarker";
import WardMarker from "./MapStateMarkers/WardMarker";
import ObjectiveMarker, {DragonMarker, RiftHeraldMarker, VoidGrubsMarker} from "./MapStateMarkers/ObjectivesMarker";
import {RiftHeraldIcon, VoidGrubsIcon} from "../Icons/SvgIcons";
import {InhibitorMarker, TurretMarker} from "./MapStateMarkers/TurretMarker";


export default function RenderMapState(props: {
    map_states: MapState[],
    playerSummaries: CompetitiveGameSummaries[]|ScrimGameSummaries[],
    timestamp: number,
}){
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState(null as number | null);
    const theme = useThemeContext();

    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.contentRect) {
                        const size = Math.floor(Math.min(entry.contentRect.width, entry.contentRect.height));
                        setContainerSize(size+1);
                    }
                }
            });
            resizeObserver.observe(containerRef.current);
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, []);

    const map_url = `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${containerSize},w_${containerSize}/leagueAssets/SummonersRift_awfeps.png`;
    const map_state = props.map_states[props.timestamp];

    return (
        <Stack direction={'column'} sx={{height:'100%', width:'100%', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',}}>
            <Box
                ref={containerRef}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {containerSize ? <MapContainer
                center={[containerSize / 2, containerSize / 2]}
                scrollWheelZoom={false}
                zoom={0}
                dragging={false}
                minZoom={0}
                maxZoom={0}
                attributionControl={false}
                zoomControl={false}
                doubleClickZoom={false}
                crs={CRS.Simple}
                bounds={[
                    [0, 0],
                    [containerSize, containerSize],
                ]}
            >
                <ImageOverlay
                    url={map_url}
                    bounds={[
                        [0, 0],
                        [containerSize, containerSize],
                    ]}
                >
                    {map_state?.buildings_up_blue.map(
                        building_id => building_id.includes('TOWER') ? <TurretMarker turret_id={building_id} containerSize={containerSize} side={'Blue'}/> : <InhibitorMarker inhibitor_id={building_id} containerSize={containerSize} side={'Blue'}/>
                    )}
                    {map_state?.buildings_up_red.map(
                        building_id => building_id.includes('TOWER') ? <TurretMarker turret_id={building_id} containerSize={containerSize} side={'Red'}/> : <InhibitorMarker inhibitor_id={building_id} containerSize={containerSize} side={'Red'}/>
                    )}
                    {
                        props.playerSummaries.map(summary => {
                            return map_state?.players_dead.includes(summary.participant_id as number) ? null : <PlayerMarker display_sweeper summary={summary} containerSize={containerSize} timestamp={props.timestamp}/>
                        })
                    }
                    {map_state?.wards_up_blue.map(w => <WardMarker event={w} containerSize={containerSize}/>)}
                    {map_state?.wards_up_red.map(w => <WardMarker event={w} containerSize={containerSize}/>)}
                    {map_state?.grubs_spawning_in >= 0 && props.timestamp <= 14*60 && map_state.blue_grubs + map_state.red_grubs < 6? <VoidGrubsMarker cooldown={map_state?.grubs_spawning_in} containerSize={containerSize}/> : null}
                    {map_state?.herald_spawning_in !== undefined && map_state?.herald_spawning_in >= 0 && (props.timestamp >= 14*60 || map_state.blue_grubs + map_state.red_grubs >= 6) && props.timestamp < 25*60 ? <RiftHeraldMarker cooldown={map_state?.herald_spawning_in} containerSize={containerSize}/> : null}
                    {map_state?.dragon_spawning_in !== undefined && map_state?.dragon_spawning_in >= 0 ? <DragonMarker cooldown={map_state?.dragon_spawning_in} containerSize={containerSize} sub_type={map_state?.dragon_type}/> : null}
                </ImageOverlay>
                </MapContainer> : <></> }
        </Box>
            <Stack direction={'row'}>
                    <ToPngButton elementReference={containerRef}/>
                    <SmallDetailButton content={
                        <Box sx={{ maxWidth: 300, whiteSpace: "pre-wrap", fontSize: "0.7rem", maxHeight:'70vh', ...scrollable }}>
                        {JSON.stringify(map_state, null, 2)}
                    </Box>}>
                        <ArrowDropDown/>
                    </SmallDetailButton>
                </Stack>
        </Stack>
    )
}