import { Popup } from "react-leaflet";
import { styled } from "@mui/material/styles";

const StyledPopup = styled(Popup)(({ theme }) => ({
  "& .leaflet-popup-content-wrapper": {
    // backgroundColor: theme.palette.background.paper, // Matches MUI Paper
    background: theme.palette.background.paper, // Matches MUI Paper
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    padding: 0,
    fontFamily: theme.typography.fontFamily,
  },

  "& .leaflet-popup-content": {
    background: theme.palette.background.paper, // Matches MUI Paper
    padding: 0, // Remove default Leaflet margin
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  "& .leaflet-popup-tip-container": {
    visibility: "hidden", // Hide popup tip
  },
}));

export default StyledPopup;
