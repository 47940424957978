import {Stack, styled, TabProps} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";
import {getNeonColor} from "../theme";
import TeamIcon from "../components/Icons/TeamIcon";


export const NeonTabs = styled(Tabs)( ({ theme }) => ({
        "& .MuiTab-root": {
          color: getNeonColor(theme), // Default tab color
          transition: "all 0.3s ease-in-out",
          fontWeight: "bold",
            display: "flex",
    flexDirection: "row", // Ensure horizontal layout
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
    textTransform: "none",
    minHeight: "50px", // Match parent height to prevent misalignment
    padding: "0px 16px", // Adjust padding to reduce excess spacing
    gap: "8px", // Space between icon and text
        },
        "& .MuiTab-root.Mui-selected": {
          color: getNeonColor(theme), // Neon color for selected tab
          // textShadow: "0px 0px 8px #0ff, 0px 0px 16px #0ff", // Neon glow effect
        },
        "& .MuiTabs-indicator": {
          backgroundColor: `${getNeonColor(theme)} !important`, // Neon indicator color
          boxShadow: "0px 0px 10px #fff, 0px 0px 20px #fff", // Glow effect
        },
        height:'50px'
}));

export const CLOUDINARY_BASE_URL = "https://res.cloudinary.com/xenesis/image/upload/";
export const CLOUDINARY_RESIZE_PARAMS = "w_25,h_25,c_fill";

export interface NeonTabProps extends TabProps {
  label: string;
  asset?: string | null;
  team_logo?: string;
}

// ✅ Remove forwardRef - Not needed!
export function NeonTab({ label, asset, team_logo, ...props }: NeonTabProps) {
  return (
    <Tab
      label={label.toUpperCase()}
      sx={{ textTransform: "uppercase" }}
      icon={
        asset ? (
          <img
            src={`${CLOUDINARY_BASE_URL}${CLOUDINARY_RESIZE_PARAMS}/v1/leagueAssets/${asset}.png`}
            alt={label}
            style={{
              margin: 0,
              padding: 0,
              verticalAlign: "middle",
              display: "block",
            }}
          />
        ) : team_logo ? <TeamIcon team_name={team_logo} sx={{width:'25px', height:'25px'}}/> : undefined
      }
      iconPosition="start"
      {...props} // ✅ Ensure all props (including `value`) are passed properly
    />
  );
}