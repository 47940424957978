import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Avatar,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useReferentialContext } from "../../Context";
import ChampionIcon from "../Icons/ChampionIcon";
import { SingleChampionSelect } from "../selectors/Shared/ChampionSelect";
import { Champion, PriorityPyramid } from "../../farsight-api";
import PyramidSelector from "../selectors/DraftPrep/PyramidSelector";

const tiers = ["S", "A", "B", "C"] as const;
type Tier = typeof tiers[number];

const roles = ["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY", "FLEX"] as const;
type Role = typeof roles[number];

const roleIcons: Record<Exclude<Role, "FLEX">, string> = {
  TOP_LANE: "TOP_LANE",
  JUNGLE: "JUNGLE",
  MID_LANE: "MID_LANE",
  BOT_LANE: "BOT_LANE",
  UTILITY: "UTILITY",
};

const tierColors: Record<Tier, string> = {
  S: "#FF5959",
  A: "#FF7F50",
  B: "#6495ED",
  C: "#1E90FF",
};

const TierListMaker: React.FC = () => {
  const { championsReferential, farsightApi } = useReferentialContext();
  const [tierList, setTierList] = useState<Record<Tier, Record<Role, Champion[]>>>(() => {
    const initial = {} as Record<Tier, Record<Role, Champion[]>>;
    for (const tier of tiers) {
      initial[tier] = { TOP_LANE: [], JUNGLE: [], MID_LANE: [], BOT_LANE: [], UTILITY: [], FLEX: [] };
    }
    return initial;
  });

  const [selectedPyramid, setSelectedPyramid] = useState<PriorityPyramid | null>(null);
  const [draggedItem, setDraggedItem] = useState<{ championId: string; sourceTier: Tier; sourceRole: Role } | null>(null);
  const [championSelectValues, setChampionSelectValues] = useState<Record<Tier, Champion | null>>({ S: null, A: null, B: null, C: null });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (selectedPyramid) {
      loadPyramid(selectedPyramid);
    }
  }, [selectedPyramid]);

  const loadPyramid = async (pyramid: PriorityPyramid) => {
    const initial = {} as Record<Tier, Record<Role, Champion[]>>;
    for (const tier of tiers) {
      initial[tier] = { TOP_LANE: [], JUNGLE: [], MID_LANE: [], BOT_LANE: [], UTILITY: [], FLEX: [] };
    }
    try {
      const items = await farsightApi.priorityPyramidItem.list({ pyramid: pyramid.id });
      for (const item of items) {
        const champ = championsReferential.find(c => c.id === item.champion);
        if (champ && tiers.includes(item.tier as Tier) && roles.includes(item.role as Role)) {
          initial[item.tier as Tier][item.role as Role].push(champ);
        }
      }
      setTierList(initial);
    } catch {
      setError("Failed to load pyramid data");
    }
  };

  const handleAddChampion = async (champion: Champion, tier: Tier) => {
    const validRole = roles.includes(champion.main_role as Role);
    const role: Role = validRole ? (champion.main_role as Role) : "FLEX";

    setTierList((prev) => {
      const updated = { ...prev };
      for (const t of tiers) {
        for (const r of roles) {
          updated[t][r] = updated[t][r].filter((c) => c.id !== champion.id);
        }
      }
      updated[tier][role].push(champion);
      return updated;
    });

    setChampionSelectValues((prev) => ({ ...prev, [tier]: null }));

    try {
      if (selectedPyramid) {
        await farsightApi.priorityPyramidItem.create({
          id: "new",
          priority_pyramid_item: {
            champion: champion.id,
            tier,
            role,
            pyramid: selectedPyramid.id
          }
        });
      }
    } catch {
      setError("Error saving champion to pyramid");
    }
  };

  const handleRemoveChampion = async (championId: string, tier: Tier, role: Role) => {
    setTierList((prev) => ({
      ...prev,
      [tier]: {
        ...prev[tier],
        [role]: prev[tier][role].filter((c) => c.id !== championId),
      },
    }));

    try {
      const items = await farsightApi.priorityPyramidItem.list({
        champion: championId,
        tier,
        role,
        pyramid: selectedPyramid?.id,
      });
      for (const item of items) {
        await farsightApi.priorityPyramidItem.delete({ id: item.id.toString() });
      }
    } catch {
      setError("Error deleting champion from pyramid");
    }
  };

  const handleDrop = async (targetTier: Tier, targetRole: Role) => {
    if (!draggedItem || !selectedPyramid) return;
    const champ = championsReferential.find((c) => c.id === draggedItem.championId);
    if (!champ) return;

    setTierList((prev) => {
      const updated = { ...prev };
      for (const r of roles) {
        updated[draggedItem.sourceTier][r] = updated[draggedItem.sourceTier][r].filter(
          (c) => c.id !== champ.id
        );
      }
      updated[targetTier][targetRole].push(champ);
      return updated;
    });

    try {
      const existingItems = await farsightApi.priorityPyramidItem.list({
        champion: champ.id,
        pyramid: selectedPyramid.id,
      });

      for (const item of existingItems) {
        await farsightApi.priorityPyramidItem.patch({
          id: item.id.toString(),
          patched_priority_pyramid_item: {
            tier: targetTier,
            role: targetRole,
          },
        });
      }
    } catch {
      setError("Failed to update champion role/tier");
    }

    setDraggedItem(null);
  };

  return (
    <Box p={1}>
      <PyramidSelector pyramid={selectedPyramid} setPyramid={setSelectedPyramid} />
      {!selectedPyramid ? (
        <Typography variant="h6" mt={4} textAlign="center">
          Please create or select a pyramid to start editing your tier list.
        </Typography>
      ) : (
        <>
          <Paper elevation={3} sx={{ mb: 0 }}>
            <Grid container p={1} alignItems="center" columns={7}>
              <Grid item sx={{ width: 270 }} />
              {roles.map((role) => (
                <Grid item xs key={role}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" gap={0.5} flexWrap="wrap" justifyContent="center">
                      {role === "FLEX"
                        ? Object.values(roleIcons).map((r) => (
                            <Avatar
                              key={r}
                              src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_23,w_23/v1/leagueAssets/${r}.png`}
                              sx={{ width: 20, height: 20 }}
                            />
                          ))
                        : (
                            <Avatar
                              src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_23,w_23/v1/leagueAssets/${role}.png`}
                              sx={{ width: 20, height: 20 }}
                            />
                          )}
                    </Box>
                    <Typography variant="body2" fontWeight={500} textAlign="center">
                      {role.replace("_", " ").toUpperCase()}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>

          {tiers.map((tier) => (
            <Grid container key={tier} columns={7} alignItems="stretch" sx={{ backgroundColor: "#2e2e2e", borderRadius: 1 }}>
              <Grid item sx={{ width: 270, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box
                  textAlign="center"
                  sx={{
                    backgroundColor: tierColors[tier],
                    p: 1,
                    width: "100%",
                  }}
                >
                  <Typography variant="h6">Tier {tier}</Typography>
                  <SingleChampionSelect
                    value={championSelectValues[tier]}
                    updateValue={(champion) => champion && handleAddChampion(champion, tier)}
                    label={`Select champion for tier ${tier}`}
                  />
                </Box>
              </Grid>
              {roles.map((role) => (
                <Grid item xs key={role}>
                  <Box
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={() => handleDrop(tier, role)}
                    sx={{
                      height: "100%",
                      minHeight: 70,
                      border: "1px solid #444",
                      backgroundColor: "#1e1e1e",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {tierList[tier][role].map((champion) => (
                      <Box
                        key={champion.id}
                        draggable
                        onDragStart={() =>
                          setDraggedItem({
                            championId: champion.id,
                            sourceTier: tier,
                            sourceRole: role,
                          })
                        }
                        sx={{ position: "relative", display: "inline-block" }}
                      >
                        <ChampionIcon champion={champion.id} role={champion.main_role as string} disable_link />
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveChampion(champion.id, tier, role)}
                          sx={{
                            position: "absolute",
                            top: -6,
                            right: -6,
                            backgroundColor: "transparent",
                            zIndex: 1,
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          ))}
        </>
      )}

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TierListMaker;